import { request } from '../request'

/**
 * 获取当前用户信息
 */
export async function getUserInfo() {
  return request({
    url: '/api/v1/userInfo',
    method: 'POST',
  })
}
