/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-23 10:35:03
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2023-01-17 15:37:08
 * @FilePath: \pod-app-react\src\components\MineFiles\index.jsx
 * @Description: 公共文件列表组件（提供给MineFile使用）
 */
import { useState, useEffect, useCallback } from 'react'
import { useGetState } from 'ahooks'
import { Card, TextField, Icon, DropZone, Toast } from '@shopify/polaris'
import { SearchMinor, DeleteMajor } from '@shopify/polaris-icons'
import _ from 'lodash'
// 图片gallery
import { Gallery } from 'react-grid-gallery'
// 搜索loading
import LoadingSearch from '@/components/LoadingSearch'
// 引入api
import { getGalleryList, delFileUpload, uploadPOD, uploadOSS } from '@/api/settings'
import { addHistory } from '@/api/images'
// 引入公共方法
import {
  sizeFilter,
  setOssImageUrl,
  getClient,
  putObject,
  handleUploadValidate,
  ossSignatureUrl,
  getImageFileWidthAndHeight,
} from '@/utils/utils'
// 引入样式
import './index.scss'

/**
 * 参数说明
 * @param {Function} onConfirm 确认点击图片
 * @param {Function} onClose // 关闭弹窗
 */

const MineFiles = ({ onConfirm, onClose }) => {
  const [loadingPage, setLoadingPage] = useState(false)
  const [fileList, setFileList] = useState([])
  const [seachText, setSeachText, getSeachText] = useGetState('')

  // Toast 文本
  const [isShowToast, setIsShowToast] = useState(false)
  const [toastContent, setToastContent] = useState('')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setLoadingPage(true) // 开启 loadingpage
    getFileList()
  }, [])

  // 获取文件列表数据源
  const getFileList = _.debounce(async () => {
    const res = await getGalleryList({
      name: getSeachText(),
      pageNum: 1,
      perPage: 9999,
    })

    if (res) {
      const imgList = _.map(res.dataList, (item, index) => {
        if (item.name) {
          item.src = ossSignatureUrl(item.name)
          item.name = _.slice(
            item.name,
            item.name.lastIndexOf('/myfile/') + 8,
            item.name.length
          ).join('')
          item.width = _.toNumber(item.wide)
          item.height = _.toNumber(item.long)
        }
        return item
      })

      // 上传图片组件
      imgList.unshift({
        src: 'upload-image',
        width: 300,
        height: 200,
      })
      setFileList(imgList)
    } else {
      setFileList([
        {
          src: 'upload-image',
          width: 300,
          height: 200,
        },
      ])
    }
    setLoadingPage(false) // 关闭 loadingpage
  }, 300)

  // 点击时加入历史
  const handleAddHistory = async (item) => {
    await addHistory({
      id: item.id,
      type: 2, // 1：素材 2：用户图片
    })
  }

  // 点击图片
  const handleClick = (item) => {
    if (_.isFunction(onConfirm)) {
      handleAddHistory(item)
      onConfirm(item.src)
    }
    if (_.isFunction(onClose)) {
      onClose()
    }
  }

  // 搜索标题
  const handleSearchChange = useCallback(async (value) => {
    setLoadingPage(true)
    await setSeachText(value)
    getFileList()
  }, [])

  // 移除name文本value
  const handleClearButtonClick = useCallback(async () => {
    setLoadingPage(true)
    await setSeachText('')
    getFileList()
  }, [])

  // 移除图片
  const handleDelFile = async (e, file) => {
    e.stopPropagation()
    console.log('file', file)
    try {
      await delFileUpload({ id: [file.id] })
      const files = _.filter([...fileList], (item) => {
        return item.id !== file.id
      })
      setFileList(files)
      setIsShowToast(true)
      setToastContent('Image removed')
    } catch (error) {}
  }

  // 点击上传
  const handleDrop = async (files) => {
    console.log('_droppedFiles', files)
    if (files && files.length <= 5) {
      const res = await uploadOSS()
      const client = getClient(res)
      const imageList = []
      const requestList = []

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const size = sizeFilter(file.size) // 文件大小
        const errorMsg = handleUploadValidate(size, file.name)
        // 获取图片的尺寸（宽和高）
        const fileSize = await getImageFileWidthAndHeight(file)
        if (errorMsg) {
          setIsShowToast(true)
          setIsError(true)
          setToastContent(errorMsg)
          return false
        } else {
          const name = setOssImageUrl({
            module: 'myfile',
            type: file.type,
            fileName: file.name,
          })
          imageList.push({
            name,
            size: file.size,
            wide: fileSize.width,
            long: fileSize.height,
          })
          requestList.push(putObject(name, file, client))
        }
      }

      await uploadPOD({ data: imageList })
      Promise.all(requestList).then(() => {
        getFileList()
      })
    } else {
      setIsShowToast(true)
      setIsError(true)
      setToastContent('Only 5 pictures can be uploaded at one time')
    }
  }

  // 自定义图像组件
  const ImageComponent = (props) => {
    console.log('props', props)
    // 上传图像
    if (props.item.src === 'upload-image') {
      return (
        <div
          className="upload-image"
          style={{ width: '100%', height: '100%' }}
        >
          <DropZone
            accept="image/*"
            type="image"
            onDrop={handleDrop}
          >
            <DropZone.FileUpload />
          </DropZone>
        </div>
      )
    }
    return (
      <>
        <div
          className="mineFileDel"
          onClick={(e) => handleDelFile(e, props.item)}
        >
          <Icon
            source={DeleteMajor}
            color="base"
          />
        </div>
        <img
          {...props.imageProps}
          onClick={() => handleClick(props.item)}
        />
        <div
          className="Image-Title"
          title={props.item.name}
        >
          {props.item.name}
        </div>
      </>
    )
  }

  // 数据渲染
  const myFileData = (
    <div id="mineFileContainer">
      <div className="Flex-Image">
        <Gallery
          images={fileList}
          rowHeight={180}
          defaultContainerWidth={900}
          enableImageSelection={false}
          margin={6}
          thumbnailImageComponent={ImageComponent}
        />
      </div>
    </div>
  )

  // 主内容
  const pageContent = (
    <Card>
      <Card.Section>
        <div
          className="row"
          style={{ marginBottom: '20px' }}
        >
          <div className="col-12">
            <TextField
              id="searchProduct"
              value={seachText}
              onChange={handleSearchChange}
              placeholder="Search"
              prefix={<Icon source={SearchMinor} />}
              clearButton
              onClearButtonClick={handleClearButtonClick}
            />
          </div>
        </div>
        {/* 搜索loading */}
        {loadingPage && <LoadingSearch size="large" />}
        {/* 图片区域 */}
        {!loadingPage && <>{myFileData}</>}
      </Card.Section>
    </Card>
  )

  const toggleShowToast = () => {
    setIsShowToast(!isShowToast)
  }

  // Toast提示
  const toastMarkup = isShowToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleShowToast}
      error={isError}
    />
  ) : null

  return (
    <>
      {pageContent}
      {toastMarkup}
    </>
  )
}

export default MineFiles
