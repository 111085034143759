import { useEffect } from 'react'
import { Modal, TextContainer } from '@shopify/polaris'
import _ from 'lodash'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {String} content 弹窗内容
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const LeavePage = ({ isShow, content, onClose, onConfirm }) => {
  useEffect(() => {}, [isShow])

  // 关闭弹窗
  const closeModal = () => {
    onClose()
  }

  // 点击确认
  const handleConfirm = () => {
    onConfirm()
    closeModal()
  }

  return (
    <div>
      <Modal
        open={isShow}
        onClose={closeModal}
        title="Leave page with unsaved changes"
        primaryAction={{
          content: 'Leave page',
          onAction: handleConfirm,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Stay',
            onAction: onClose,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            {content || 'Leaving this page will delete all unsaved changes'}
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  )
}
export default LeavePage
