/*
 * @Description: 自定义按钮
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-03 18:13:48
 * @LastEditTime: 2023-02-06 10:41:25
 * @LastEditors: huang-chao hc18174419502@163.com
 * @FilePath: \pod-app-react\src\components\Buttons\index.jsx
 */
import { useEffect, useMemo } from 'react'
import { Spinner } from '@shopify/polaris'
import './index.scss'

/**
 * Paypal支付按钮
 * @param {Boolean} loading 是否处于loading状态
 * @param {Boolean} active 是否处于active状态
 * @param {Boolean} primary 是否为常用样式
 * @param {Function} onClick 点击事件
 */
const Paypal = ({ loading, active, primary, onClick }) => {
  // 点击事件
  const handleClick = () => {
    if (loading) return
    onClick()
  }

  const className = useMemo(() => {
    if (active) {
      return 'Paypal-Button' + ' ' + 'Paypal-Button-active'
    } else if (primary) {
      return 'Paypal-Button' + ' ' + 'Paypal-Button-primary'
    }
    return 'Paypal-Button' + ' ' + 'Paypal-Button-default'
  }, [active, primary])

  return (
    <div
      className={className}
      onClick={handleClick}
      style={{ cursor: loading ? 'default' : 'pointer' }}
    >
      {loading && (
        <Spinner
          accessibilityLabel="Spinner example"
          size="small"
        />
      )}
      <img
        src="/images/icon/paypal.svg"
        alt="paypal"
      />
    </div>
  )
}

/**
 * 钱包支付按钮
 * @param {Boolean} active 是否处于active状态
 * @param {Boolean} disabled 是否可点击
 * @param {Function} onClick 点击事件
 */
const Wallet = ({ active, disabled, onClick }) => {
  const className = useMemo(() => {
    if (disabled) {
      return 'Wallet-Button' + ' ' + 'Wallet-Button-disabled'
    }
    if (active) {
      return 'Wallet-Button' + ' ' + 'Wallet-Button-active'
    }
    return 'Wallet-Button' + ' ' + 'Wallet-Button-default'
  }, [active])

  // 点击事件
  const handleClick = () => {
    if (disabled) return
    onClick()
  }

  return (
    <div
      className={className}
      onClick={handleClick}
    >
      <span>Wallet</span>
    </div>
  )
}

export { Paypal, Wallet }
