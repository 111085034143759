/*
 * @Description: 首页
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-12-27 15:40:30
 * @LastEditTime: 2023-02-13 10:42:41
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Home\index.jsx
 */
import { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useGetState } from 'ahooks'
import { Page, Card, Icon, TextField, Button, DropZone, Toast } from '@shopify/polaris'
import {
  AddProductMajor,
  ProductsMajor,
  FirstOrderMajor,
  BalanceMajor,
  NotificationMajor,
  ChatMajor,
  AttachmentMajor,
  QuestionMarkMajor,
  CircleDotsMajor,
  CancelSmallMinor,
} from '@shopify/polaris-icons'
// 纵向tab组件
import TabsVertical from '@/components/TabsVertical'
// 折叠面板
import CollapsibleItem from './components/CollapsibleItem'
import _ from 'lodash'
import axios from 'axios'
import { getGuid } from '@/utils/utils'
import { addOpinion } from '@/api/home'
import { uploadOSS } from '@/api/settings'
import { setOssImageUrl, getClient, putObject } from '@/utils/utils'
import './index.scss'

const Home = () => {
  const router = useHistory()
  // 当前tab
  const [selected, setSelected] = useState(0)
  // 输入框文本内容
  const [inputText, setInputText] = useState('')
  // 保存loading
  const [loadingSave, setLoadingSave] = useState(false)

  // 上传附件
  const [openFileDialog, setOpenFileDialog] = useState(false)
  const [files, setFiles, getFiles] = useGetState([])

  // Toast提示
  const [active, setActive] = useState(false)
  const [error, setError] = useState(false)
  const [toastContent, setToastContent] = useState('')
  const toggleActive = useCallback(() => setActive((active) => !active), [])
  const toastMarkup = active ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActive}
      error={error}
    />
  ) : null

  const tabs = [
    { id: 0, name: 'Create a product', icon: AddProductMajor },
    { id: 1, name: 'Publish product', icon: ProductsMajor },
    { id: 2, name: 'Create an order', icon: FirstOrderMajor },
    { id: 3, name: 'Manage wallet', icon: BalanceMajor },
    { id: 4, name: 'Set up notification', icon: NotificationMajor },
  ]

  const [collapsList, setCollapsList] = useState([])

  useEffect(() => {
    getData()
  }, [])

  // 获取QA
  const getData = async () => {
    const res = await axios.get('/static/questions.json')
    if (res && res.status === 200) {
      const list = _.map(res.data, (item, index) => {
        return {
          id: index,
          icon: item.icon === 'QuestionMarkMajor' ? QuestionMarkMajor : CircleDotsMajor,
          open: false,
          question: item.q,
          answers: item.a,
        }
      })
      setCollapsList(list)
    }
  }

  // 切换tab
  const handleSelect = (index) => {
    setSelected(index)
  }

  // 输入文本
  const handleChange = useCallback((newValue) => setInputText(newValue), [])

  // 展开/收缩
  const handleToggle = (item) => {
    const list = [...collapsList]
    _.forEach(list, (o) => {
      if (o.id === item.id) {
        o.open = !o.open
      } else {
        o.open = false
      }
    })
    setCollapsList(list)
  }

  // 打开上传附件弹窗
  const toggleOpenFileDialog = useCallback(() => {
    if (getFiles().length >= 5) {
      return
    }
    setOpenFileDialog((openFileDialog) => !openFileDialog)
  }, [])

  // 上传附件校验
  const handleValidator = useCallback((file) => {
    // max的单位为m
    const typeList = [
      { type: 'image/jpeg', max: 10 },
      { type: 'image/jpg', max: 10 },
      { type: 'image/png', max: 10 },
      { type: 'image/gif', max: 10 },
      { type: 'video/mp4', max: 100 },
      { type: 'video/avi', max: 100 },
      { type: 'video/mov', max: 100 },
    ]
    const item = _.find(typeList, (item) => {
      return item.type === file.type
    })
    if (item) {
      if (file.size / 1024000 > item.max) {
        return false
      } else {
        return true
      }
    }
    return false
  }, [])

  // 上传附件
  const handleDropZoneDrop = useCallback((dropFiles, _acceptedFiles, _rejectedFiles) => {
    // console.log('未通过验证的文件', _rejectedFiles)
    if (dropFiles && dropFiles.length > 5) {
      setActive(true)
      setToastContent('Only 5 attachments can be uploaded at a time')
      return
    }
    if (!_.isEmpty(_rejectedFiles)) {
      setActive(true)
      setToastContent(
        'Please upload pictures in jpg, jpeg, png, gif format or videos in mp4, avi, mov format, the maximum size of the picture is 10m, and the maximum size of the video is 100m'
      )
      return
    }
    if (getFiles().length + dropFiles.length > 5) {
      setActive(true)
      setToastContent('Upload up to 5 attachments')
      return
    }
    _.forEach(_acceptedFiles, (item) => {
      item.id = getGuid()
      if (item.type.indexOf('video') !== -1) {
        item.typeName = 'VID'
      } else {
        item.typeName = 'IMG'
      }
    })
    setFiles((files) => [...files, ..._acceptedFiles])
  }, [])

  // 获取文件显示名称
  const getFileName = (file) => {
    const list = _.filter([...files], (item) => {
      return item.typeName === file.typeName
    })
    const index = _.findIndex(list, (item) => {
      return item.id === file.id
    })
    return file.typeName + ' ' + (index + 1)
  }

  // 删除附件
  const handleDelete = (file) => {
    const list = _.filter(getFiles(), (item) => {
      return item.id !== file.id
    })
    setFiles(list)
    setActive(true)
    setToastContent('Attachment removed')
  }

  // 保存
  const handleSave = async () => {
    setLoadingSave(true)
    const fileList = getFiles()
    const requestList = []
    const list = []

    // 如果存在附件
    if (!_.isEmpty(fileList)) {
      const res = await uploadOSS()
      const client = getClient(res)

      _.forEach(fileList, (file) => {
        const fileName = setOssImageUrl({
          module: 'home',
          type: file.type,
          fileName: file.name,
        })
        list.push(fileName)
        requestList.push(putObject(fileName, file, client))
      })
    }

    const params = {
      remark: inputText,
      url: list,
    }

    try {
      await addOpinion(params)
      await Promise.all(requestList)

      // 清空输入框和附件
      setInputText('')
      setFiles([])

      // 成功提示
      setActive(true)
      setToastContent("Thank you! We've heard your feedback. We might email you these days")
    } catch (e) {
      const msg = e.data && e.data.message ? e.data.message : 'Server Error'
      setActive(true)
      setError(true)
      setToastContent(msg)
    }

    setTimeout(() => {
      setLoadingSave(false)
    }, 300)
  }

  return (
    <Page fullWidth>
      <div className="Home-Title">
        <img
          src="./images/home-image.png"
          alt=""
        />
        <span>Welcome to the POD world, start your creative journey.</span>
      </div>
      <div className="Home-Body row">
        <div className="Home-Tabs col-8">
          <Card>
            <Card.Section>
              <span style={{ fontWeight: 500, fontSize: '16px' }}>Setup guide</span>
            </Card.Section>
            <Card.Section flush>
              <TabsVertical
                selected={selected}
                onSelect={handleSelect}
              >
                <div key="Tabs">
                  {_.map(tabs, (tab, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          handleSelect(index)
                        }}
                      >
                        <div style={{ display: 'inline-block', marginRight: '12px' }}>
                          <Icon
                            source={tab.icon}
                            color={selected === index ? 'primary' : 'base'}
                          />
                        </div>
                        <span style={{ fontWeight: 500 }}>{tab.name}</span>
                      </li>
                    )
                  })}
                </div>
                <div key="Panel">
                  <div
                    key={0}
                    className="Tab-Panel"
                  >
                    <h3>Add your first product</h3>
                    <p>
                      1. <b>Browse</b> products, in the Catalog
                      <br />
                      2. <b>Design</b> products, show your art-Text Patterns & Image. Put them in
                      the Clothes Front and Back. Then choose variants.
                      <br />
                      3. <b>Edit</b> products, adjust the product information. Items are public. It
                      is recommended to adjust to keep your product unique. Don’t forget the Retial
                      price.
                      <br />
                      4. <b>Save</b> products, click to save the product.
                    </p>
                    <div className="Tas-btn">
                      <Button
                        primary
                        onClick={() => {
                          router.push('/catalog')
                        }}
                      >
                        View products
                      </Button>
                    </div>
                  </div>
                  <div
                    key={1}
                    className="Tab-Panel"
                  >
                    <h3>Publish product</h3>
                    <p>
                      Saved products are only saved in the APP. There are <b>Unpublished </b>
                      products
                    </p>
                    <p>
                      1. <b>Single publish</b>, click ‘there-point’ then ‘Publish’. Just publish the
                      product which one you choose to the Shopify online store.
                      <br />
                      2. <b>Bulk publish</b> products, choose multiple products then click ‘Set as
                      Active’, those products will be published to the Shopify Online Store. It will
                      be available for customers.
                      <br />
                    </p>
                    <div className="Tas-btn">
                      <Button
                        primary
                        onClick={() => {
                          router.push('/myProducts')
                        }}
                      >
                        Manage product
                      </Button>
                    </div>
                  </div>
                  <div
                    key={2}
                    className="Tab-Panel"
                  >
                    <h3>Create an order</h3>
                    {/* <p>
                      Before we officially start selling, we can create a <b>draft order</b> to
                      confirm the quality of clothing and printing
                    </p>
                    <p>
                      we can create a draft order, whether it is a Active or unpublished product.
                    </p> */}
                    <p>
                      We can create a draft order to confirm the quality of clothing and printing,
                      both for Active and unpublished products.
                    </p>
                    <p>
                      1. <b>Select a product</b>, then select a variant or many variants.
                      <br />
                      2. Fill in the <b>address for Shipping</b>.
                      <br />
                      3. Choose a <b>shipping method</b>.
                      <br />
                      4. <b>Pay out</b> the order.
                    </p>
                    <p>Then we can wait for the order to be delivered.</p>
                    <div className="Tas-btn">
                      <Button
                        primary
                        onClick={() => {
                          router.push('/myOrders')
                        }}
                      >
                        Manage order
                      </Button>
                    </div>
                  </div>
                  <div
                    key={3}
                    className="Tab-Panel"
                  >
                    <h3>Wallet</h3>
                    <p>
                      Recharge in advance, the wallet balance can be deducted when paying for the
                      order, and there is no need to call PayPal for payment.
                    </p>
                    <p>
                      1. Click the <b>Account(icon)</b> on the top right corner.
                      <br />
                      2. Click <b>Billing</b> in the drop-down options.
                      <br />
                      3. Click the <b>Recharge</b> button and charge.
                    </p>
                    <p>You can withdraw at any time without paying us any handling fees.</p>
                    <div className="Tas-btn">
                      <Button
                        primary
                        onClick={() => {
                          router.push('/setting/billing')
                        }}
                      >
                        View Billing
                      </Button>
                    </div>
                  </div>
                  <div
                    key={4}
                    className="Tab-Panel"
                  >
                    <h3>Order Notification</h3>
                    <p>
                      Set up order push reminders, and never miss any new orders. At the same time,
                      it can receive Order Delivery notifications and synchronize messages to buyers
                      in a timely manner.
                    </p>
                    <p>
                      Notifications can be displayed to you in the form of APP internal information
                      and emails.
                    </p>
                    <p>Tick to enable, uncheck to disable. Simple and fast. Push in real time.</p>
                    <div className="Tas-btn">
                      <Button
                        primary
                        onClick={() => {
                          router.push('/setting/notification')
                        }}
                      >
                        Set up
                      </Button>
                    </div>
                  </div>
                </div>
              </TabsVertical>
            </Card.Section>
          </Card>
        </div>

        <div className="col-4">
          <Card>
            <Card.Section>
              <p>
                About the use of APP. And any questions about products, or orders, please feel free
                to contact us.
              </p>
              <div
                className="Live-Chat"
                onClick={() => {
                  window.$crisp.push(['do', 'chat:open'])
                }}
              >
                <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
                  <Icon
                    source={ChatMajor}
                    color="base"
                  />
                </div>
                <span>Live chat</span>
              </div>
            </Card.Section>
            <Card.Section>
              <p style={{ marginBottom: '0.5rem' }}>
                We’d love to hear from you, maybe you want a new feature, or optimize, or you found
                bugs:
              </p>
              <TextField
                value={inputText}
                onChange={handleChange}
                multiline={4}
                maxLength={300}
                autoComplete="off"
                showCharacterCount
              />
              <div className="Upload">
                <DropZone
                  openFileDialog={openFileDialog}
                  onDrop={handleDropZoneDrop}
                  onFileDialogClose={toggleOpenFileDialog}
                  customValidator={handleValidator}
                  variableHeight={true}
                  outline={false}
                ></DropZone>
                <div className="Upload-Files">
                  <div
                    className="File-Add"
                    style={{
                      opacity: files.length >= 5 ? 0.3 : 1,
                      cursor: files.length >= 5 ? 'default' : 'pointer',
                    }}
                    onClick={toggleOpenFileDialog}
                  >
                    <Icon
                      source={AttachmentMajor}
                      color="base"
                    />
                  </div>
                  {_.map(files, (file, index) => {
                    return (
                      <div
                        key={index}
                        className="File-Box"
                      >
                        <span>{getFileName(file)}</span>
                        <div
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                          onClick={() => {
                            handleDelete(file)
                          }}
                        >
                          <Icon
                            source={CancelSmallMinor}
                            color="base"
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="Send-Message">
                <Button
                  primary
                  onClick={handleSave}
                  disabled={_.isEmpty(files) && _.isEmpty(inputText)}
                  loading={loadingSave}
                >
                  Give a feedback
                </Button>
              </div>
            </Card.Section>
          </Card>
        </div>
      </div>
      <div className="Home-Question-Answers">
        <Card>
          <Card.Section>
            <span style={{ fontWeight: 500, fontSize: '16px' }}>Q & A</span>
          </Card.Section>
          <Card.Section>
            <div className="row">
              <div className="col-6">
                {_.map(collapsList, (item, index) => {
                  return (
                    index % 2 === 0 && (
                      <CollapsibleItem
                        item={item}
                        onClick={() => handleToggle(item)}
                      />
                    )
                  )
                })}
              </div>
              <div className="col-6">
                {_.map(collapsList, (item, index) => {
                  return (
                    index % 2 === 1 && (
                      <CollapsibleItem
                        item={item}
                        onClick={() => handleToggle(item)}
                      />
                    )
                  )
                })}
              </div>
            </div>
          </Card.Section>
        </Card>
      </div>
      {toastMarkup}
    </Page>
  )
}

export default Home
