import React, { Component } from 'react'
import { Modal, TextContainer, Toast } from '@shopify/polaris'
import { setMyProductStatus } from '@/api/myproducts/index'
import SelVariantModal from './components/SelVariantModal'
import './index.scss'
class IconCard extends Component {
  state = {
    deleteModalActive: false,
    delistModalActive: false,
    currentId: 0,
    showToast: false,
    isError: false,
    toastContent: '',

    selVariants: [],
    isShowSelVariantModal: false,

    variantsId: null,
    orderType: '',
  }

  // 删除提醒
  showDelModal = (id) => {
    // store.dispatch(createIconCardShowAction(false))
    this.props.cardShowFnc(true)
    this.setState({
      deleteModalActive: true,
    })
  }

  // 关闭删除提醒
  handleDelCloseModal = () => {
    this.setState({
      deleteModalActive: false,
    })
  }

  // 展示修改状态弹窗
  handleShowModal = (currentId, type) => {
    if (type !== 'publish') {
      this.props.cardShowFnc(true)
      this.setState({
        delistModalActive: true,
        currentId: currentId,
      })
    } else {
      // publish 状态下 不用弹窗 直接调用发布接口
      this.props.cardShowFnc(true)
      this.setState(
        {
          currentId: currentId,
        },
        () => {
          this.handleChange('Unpublished')
          this.props.initDataFn()
        }
      )
    }
  }

  // 关闭修改状态弹窗
  handleCloseModal = () => {
    this.setState({
      delistModalActive: false,
    })
  }

  // 修改产品状态
  async handleChange(status) {
    const { currentId } = this.state
    switch (status) {
      case 'Active':
        status = 'Draft'
        break

      case 'Draft':
        status = 'Active'
        break

      case 'Unpublished':
        status = 'Publishing'
        break

      case 'Publishing':
        status = 'Unpublished'
        break

      default:
        break
    }
    try {
      await setMyProductStatus({ finishedId: [currentId], status: status })
      let content = ''
      if (status === 'Publish') {
        content = 'Product published'
      } else {
        content = 'Product saved'
      }
      //关闭弹窗
      this.handleCloseModal()
      this.setState(
        {
          toastContent: content,
          showToast: true,
        },
        () => {
          this.props.initDataFn()
        }
      )
    } catch (error) {
      this.setState({
        isError: true,
        showToast: true,
      })
    }
  }

  // 关闭Toast
  toggleActive() {
    this.setState({
      showToast: !this.state.showToast,
    })
  }

  // 显示 Select variant 弹窗
  async handleCreateOrder(id) {
    this.props.cardShowFnc(true)
    // store.dispatch(createIconCardShowAction(false))
    this.setState({
      variantsId: id,
      isShowSelVariantModal: true,
      orderType: 'basic',
    })
  }

  callShowModalFn() {
    this.setState({
      isShowSelVariantModal: false,
    })
  }

  render() {
    const { id, status, onConfirm } = this.props
    const { deleteModalActive, delistModalActive, showToast, isError, toastContent } = this.state
    let textConent = ''
    switch (status) {
      case 'Active':
        textConent = 'Draft'
        break
      case 'Draft':
        textConent = 'Active'
        break
      case 'Unpublished':
        textConent = 'Publish'
        break
      case 'Publishing':
        textConent = 'Unpublished'
        break
      default:
        break
    }
    // 移除 Modal
    const deleteModal = (
      <Modal
        open={deleteModalActive}
        onClose={this.handleDelCloseModal}
        title="Delete product"
        primaryAction={{
          content: 'Delete',
          destructive: 'destructive',
          onAction: () => {
            this.handleDelCloseModal()
            onConfirm('Delete')
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: this.handleDelCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Are you sure to delete this product? From Shopify Admin, the Active products will be
              sync removed either.
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    )

    const editStatusModal = (
      <Modal
        open={delistModalActive}
        onClose={this.handleCloseModal}
        title={textConent + ' product'}
        primaryAction={{
          content: textConent,
          destructive: 'destructive',
          onAction: () => this.handleChange(status),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: this.handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            {textConent === 'Active' ? (
              <p>
                <p>
                  Are you sure you want to activate this products? It will be listed on the online
                  store.
                </p>
              </p>
            ) : (
              <p>
                Are you sure to {textConent} this product? This product will be hidden from sales.
              </p>
            )}
          </TextContainer>
        </Modal.Section>
      </Modal>
    )

    const toastMarkup = showToast ? (
      <Toast
        content={!isError ? toastContent : 'Server error'}
        onDismiss={() => this.toggleActive()}
        duration={1200}
        error={isError}
      />
    ) : null

    return (
      <div>
        <div
          className="IconCard_Container"
          style={{
            display: this.props.isShow ? 'block' : 'none',
            //  height: status !== 'Active' ? '120px' : '100%'
          }}
        >
          <ul className="IconCard_Ul">
            {status === 'Active' && <li onClick={() => this.handleShowModal(id)}>Set as Draft</li>}
            {status === 'Draft' && <li onClick={() => this.handleShowModal(id)}>Set as Active</li>}
            {status === 'Unpublished' && (
              <li onClick={() => this.handleShowModal(id, 'publish')}>Publish</li>
            )}
            {status === 'Publishing' && (
              <li onClick={() => this.handleShowModal(id)}>Unpublished</li>
            )}
            <li onClick={() => this.handleCreateOrder(id)}>Create order</li>
            <li onClick={() => this.showDelModal(id)}>Delete</li>
          </ul>
        </div>
        {this.state.variantsId && (
          <SelVariantModal
            callShowModalFn={() => this.callShowModalFn()}
            variantsId={this.state.variantsId}
            orderType={this.state.orderType}
            isShowSelVariantModal={this.state.isShowSelVariantModal}
          />
        )}
        {deleteModal}
        {editStatusModal}
        {toastMarkup}
      </div>
    )
  }
}

export default IconCard
