/*
 * @Description: 产品设计 - 颜色预览
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-21 16:57:06
 * @LastEditTime: 2022-12-26 14:02:57
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Catalog\ChildrenPages\ConfugureDesign\components\ColorsPreview\index.jsx
 */
import React, { useCallback } from 'react'
import { useGetState } from 'ahooks'
import { Popover, Icon, Tooltip } from '@shopify/polaris'
import { ColorsMajor } from '@shopify/polaris-icons'
import _ from 'lodash'
import './index.scss'

const ColorsPreview = ({ variantData, onConfirm }) => {
  const [popoverActive, setPopoverActive, getPopoverActive] = useGetState(false)
  console.log('variantData', variantData)
  const togglePopoverActive = useCallback((e) => {
    console.log('popoverActive', e)
    if (!getPopoverActive()) {
      setTimeout(() => {
        const dom = document.getElementById('ColorsPreview')
        console.log('dom', dom.style)
        dom.style.setProperty('top', e.clientY + 'px')
      })
    }
    setPopoverActive((popoverActive) => !popoverActive)
  }, [])
  const colorList = _.uniqBy(variantData, 'color')

  const activator = (
    <div
      className="Design-Options-Icon"
      onClick={togglePopoverActive}
    >
      <Icon
        source={ColorsMajor}
        color="base"
      />
    </div>
  )

  const colorNodes = _.map(colorList, (item, index) => {
    return (
      <div
        key={index}
        style={{ margin: '0 12px 6px 0' }}
        title={item.statement || item.color}
      >
        {/* <Tooltip
          content={item.statement || item.color}
          preferredPosition="above"
          dismissOnMouseOut
        >
          <div
            className="Design-Color-item"
            style={{ background: item.color }}
            onClick={() => {
              onConfirm(item.color)
            }}
          ></div>
        </Tooltip> */}
        <div
          className="Design-Color-item"
          style={{ background: item.color }}
          onClick={() => {
            onConfirm(item.color)
          }}
        ></div>
      </div>
    )
  })

  return (
    <Popover
      id="ColorsPreview"
      active={popoverActive}
      activator={activator}
      autofocusTarget="first-node"
      preferredAlignment="left"
      onClose={togglePopoverActive}
      fixed
    >
      <Popover.Pane fixed>
        <Popover.Section>
          <p>Colors preview</p>
        </Popover.Section>
      </Popover.Pane>
      <Popover.Pane>
        <div className="Design-Color-box">{colorNodes}</div>
      </Popover.Pane>
    </Popover>
  )
}

export default ColorsPreview
