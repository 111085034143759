/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-02 17:52:53
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2022-12-14 15:13:53
 * @FilePath: \pod-app-react\src\api\productContentEdit\MyUploadAdapter.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { signleupload } from '@/api/settings'

export default class MyUploadAdapter {
  constructor(loader, url) {
    this.loader = loader
    this.url = process.env.REACT_APP_ADMIN_API_SERVER + url
  }

  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest()
      this._initListeners(resolve, reject)
      this._sendRequest(resolve)
    })
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest())
    xhr.open('POST', this.url, true)
    xhr.responseType = 'json'
  }

  _initListeners(resolve, reject) {
    const xhr = this.xhr
    const loader = this.loader
    // eslint-disable-next-line no-useless-concat
    const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`

    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const response = xhr.response

      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText)
      }

      // 上传成功
      resolve({
        default: response.url,
      })
    })
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  async _sendRequest(resolve) {
    const data = new FormData()
    let file
    this.loader.file.then(async (res) => {
      file = res //文件流
      data.append(`file`, file)
      data.append('type', 1)
      const result = await signleupload(data)
      let imgURL = result.imageUrl
      resolve({
        default: process.env.REACT_APP_ADMIN_API_SERVER + imgURL,
      })
    })
  }
}
