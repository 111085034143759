import { useState, useEffect, useCallback } from 'react'
import { Modal, TextField, Toast, Tooltip, Icon } from '@shopify/polaris'
import { AlertMinor } from '@shopify/polaris-icons'
import _ from 'lodash'
// 接口
import BillingService from '@/api/settings/billing'
// 样式文件
import './index.scss'

const Withdraw = ({ isShow, onClose, onConfirm }) => {
  // 是否显示弹窗
  const [active, setActive] = useState(isShow)
  // 余额
  const [myBalance, setMyBalance] = useState(0)
  // 表单数据
  const [ruleForm, setRuleForm] = useState({
    money: '', // 提现金额
    account: '', // 提现账号
    remark: '', // 备注
  })
  // 必填校验
  const [validator, setValidator] = useState({
    money: '', // 提现金额
    account: '', // 提现账号
  })

  // Toast提示
  const [showToast, setShowToast] = useState(false)
  const [isError, setIsError] = useState(false)
  const [toastContent, setToastContent] = useState('')

  useEffect(() => {
    if (isShow) {
      getBalance()
    }
    setActive(isShow)
  }, [isShow])

  // 获取余额
  const getBalance = async () => {
    const res = await BillingService.getBalance()
    if (res) {
      setMyBalance(res.money)
    } else {
      setMyBalance(0)
    }
  }

  // 关闭弹窗
  const closeModal = () => {
    setRuleForm({
      money: '', // 提现金额
      account: '', // 提现账号
      remark: '', // 备注
    })
    setValidator({
      money: '', // 提现金额
      account: '', // 提现账号
    })
    onClose()
  }

  // 修改文本
  const handleChange = (field, value) => {
    if (Number(value <= 0)) {
      value = 0
    }
    setRuleForm((preSatate) => ({
      ...preSatate,
      [field]: field === 'money' ? _.round(_.toNumber(value), 2).toString() : value,
    }))
  }

  // 输入框blur事件
  const handleBlur = (field, event) => {
    const value = field === 'money' ? _.toNumber(event.target.value) : event.target.value
    if (typeof value === 'number') {
      let errorMsg = null
      if (value) {
        if (value > myBalance) {
          errorMsg = `Insufficient balance`
          setValidator((preSatate) => ({
            ...preSatate,
            [field]: errorMsg,
          }))
        } else {
          setValidator((preSatate) => ({
            ...preSatate,
            [field]: '',
          }))
        }
      } else {
        let errorMsg = null
        if (event.target.value === '0') {
          errorMsg = 'Please fill the amount'
        } else {
          errorMsg = `Please fill the amount`
        }
        setValidator((preSatate) => ({
          ...preSatate,
          [field]: errorMsg,
        }))
      }
    } else {
      let errorMsg = ''
      if (!value) {
        errorMsg = `Please fill the account`
      }
      setValidator((preSatate) => ({
        ...preSatate,
        [field]: errorMsg,
      }))
    }
    // if (value) {
    //   if (value > myBalance) {
    //     let errorMsg = null
    //     if (field === 'money') {
    //       errorMsg = `Insufficient balance`
    //     } else {
    //       errorMsg = `Please fill the account`
    //     }
    //     setValidator((preSatate) => ({
    //       ...preSatate,
    //       [field]: errorMsg,
    //     }))
    //   } else {
    //     setValidator((preSatate) => ({
    //       ...preSatate,
    //       [field]: '',
    //     }))
    //   }
    // } else {
    //   let errorMsg = null
    //   if (field === 'money') {
    //     if (event.target.value === '0') {
    //       errorMsg = 'Please fill the amount'
    //     } else if (value > myBalance) {
    //       errorMsg = `Please fill the amount`
    //     } else {
    //       errorMsg = `Please fill the amount`
    //     }
    //   } else {
    //     errorMsg = 'Please fill the account'
    //   }
    //   setValidator((preSatate) => ({
    //     ...preSatate,
    //     [field]: errorMsg,
    //   }))
    // }
  }

  // 确认
  const handleConfirm = async () => {
    if (!ruleForm.money || !ruleForm.account) {
      return
    }
    try {
      await BillingService.apiWithdrawal(ruleForm)
      onConfirm()
      closeModal()
    } catch (e) {
      const msg = e.data ? e.data.message : 'Server error'
      setShowToast(true)
      setIsError(true)
      setToastContent(msg)
    }
  }

  // 显示toast
  const toggleActive = useCallback(() => setShowToast((active) => !active), [])

  // Toast提示
  const toastMarkup = showToast ? (
    <Toast
      content={toastContent}
      error={isError}
      onDismiss={toggleActive}
    />
  ) : null

  return (
    <Modal
      open={active}
      onClose={closeModal}
      title="Withdraw"
      primaryAction={{
        content: 'Done',
        onAction: handleConfirm,
        disabled:
          myBalance <= 0 ||
          _.toNumber(ruleForm.money) <= 0 ||
          myBalance < _.toNumber(ruleForm.money) ||
          !ruleForm.account,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        <div className="Withdraw">
          <TextField
            requiredIndicator
            label="Amount (USD)"
            type="number"
            prefix="$"
            min={0}
            value={ruleForm.money}
            onChange={(value) => {
              handleChange('money', value)
            }}
            onBlur={(value) => {
              handleBlur('money', value)
            }}
            helpText={`${myBalance} available`}
            error={validator.money}
            autoComplete="off"
          />
          <TextField
            requiredIndicator
            label={
              <div style={{ display: 'inline-flex' }}>
                <span>Return account</span>
                <Tooltip
                  dismissOnMouseOut
                  content="It is recommended to be consistent with your payment account. If the withdrawal account is inconsistent with your payment account, we need to confirm with you. After confirmation, the balance will be withdrawn to the account, and we will not be responsible for subsequent problems."
                >
                  <div
                    style={{
                      display: 'inline-block',
                      width: '20px',
                      height: '20px',
                      marginLeft: '0.5rem',
                    }}
                  >
                    <Icon
                      source={AlertMinor}
                      color="base"
                    />
                  </div>
                </Tooltip>
              </div>
            }
            value={ruleForm.account}
            onChange={(value) => {
              handleChange('account', value)
            }}
            onBlur={(value) => {
              handleBlur('account', value)
            }}
            error={validator.account}
            autoComplete="off"
          />
          <TextField
            label="Description"
            multiline={4}
            value={ruleForm.remark}
            onChange={(value) => {
              handleChange('remark', value)
            }}
            autoComplete="off"
            showCharacterCount
            maxLength={200}
          />
        </div>
      </Modal.Section>

      {/* Toast提示 */}
      {toastMarkup}
    </Modal>
  )
}

export default Withdraw
