/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-02 14:09:48
 * @LastEditTime: 2022-11-12 10:02:20
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\containers\IconCard\index.jsx
 */
/**
 *  @constructor 创建一个容器组件
 */

//IconCardUI --> UI组件
import IconCardUI from '@/components/IconCard'
import { createIconCardShowAction } from '@/redux/action'

import { connect } from 'react-redux'

/**
 * 将state映射成props到外部UI组件
 * @param {*} state
 * @returns object {key, value}
 */
const mapStateToProps = (state) => {
  return {
    isShow: state.iconCard,
  }
}

/**
 * 为操作state提供操作方法
 * @param {*} dispatch
 * @returns callBack
 */
const mapDispatchToProps = (dispatch) => {
  return { cardShowFnc: (data) => dispatch(createIconCardShowAction(data)) }
}

export default connect(mapStateToProps, mapDispatchToProps)(IconCardUI)
