import { Modal, TextContainer } from '@shopify/polaris'

const TaxModal = (props) => {
  const { isShowTaxModal, callTaxModalFn } = props

  const handleChange = () => {
    callTaxModalFn(false)
  }

  return (
    <Modal
      open={isShowTaxModal}
      onClose={handleChange}
      title="Tax"
    >
      <Modal.Section>
        <TextContainer>
          <h3>EU VAT</h3>
          <p>
            Printing.ONE is obligated by law to collect value-added tax (VAT) on EU-bound orders.
          </p>
          <h3>For EU sellers</h3>
          <p>VAT is applied depending on if/where you're registered as a VAT payer</p>
          <h3>For non-EU sellers</h3>
          <p>
            VAT is applied depending on if you're registered as a VAT payer. Note that you might
            also be liable to collect VAT from your customers. This does not constitute professional
            tax advice. We strongly advise consulting with a tax specialist to find out your
            specific obligations.
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}

export default TaxModal
