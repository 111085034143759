/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-08-27 14:23:51
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2023-01-06 11:11:56
 * @FilePath: \pod-app-react\src\api\notices\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE,
 */
import { request } from '../request'

export default class NoticesService {
  /**
   * 获取通知列表集合
   * @returns
   */
  static async getNoticeList() {
    return request({
      url: '/api/v1/message',
      method: 'POST',
    })
  }

  /**
   * 将消息设为已读
   * @param data 消息id
   */
  static async readMessage(data) {
    return request({
      url: '/api/v1/readMessage',
      method: 'POST',
      data,
    })
  }

  /**
   * 是否弹出站内信
   * @param data 消息id
   */
  static async getMail(data) {
    return request({
      url: '/api/v1/isPop',
      method: 'POST',
      data,
    })
  }
}
