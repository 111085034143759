/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-07 20:22:57
 * @LastEditTime: 2023-01-04 15:29:28
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\Image.jsx
 */
import React from 'react'
class Image extends React.Component {
  state = {
    src: '',
    alt: '',
    size: 30,
    backgroundColor: '',
    defaultImage: '/images/icon/default_image.svg',
  }
  errorImage() {
    if (this.state.src && !this.state.src.includes('default_image')) {
      this.setState({
        src: this.state.defaultImage,
        backgroundColor: '',
      })
    }
  }
  componentDidMount() {
    const { src, alt, size, backgroundColor } = this.props
    this.setState({ src, alt, size, backgroundColor })
  }
  shouldComponentUpdate(nextProps) {
    if (this.state.src !== nextProps.src) {
      this.setState({
        src: nextProps.src,
      })
      return true
    }
    return true
  }
  render() {
    const { src, alt, size, defaultImage, backgroundColor } = this.state
    return (
      <img
        src={src || defaultImage}
        alt={alt}
        style={{
          width: size + 'px',
          height: size + 'px',
          backgroundColor: backgroundColor ? backgroundColor : '',
        }}
        onError={this.errorImage.bind(this)}
      />
    )
  }
}

export default Image
