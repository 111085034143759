import { request } from '../request'
import MyUploadAdapter from './MyUploadAdapter'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'

// 产品内容编辑页获取数据
export async function getProductContent(data) {
  return request({
    url: '/api/v1/finished/show',
    method: 'POST',
    data,
  })
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository', Image, ImageToolbar).createUploadAdapter = (loader) => {
    // 第二个参数设置上传图片的地址
    return new MyUploadAdapter(loader, '/api/v1/upload')
  }
  window.editor = editor
}

// 富文本配置
export const ckeditorConfig = {
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
    ],
  },
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      // 'bulletedList',
      // 'numberedList',
      // '|',
      'indent',
      'outdent',
      '|',
      'imageUpload',
      'blockQuote',
      'insertTable',
      // 'mediaEmbed',
      'undo',
      'redo',
    ],
  },
  language: 'zh',
  // plugins: [Image],
  image: {
    toolbar: ['toggleImageCaption', 'imageTextAlternative'],
    types: ['png', 'png', 'jpeg'], // 限制上传文件类型
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
  extraPlugins: [MyCustomUploadAdapterPlugin], // 添加上传适配器
}

// 产品变体删除
export async function getProductVariantsDelete(params) {
  return request({
    url: '/api/v1/finished/variantDel',
    method: 'POST',
    data: params,
  })
}

/**
 * 变体保存
 * @param {*} params
 * @param {*} mark 0:修改库存、利润、定价 1:修改变体图
 * @returns
 */
export async function getProductVariantSave(params) {
  return request({
    url: '/api/v1/finished/variantSave',
    method: 'POST',
    data: params,
  })
}
// 变体上下架状态操作
export async function getUpdateVariantStatus(params) {
  return request({
    url: '/api/v1/finished/updateVariantStatus',
    method: 'POST',
    data: params,
  })
}
// 复制产品
export async function getProductCopy(params) {
  return request({
    url: '/api/v1/finished/copy',
    method: 'POST',
    data: params,
  })
}
// 产品发布或存为草稿
export async function getProductSave(params) {
  return request({
    url: '/api/v1/finished/finishedSave',
    method: 'POST',
    data: params,
  })
}
// 查看成品有无订单
export async function getQueryVolume(params) {
  return request({
    url: '/api/v1/finished/queryVolume',
    method: 'POST',
    data: params,
  })
}
// 成品删除
export async function getProductDelete(params) {
  return request({
    url: '/api/v1/finished/del',
    method: 'POST',
    data: params,
  })
}

/**
 * 获取设计好的图片（成品图）
 * @param {*} finishedId
 * @returns
 */
export async function getFinshedImages(finishedId) {
  return request({
    url: '/api/v1/finished/images',
    method: 'GET',
    params: {
      finishedId,
    },
  })
}

/**
 * 移除成品图
 * @param {*} data
 * @returns
 */
export async function destroyImage(data) {
  return request({
    url: '/api/v1/finished/destroyImage',
    method: 'POST',
    type: 'upload',
    data,
  })
}

/**
 * 复制成品的时候需要多调用一个复制成品图片的接口
 */
export async function copyImage(data) {
  return request({
    url: 'api/v1/finished/copyImage',
    method: 'POST',
    type: 'upload',
    data,
  })
}
