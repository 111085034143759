/*
 * @Description: 批量结账
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-04 17:25:50
 * @LastEditTime: 2023-02-17 09:57:02
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Orders\ChildrenPages\CheckoutsBulk\index.jsx
 */
import { useState, useEffect, useMemo } from 'react'
import { Modal, IndexTable, Button, Popover, Badge } from '@shopify/polaris'
// 物流方式
import Shipping from './components/Shipping'
// Items
import ProductItems from '../ProductItems'
import _ from 'lodash'
import './index.scss'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Array} checkList 选中的订单
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const CheckoutsBulk = ({ isShow, checkList, onClose, onConfirm }) => {
  const [active, setActive] = useState(isShow) // 是否显示弹窗
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setActive(isShow)
    setTableData(checkList)
  }, [isShow, checkList])

  // 打开或关闭弹窗
  const closeModal = () => {
    onClose()
    setActive(!active)
  }

  // 修改物流方式
  const handleChangeShipping = ({ orderId, shippingList }) => {
    const newData = [...tableData]
    _.forEach(newData, (item) => {
      if (item.id === orderId) {
        // 更新物流方式和运费
        item.deliveryMethod = shippingList
        item.shippingFee = _.get(
          _.find(shippingList, (o) => {
            return o.selected === 1
          }),
          'Cost'
        )
      }
    })
    setTableData(newData)
  }

  // Popover是否显示
  const togglePopoverActive = (item) => {
    const list = [...tableData]
    _.forEach(list, (el) => {
      if (el.id === item.id) {
        item.showPopver = !item.showPopver
      }
    })
    setTableData(list)
    if (list.length > 0) {
      document.getElementsByClassName('Shipping')[0].parentNode.parentNode.style.height = '0px'
    }
  }

  // 显示Item项
  const handleShowItems = (item) => {
    const data = [...tableData]
    _.forEach(data, (el) => {
      if (el.id === item.id) {
        el.showItems = !el.showItems
      } else {
        el.showItems = false
      }
    })
    setTableData(data)
  }

  // 产品价格合计
  const price = useMemo(() => {
    const sum = _.sumBy(tableData, (item) => {
      return _.toNumber(item.total) || 0
    })
    return _.round(sum, 2)
  }, [tableData])

  // 运费合计
  const shippingFee = useMemo(() => {
    const sum = _.sumBy(tableData, (item) => {
      return _.toNumber(item.shippingFee) || 0
    })
    // console.log('运费合计', tableData)
    return _.round(sum, 2)
  }, [tableData])

  // 税费合计
  const vat = useMemo(() => {
    const sum = _.sumBy(tableData, (item) => {
      return _.toNumber(item.iossVat) || 0
    })
    return _.round(sum, 2)
  }, [tableData])

  // 总价合计
  const totalPrice = useMemo(() => {
    const sum = _.sumBy(tableData, (item) => {
      return (
        _.toNumber(item.total || 0) +
        _.toNumber(item.shippingFee || 0) +
        _.toNumber(item.iossVat || 0)
      )
    })
    return _.round(sum, 2)
  }, [tableData])

  const rowMarkup = _.map(tableData, (item, index) => (
    <IndexTable.Row
      id={item.id}
      key={index}
      position={index}
    >
      <IndexTable.Cell>{`#${item.order}`}</IndexTable.Cell>
      <IndexTable.Cell>
        <Popover
          fixed
          fluidContent
          preferredPosition="mostSpace"
          preferredAlignment="center"
          active={item.showItems}
          activator={
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation()
                handleShowItems(item)
              }}
            >
              {item.items || 0} items
            </div>
          }
          autofocusTarget="first-node"
          onClose={() => handleShowItems(item)}
        >
          <ProductItems orderId={item.id} />
        </Popover>
      </IndexTable.Cell>
      <IndexTable.Cell>{`$ ${item.total}`}</IndexTable.Cell>
      <IndexTable.Cell>
        {item.deliveryMethod && item.deliveryMethod.length > 0 && (
          <div className="shipping_medthod_button">
            <Popover
              fixed
              fluidContent
              preferredPosition="mostSpace"
              preferredAlignment="center"
              active={item.showPopver}
              onClose={() => {
                togglePopoverActive(item)
              }}
              activator={
                <Button
                  disclosure
                  onClick={() => {
                    togglePopoverActive(item)
                  }}
                >
                  {_.map(item.deliveryMethod, (method) => {
                    if (method.selected === 1) {
                      return method.ShippingMethod
                    } else {
                      return false
                    }
                  })}
                </Button>
              }
            >
              <Shipping
                orderId={item.id}
                shippingId={item.shippingId}
                data={item.deliveryMethod}
                onClose={() => {
                  togglePopoverActive(item)
                }}
                onConfirm={handleChangeShipping}
              />
            </Popover>
          </div>
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>$ {item.shippingFee || 0}</IndexTable.Cell>
      <IndexTable.Cell>$ {item.iossVat || 0}</IndexTable.Cell>
    </IndexTable.Row>
  ))

  // 小计
  const subtotal = (
    <IndexTable.Row>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell>
        <span style={{ fontWeight: 500 }}>$ {price}</span>
      </IndexTable.Cell>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell>
        <span style={{ fontWeight: 500 }}>$ {shippingFee}</span>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <span style={{ fontWeight: 500 }}>$ {vat}</span>
      </IndexTable.Cell>
    </IndexTable.Row>
  )

  // 总计
  const total = (
    <IndexTable.Row>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell>
        <div className="CheckoutsBulk-Total">
          <span>Total:</span>
          <span style={{ fontWeight: 500 }}>$ {totalPrice}</span>
        </div>
      </IndexTable.Cell>
    </IndexTable.Row>
  )

  return (
    <Modal
      title="Checkouts in bulk"
      large
      open={active}
      onClose={closeModal}
      primaryAction={{
        content: 'Pay it now',
        disabled: totalPrice <= 0,
        onAction: () => {
          onConfirm(totalPrice)
          closeModal()
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <div className="CheckoutsBulk">
        <div className="CheckoutsBulk-Title">
          <Badge status="warning">Unpaid</Badge>
        </div>
        <IndexTable
          itemCount={tableData.length}
          selectable={false}
          headings={[
            { title: 'Order' },
            { title: 'Items' },
            { title: 'Price' },
            { title: 'Delivery method' },
            { title: 'Shipping' },
            { title: 'VAT' },
          ]}
        >
          {rowMarkup}

          {/* 小计 */}
          {subtotal}

          {/* 总计 */}
          {total}
        </IndexTable>
      </div>
    </Modal>
  )
}

export default CheckoutsBulk
