import React from 'react'

const CountryFlag = (country) => {
  let _country_name_flag = {
    backgroundPosition: '0 0',
  }
  switch (country.country) {
    case 'AF':
      _country_name_flag = {
        backgroundPosition: `0 -72px`,
      }
      break
    case 'ALA':
      _country_name_flag = {
        backgroundPosition: `0 -216px`,
      }
      break
    case 'AL':
      _country_name_flag = {
        backgroundPosition: `0 -180px`,
      }
      break
    case 'GBA':
      _country_name_flag = {
        backgroundPosition: `0 -2700px`,
      }
      break
    case 'DZ':
      _country_name_flag = {
        backgroundPosition: `0 -2124px`,
      }
      break
    case 'AS':
      _country_name_flag = {
        backgroundPosition: `0 -432px`,
      }
      break
    case 'AD':
      _country_name_flag = {
        backgroundPosition: `0 0px`,
      }
      break
    case 'AO':
      _country_name_flag = {
        backgroundPosition: `0 -324px`,
      }
      break
    case 'AI':
      _country_name_flag = {
        backgroundPosition: `0 -144px`,
      }
      break
    case 'AG':
      _country_name_flag = {
        backgroundPosition: `0 -108px`,
      }
      break
    case 'AR':
      _country_name_flag = {
        backgroundPosition: `0 -396px`,
      }
      break
    case 'AM':
      _country_name_flag = {
        backgroundPosition: `0 -252px`,
      }
      break
    case 'AW':
      _country_name_flag = {
        backgroundPosition: `0 -576px`,
      }
      break
    case 'ASC':
      _country_name_flag = {
        backgroundPosition: `0 -468px`,
      }
      break
    case 'AU':
      _country_name_flag = {
        backgroundPosition: `0 -540px`,
      }
      break
    case 'AT':
      _country_name_flag = {
        backgroundPosition: `0 -504px`,
      }
      break
    case 'AZ':
      _country_name_flag = {
        backgroundPosition: `0 -612px`,
      }
      break
    case 'BS':
      _country_name_flag = {
        backgroundPosition: `0 -1152px`,
      }
      break
    case 'BH':
      _country_name_flag = {
        backgroundPosition: `0 -864px`,
      }
      break
    case 'BD':
      _country_name_flag = {
        backgroundPosition: `0 -720px`,
      }
      break
    case 'BB':
      _country_name_flag = {
        backgroundPosition: `0 -684px`,
      }
      break
    case 'BY':
      _country_name_flag = {
        backgroundPosition: `0 -1260px`,
      }
      break
    case 'BE':
      _country_name_flag = {
        backgroundPosition: `0 -756px`,
      }
      break
    case 'BZ':
      _country_name_flag = {
        backgroundPosition: `0 -1296px`,
      }
      break
    case 'BJ':
      _country_name_flag = {
        backgroundPosition: `0 -936px`,
      }
      break
    case 'BM':
      _country_name_flag = {
        backgroundPosition: `0 -1008px`,
      }
      break
    case 'BT':
      _country_name_flag = {
        backgroundPosition: `0 -1188px`,
      }
      break
    case 'BO':
      _country_name_flag = {
        backgroundPosition: `0 -1080px`,
      }
      break
    case 'BA':
      _country_name_flag = {
        backgroundPosition: `0 -648px`,
      }
      break
    case 'BW':
      _country_name_flag = {
        backgroundPosition: `0 -1224px`,
      }
      break
    case 'BR':
      _country_name_flag = {
        backgroundPosition: `0 -1116px`,
      }
      break
    case 'BN':
      _country_name_flag = {
        backgroundPosition: `0 -1044px`,
      }
      break
    case 'BG':
      _country_name_flag = {
        backgroundPosition: `0 -828px`,
      }
      break
    case 'BF':
      _country_name_flag = {
        backgroundPosition: `0 -792px`,
      }
      break
    case 'BI':
      _country_name_flag = {
        backgroundPosition: `0 -900px`,
      }
      break
    case 'KH':
      _country_name_flag = {
        backgroundPosition: `0 -4031px`,
      }
      break
    case 'CM':
      _country_name_flag = {
        backgroundPosition: `0 -1620px`,
      }
      break
    case 'CA':
      _country_name_flag = {
        backgroundPosition: `0 -1332px`,
      }
      break
    case 'CV':
      _country_name_flag = {
        backgroundPosition: `0 -1764px`,
      }
      break
    case 'BQ':
      _country_name_flag = {
        backgroundPosition: `0 -6047px`,
      }
      break
    case 'KY':
      _country_name_flag = {
        backgroundPosition: `0 -4283px`,
      }
      break
    case 'CF':
      _country_name_flag = {
        backgroundPosition: `0 -1404px`,
      }
      break
    case 'TD':
      _country_name_flag = {
        backgroundPosition: `0 -7595px`,
      }
      break
    case 'CL':
      _country_name_flag = {
        backgroundPosition: `0 -1584px`,
      }
      break
    case 'CX':
      _country_name_flag = {
        backgroundPosition: `0 -1836px`,
      }
      break
    case 'CC':
      _country_name_flag = {
        backgroundPosition: `0 -1368px`,
      }
      break
    case 'CO':
      _country_name_flag = {
        backgroundPosition: `0 -1692px`,
      }
      break
    case 'KM':
      _country_name_flag = {
        backgroundPosition: `0 -4103px`,
      }
      break
    case 'ZR':
      _country_name_flag = {
        backgroundPosition: `0 -8891px`,
      }
      break
    case 'CG':
      _country_name_flag = {
        backgroundPosition: `0 -1440px`,
      }
      break
    case 'CK':
      _country_name_flag = {
        backgroundPosition: `0 -1548px`,
      }
      break
    case 'CR':
      _country_name_flag = {
        backgroundPosition: `0 -1728px`,
      }
      break
    case 'CI':
      _country_name_flag = {
        backgroundPosition: `0 -1512px`,
      }
      break
    case 'HR':
      _country_name_flag = {
        backgroundPosition: `0 -3384px`,
      }
      break
    case 'CW':
      _country_name_flag = {
        backgroundPosition: `0 -1800px`,
      }
      break
    case 'CY':
      _country_name_flag = {
        backgroundPosition: `0 -1872px`,
      }
      break
    case 'CZ':
      _country_name_flag = {
        backgroundPosition: `0 -1908px`,
      }
      break
    case 'DK':
      _country_name_flag = {
        backgroundPosition: `0 -2016px`,
      }
      break
    case 'DJ':
      _country_name_flag = {
        backgroundPosition: `0 -1980px`,
      }
      break
    case 'DM':
      _country_name_flag = {
        backgroundPosition: `0 -2052px`,
      }
      break
    case 'DO':
      _country_name_flag = {
        backgroundPosition: `0 -2088px`,
      }
      break
    case 'EC':
      _country_name_flag = {
        backgroundPosition: `0 -2196px`,
      }
      break
    case 'EG':
      _country_name_flag = {
        backgroundPosition: `0 -2268px`,
      }
      break
    case 'SV':
      _country_name_flag = {
        backgroundPosition: `0 -7451px`,
      }
      break
    case 'GQ':
      _country_name_flag = {
        backgroundPosition: `0 -3096px`,
      }
      break
    case 'ER':
      _country_name_flag = {
        backgroundPosition: `0 -2340px`,
      }
      break
    case 'EE':
      _country_name_flag = {
        backgroundPosition: `0 -2232px`,
      }
      break
    case 'ET':
      _country_name_flag = {
        backgroundPosition: `0 -2412px`,
      }
      break
    case 'FK':
      _country_name_flag = {
        backgroundPosition: `0 -2520px`,
      }
      break
    case 'FO':
      _country_name_flag = {
        backgroundPosition: `0 -2592px`,
      }
      break
    case 'FJ':
      _country_name_flag = {
        backgroundPosition: `0 -2484px`,
      }
      break
    case 'FI':
      _country_name_flag = {
        backgroundPosition: `0 -2448px`,
      }
      break
    case 'FR':
      _country_name_flag = {
        backgroundPosition: `0 -2628px`,
      }
      break
    case 'GF':
      _country_name_flag = {
        backgroundPosition: `0 -2808px`,
      }
      break
    case 'PF':
      _country_name_flag = {
        backgroundPosition: `0 -6155px`,
      }
      break
    case 'GM':
      _country_name_flag = {
        backgroundPosition: `0 -2988px`,
      }
      break
    case 'GE':
      _country_name_flag = {
        backgroundPosition: `0 -2772px`,
      }
      break
    case 'DE':
      _country_name_flag = {
        backgroundPosition: `0 -1944px`,
      }
      break
    case 'GH':
      _country_name_flag = {
        backgroundPosition: `0 -2880px`,
      }
      break
    case 'GI':
      _country_name_flag = {
        backgroundPosition: `0 -2916px`,
      }
      break
    case 'GR':
      _country_name_flag = {
        backgroundPosition: `0 -3132px`,
      }
      break
    case 'GL':
      _country_name_flag = {
        backgroundPosition: `0 -2952px`,
      }
      break
    case 'GD':
      _country_name_flag = {
        backgroundPosition: `0 -2736px`,
      }
      break
    case 'GP':
      _country_name_flag = {
        backgroundPosition: `0 -3060px`,
      }
      break
    case 'GU':
      _country_name_flag = {
        backgroundPosition: `0 -3204px`,
      }
      break
    case 'GT':
      _country_name_flag = {
        backgroundPosition: `0 -3168px`,
      }
      break
    case 'GGY':
      _country_name_flag = {
        backgroundPosition: `0 -2844px`,
      }
      break
    case 'GN':
      _country_name_flag = {
        backgroundPosition: `0 -3024px`,
      }
      break
    case 'GW':
      _country_name_flag = {
        backgroundPosition: `0 -3240px`,
      }
      break
    case 'GY':
      _country_name_flag = {
        backgroundPosition: `0 -3276px`,
      }
      break
    case 'HT':
      _country_name_flag = {
        backgroundPosition: `0 -3419px`,
      }
      break
    case 'HN':
      _country_name_flag = {
        backgroundPosition: `0 -3348px`,
      }
      break
    case 'HK':
      _country_name_flag = {
        backgroundPosition: `0 -3312px`,
      }
      break
    case 'HU':
      _country_name_flag = {
        backgroundPosition: `0 -3455px`,
      }
      break
    case 'IS':
      _country_name_flag = {
        backgroundPosition: `0 -3743px`,
      }
      break
    case 'IN':
      _country_name_flag = {
        backgroundPosition: `0 -3635px`,
      }
      break
    case 'ID':
      _country_name_flag = {
        backgroundPosition: `0 -3491px`,
      }
      break
    case 'IQ':
      _country_name_flag = {
        backgroundPosition: `0 -3707px`,
      }
      break
    case 'IE':
      _country_name_flag = {
        backgroundPosition: `0 -3527px`,
      }
      break
    case 'IL':
      _country_name_flag = {
        backgroundPosition: `0 -3563px`,
      }
      break
    case 'IT':
      _country_name_flag = {
        backgroundPosition: `0 -3779px`,
      }
      break
    case 'JM':
      _country_name_flag = {
        backgroundPosition: `0 -3851px`,
      }
      break
    case 'JP':
      _country_name_flag = {
        backgroundPosition: `0 -3923px`,
      }
      break
    case 'JO':
      _country_name_flag = {
        backgroundPosition: `0 -3887px`,
      }
      break
    case 'KZ':
      _country_name_flag = {
        backgroundPosition: `0 -4319px`,
      }
      break
    case 'KE':
      _country_name_flag = {
        backgroundPosition: `0 -3959px`,
      }
      break
    case 'KI':
      _country_name_flag = {
        backgroundPosition: `0 -4067px`,
      }
      break
    case 'KR':
      _country_name_flag = {
        backgroundPosition: `0 -4175px`,
      }
      break
    case 'KW':
      _country_name_flag = {
        backgroundPosition: `0 -4247px`,
      }
      break
    case 'KG':
      _country_name_flag = {
        backgroundPosition: `0 -3995px`,
      }
      break
    case 'LA':
      _country_name_flag = {
        backgroundPosition: `0 -4355px`,
      }
      break
    case 'LV':
      _country_name_flag = {
        backgroundPosition: `0 -4679px`,
      }
      break
    case 'LB':
      _country_name_flag = {
        backgroundPosition: `0 -4391px`,
      }
      break
    case 'LS':
      _country_name_flag = {
        backgroundPosition: `0 -4571px`,
      }
      break
    case 'LR':
      _country_name_flag = {
        backgroundPosition: `0 -4535px`,
      }
      break
    case 'LY':
      _country_name_flag = {
        backgroundPosition: `0 -4715px`,
      }
      break
    case 'LI':
      _country_name_flag = {
        backgroundPosition: `0 -4463px`,
      }
      break
    case 'LU':
      _country_name_flag = {
        backgroundPosition: `0 -4643px`,
      }
      break
    case 'MO':
      _country_name_flag = {
        backgroundPosition: `0 -5147px`,
      }
      break
    case 'MK':
      _country_name_flag = {
        backgroundPosition: `0 -4967px`,
      }
      break
    case 'MG':
      _country_name_flag = {
        backgroundPosition: `0 -4895px`,
      }
      break
    case 'MW':
      _country_name_flag = {
        backgroundPosition: `0 -5435px`,
      }
      break
    case 'MY':
      _country_name_flag = {
        backgroundPosition: `0 -5507px`,
      }
      break
    case 'MV':
      _country_name_flag = {
        backgroundPosition: `0 -5399px`,
      }
      break
    case 'ML':
      _country_name_flag = {
        backgroundPosition: `0 -5003px`,
      }
      break
    case 'MT':
      _country_name_flag = {
        backgroundPosition: `0 -5327px`,
      }
      break
    case 'MH':
      _country_name_flag = {
        backgroundPosition: `0 -4931px`,
      }
      break
    case 'MQ':
      _country_name_flag = {
        backgroundPosition: `0 -5219px`,
      }
      break
    case 'MR':
      _country_name_flag = {
        backgroundPosition: `0 -5255px`,
      }
      break
    case 'MU':
      _country_name_flag = {
        backgroundPosition: `0 -5363px`,
      }
      break
    case 'YT':
      _country_name_flag = {
        backgroundPosition: `0 -8747px`,
      }
      break
    case 'MX':
      _country_name_flag = {
        backgroundPosition: `0 -5471px`,
      }
      break
    case 'FM':
      _country_name_flag = {
        backgroundPosition: `0 -2556px`,
      }
      break
    case 'MD':
      _country_name_flag = {
        backgroundPosition: `0 -4859px`,
      }
      break
    case 'MC':
      _country_name_flag = {
        backgroundPosition: `0 -4823px`,
      }
      break
    case 'MN':
      _country_name_flag = {
        backgroundPosition: `0 -5075px`,
      }
      break
    case 'MNE':
      _country_name_flag = {
        backgroundPosition: `0 -5111px`,
      }
      break
    case 'MS':
      _country_name_flag = {
        backgroundPosition: `0 -5291px`,
      }
      break
    case 'MA':
      _country_name_flag = {
        backgroundPosition: `0 -4751px`,
      }
      break
    case 'MZ':
      _country_name_flag = {
        backgroundPosition: `0 -5543px`,
      }
      break
    case 'MM':
      _country_name_flag = {
        backgroundPosition: `0 -5039px`,
      }
      break
    case 'NA':
      _country_name_flag = {
        backgroundPosition: `0 -5579px`,
      }
      break
    case 'NR':
      _country_name_flag = {
        backgroundPosition: `0 -5903px`,
      }
      break
    case 'NP':
      _country_name_flag = {
        backgroundPosition: `0 -5867px`,
      }
      break
    case 'NL':
      _country_name_flag = {
        backgroundPosition: `0 -5795px`,
      }
      break
    case 'AN':
      _country_name_flag = {
        backgroundPosition: `0 -288px`,
      }
      break
    case 'NC':
      _country_name_flag = {
        backgroundPosition: `0 -5615px`,
      }
      break
    case 'NZ':
      _country_name_flag = {
        backgroundPosition: `0 -5975px`,
      }
      break
    case 'NI':
      _country_name_flag = {
        backgroundPosition: `0 -5759px`,
      }
      break
    case 'NE':
      _country_name_flag = {
        backgroundPosition: `0 -5651px`,
      }
      break
    case 'NG':
      _country_name_flag = {
        backgroundPosition: `0 -5723px`,
      }
      break
    case 'NU':
      _country_name_flag = {
        backgroundPosition: `0 -5939px`,
      }
      break
    case 'NF':
      _country_name_flag = {
        backgroundPosition: `0 -5687px`,
      }
      break
    case 'MP':
      _country_name_flag = {
        backgroundPosition: `0 -5183px`,
      }
      break
    case 'NO':
      _country_name_flag = {
        backgroundPosition: `0 -5831px`,
      }
      break
    case 'OM':
      _country_name_flag = {
        backgroundPosition: `0 -6011px`,
      }
      break
    case 'OTHER':
      _country_name_flag = {
        backgroundPosition: `0 -6047px`,
      }
      break
    case 'PK':
      _country_name_flag = {
        backgroundPosition: `0 -6263px`,
      }
      break
    case 'PW':
      _country_name_flag = {
        backgroundPosition: `0 -6515px`,
      }
      break
    case 'PS':
      _country_name_flag = {
        backgroundPosition: `0 -6443px`,
      }
      break
    case 'PA':
      _country_name_flag = {
        backgroundPosition: `0 -6083px`,
      }
      break
    case 'PG':
      _country_name_flag = {
        backgroundPosition: `0 -6191px`,
      }
      break
    case 'PY':
      _country_name_flag = {
        backgroundPosition: `0 -6551px`,
      }
      break
    case 'PE':
      _country_name_flag = {
        backgroundPosition: `0 -6119px`,
      }
      break
    case 'PH':
      _country_name_flag = {
        backgroundPosition: `0 -6227px`,
      }
      break
    case 'PL':
      _country_name_flag = {
        backgroundPosition: `0 -6299px`,
      }
      break
    case 'PT':
      _country_name_flag = {
        backgroundPosition: `0 -6479px`,
      }
      break
    case 'PR':
      _country_name_flag = {
        backgroundPosition: `0 -6407px`,
      }
      break
    case 'QA':
      _country_name_flag = {
        backgroundPosition: `0 -6587px`,
      }
      break
    case 'RE':
      _country_name_flag = {
        backgroundPosition: `0 -6623px`,
      }
      break
    case 'RO':
      _country_name_flag = {
        backgroundPosition: `0 -6659px`,
      }
      break
    case 'RU':
      _country_name_flag = {
        backgroundPosition: `0 -6695px`,
      }
      break
    case 'RW':
      _country_name_flag = {
        backgroundPosition: `0 -6731px`,
      }
      break
    case 'BLM':
      _country_name_flag = {
        backgroundPosition: `0 -972px`,
      }
      break
    case 'KN':
      _country_name_flag = {
        backgroundPosition: `0 -4139px`,
      }
      break
    case 'LC':
      _country_name_flag = {
        backgroundPosition: `0 -4427px`,
      }
      break
    case 'MAF':
      _country_name_flag = {
        backgroundPosition: `0 -4787px`,
      }
      break
    case 'VC':
      _country_name_flag = {
        backgroundPosition: `0 -8423px`,
      }
      break
    case 'WS':
      _country_name_flag = {
        backgroundPosition: `0 -8675px`,
      }
      break
    case 'SM':
      _country_name_flag = {
        backgroundPosition: `0 -7199px`,
      }
      break
    case 'ST':
      _country_name_flag = {
        backgroundPosition: `0 -7415px`,
      }
      break
    case 'SA':
      _country_name_flag = {
        backgroundPosition: `0 -6767px`,
      }
      break
    case 'SN':
      _country_name_flag = {
        backgroundPosition: `0 -7235px`,
      }
      break
    case 'SRB':
      _country_name_flag = {
        backgroundPosition: `0 -7343px`,
      }
      break
    case 'SC':
      _country_name_flag = {
        backgroundPosition: `0 -6839px`,
      }
      break
    case 'SL':
      _country_name_flag = {
        backgroundPosition: `0 -7163px`,
      }
      break
    case 'SG':
      _country_name_flag = {
        backgroundPosition: `0 -6947px`,
      }
      break
    case 'SX':
      _country_name_flag = {
        backgroundPosition: `0 -7487px`,
      }
      break
    case 'SK':
      _country_name_flag = {
        backgroundPosition: `0 -7127px`,
      }
      break
    case 'SI':
      _country_name_flag = {
        backgroundPosition: `0 -7055px`,
      }
      break
    case 'SB':
      _country_name_flag = {
        backgroundPosition: `0 -6803px`,
      }
      break
    case 'SO':
      _country_name_flag = {
        backgroundPosition: `0 -7271px`,
      }
      break
    case 'ZA':
      _country_name_flag = {
        backgroundPosition: `0 -8819px`,
      }
      break
    case 'SGS':
      _country_name_flag = {
        backgroundPosition: `0 -6983px`,
      }
      break
    case 'SS':
      _country_name_flag = {
        backgroundPosition: `0 -7379px`,
      }
      break
    case 'ES':
      _country_name_flag = {
        backgroundPosition: `0 -2376px`,
      }
      break
    case 'LK':
      _country_name_flag = {
        backgroundPosition: `0 -4499px`,
      }
      break
    case 'PM':
      _country_name_flag = {
        backgroundPosition: `0 -6335px`,
      }
      break
    case 'SR':
      _country_name_flag = {
        backgroundPosition: `0 -7307px`,
      }
      break
    case 'SZ':
      _country_name_flag = {
        backgroundPosition: `0 -7523px`,
      }
      break
    case 'SE':
      _country_name_flag = {
        backgroundPosition: `0 -6911px`,
      }
      break
    case 'CH':
      _country_name_flag = {
        backgroundPosition: `0 -1476px`,
      }
      break
    case 'TW':
      _country_name_flag = {
        backgroundPosition: `0 -8099px`,
      }
      break
    case 'TJ':
      _country_name_flag = {
        backgroundPosition: `0 -7739px`,
      }
      break
    case 'TZ':
      _country_name_flag = {
        backgroundPosition: `0 -8135px`,
      }
      break
    case 'TH':
      _country_name_flag = {
        backgroundPosition: `0 -7703px`,
      }
      break
    case 'TLS':
      _country_name_flag = {
        backgroundPosition: `0 -7811px`,
      }
      break
    case 'TO':
      _country_name_flag = {
        backgroundPosition: `0 -7919px`,
      }
      break
    case 'TT':
      _country_name_flag = {
        backgroundPosition: `0 -8027px`,
      }
      break
    case 'TN':
      _country_name_flag = {
        backgroundPosition: `0 -7883px`,
      }
      break
    case 'TR':
      _country_name_flag = {
        backgroundPosition: `0 -7991px`,
      }
      break
    case 'TM':
      _country_name_flag = {
        backgroundPosition: `0 -7847px`,
      }
      break
    case 'TC':
      _country_name_flag = {
        backgroundPosition: `0 -7559px`,
      }
      break
    case 'TV':
      _country_name_flag = {
        backgroundPosition: `0 -8063px`,
      }
      break
    case 'UA':
      _country_name_flag = {
        backgroundPosition: `0 -8171px`,
      }
      break
    case 'AE':
      _country_name_flag = {
        backgroundPosition: `0 -36px`,
      }
      break
    case 'UK':
      _country_name_flag = {
        backgroundPosition: `0 -8243px`,
      }
      break
    case 'US':
      _country_name_flag = {
        backgroundPosition: `0 -8279px`,
      }
      break
    case 'UY':
      _country_name_flag = {
        backgroundPosition: `0 -8315px`,
      }
      break
    case 'UZ':
      _country_name_flag = {
        backgroundPosition: `0 -8351px`,
      }
      break
    case 'VU':
      _country_name_flag = {
        backgroundPosition: `0 -8603px`,
      }
      break
    case 'VA':
      _country_name_flag = {
        backgroundPosition: `0 -8387px`,
      }
      break
    case 'VE':
      _country_name_flag = {
        backgroundPosition: `0 -8459px`,
      }
      break
    case 'VN':
      _country_name_flag = {
        backgroundPosition: `0 -8567px`,
      }
      break
    case 'VG':
      _country_name_flag = {
        backgroundPosition: `0 -8495px`,
      }
      break
    case 'VI':
      _country_name_flag = {
        backgroundPosition: `0 -8531px`,
      }
      break
    case 'WF':
      _country_name_flag = {
        backgroundPosition: `0 -8639px`,
      }
      break
    case 'YE':
      _country_name_flag = {
        backgroundPosition: `0 -8711px`,
      }
      break
    case 'ZM':
      _country_name_flag = {
        backgroundPosition: `0 -8855px`,
      }
      break
    case 'EAZ':
      _country_name_flag = {
        backgroundPosition: `0 -2160px`,
      }
      break
    case 'ZW':
      _country_name_flag = {
        backgroundPosition: `0 -8927px`,
      }
      break
    default:
      _country_name_flag = {
        backgroundPosition: '0 0',
      }
  }

  return (
    <div
      className="_country_name_flag"
      style={_country_name_flag}
    ></div>
  )
}

export default CountryFlag
