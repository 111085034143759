/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-09-21 15:58:42
 * @LastEditTime: 2022-11-14 17:00:03
 * @LastEditors: huang-chao hc18174419502@163.com
 * @FilePath: \pod-app-react\src\api\myproducts\index.js
 */
import { request } from '../request'

/**
 * 获取通知列表集合
 * @returns
 */
export async function getMyProductList(params) {
  return request({
    url: '/api/v1/finished/index',
    method: 'POST',
    data: params,
  })
}

/**
 * 产品状态修改
 * @returns
 */
export async function setMyProductStatus(params) {
  return request({
    url: '/api/v1/finished/status',
    method: 'POST',
    data: params,
  })
}

/**
 * 产品变体
 * @returns
 */
export async function getProductVariants(params) {
  return request({
    url: '/api/v1/order/finishedList',
    method: 'POST',
    data: params,
  })
}

/**
 * 添加查询产品
 * @param {*} params
 * @returns
 */
export async function selVariantsAdd(params) {
  return request({
    url: '/api/v1/order/insert',
    method: 'POST',
    data: params,
  })
}

/**
 * 获取shopfiy Collection
 */
export async function getCollections() {
  return request({
    url: '/api/v1/finished/getTypeMap',
    method: 'POST',
  })
}
