import { useState, useCallback, useEffect } from 'react'
import { Modal, TextContainer } from '@shopify/polaris'

import './index.scss'
const Index = (props) => {
  // const isShowGuide = getStore('isShowGuide')

  const [active, setActive] = useState(!window.isShowGuide ? false : true)

  useEffect(() => {
    window.isShowGuide = false
  }, [active])

  const handleChange = useCallback(() => {
    if (!props.showGuide) {
      window.isShowGuide = false
    }
    setActive(!active)
    props.onClose()
  }, [active, props])

  const [current, setCurrent] = useState(0)
  const handleChangeNext = (value) => {
    if (value >= 0 && value < 5) {
      setCurrent(value + 1)
    } else {
      handleChange()
      return
    }
  }
  const handleChangePrev = (value) => {
    if (value > 0 && value <= 5) {
      setCurrent(value - 1)
    } else {
      return
    }
  }

  return (
    <div>
      <Modal
        open={active || props.showGuide}
        onClose={handleChange}
        title={list[current].title}
        primaryAction={{
          content: current === 5 ? 'Close' : 'Next',
          onAction: () => handleChangeNext(current),
        }}
        secondaryActions={
          current === 0
            ? ''
            : [
                {
                  content: 'Prev',
                  onAction: () => handleChangePrev(current),
                },
              ]
        }
      >
        <Modal.Section>
          <TextContainer>
            <div className="guide_image">
              <img
                src={list[current].image}
                alt={list[current].title}
              />
            </div>
            <p className="guide_dsc">{list[current].dsc}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  )
}
const list = [
  {
    id: 1,
    title: 'Step 1',
    dsc: '1. In the product list, select the clothes sample you want to design.',
    image: '/images/guide1.png',
  },
  {
    id: 2,
    title: 'Step 2',
    dsc: "2. After selecting the product to be designed, click 'Design' on the product details page to design.",
    image: '/images/guide2.png',
  },
  {
    id: 3,
    title: 'Step 3',
    dsc: '3. Use the design toold provided by the plateform to design your favorite patterns on the clothes samples you choose.',
    image: '/images/guide3.png',
  },
  {
    id: 4,
    title: 'Step 4',
    dsc: '4. After designing the clothes, you can order clothes samples.',
    image: '/images/guide4.png',
  },
  {
    id: 5,
    title: 'Step 5',
    dsc: '5. After placing an order, the platform will complete the production and printing of the goods, and send them to your hands.',
    image: '/images/guide5.png',
  },
  {
    id: 6,
    title: 'Step 6',
    dsc: '6. After receiving the product, remember to give us a 5-star review.',
    image: '/images/guide6.png',
  },
]
// const
export default Index
