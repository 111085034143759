import { Modal, Autocomplete, Icon, Checkbox } from '@shopify/polaris'
import { SearchMinor } from '@shopify/polaris-icons'
import { useState, useCallback, useMemo, useEffect } from 'react'
import AddIcon from '../../../icons/AddIcon'
const ProductManageTag = (props) => {
  const handleChange = () => {
    setInputValue('')
    setNewTagsData([])
    if (!props.orderList) {
      props.setTagsData(props.tagsData)
    }
    // props.setSelectedOptionsTags(props.selectedOptionsTags)
    setOldSelect(props.selectedOptionsTags)
    props.setManageTags(!props.show)
  }
  const deselectedOptions = useMemo(
    () => [
      // { value: "rustic", label: "Rustic" },
      // { value: "antique", label: "Antique" },
      // { value: "vinyl", label: "Vinyl" },
      // { value: "vintage", label: "Vintage" },
      // { value: "refurbished", label: "Refurbished" },
    ],
    []
  )
  // const [tipTags,setTipTags] = useState('No tags yet')
  const [selectedOptions, setSelectedOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState(deselectedOptions)
  const [loading, setLoading] = useState(false)

  const updateText = useCallback(
    (value) => {
      setInputValue(value)
      if (!loading) {
        setLoading(true)
      }
      setTimeout(() => {
        if (value === '') {
          setOptions(deselectedOptions)
          setLoading(false)
          return
        }
        const filterRegex = new RegExp(value, 'i')
        const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegex))
        setOptions(resultOptions)
        setLoading(false)
      }, 300)
    },
    [deselectedOptions, loading]
  )

  const onClearValue = useCallback((oldValue) => setInputValue(''), [])
  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem)
        })
        return matchedOption && matchedOption.label
      })

      setSelectedOptions(selected)
      setInputValue(selectedValue[0])
    },
    [options]
  )

  const [oldSelect, setOldSelect] = useState(props.selectedOptionsTags)
  useEffect(() => {
    let isMount = true
    if (isMount && props.selectedOptionsTags) {
      setOldSelect(props.selectedOptionsTags)
    }
    return () => (isMount = false)
  }, [props.selectedOptionsTags])
  const handleCkeckedChange = (id, value) => {
    let list = props.selectedOptionsTags
    let data = list.filter((item, ind) => {
      if (value !== item) {
        return item
      } else {
        return !item
      }
    })
    setOldSelect(data)
  }
  const textField = (
    <Autocomplete.TextField
      clearButton
      onChange={updateText}
      value={inputValue}
      onClearButtonClick={() => onClearValue()}
      prefix={
        <div style={{ cursor: 'pointer' }}>
          <Icon
            source={SearchMinor}
            color="base"
          />
        </div>
      }
      placeholder="Tags"
    />
  )
  // 添加新标签
  const [newTagsData, setNewTagsData] = useState([])
  const [newTagsDatas, setNewTagsDatas] = useState([])

  let newT = []
  const newTagsAdd = () => {
    let originalOptions = props.tagsData
    let add_tag = { value: inputValue, label: inputValue }
    if (newTagsData.length > 0) {
      if (props.selectedOptionsTags.includes(inputValue) || newTagsData.includes(inputValue)) {
        originalOptions.push(add_tag)
        setNewTagsDatas(originalOptions)
        newT = newTagsData
      } else {
        newT = [...newTagsData, inputValue]
      }
    } else {
      originalOptions.push(add_tag)
      setNewTagsDatas(originalOptions)
      newT.push(inputValue)
    }
    setNewTagsData(newT)
    setInputValue('')
  }
  // 删除标签
  const tagsAddDelete = (ind, text) => {
    let data = newTagsData.filter((item) => {
      if (item === text) {
        return !item
      } else {
        return item
      }
    })
    setNewTagsData(data)
  }
  // 保存合并标签
  const tagsAddSave = () => {
    let data = [...oldSelect, ...newTagsData]
    if (!props.orderList) {
      props.setTags(data)
      props.setTagsData(newTagsDatas)
    }
    props.setSelectedOptionsTags(data)
    props.setManageTags(false)
    setInputValue('')
    setNewTagsData([])
  }
  return (
    <Modal
      open={props.show}
      onClose={handleChange}
      title="Manage tags"
      primaryAction={{
        content: 'Done',
        onAction: tagsAddSave,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleChange,
        },
      ]}
    >
      <div style={{ padding: '2rem' }}>
        <Autocomplete
          options={options}
          selected={selectedOptions}
          onSelect={updateSelection}
          loading={loading}
          textField={textField}
        />
      </div>
      <div style={{ padding: '0 2rem', height: '300px' }}>
        {/* 查询未找到的，添加 */}
        {inputValue !== '' &&
          inputValue.trim() &&
          !newTagsData.includes(inputValue) &&
          !oldSelect.includes(inputValue) && (
            <div className="tags_search_list">
              <div
                className="tags_search_item"
                onClick={() => newTagsAdd()}
              >
                <div
                  className="tag_add_icon"
                  style={{ marginRight: '5px' }}
                >
                  <AddIcon />
                </div>
                <span>Add "{inputValue}"</span>
              </div>
            </div>
          )}
        <div className="tags_search_result">
          {newTagsData.length > 0 && <div>New Add</div>}
          {newTagsData.length > 0 &&
            newTagsData.map((item, ind) => (
              <div
                key={ind}
                className="tags_search_result_list"
              >
                <Checkbox
                  label={item}
                  checked={true}
                  onChange={() => tagsAddDelete(ind, item)}
                />
              </div>
            ))}
        </div>
        {!props.orderList && (
          <div className="tags_search_result">
            {oldSelect.length > 0 && <div>selected</div>}
            {oldSelect.length > 0 &&
              oldSelect.map((item, ind) => (
                <div
                  key={ind}
                  className="tags_search_result_list"
                >
                  <Checkbox
                    label={item}
                    checked={true}
                    onChange={() => handleCkeckedChange(ind, item)}
                  />
                </div>
              ))}
          </div>
        )}
        {inputValue === '' && oldSelect.length <= 0 && newTagsData.length <= 0 && (
          <p style={{ display: 'flex', justifyContent: 'center' }}>{'No tags yet'}</p>
        )}
      </div>
    </Modal>
  )
}
export default ProductManageTag
