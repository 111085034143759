import React, { useState } from 'react'
import './index.scss'

const PodSize = (props) => {
  const { lable } = props
  const [active, setActive] = useState(-1)
  const handleClickLabel = (index) => {
    setActive(index)
  }
  return (
    <div className="Size_Container">
      {lable.map((item, index) => (
        <div
          key={index}
          className={`Size_box ${index === active ? 'isActive' : ''}`}
          onClick={() => handleClickLabel(index)}
        >
          <span style={{ color: active === index ? '#fff' : '' }}>{item}</span>
        </div>
      ))}
    </div>
  )
}

export default PodSize
