import React from 'react'

const LeftIcon = () => {
  return (
    <div className="Design-Tab-Icon">
      <img
        src="/images/icon/design/left.svg"
        alt="left"
      />
    </div>
  )
}

const HCenterIcon = () => {
  return (
    <div className="Design-Tab-Icon">
      <img
        src="/images/icon/design/h_center.svg"
        alt="h_center"
      />
    </div>
  )
}

const RightIcon = () => {
  return (
    <div className="Design-Tab-Icon">
      <img
        src="/images/icon/design/right.svg"
        alt="right"
      />
    </div>
  )
}

const TopIcon = () => {
  return (
    <div className="Design-Tab-Icon">
      <img
        src="/images/icon/design/top.svg"
        alt="top"
      />
    </div>
  )
}

const VCenterIcon = () => {
  return (
    <div className="Design-Tab-Icon">
      <img
        src="/images/icon/design/v_center.svg"
        alt="v_center"
      />
    </div>
  )
}

const BottomIcon = () => {
  return (
    <div className="Design-Tab-Icon">
      <img
        src="/images/icon/design/bottom.svg"
        alt="bottom"
      />
    </div>
  )
}

const DragIcon = () => {
  return (
    <div className="Design-Layer-Drag-Icon">
      <img
        src="/images/icon/design/drag.svg"
        alt="drag"
      />
    </div>
  )
}

const HideIcon = ({ isHide, isLock }) => {
  let src = '/images/icon/design/view_show.svg'
  if (isLock) {
    if (isHide) {
      src = '/images/icon/design/view_hide_d.svg'
    } else {
      src = '/images/icon/design/view_show_d.svg'
    }
  } else {
    if (isHide) {
      src = '/images/icon/design/view_hide.svg'
    } else {
      src = '/images/icon/design/view_show.svg'
    }
  }
  return (
    <div
      className="Polaris-Icon"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <img
        src={src}
        alt="view"
      />
    </div>
  )
}

const DuplicateIcon = ({ isLock }) => {
  return (
    <div
      className="Polaris-Icon"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <img
        src={isLock ? '/images/icon/design/duplicate_d.svg' : '/images/icon/design/duplicate.svg'}
        alt="duplicate"
      />
    </div>
  )
}

const UndoIcon = ({ isLock }) => {
  return (
    <div
      className="Polaris-Icon"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <img
        src={isLock ? '/images/icon/design/undo_d.svg' : '/images/icon/design/undo.svg'}
        alt="undo"
      />
    </div>
  )
}

const LockIcon = ({ isLock }) => {
  return (
    <div
      className="Polaris-Icon"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <img
        src={isLock ? '/images/icon/design/lock.svg' : '/images/icon/design/lock_d.svg'}
        alt="lock"
      />
    </div>
  )
}

const DeleteIcon = ({ isLock }) => {
  return (
    <div
      className="Polaris-Icon"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <img
        src={isLock ? '/images/icon/design/delete_d.svg' : '/images/icon/design/delete.svg'}
        alt="delete"
      />
    </div>
  )
}

export {
  LeftIcon,
  HCenterIcon,
  RightIcon,
  TopIcon,
  VCenterIcon,
  BottomIcon,
  DragIcon,
  HideIcon,
  DuplicateIcon,
  UndoIcon,
  LockIcon,
  DeleteIcon,
}
