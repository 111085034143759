import { useState, useEffect, useMemo, useCallback } from 'react'
import { Card, Button, IndexTable, Badge, Pagination, Toast, EmptyState } from '@shopify/polaris'
import { useGetState } from 'ahooks'
import _ from 'lodash'
// 骨架屏
import LoadingPage from './components/LoadingPage'
// 充值
import Charge from './components/Charge'
// 提现
import Withdraw from './components/Withdraw'
// 接口
import BillingService from '@/api/settings/billing'
// 引入样式文件
import './index.scss'

const Billing = () => {
  const [loading, setLoading] = useState(false)
  const [myBalance, setMyBalance] = useState(0) // 余额
  const [pageNum, setPageNum, getPageNum] = useGetState(1) // 当前页
  const [pageSize] = useState(10) // 分页条数
  const [total, setTotal] = useState(0) // 总条数
  const [tableData, setTableData] = useState([]) // 账单列表
  const [showCharge, setShowCharge] = useState(false) // 充值弹窗是否显示
  const [showWithdraw, setShowWithdraw] = useState(false) // 提现弹窗是否显示

  // Toast提示
  const [showToast, setShowToast] = useState(false)
  const [toastContent, setToastContent] = useState('')

  useEffect(() => {
    initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 初始化数据
  const initData = () => {
    setLoading(true)
    Promise.all([getBalance(), getWalletData()])
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  // 获取余额
  const getBalance = async () => {
    const res = await BillingService.getBalance()
    if (res) {
      setMyBalance(res.money)
    } else {
      setMyBalance(0)
    }
  }

  // 上一页
  const handlePrev = async () => {
    await setPageNum((preState) => preState - 1)
    getWalletData()
  }

  // 下一页
  const handleNext = async () => {
    await setPageNum((preState) => preState + 1)
    getWalletData()
  }

  // 获取账单列表
  const getWalletData = async () => {
    const res = await BillingService.getWalletList({
      pageNum: getPageNum(),
      perPage: pageSize,
    })
    if (res) {
      setTotal(res.count)
      setTableData(res.dataList)
    } else {
      setTotal(0)
      setTableData([])
    }
  }

  // 关闭充值弹窗
  const handleCloseCharge = () => {
    setShowCharge(false)
  }

  // 充值成功刷新页面
  const handleChargeSuccess = async () => {
    setPageNum(1)
    setTimeout(() => {
      initData()
    })
  }

  // 关闭提现弹窗
  const handleCloseWithdraw = () => {
    setShowWithdraw(false)
  }

  // 提现成功刷新页面
  const handleConfirmWithdraw = () => {
    setShowToast(true)
    setToastContent('Withdrawal application successful')
    setPageNum(1)
    setTimeout(() => {
      initData()
    })
  }

  // 状态
  const getStatus = (status) => {
    const list = [
      { name: 'pending', status: 'attention' },
      { name: 'succeed', status: 'success' },
      { name: 'Paid', status: 'success' },
      { name: 'Refunded', status: '' },
      { name: 'Partially refunded', status: '' },
    ]
    return _.get(
      _.find(list, (item) => {
        return _.toLower(item.name) === _.toLower(status)
      }),
      'status'
    )
  }

  // 总条数
  const pageMsg = useMemo(() => {
    const startNum = (pageNum - 1) * pageSize + 1
    const endNum = pageNum * pageSize > total ? total : pageNum * pageSize
    return `Showing ${startNum} - ${endNum} of ${total} results`
  }, [pageNum, pageSize, total])

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const maxPageNum = _.ceil(total / pageSize)
    if (pageNum > 1 && pageNum <= maxPageNum) {
      return true
    } else if (pageNum === 1) {
      return false
    }
  }, [total, pageNum, pageSize])

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const maxPageNum = _.ceil(total / pageSize)
    if (_.inRange(pageNum, 1, maxPageNum)) {
      return true
    } else if (pageNum === maxPageNum) {
      return false
    }
  }, [total, pageNum, pageSize])

  const rowMarkup = _.map(
    tableData,
    ({ actionType, createdAt, statement, status, amount }, index) => (
      <IndexTable.Row
        id={index}
        key={index}
        position={index}
      >
        <IndexTable.Cell>{actionType}</IndexTable.Cell>
        <IndexTable.Cell>{createdAt}</IndexTable.Cell>
        <IndexTable.Cell>{statement}</IndexTable.Cell>
        <IndexTable.Cell>
          <Badge status={getStatus(status)}>{status}</Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>{`$ ${amount}`}</IndexTable.Cell>
      </IndexTable.Row>
    )
  )

  // 显示toast
  const toggleActive = useCallback(() => setShowToast((active) => !active), [])

  // Toast提示
  const toastMarkup = showToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActive}
    />
  ) : null

  return (
    <div className="Billing">
      {/* 骨架屏 */}
      {loading && <LoadingPage />}

      {/* 正文区域 */}
      {!loading && (
        <>
          <Card>
            <Card.Section>
              <div className="Billing-Title">
                <h3>Wallet</h3>
                <p>Recharge in advance, withdraw at any time</p>
              </div>
            </Card.Section>
            <Card.Section>
              <div
                className="row"
                style={{ padding: '0 2rem' }}
              >
                <div className="Billing-Balance col-6">$ {myBalance} USD</div>
                <div className="Billing-Btn-Group col-6">
                  <div className="Billing-Btn">
                    <Button
                      primary
                      fullWidth
                      onClick={() => {
                        setShowWithdraw(true)
                      }}
                      disabled={myBalance <= 0}
                    >
                      Withdraw
                    </Button>
                    <Button
                      primary
                      fullWidth
                      onClick={() => {
                        setShowCharge(true)
                      }}
                    >
                      Charge
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Section>
          </Card>

          <Card>
            <Card.Section>
              <div className="Billing-Title">
                <h3>Bills</h3>
              </div>
            </Card.Section>

            <Card.Section flush>
              {tableData.length > 0 ? (
                <IndexTable
                  itemCount={tableData.length}
                  headings={[
                    { title: 'Type' },
                    { title: 'Date' },
                    { title: 'Detail' },
                    { title: 'Status' },
                    { title: 'Total' },
                  ]}
                  selectable={false}
                >
                  {rowMarkup}
                </IndexTable>
              ) : (
                <EmptyState
                  heading="There are no charges"
                  image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                ></EmptyState>
              )}

              {tableData.length > 0 && (
                <div className="Pagination-Box">
                  <Pagination
                    label={pageMsg}
                    hasPrevious={hasPrevious}
                    hasNext={hasNext}
                    onPrevious={handlePrev}
                    onNext={handleNext}
                  />
                </div>
              )}
            </Card.Section>
          </Card>
        </>
      )}

      {/* 充值弹窗 */}
      <Charge
        isShow={showCharge}
        onClose={handleCloseCharge}
        onConfirm={handleChargeSuccess}
      />

      {/* 提现弹窗 */}
      <Withdraw
        isShow={showWithdraw}
        onClose={handleCloseWithdraw}
        onConfirm={handleConfirmWithdraw}
      />

      {/* Toast提示 */}
      {toastMarkup}
    </div>
  )
}

export default Billing
