/*
 * @Description: Terms of service
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-09-21 15:58:43
 * @LastEditTime: 2023-01-04 11:47:44
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Setting\TermsOfService\index.jsx
 */
import { useEffect, useState } from 'react'
import { Card } from '@shopify/polaris'
import axios from 'axios'
import './index.scss'

const TermsOfService = () => {
  const [content, setContent] = useState('')

  useEffect(() => {
    getData()
  }, [])

  // 获取内容
  const getData = async () => {
    const res = await axios.get('/static/terms.json')
    if (res && res.status === 200 && res.data) {
      setContent(res.data.data)
    }
  }

  return (
    <div>
      <Card>
        <Card.Section>
          <div
            className="TermsOfService"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </Card.Section>
      </Card>
    </div>
  )
}
export default TermsOfService
