/*
 * @Description: 订单列表items项
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-09 10:06:16
 * @LastEditTime: 2023-02-17 09:56:11
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Orders\ChildrenPages\ProductItems\index.jsx
 */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingSearch from '@/components/LoadingSearch'
import Image from '@/components/Image'
import _ from 'lodash'
// 引入oss请求
import { ossSignatureUrl } from '@/utils/utils'
// 引入api
import { getProductOrderData } from '@/api/orderBuild'
import './index.scss'

/**
 * 参数说明
 * @param {Number | String} orderId 订单id
 */
const ProductItems = ({ orderId }) => {
  const router = useHistory()

  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState([])

  useEffect(() => {
    getData()
  }, [orderId])

  // 根据订单id查询订单详情
  const getData = async () => {
    if (!orderId) return
    setLoading(true)
    const res = await getProductOrderData({ id: orderId })
    if (res) {
      setOrderData(res.orderDetailData)
    }
    setLoading(false)
  }

  // 跳转至产品详情
  const toDetail = (item) => {
    router.push({
      pathname: '/productContentEdit',
      state: {
        finishedId: item.finishedId,
      },
    })
  }

  return (
    <div
      className="Orders-Items"
      onClick={(e) => e.stopPropagation()}
    >
      {loading && (
        <LoadingSearch
          width="400px"
          height="120px"
          size="small"
        />
      )}
      {!loading && (
        <ul className="Orders-List">
          {_.map(orderData, (item, index) => {
            return (
              <li
                key={index}
                className="row"
                style={{ gap: 0 }}
              >
                <div className="Orders-List-Image col-2">
                  <div className="Orders-List-Thumbnail">
                    <Image
                      src={ossSignatureUrl(item.imageUrl, {
                        'x-oss-process': 'image/resize,w_40',
                      })}
                      size={36}
                    />
                    <div className="Orders-List-Badge">{item.quantity}</div>
                  </div>
                </div>
                <div className="Orders-List-Content col-7">
                  <h3
                    onClick={() => toDetail(item)}
                    title={item.title}
                  >
                    {item.title}
                  </h3>
                  <div style={{ margin: '0.8rem 0' }}>
                    {item.color} / {item.size}
                  </div>
                  <div>SKU: {item.sku}</div>
                </div>
                <div className="Orders-List-Price col-3">$ {item.total}</div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default ProductItems
