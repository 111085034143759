/*
 * @Description: 首页相关接口
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-22 17:10:11
 * @LastEditTime: 2022-12-29 09:42:19
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\api\home\index.js
 */
import { request } from '../request'

/**
 * 发送用户反馈
 * @param {Object} params 请求参数
 * @returns
 */
export async function addOpinion(data) {
  return request({
    url: '/api/v1/addOpinion',
    method: 'POST',
    data,
  })
}
