import { useState, useEffect, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useGetState } from 'ahooks'
// 引入fabric
import { fabric } from 'fabric'
import '@/utils/canvas_config'
import { conditionsFavor } from '@/api/favorable/index'
import store from '@/redux/store'
import { createCheckFavorable } from '@/redux/action'
import {
  Card,
  Tabs,
  ButtonGroup,
  TextField,
  Button,
  Icon,
  Tooltip,
  Autocomplete,
  RangeSlider,
  Toast,
} from '@shopify/polaris'
import {
  TypeMajor,
  ImagesMajor,
  FolderUpMajor,
  VariantMajor,
  UndoMajor,
  RedoMajor,
} from '@shopify/polaris-icons'
// 引入自定义icon组件
import {
  LeftIcon,
  HCenterIcon,
  RightIcon,
  TopIcon,
  VCenterIcon,
  BottomIcon,
} from './components/ViewComponent'
// 图库
import ImageLibrary from './components/ImageLibrary'
// 颜色预览
import ColorsPreview from './components/ColorsPreview'
// 变体弹窗
import Variant from '@/components/Variant'
// 离开页面弹窗
import LeavePage from '@/components/LeavePage'
// 颜色选择器
import ColorPicker from '@/components/ColorPicker'
// 字体下拉
import Config from '@/utils/config'
// 字体
import WebFont from 'webfontloader'
// lodash
import _ from 'lodash'
// 图层组件
import LayersComponent from './components/Layers'
import { arrayMoveImmutable } from 'array-move'
// 请求商品详情
import { getCatelogOrDesignDetails } from '@/api/catelog'
// 请求成品详情
import { getProductContent } from '@/api/productContentEdit/index'
// 引入oss请求
import { ossSignatureUrl } from '@/utils/utils'
// 引入公共方法
import { getGuid, checkWebp } from '@/utils/utils'
// 引入样式文件
import './index.scss'

const ConfugureDesign = () => {
  const history = useHistory()
  const isCheckWebp = checkWebp() // 是否支持webp格式图片
  const routeState = history.location.state // 路由参数
  // 导航菜单高度
  const headerTop = document.querySelector('.Polaris-Frame__TopBar')
    ? document.querySelector('.Polaris-Frame__TopBar').offsetHeight
    : 56
  // Tabs
  const tabs = [
    { id: 'Attribute', content: 'Attribute' },
    { id: 'Layer', content: 'Layer' },
  ]
  // 谷歌字体下拉数据源
  const [allFonts, setAllFonts] = useState(Config.GoogleFonts)
  // Card 高度
  const [cardHeight, setCardHeight] = useState(document.body.offsetHeight - headerTop)
  // 当前视图
  const [currentView, setCurrentView, getCurrentView] = useGetState('Front')
  // 右侧Tab
  const [currentTab, setCurrentTab] = useState(0)
  // 画布属性
  const [canvasParam, setCanvasParam, getCanvasParam] = useGetState({
    scale: 1, // 画布缩放
    minScale: 0.3, // 最小缩放
    maxScale: 2, // 最大缩放
    clipPath: null, // 画布裁剪路径
    backgroundImage: {
      Front: '', // 前视图
      Back: '', // 后视图
    },
  })
  // 设计数据json
  const [canvasJson, setCanvasJson, getCanvasJson] = useGetState({
    Front: {}, // 前视图Json
    Back: {}, // 后视图Json
  })
  // 设计图
  const [canvasImage, setCanvasImage, getCanvasImage] = useGetState({
    Front: null,
    Back: null,
  })
  // 属性
  const [attribute, setAttribute, getAttribute] = useGetState({
    textValue: '',
    fontFamily: 'Roboto',
    fontColor: '#000000',
    angle: 0,
    scale: 1,
    left: 0,
    top: 0,
    flipX: false, // 是否水平翻转
    flipY: false, // 是否垂直翻转
  })
  /**
   * 图层示例数据
   * {
   *   id: '', // 唯一标识
   *   type: 'Image', // 区分图片和文本【Text: 文本, Image: 图片】
   *   Itext: '', // 文本
   *   Image: '', // 图片
   *   content: '' // 【type为Text, 则为文本内容】【type为Image, 则为图片路径】
   * }
   */
  const [currentLayer, setCurrentLayer] = useState(null) // 当前点击图层
  const [layers, setLayers, getLayers] = useGetState([])
  const [productInfo, setProductInfo, getProductInfo] = useGetState({}) // 商品详情
  const [defaultImage, setDefaultImage] = useState('') // 默认展示前视图第一张
  const [isShowToast, setIsShowToast] = useState(false) // 是否显示消息提示
  const [toastMsg, setToastMsg] = useState('') // 消息提示内容
  const [isDrag, setIsDrag, getIsDrag] = useGetState(false) // 是否处于拖拽状态

  // 设计区域参数
  const designArea = {
    width: 160,
    height: 250,
    type: 'Rect',
  }
  let fabricCanvas = {} // 画布

  // 图库弹窗
  const [isShowImageModal, setIsShowImageModal] = useState(false)
  const [imageTab, setImageTab] = useState('Recommend') // 默认为推荐图片

  // 变体弹窗
  const [isShowVariantModal, setIsShowVariantModal] = useState(false)
  const [checkVariants, setCheckVariants, getCheckVariants] = useGetState([])

  // 离开当前页
  const [isShowLeavePage, setIsShowLeavePage] = useState(false)
  const [lastRouterPath, setLastRouterPath] = useState('')

  const [historyUndo, setHistoryUndo, getHistoryUndo] = useGetState({
    Front: [],
    Back: [],
  }) // 撤销
  const [historyRedo, setHistoryRedo, getHistoryRedo] = useGetState({
    Front: [],
    Back: [],
  }) // 下一步

  const checkFavorableRef = useRef(null)
  useEffect(() => {
    initCanvas()
    initData()
    window.addEventListener('resize', _.debounce(watchWindowSize, 30))
    window.delObject = handleDelLayer
    WebFont.load({
      // 加载谷歌字体
      google: {
        families: Config.families,
      },
    })

    // 路由拦截
    history.block((param) => {
      if (!isDisabled() && param.pathname !== '/productContentEdit') {
        setLastRouterPath(param.pathname)
        setIsShowLeavePage(true)
        return false
      }
      return true
    })

    const unSubscribe = store.subscribe(() => {
      console.log('design')
      const checkFavorable = store.getState().favorable
      console.log('design', checkFavorable)
      if (!_.isEmpty(checkFavorable)) {
        checkFavorableRef.current = checkFavorable
        // setCheckFavorable(checkFavorable)
      }
    })
    return () => unSubscribe()
  }, [history])

  // 判断改产品是否符合引导好评弹窗条件
  const checkFavorIsShowModal = async () => {
    const response = await conditionsFavor()
    store.dispatch(createCheckFavorable(response))
  }

  // 获取商品详情
  const initData = async () => {
    const id = routeState.productId // 解构品类id
    const res = await getCatelogOrDesignDetails({ id })
    if (res) {
      await setProductInfo(res)
      await getDesignImages(res.images) // 获取设计背景图
      // 是否支持
      if (isCheckWebp) {
        // 查找webp设计图
        const firstWebp = _.find(res.images.front, (o) => {
          return _.includes(_.toLower(o), '.webp')
        })
        if (firstWebp) {
          await setDefaultImage(firstWebp)
        } else {
          await setDefaultImage(res.images.front[0])
        }
      } else {
        await setDefaultImage(res.images.front[0])
      }
      // 因useState值更新为异步，所以设置延时
      setTimeout(() => {
        initCanvasContent() // 初始化画布内容
      }, 30)
    }
  }

  // 获取设计背景图
  const getDesignImages = async (images) => {
    let [Front, Back] = [null, null]
    const frontWebpImages = _.filter(images.front, (fileName) => {
      return _.includes(_.toLower(fileName), _.toLower('.webp'))
    })
    const frontPngImages = _.filter(images.front, (fileName) => {
      return _.includes(_.toLower(fileName), _.toLower('.png'))
    })
    const rearWebpImages = _.filter(images.rear, (fileName) => {
      return _.includes(_.toLower(fileName), _.toLower('.webp'))
    })
    const rearPngImages = _.filter(images.rear, (fileName) => {
      return _.includes(_.toLower(fileName), _.toLower('.png'))
    })

    // 浏览器支持webp格式图片
    if (isCheckWebp) {
      // 如果前视图webp图片为空
      if (_.isEmpty(frontWebpImages)) {
        Front = ossSignatureUrl(frontPngImages[0]) // 获取前视图背景
      } else {
        Front = ossSignatureUrl(frontWebpImages[0]) // 获取前视图背景
      }

      // 如果后视图webp图片为空
      if (_.isEmpty(rearWebpImages)) {
        Back = rearPngImages[0] ? ossSignatureUrl(rearPngImages[0]) : null // 获取后视图背景
      } else {
        Back = ossSignatureUrl(rearWebpImages[0]) // 获取后视图背景
      }
    } else {
      Front = ossSignatureUrl(frontPngImages[0])
      Back = rearPngImages[0] ? ossSignatureUrl(rearPngImages[0]) : null
    }

    await setCanvasParam((preSatate) => ({
      ...preSatate,
      backgroundImage: {
        Front,
        Back,
      },
    }))
  }

  // 切换视图
  const handleClickView = useCallback((view) => {
    updateCanvasImage() // 更新设计图
    const currentView = getCurrentView()
    if (currentView === view) {
      return
    }

    // 切换视图
    setCurrentView(view)
    // 因useState值更新为异步，所以设置延时
    setTimeout(() => {
      const canvasJson = getCanvasJson()
      console.log('重新初始化', console.log('canvasParam', canvasJson))
      fabricCanvas.loadFromJSON(canvasJson[view], () => {
        console.log('加载成功', console.log('canvasParam', canvasJson))
        if (_.isEmpty(canvasJson[view]) || !fabricCanvas.backgroundImage) {
          console.log('重新初始化', console.log('canvasParam', canvasJson))
          initBackgroundImage()
        }
      })
    })
  }, [])

  // 初始化画布
  const initCanvas = () => {
    let [width, height] = [500, 500]
    const DesignCanvas = document.querySelector('.Design-Canvas')
    if (DesignCanvas) {
      ;[width, height] = [DesignCanvas.offsetWidth, DesignCanvas.offsetHeight]
    }
    if (_.isEmpty(fabricCanvas)) {
      // 初始化画布
      fabricCanvas = new fabric.Canvas('PringCanvas', {
        hoverCursor: 'pointer', // 鼠标浮动变化
        selection: false, // 画布是否可通过鼠标框选。默认true；false 不可选中
        stopContextMenu: true, // 禁用鼠标右键
        width,
        height,
      })
      // 初始化完成，对画布添加监听事件
      handleAddCanvasEvent()
    } else {
      // 如果窗口高度改变了，则canas区域也跟着改变
      fabricCanvas.set({
        width,
        height,
      })
    }
  }

  /**
   * 从产品内容编辑页面跳转
   */
  const onLoadHistoryData = () => {
    const jsonData = routeState.canvasJson
    setCanvasJson(jsonData)
    setCanvasImage(routeState.canvasImage)

    // 设置勾选的变体
    const productInfo = getProductInfo()
    const variants = _.filter(productInfo.variantData, (item) => {
      if (_.includes(routeState.variantIds, item.variantId)) {
        item.checked = true
      } else {
        item.checked = false
      }
      return _.includes(routeState.variantIds, item.variantId)
    })
    setCheckVariants(variants)

    setTimeout(() => {
      fabricCanvas.loadFromJSON(jsonData[currentView], () => {
        if (_.isEmpty(jsonData[currentView])) {
          initBackgroundImage()
        }
        const areaRect = _.find(fabricCanvas.getObjects(), (item) => {
          return item.type === 'rect'
        })
        if (areaRect) {
          setCanvasParam((preSatate) => ({
            ...preSatate,
            clipPath: areaRect,
          }))
          setAreaControl()
        }
        console.log('area', areaRect, fabricCanvas)
        fabricCanvas.renderAll()
      })
    })
  }

  /**
   * 从产品列表或者产品内容编辑跳转，并且路由没有携带设计参数
   */
  const initFinishData = async () => {
    const finishedId = routeState.finishedId
    const res = await getProductContent({ finishedId })
    if (res) {
      const canvasJson = {
        Front: res.frontData.design,
        Back: res.rearData.design,
      }
      setCanvasJson(canvasJson)

      const canvasImage = {
        Front: res.frontData.frontUrl,
        Back: res.rearData.frontUrl,
      }
      setCanvasImage(canvasImage)

      // 设置变体勾选状态
      const ids = _.map(res.variantsData, 'podVariantId')
      const variants = _.filter(res.productVariant, (item) => {
        if (_.includes(ids, item.variantId)) {
          item.checked = true
        } else {
          item.checked = false
        }
        return _.includes(ids, item.variantId)
      })
      setCheckVariants(variants)

      setTimeout(() => {
        fabricCanvas.loadFromJSON(canvasJson[currentView], () => {
          // 如果背景图加载失败，则重新加载
          if (!fabricCanvas.backgroundImage) {
            initBackgroundImage()
          }
          // 设置裁剪路径
          const areaRect = _.find(fabricCanvas.getObjects(), (item) => {
            return item.type === 'rect'
          })
          if (areaRect) {
            setCanvasParam((preSatate) => ({
              ...preSatate,
              clipPath: areaRect,
            }))
            setAreaControl() // 设计区域控制状态
          }
        })
      })
    }
  }

  /**
   * 初始化画布内容
   */
  const initCanvasContent = () => {
    if (routeState.canvasJson) {
      // 如果从产品内容编辑页面跳转回来，则加载json数据
      onLoadHistoryData() // 加载json数据
    } else if (routeState.finishedId) {
      // 如果是从产品列表或者产品内容编辑过来，并且路由没有携带设计参数，则重新通过接口获取之前的设计
      initFinishData()
    } else {
      initBackgroundImage()
    }
  }

  /**
   * 初始化背景图
   */
  const initBackgroundImage = async () => {
    // 获取画布属性
    const canvasParam = getCanvasParam()

    // 商品详情不为空才可进行下一步
    if (_.isEmpty(getProductInfo())) {
      return
    }

    // 获取当前视图名称
    const viewName = getCurrentView()

    // 默认获取前后视图背景图
    const imageUrl = canvasParam.backgroundImage[viewName]
    const productInfo = getProductInfo()

    fabric.Image.fromURL(
      imageUrl,
      (oImg, isError) => {
        // 图片加载失败时，提示错误
        if (isError) {
          showToast('Resource loading failed')
          return
        }

        console.log('getCurrentViewJson', getCurrentViewJson())
        if (_.isEmpty(getCurrentViewJson())) {
          initArea() // 初始化设计区域
        }

        // 计算画布缩放系数
        const scale = (fabricCanvas.height - 172) / oImg.height
        // 设置画布缩放系数
        setCanvasParam((preSatate) => ({
          ...preSatate,
          scale,
        }))
        console.log('scale', scale, attribute)
        oImg.set({
          scaleX: scale,
          scaleY: scale,
        })
        let defaultColor = '#fff' // 衣服默认颜色
        if (routeState.color) {
          defaultColor = routeState.color
        } else {
          defaultColor = productInfo.color[0]
        }
        fabricCanvas.setBackgroundImage(oImg, fabricCanvas.renderAll.bind(fabricCanvas), {
          // 背景图居中
          left: (fabricCanvas.width - oImg.width * scale) / 2,
          top: (fabricCanvas.height - oImg.height * scale) / 2,
          backgroundColor: defaultColor, // 设置衣服默认颜色
        })
        fabricCanvas.renderAll()
      },
      {
        crossOrigin: 'anonymous',
      }
    )
  }

  // 初始化设计区域
  const initArea = () => {
    // 清除画布内容
    fabricCanvas.clear()

    const areaRect = new fabric.Rect({
      width: designArea.width,
      height: designArea.height,
      borderColor: 'aqua',
      cornerStyle: 'circle',
      transparentCorners: false,
      stroke: '#8C9196',
      strokeWidth: 1,
      strokeDashArray: [5, 5],
      fill: 'rgba(255, 255, 255, 0.16)', // transparent
      visible: true,
      rx: 4, // 设置圆角
      ry: 4, // 设置圆角
    })
    // 获取当前对象在画布的中心点
    const center = getObjectCenter(areaRect)
    areaRect.set({
      top: center.top,
      left: center.left,
    })
    setCanvasParam((preSatate) => ({
      ...preSatate,
      clipPath: areaRect,
    }))
    fabricCanvas.add(areaRect)
    setAreaControl() // 设计区域控制状态
    initAreaContent() // 初始化设计区域内容
    fabricCanvas.renderAll()
  }

  // 更新设计区域位置
  const updateAreaLayout = () => {
    const area = _.find(fabricCanvas.getObjects(), (item) => {
      return item.type === 'rect'
    })
    const center = getObjectCenter(area)
    area.set({
      top: center.top,
      left: center.left,
    })

    _.forEach(fabricCanvas.getObjects(), (item) => {
      if (item.type !== 'rect') {
        const offsetTop = item.top - item.clipPath.top
        const offsetLeft = item.left - item.clipPath.left

        item.top = center.top + offsetTop
        item.left = center.left + offsetLeft
        item.clipPath.set({
          top: center.top,
          left: center.left,
        })
        fabricCanvas.requestRenderAll()
      }
    })
    setCanvasParam((preSatate) => ({
      ...preSatate,
      clipPath: area,
    }))
  }

  // 更新设计区域样式
  const updateArea = () => {
    const canvasParam = getCanvasParam()
    // 获取图层，不包含设计区域
    const layers = _.filter(fabricCanvas.getObjects(), (item) => {
      return item.type !== 'rect'
    })
    const area = canvasParam.clipPath
    if (_.isEmpty(layers)) {
      area.set({
        fill: 'rgba(255, 255, 255, 0.16)', // 设置填充背景
        stroke: '#8C9196', // 设置边框颜色
      })
    } else {
      area.set({
        fill: 'transparent', // 背景透明
        //stroke: 'transparent', // 边框透明
      })
    }
    setCanvasParam((preSatate) => ({
      ...preSatate,
      clipPath: area,
    }))
  }

  // 设计区域控制状态
  const setAreaControl = () => {
    const area = _.find(fabricCanvas.getObjects(), (item) => {
      return item.type === 'rect'
    })
    if (area) {
      area.set({
        absolutePositioned: true, // 必须为true
        objectCaching: false, // 必须为false
        selectable: false, // 是否能选中
        hasControls: false, // 是否能控制，如拖动、删除等
        evented: false,
      })
    }
  }

  /**
   * 初始化设计区域内容
   */
  const initAreaContent = () => {
    // 获取画布图层，不包含绘制的矩形区域以及非初始化内容
    const layerList = _.filter(fabricCanvas.getObjects(), (el) => {
      return el.type !== 'rect' && !el.isInitContent
    })
    // 如果画布没有任何图层，则显示默认图层
    if (_.isEmpty(layerList)) {
      const area = _.find(fabricCanvas.getObjects(), (item) => {
        return item.type === 'rect'
      })
      const center = area.getCenterPoint()
      const textbox = new fabric.Textbox(' Drop your design here', {
        isInitContent: true, // 是否初始化内容
        width: 100,
        fontSize: 20,
        splitByGrapheme: true, // 自动换行
        textAlign: 'center', // 文字居中
        hasControls: false, // 不可操作
        selectable: false, // 不可选中
      })
      // 设置居中
      textbox.set({
        top: center.y - textbox.height / 2 + 10, // 向下偏移10px
        left: center.x - textbox.width / 2,
      })
      fabricCanvas.add(textbox)
      fabric.Image.fromURL('/images/icon/design/default_image.png', (oImg) => {
        const imgWidth = 18
        oImg.scaleToWidth(imgWidth)
        oImg.set({
          isInitContent: true, // 是否初始化内容
          top: center.y - imgWidth / 2 - 30, // 向上偏移30px
          left: center.x - imgWidth / 2,
          hasControls: false, // 不可操作
          selectable: false, // 不可选中
        })
        fabricCanvas.add(oImg)
        fabricCanvas.renderAll()
      })

      console.log('favb', fabricCanvas)
    }
  }

  // 清除设计区域初始化内容
  const clearAreaContent = () => {
    // 筛选出除设计区域及初始化内容
    const clearList = _.filter(fabricCanvas.getObjects(), (item) => {
      return item.type !== 'rect' && item.isInitContent
    })
    _.forEach(clearList, (el) => {
      fabricCanvas.remove(el)
    })
    fabricCanvas.renderAll()
  }

  // 监听画布事件
  const handleAddCanvasEvent = () => {
    // 鼠标点击
    fabricCanvas.on('mouse:down', (options) => {
      handleSetAttribute() // 设置属性
      handleClickLayer() // 选中图层
      const isDrag = getIsDrag()
      if (options.e.altKey === true || isDrag) {
        fabricCanvas.defaultCursor = 'move'
        // 是否按住alt或是否处于拖拽状态
        fabricCanvas.isDragging = true // isDragging 是自定义的
        fabricCanvas.lastPosX = options.e.clientX // lastPosX 是自定义的
        fabricCanvas.lastPosY = options.e.clientY // lastPosY 是自定义的
        fabricCanvas.renderAll()
      } else {
        fabricCanvas.defaultCursor = 'default'
        fabricCanvas.renderAll()
      }
    })

    // 鼠标移出
    fabricCanvas.on('mouse:over', (options) => {
      // console.log('options', options)
    })

    // 鼠标滚轮
    fabricCanvas.on('mouse:wheel', (options) => {
      console.log('options', options)
      const canvasParam = getCanvasParam()
      const delta = options.e.deltaY // 滚轮，向上滚一下是 -100，向下滚一下是 100
      let zoom = fabricCanvas.getZoom() // 获取画布当前缩放值
      zoom *= 0.999 ** delta
      console.log('zoom', zoom)
      if (zoom > canvasParam.maxScale) zoom = canvasParam.maxScale
      if (zoom < canvasParam.minScale) zoom = canvasParam.minScale
      fabricCanvas.zoomToPoint(
        {
          // 关键点
          x: options.e.offsetX,
          y: options.e.offsetY,
        },
        zoom
      )
      options.e.preventDefault()
      options.e.stopPropagation()
    })

    // 鼠标移动
    fabricCanvas.on('mouse:move', (options) => {
      if (fabricCanvas.isDragging) {
        let evt = options.e
        let vpt = fabricCanvas.viewportTransform // 聚焦视图的转换
        vpt[4] += evt.clientX - fabricCanvas.lastPosX
        vpt[5] += evt.clientY - fabricCanvas.lastPosY
        fabricCanvas.requestRenderAll()
        fabricCanvas.lastPosX = evt.clientX
        fabricCanvas.lastPosY = evt.clientY
      }
    })

    // 鼠标松开
    fabricCanvas.on('mouse:up', (opt) => {
      fabricCanvas.setViewportTransform(fabricCanvas.viewportTransform) // 设置此画布实例的视口转换
      fabricCanvas.isDragging = false
    })

    // 添加对象
    fabricCanvas.on('object:added', (options) => {
      console.log('添加对象', options)
      updateCurrentViewJson() // 更新当前视图JSON数据
      updateLayers('add', options.target) // 更新图层
      setTimeout(() => {
        handleSetAttribute() // 设置属性
      })
    })

    // 更新对象
    fabricCanvas.on('object:modified', (options) => {
      console.log('更新对象')
      updateCurrentViewJson() // 更新当前视图JSON数据
      updateLayers('update', options.target) // 更新图层
      updateHistory('update', options.target) // 更新历史操作及下一步
    })

    // 删除对象
    fabricCanvas.on('object:removed', (options) => {
      console.log('删除对象', options)
      updateCurrentViewJson() // 更新当前视图JSON数据
      updateLayers('remove', options.target) // 更新图层
    })

    // 对象旋转事件
    fabricCanvas.on('object:rotating', (options) => {
      setAttribute((preSatate) => ({
        ...preSatate,
        angle: _.round(options.target.angle),
      }))
    })

    // 对象缩放事件
    fabricCanvas.on('object:scaling', (options) => {
      setAttribute((preSatate) => ({
        ...preSatate,
        scale: _.round(options.target.scaleX, 1),
      }))
    })

    // 对象移动事件
    fabricCanvas.on('object:moving', (options) => {
      handleSetAttribute() // 设置属性
    })
  }

  // 监听窗口高度变化
  const watchWindowSize = () => {
    // 重新设置card高度
    const cardHeight = document.body.offsetHeight
    setCardHeight(cardHeight - headerTop)

    // 重新渲染canvas宽高、背景图以及设计区域尺寸缩放
    const productInfo = getProductInfo()
    if (!_.isEmpty(productInfo)) {
      initCanvas()
      initBackgroundImage() // 重新设置背景图尺寸
      updateAreaLayout()
    }
  }

  // 取消
  const handleCancel = () => {
    history.push('/catalog')
  }

  // 继续
  const handleContinue = useCallback(async () => {
    checkFavorIsShowModal()

    await updateCanvasImage() // 更新当前视图下的设计图

    // 如果没有勾选变体，则打开变体弹窗
    if (_.isEmpty(getCheckVariants())) {
      handleShowVariantModal()
      return
    }

    setTimeout(() => {
      const variants = getCheckVariants()
      history.block(() => true)
      history.push({
        pathname: '/productContentEdit',
        state: {
          entry: 'design',
          productId: routeState.productId,
          finishedId: routeState.finishedId,
          canvasJson: getCanvasJson(), // 获取所有视图json,
          canvasImage: getCanvasImage(), // 设计图
          variantIds: _.map(variants, 'variantId'), // 勾选的变体
          colors: _.uniq(_.map(variants, 'color')),
          checkFavorable: checkFavorableRef.current, // 判断是否第一次设计产品触发引导好评弹窗
        },
      })
    }, 30)
  }, [history])

  // 更新当前视图下设计图
  const updateCanvasImage = async () => {
    // 获取当前视图json，当前视图为空，则设计图也为空
    const currentJson = getCurrentViewJson()
    if (_.isEmpty(currentJson)) {
      return
    }

    const currentView = getCurrentView()
    const objects = fabricCanvas.getObjects()
    const allObject = await cloneAll(objects)
    console.log('allObject', allObject)
    const area = _.find(allObject, (item) => {
      return item.type === 'rect'
    })
    // 设置区域属性
    area.set({
      absolutePositioned: false,
      objectCaching: true,
      evented: false,
      stroke: 'transparent', // 边框透明
    })
    if (_.isEmpty(area)) {
      return
    }

    const layersList = _.filter(allObject, (item) => {
      return item.type !== 'rect'
    })
    const group = new fabric.Group([area], {
      selectable: false, // 不可选中
      clipPath: area,
    })

    console.log('group', group)
    _.forEach(layersList, (item) => {
      item.clipPath = null
      // 计算图层在设计区域的位置，group的起始点位于中心点, 将图层分组后，需要将图层往左上角偏移
      item.left = item.left - group.left - group.width / 2
      item.top = item.top - group.top - group.height / 2
      group.add(item)
    })

    // 获取设计区域与背景缩放系数
    const multiplier = group.width / fabricCanvas.backgroundImage.scaleX / group.width
    const image = group.toDataURL({
      format: 'png',
      multiplier,
    })

    // 更新当前视图的设计图
    setCanvasImage((preSatate) => ({
      ...preSatate,
      [currentView]: image,
    }))
  }

  // 克隆所有节点
  const cloneAll = async (data) => {
    if (_.isEmpty(data)) {
      return []
    }
    const list = _.map(data, (item) => {
      return new Promise((resolve) => {
        item.clone((clonedObj) => {
          resolve(clonedObj)
        })
      })
    })
    return await Promise.all(list)
  }

  // 切换Tab
  const handleTabChange = useCallback((selectedTabIndex) => {
    console.log('fabric', fabricCanvas)
    setCurrentTab(selectedTabIndex)
  }, [])

  // 添加文本
  const handleAddText = useCallback(() => {
    // 超过20层无法添加
    if (getLayers().length >= 20) {
      showToast('Maximum number of layers 20')
      return
    }
    clearAreaContent() // 清除画布内容
    const id = getGuid()
    const textValue = 'ENTER TEXT'
    const canvasParam = getCanvasParam()
    const fabIText = new fabric.IText(textValue, {
      id,
      angle: 0, // 旋转
      fill: attribute.fontColor, // 默认颜色填充
      fontSize: 14,
      fontFamily: attribute.fontFamily, // 字体fontfamily
      selectable: true,
      hasControls: true,
      hasRotatingPoint: true, // 对象的控制旋转点将可见或可选
      clipPath: canvasParam.clipPath, // 设置裁剪路径，在裁剪路径外内容不显示
    })

    // 获取当前图层中心点，并设置水平垂直居中
    const center = canvasParam.clipPath.getCenterPoint()
    fabIText.set({
      top: center.y - fabIText.height / 2,
      left: center.x - fabIText.width / 2,
    })

    fabricCanvas.add(fabIText) // 添加到画布中
    fabricCanvas.setActiveObject(fabIText) // 选中

    updateArea() // 更新设计区域样式
    fabricCanvas.renderAll()

    updateHistory('add', fabIText) // 更新操作步骤

    const position = getObjPositionForArea(fabIText) // 获取某个图层基于设计区域的位置
    setAttribute((preSatate) => ({
      ...preSatate,
      textValue,
      top: position.top,
      left: position.left,
    }))
  }, [])

  // 修改文本
  const handleTextChange = useCallback((textValue) => {
    setAttribute((preSatate) => ({
      ...preSatate,
      textValue,
    }))
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      activeObj.set({
        text: textValue,
      })
      updateCurrentViewJson() // 更新当前视图JSON数据
      updateLayers('update', activeObj)
      fabricCanvas.requestRenderAll()
    }
  }, [])

  // 文本框blur事件
  const handleTextBlur = useCallback((event) => {
    console.log('blur', event)
  })

  // 选择字体
  const handleFontChange = useCallback((selected) => {
    setAttribute((preSatate) => ({
      ...preSatate,
      fontFamily: selected[0],
    }))
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      activeObj.set({
        fontFamily: selected[0],
      })
      updateCurrentViewJson() // 更新当前视图JSON数据
      updateLayerHistory() // 更新选中图层操作步骤
      fabricCanvas.requestRenderAll()
    }
  }, [])

  // 输入框输入触发
  const handleUpdateText = (value) => {
    // 输入框有值时，筛选字体
    if (value) {
      const filterList = _.filter(Config.GoogleFonts, (item) => {
        return _.includes(_.toLower(item.label), _.toLower(value))
      })
      setAttribute((preSatate) => ({
        ...preSatate,
        fontFamily: value,
      }))
      setAllFonts(filterList)
    } else {
      setAttribute((preSatate) => ({
        ...preSatate,
        fontFamily: '',
      }))
      setAllFonts(Config.GoogleFonts)
    }
  }

  // 选择字体颜色
  const handleColorChange = useCallback((option) => {
    setAttribute((preSatate) => ({
      ...preSatate,
      fontColor: _.toUpper(option.hex),
    }))
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      activeObj.set({
        fill: option.hex,
      })
      fabricCanvas.requestRenderAll()
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 打开或关闭图库
  const handleShowImageModal = useCallback((tabId) => {
    setIsShowImageModal((active) => !active)
    setImageTab(tabId)
  }, [])

  // 添加图片
  const handleAddImage = useCallback((img) => {
    // 超过20层无法添加
    if (getLayers().length >= 20) {
      showToast('Maximum number of layers 20')
      return
    }
    clearAreaContent() // 清除设计区域初始化内容
    const id = getGuid()
    fabric.Image.fromURL(
      img,
      (oImg) => {
        const canvasParam = getCanvasParam()
        // 获取画布中心点
        const center = canvasParam.clipPath.getCenterPoint()

        let top = oImg.top
        let left = oImg.left
        // 如果图片太大，超出设计区域，则缩小图片
        if (oImg.height > designArea.height || oImg.width > designArea.width) {
          if (oImg.width > oImg.height) {
            oImg.scaleToWidth(designArea.width - 60)
          } else {
            oImg.scaleToHeight(designArea.height - 100)
          }
          // 获取图片居中的top及left
          top = center.y - oImg.getScaledHeight() / 2
          left = center.x - oImg.getScaledWidth() / 2
        } else {
          top = center.y - oImg.height / 2
          left = center.x - oImg.width / 2
        }
        oImg.set({
          id,
          top,
          left,
          clipPath: canvasParam.clipPath, // 设置图片裁剪路径，超出裁剪区域的内容不显示
        })

        fabricCanvas.add(oImg) // 将图片加入到画布
        fabricCanvas.setActiveObject(oImg) // 选中

        updateArea() // 更新设计区域样式
        fabricCanvas.renderAll()

        updateHistory('add', oImg) // 更新操作步骤
      },
      {
        crossOrigin: 'anonymous',
      }
    )
  }, [])

  // 打开或关闭变体弹窗
  const handleShowVariantModal = useCallback(() => {
    setIsShowVariantModal((active) => !active)
  }, [])

  // 选择变体
  const handleCheckVariant = useCallback((list) => {
    console.log('所选变体列表', list)
    setCheckVariants(list)
  }, [])

  // 设置选中对象的属性
  const handleSetAttribute = () => {
    const activeObj = fabricCanvas.getActiveObject()
    console.log('设置选中对象的属性', activeObj)
    if (activeObj) {
      setCurrentLayer(activeObj)
      const position = getObjPositionForArea(activeObj) // 获取某个图层基于设计区域的位置
      if (activeObj.type === 'i-text') {
        // 如果选中的是文本
        setAttribute((preSatate) => ({
          ...preSatate,
          id: activeObj.id,
          textValue: activeObj.text,
          fontFamily: activeObj.fontFamily,
          fontColor: activeObj.fill,
          angle: _.round(activeObj.angle),
          scale: _.round(activeObj.scaleX, 2) || 1,
          top: position.top,
          left: position.left,
        }))
      } else {
        // 选中的是图片
        setAttribute((preSatate) => ({
          ...preSatate,
          id: activeObj.id,
          angle: _.round(activeObj.angle),
          scale: _.round(activeObj.scaleX, 2) || 1,
          top: position.top,
          left: position.left,
        }))
      }
    } else {
      setCurrentLayer(null)
      setAttribute((preSatate) => ({
        ...preSatate,
        id: '',
        textValue: '',
        fontFamily: '',
        fontColor: '#000000',
        angle: 0,
        scale: 1,
        left: 0,
        top: 0,
      }))
    }
  }

  // 获取当前视图下的json
  const getCurrentViewJson = () => {
    // 是否为初始化内容，如果是初始化的画布内容，则返回空对象，否则返回JSON数据
    const isInit = !!_.find(fabricCanvas.getObjects(), (item) => {
      return item && item.isInitContent
    })
    // 如果为初始化内容，以及子节点为空，则返回空对象
    if (isInit || _.isEmpty(fabricCanvas.getObjects())) {
      return {}
    }
    const objects = []
    const data = _.cloneDeep(fabricCanvas.getObjects())
    _.forEach(data, (item) => {
      objects.push(item.toJSON(['id', 'selectable', 'hasControls']))
    })

    const jsonData = fabricCanvas.toJSON()
    jsonData.objects = objects
    return jsonData
  }

  // 更新当前视图下的json
  const updateCurrentViewJson = _.debounce(() => {
    const currentView = getCurrentView()
    setCanvasJson((preSatate) => ({
      ...preSatate,
      [currentView]: getCurrentViewJson(), // 更新当前视图JSON数据
    }))
  }, 30)

  // 更新当前视图下所有图层
  const updateLayers = (type, layer) => {
    if (layer.isInitContent || layer.type === 'rect') {
      return
    }
    setLayers((preSatate) => {
      const list = [...preSatate]
      if (type === 'add') {
        if (layer.type === 'i-text') {
          list.unshift({
            id: layer.id,
            type: 'Text',
            IText: layer,
            content: layer.text,
            visible: true,
            isHide: layer.visible ? false : true, // 是否隐藏
            isLock: layer.selectable ? false : true, // 是否锁定
          })
        } else if (layer.type === 'image') {
          list.unshift({
            id: layer.id,
            type: 'Image',
            Image: layer,
            content: layer._element.currentSrc,
            isHide: layer.visible ? false : true, // 是否隐藏
            isLock: layer.selectable ? false : true, // 是否锁定
          })
        }
        return list
      } else if (type === 'update') {
        _.forEach(list, (item) => {
          if (item.id === layer.id && layer.type === 'i-text') {
            item.content = layer.text
          }
        })
        return list
      } else {
        const newList = _.filter(list, (item) => {
          return item.id !== layer.id
        })
        return newList
      }
    })
  }

  // 更新单个图层
  const updateObjLayer = ({ layer, canvasObj }) => {
    const list = [...getLayers()]
    _.forEach(list, (item) => {
      if (layer && item.id === layer.id) {
        item.isHide = layer.isHide
        item.isLock = layer.isLock
      } else if (canvasObj && item.id === canvasObj.id) {
        item.isHide = !canvasObj.visible
        item.isLock = !canvasObj.selectable
      }
    })
    setLayers(list)
  }

  // 获取单个图层对象的中心点
  const getObjectCenter = (object) => {
    // 获取画布的中心点
    const center = fabricCanvas.getCenter()
    console.log('center', center, object)
    if (_.isEmpty(object)) {
      return center
    }
    return {
      top: center.top - object.height / 2,
      left: center.left - object.width / 2,
    }
  }

  // 获取某个图层基于设计区域的位置
  const getObjPositionForArea = (object) => {
    if (_.isEmpty(object)) {
      return {
        top: '0',
        left: '0',
      }
    }
    return {
      top: _.round(((object.top - object.clipPath.top) * 100) / designArea.height, 2).toString(),
      left: _.round(((object.left - object.clipPath.left) * 100) / designArea.width, 2).toString(),
    }
  }

  // 旋转
  const handleRotateChange = useCallback((angle) => {
    setAttribute((preSatate) => ({
      ...preSatate,
      angle,
    }))
    // 获取选中的图层
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      activeObj.rotate(angle)
      fabricCanvas.requestRenderAll()
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 缩放
  const handleScaleChange = useCallback((scale) => {
    console.log('scale', scale)
    setAttribute((preSatate) => ({
      ...preSatate,
      scale,
    }))
    // 获取选中的图层
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      activeObj.scale(scale).setCoords()
      fabricCanvas.requestRenderAll()
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 左对齐
  const handleAlignLeft = useCallback(() => {
    // 获取选中图层
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      // 默认边界值为设计区域距离画布左侧的长度值
      let left = activeObj.clipPath.left
      if (activeObj.angle <= 90) {
        left += activeObj.aCoords.tl.x - activeObj.aCoords.bl.x
      }
      if (activeObj.angle > 90 && activeObj.angle <= 180) {
        left += activeObj.aCoords.tl.x - activeObj.aCoords.br.x
      }
      if (activeObj.angle > 180 && activeObj.angle <= 270) {
        left += activeObj.aCoords.tl.x - activeObj.aCoords.tr.x
      }
      activeObj.set({
        left,
      })
      fabricCanvas.requestRenderAll()
      handleSetAttribute() // 设置属性
      updateLayerHistory() // 更新选中图层操作步骤
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 水平居中对齐
  const handleAlignCenterH = useCallback(() => {
    // 获取选中图层
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      const objCenter = activeObj.getCenterPoint() // 当前对象实际中心点
      const areaCenter = activeObj.clipPath.getCenterPoint() // 设计区域实际中心点

      const offset = objCenter.x - areaCenter.x
      const left = activeObj.left - offset

      activeObj.set({
        left,
      })
      fabricCanvas.requestRenderAll()
      handleSetAttribute() // 设置属性
      updateLayerHistory() // 更新选中图层操作步骤
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 右对齐
  const handleAlignRight = useCallback(() => {
    // 获取选中图层
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      // 默认边界值为设计区域距离画布左侧的长度值
      let left = activeObj.clipPath.left
      if (activeObj.angle <= 90) {
        left += activeObj.aCoords.tl.x + (designArea.width - activeObj.aCoords.tr.x)
      }
      if (activeObj.angle > 90 && activeObj.angle <= 180) {
        left += designArea.width
      }
      if (activeObj.angle > 180 && activeObj.angle <= 270) {
        left += activeObj.aCoords.tl.x + (designArea.width - activeObj.aCoords.bl.x)
      }
      if (activeObj.angle > 270) {
        left += activeObj.aCoords.tl.x + (designArea.width - activeObj.aCoords.br.x)
      }
      activeObj.set({
        left,
      })
      fabricCanvas.requestRenderAll()
      handleSetAttribute() // 设置属性
      updateLayerHistory() // 更新选中图层操作步骤
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 顶部对齐
  const handleAlignTop = useCallback(() => {
    // 获取选中图层
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      // 默认边界值为设计区域距离画布顶部的长度值
      let top = activeObj.clipPath.top
      if (activeObj.angle > 90 && activeObj.angle <= 180) {
        top += activeObj.aCoords.tl.y - activeObj.aCoords.bl.y
      }
      if (activeObj.angle > 180 && activeObj.angle <= 270) {
        top += activeObj.aCoords.tl.y - activeObj.aCoords.br.y
      }
      if (activeObj.angle > 270) {
        top += activeObj.aCoords.tl.y - activeObj.aCoords.tr.y
      }
      activeObj.set({
        top,
      })
      fabricCanvas.requestRenderAll()
      handleSetAttribute() // 设置属性
      updateLayerHistory() // 更新选中图层操作步骤
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 垂直居中对齐
  const handleAlignCenterV = useCallback(() => {
    // 获取选中图层
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      const objCenter = activeObj.getCenterPoint() // 当前对象实际中心点
      const areaCenter = activeObj.clipPath.getCenterPoint() // 设计区域实际中心点

      const offset = objCenter.y - areaCenter.y
      const top = activeObj.top - offset

      activeObj.set({
        top,
      })
      fabricCanvas.requestRenderAll()
      handleSetAttribute() // 设置属性
      updateLayerHistory() // 更新选中图层操作步骤
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 底部对齐
  const handleAlignBottom = useCallback(() => {
    // 获取选中图层
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      // 默认边界值为设计区域距离画布顶部的长度值
      let top = activeObj.clipPath.top
      if (activeObj.angle <= 90) {
        top += activeObj.aCoords.tl.y + (designArea.height - activeObj.aCoords.br.y)
      }
      if (activeObj.angle > 90 && activeObj.angle <= 180) {
        top += activeObj.aCoords.tl.y + (designArea.height - activeObj.aCoords.tr.y)
      }
      if (activeObj.angle > 180 && activeObj.angle <= 270) {
        top += designArea.height
      }
      if (activeObj.angle > 270) {
        top += activeObj.aCoords.tl.y + (designArea.height - activeObj.aCoords.bl.y)
      }
      activeObj.set({
        top,
      })
      fabricCanvas.requestRenderAll()
      handleSetAttribute() // 设置属性
      updateLayerHistory() // 更新选中图层操作步骤
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 修改左侧位置
  const handleLeftChange = useCallback((value) => {
    let newValue = _.toNumber(value)
    if (newValue < 0) {
      newValue = 0
    } else if (newValue > 100) {
      newValue = 100
    }
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      setAttribute((preSatate) => ({
        ...preSatate,
        left: newValue.toString(),
      }))
      const left = (designArea.width * newValue) / 100 + activeObj.clipPath.left
      activeObj.set({
        left,
      })
      fabricCanvas.requestRenderAll()
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 修改顶部位置
  const handleTopChange = useCallback((value) => {
    let newValue = _.toNumber(value)
    if (newValue < 0) {
      newValue = 0
    } else if (newValue > 100) {
      newValue = 100
    }
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      setAttribute((preSatate) => ({
        ...preSatate,
        top: newValue.toString(),
      }))
      const top = (designArea.height * newValue) / 100 + activeObj.clipPath.top
      activeObj.set({
        top,
      })
      fabricCanvas.requestRenderAll()
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  /**
   * 翻转
   * @param {String} type: 【X: 水平翻转, Y: 垂直翻转】
   */
  const handleFlip = useCallback((type) => {
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj) {
      if (type === 'X') {
        setAttribute((preSatate) => ({
          ...preSatate,
          flipX: !preSatate.flipX,
        }))
      } else {
        setAttribute((preSatate) => ({
          ...preSatate,
          flipY: !preSatate.flipY,
        }))
      }
      setTimeout(() => {
        activeObj.set({
          flipX: getAttribute().flipX,
          flipY: getAttribute().flipY,
        })
        updateLayerHistory() // 更新选中图层操作步骤
        updateCurrentViewJson() // 更新当前视图JSON数据
        fabricCanvas.requestRenderAll()
      })
    }
  }, [])

  // 拖拽排序
  const handleOnSort = useCallback(({ oldIndex, newIndex }) => {
    const layers = [...getLayers()]
    const newList = arrayMoveImmutable(layers, oldIndex, newIndex)
    const item = layers[oldIndex].IText || layers[oldIndex].Image
    const level = layers.length - newIndex
    if (item) {
      item.moveTo(level)
      fabricCanvas.renderAll()
    }
    setLayers(newList)
  }, [])

  // 点击图层
  const handleClickLayer = useCallback((layer) => {
    if (layer) {
      setCurrentLayer(layer)
      // 图层未被锁定，才可以设置选中状态
      if (layer.selectable) {
        fabricCanvas.setActiveObject(layer)
        fabricCanvas.requestRenderAll()
      }
    } else {
      const activeObj = fabricCanvas.getActiveObject()
      if (activeObj) {
        setCurrentLayer(activeObj)
      }
    }
  }, [])

  // 隐藏或显示图层
  const handleView = useCallback((layer, event) => {
    event.stopPropagation()
    // 锁定后不可操作
    if (layer.isLock) {
      return
    }
    layer.isHide = !layer.isHide // 改变显示状态
    const canvasObj = layer.IText || layer.Image
    canvasObj.set({
      visible: !layer.isHide,
    })
    updateCurrentViewJson() // 更新当前视图JSON数据
    updateLayerHistory() // 更新选中图层操作步骤

    // 如果当前选中的图层为锁定的图层，则取消选中状态
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj && activeObj.id === layer.id) {
      fabricCanvas.discardActiveObject() // 取消选中
    } else {
      updateHistory('update', canvasObj)
    }
    fabricCanvas.renderAll()

    // 更新当前图层
    updateObjLayer({ layer })
  }, [])

  // 复制图层
  const handleDuplicate = useCallback((layer, event) => {
    event.stopPropagation()
    // 锁定后不可复制
    if (layer.isLock) {
      return
    }
    // 超过20层无法添加
    if (getLayers().length >= 20) {
      showToast('Maximum number of layers 20')
      return
    }
    fabricCanvas.discardActiveObject() // 取消选中
    const canvasObj = layer.IText || layer.Image
    canvasObj.clone((clonedObj) => {
      const id = getGuid()
      clonedObj.set({
        id,
        top: clonedObj.top,
        left: clonedObj.left,
      })
      fabricCanvas.add(clonedObj)
      fabricCanvas.setActiveObject(clonedObj)
      fabricCanvas.requestRenderAll()

      updateHistory('add', clonedObj)
    })
  }, [])

  // 撤销单个图层操作
  const handleUndoObj = useCallback((layer, event) => {
    event.stopPropagation()
    // 锁定后不可撤销
    if (layer.isLock) {
      return
    }
    const currentView = getCurrentView()
    const historyUndo = getHistoryUndo()
    const historyRedo = getHistoryRedo()

    const undoList = historyUndo[currentView]
    const redoList = historyRedo[currentView]

    const canvasObj = layer.IText || layer.Image

    const lastIndex = _.findLastIndex(undoList, (item) => {
      return item.id === layer.id
    })
    const lastStep = undoList[lastIndex]
    const list = _.filter(undoList, (item) => {
      return item.id === layer.id
    })
    if (lastIndex > 0 && list.length > 1) {
      undoList.splice(lastIndex, 1)
    }

    const prevStep = _.findLast(undoList, (item, index) => {
      return item.id === layer.id && index !== lastIndex
    })

    if (prevStep) {
      redoList.push(lastStep)
      setHistoryRedo((preSatate) => ({
        ...preSatate,
        [currentView]: redoList,
      }))
      // 更新该节点
      canvasObj.set({
        top: prevStep.top,
        left: prevStep.left,
        angle: prevStep.angle,
        scaleX: prevStep.scaleX,
        scaleY: prevStep.scaleY,
        flipX: prevStep.flipX,
        flipY: prevStep.flipY,
        fill: prevStep.fill,
        text: prevStep.text,
        visible: prevStep.visible,
        fontFamily: prevStep.fontFamily,
        selectable: prevStep.selectable,
        hasControls: prevStep.hasControls,
      })
      fabricCanvas.requestRenderAll()
      updateCurrentViewJson() // 更新当前视图JSON数据
    }
  }, [])

  // 锁定图层
  const handleLock = useCallback((layer, event) => {
    event.stopPropagation()
    layer.isLock = !layer.isLock // 改变锁定状态
    const canvasObj = layer.IText || layer.Image
    canvasObj.set({
      selectable: !layer.isLock, // 如果已锁定，则不允许选中
    })
    updateLayerHistory() // 更新选中图层操作步骤

    // 如果当前选中的图层为锁定的图层，则取消选中状态
    const activeObj = fabricCanvas.getActiveObject()
    if (activeObj && activeObj.id === layer.id) {
      fabricCanvas.discardActiveObject() // 取消选中
    }
    fabricCanvas.requestRenderAll()

    updateCurrentViewJson() // 更新当前视图JSON数据
    // 更新当前图层
    updateObjLayer({ canvasObj })
  }, [])

  // 删除图层
  const handleDelLayer = useCallback(({ layer, canvasObj, event }) => {
    event.stopPropagation()
    // 锁定后不可删除
    if (layer && layer.isLock) {
      return
    }
    let obj = canvasObj
    if (layer) {
      obj = layer.IText || layer.Image
    }
    fabricCanvas.remove(obj)

    updateHistory('remove', obj) // 更新操作步骤

    updateArea() // 更新设计区域样式
    initAreaContent()
  }, [])

  // 颜色预览
  const handleColorsPreview = useCallback((color) => {
    console.log('当前选中的颜色', fabricCanvas)
    fabricCanvas.backgroundImage.backgroundColor = color
    fabricCanvas.renderAll()
  }, [])

  // 点击改变拖拽状态
  const handleDrag = useCallback(() => {
    setIsDrag((active) => {
      if (!active) {
        fabricCanvas.defaultCursor = 'move'
      } else {
        fabricCanvas.defaultCursor = 'default'
      }
      return !active
    })
  }, [])

  // 放大缩小
  const handleZoom = useCallback((deltaY) => {
    const canvasParam = getCanvasParam()
    const center = fabricCanvas.getCenter()
    let zoom = fabricCanvas.getZoom() // 获取画布当前缩放值
    zoom *= 0.999 ** deltaY
    if (zoom > canvasParam.maxScale) zoom = canvasParam.maxScale
    if (zoom < canvasParam.minScale) zoom = canvasParam.minScale
    fabricCanvas.zoomToPoint(
      {
        // 关键点
        x: center.left, // 根据画布中心点缩放
        y: center.top, // 根据画布中心缩放
      },
      zoom
    )
  }, [])

  // 更新历史步骤
  const updateHistory = (type, obj) => {
    if (obj.isInitContent || obj.type === 'rect') {
      return
    }

    setTimeout(() => {
      const historyUndo = getHistoryUndo()
      const currentView = getCurrentView()
      const undoList = historyUndo[currentView]
      const jsonData = obj.toJSON(['id', 'selectable', 'hasControls'])
      undoList.push({
        ...jsonData,
        operation: type,
      })
      setHistoryUndo((preSatate) => ({
        ...preSatate,
        [currentView]: undoList,
      }))
      console.log('操作历史', undoList)
    })
  }

  // 更新选中图层操作历史
  const updateLayerHistory = useCallback(() => {
    const activeObj = fabricCanvas.getActiveObject()
    console.log('更新单个图层操作历史', activeObj)
    if (activeObj) {
      updateHistory('update', activeObj)
    }
  }, [])

  // 撤销
  const handleUndo = useCallback(async () => {
    const historyUndo = getHistoryUndo()
    const historyRedo = getHistoryRedo()
    const currentView = getCurrentView()
    const undoList = historyUndo[currentView]
    const redoList = historyRedo[currentView]

    // 获取最后一步节点
    const lastStep = _.last(undoList)

    if (lastStep) {
      undoList.pop()
      setHistoryUndo((preSatate) => ({
        ...preSatate,
        [currentView]: undoList,
      }))

      // 历史步骤删除一个节点，下一步步骤增加一个节点
      redoList.push(lastStep)
      setHistoryRedo((preSatate) => ({
        ...preSatate,
        [currentView]: redoList,
      }))

      // 查找画布节点
      const canvasObj = _.find(fabricCanvas.getObjects(), (el) => {
        return el.id === lastStep.id
      })

      // 如果上一步是删除，则需要添加节点
      if (lastStep.operation === 'remove') {
        clearAreaContent() // 清除画布内容
        const obj = await createObject(lastStep)
        fabricCanvas.add(obj)
        updateArea() // 更新设计区域样式
      } else if (lastStep.operation === 'add') {
        // 如果上一步是添加，则需要删除节点
        fabricCanvas.remove(canvasObj)
        updateArea() // 更新设计区域样式
        initAreaContent() // 初始化画布区域内容
      } else {
        let prevStep = _.findLast(undoList, (item) => {
          return item.id === lastStep.id
        })
        if (!prevStep && !canvasObj.visible) {
          prevStep = {
            ...canvasObj,
            visible: true,
          }
        }
        // 更新该节点
        canvasObj.set({
          top: prevStep.top,
          left: prevStep.left,
          angle: prevStep.angle,
          scaleX: prevStep.scaleX,
          scaleY: prevStep.scaleY,
          flipX: prevStep.flipX,
          flipY: prevStep.flipY,
          fill: prevStep.fill,
          text: prevStep.text,
          visible: prevStep.visible,
          fontFamily: prevStep.fontFamily,
          selectable: prevStep.selectable,
          hasControls: prevStep.hasControls,
        })

        // 更新当前图层
        updateObjLayer({ canvasObj })
      }
      console.log('undoList', undoList)
      console.log('redoList', redoList)
      fabricCanvas.requestRenderAll()
    }
    // fabricCanvas.undo()
  }, [])

  // 下一步
  const handleRedo = useCallback(async () => {
    const historyUndo = getHistoryUndo()
    const historyRedo = getHistoryRedo()
    const currentView = getCurrentView()
    const undoList = historyUndo[currentView]
    const redoList = historyRedo[currentView]

    // 获取最后一步节点
    const lastStep = _.last(redoList)

    if (lastStep) {
      redoList.pop()
      setHistoryRedo((preSatate) => ({
        ...preSatate,
        [currentView]: redoList,
      }))

      undoList.push(lastStep)
      setHistoryUndo((preSatate) => ({
        ...preSatate,
        [currentView]: undoList,
      }))

      // 查找画布节点
      const canvasObj = _.find(fabricCanvas.getObjects(), (el) => {
        return el.id === lastStep.id
      })

      if (lastStep.operation === 'add') {
        // 如果下一步是添加
        clearAreaContent() // 清除画布内容
        const obj = await createObject(lastStep)
        fabricCanvas.add(obj)
        updateArea() // 更新设计区域样式
      } else if (lastStep.operation === 'remove') {
        // 如果下一步是删除
        fabricCanvas.remove(canvasObj)
        updateArea() // 更新设计区域样式
        initAreaContent() // 初始化画布区域内容
      } else {
        // 更新该节点
        canvasObj.set({
          top: lastStep.top,
          left: lastStep.left,
          angle: lastStep.angle,
          scaleX: lastStep.scaleX,
          scaleY: lastStep.scaleY,
          flipX: lastStep.flipX,
          flipY: lastStep.flipY,
          fill: lastStep.fill,
          text: lastStep.text,
          visible: lastStep.visible,
          fontFamily: lastStep.fontFamily,
          selectable: lastStep.selectable,
          hasControls: lastStep.hasControls,
        })

        // 更新当前图层
        updateObjLayer({ canvasObj })
      }

      fabricCanvas.requestRenderAll()
    }
    console.log('undoList', undoList)
    console.log('redoList', redoList)
    // fabricCanvas.redo()
  }, [])

  // 创建一个对象
  const createObject = async (obj) => {
    if (obj.type === 'i-text') {
      const iText = new fabric.IText(obj.text, {
        id: obj.id,
        angle: obj.angle, // 旋转
        fill: obj.fill, // 默认颜色填充
        fontSize: obj.fontSize,
        fontFamily: obj.fontFamily, // 字体fontfamily
        selectable: obj.selectable,
        hasControls: obj.hasControls,
        left: obj.left,
        top: obj.top,
        clipPath: getCanvasParam().clipPath, // 设置裁剪路径，在裁剪路径外内容不显示
      })
      return iText
    } else if (obj.type === 'image') {
      return new Promise((resolve) => {
        fabric.Image.fromURL(obj.src, (oImg) => {
          oImg.set({
            id: obj.id,
            angle: obj.angle, // 旋转
            selectable: obj.selectable,
            hasControls: obj.hasControls,
            left: obj.left,
            top: obj.top,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            clipPath: getCanvasParam().clipPath, // 设置裁剪路径，在裁剪路径外内容不显示
          })
          resolve(oImg)
        })
      })
    }
  }

  // 计算实时价格
  const getCurrentPrice = () => {
    // 商品详情
    const productInfo = getProductInfo()
    const printingPrice = _.get(productInfo, 'printingPrice')
    // 获取所有视图json
    const canvasJson = getCanvasJson()
    let price = 0
    // 判断前后视图是否有设计图层
    if (!_.isEmpty(canvasJson.Front)) {
      price = _.round(price + printingPrice.frontPrice, 2)
    }
    if (!_.isEmpty(canvasJson.Back)) {
      price = _.round(price + printingPrice.realPrice, 2)
    }
    // moneyFormat 货币符号
    const moneyFormat = productInfo.moneyFormat ? productInfo.moneyFormat + ' ' : ''
    return `${moneyFormat}${price}`
  }

  // 关闭拦截弹窗
  const handleCloseLeavePageModal = () => {
    setIsShowLeavePage(false)
  }

  // 确认离开当前页
  const handleConfirmLeavePage = () => {
    history.block(() => true)
    history.push(lastRouterPath)
  }

  // 判断保存按钮是否可点击
  const isDisabled = () => {
    // 获取所有视图json
    const canvasJson = getCanvasJson()
    let disabled = false
    // 如果都没有设计图层，则不可保存
    if (_.isEmpty(canvasJson.Front) && _.isEmpty(canvasJson.Back)) {
      disabled = true
    }
    return disabled
  }

  // 显示消息提示
  const showToast = useCallback((msg) => {
    setToastMsg(msg)
    setIsShowToast((active) => !active)
  }, [])

  const toastMarkup = isShowToast ? (
    <Toast
      content={toastMsg}
      onDismiss={showToast}
      duration={3000}
    />
  ) : null

  return (
    <div
      className="Design-Main"
      style={{ height: cardHeight + 'px' }}
    >
      <Card>
        <Card.Section
          title={
            <div className="Design-Header">
              <div className="Design-Header-Title">
                <h3>{getCurrentPrice()}</h3>
                <span>Excl. taxes and product price</span>
              </div>
              <div>
                <ButtonGroup>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button
                    primary
                    onClick={handleContinue}
                    disabled={isDisabled()}
                  >
                    Continue
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          }
        ></Card.Section>
        <div className="Design-Body">
          <div className="Design-Left">
            {/* 前后视图Tab栏 */}
            <div className="Design-Tab">
              <ButtonGroup>
                <Button
                  primary={currentView === 'Front'}
                  onClick={() => {
                    handleClickView('Front')
                  }}
                >
                  Front
                </Button>
                {canvasParam.backgroundImage.Back && (
                  <Button
                    primary={currentView === 'Back'}
                    onClick={() => {
                      handleClickView('Back')
                    }}
                  >
                    Back
                  </Button>
                )}
              </ButtonGroup>
            </div>

            {/* 左侧菜单 */}
            <div className="Design-Options">
              {/* 文本 */}
              <Tooltip
                content="Text"
                preferredPosition="above"
                dismissOnMouseOut
              >
                <div
                  className="Design-Options-Icon"
                  onClick={handleAddText}
                >
                  <Icon
                    source={TypeMajor}
                    color="base"
                  />
                </div>
              </Tooltip>

              {/* 图片 */}
              <Tooltip
                content="Image"
                preferredPosition="above"
                dismissOnMouseOut
              >
                <div
                  className="Design-Options-Icon"
                  onClick={() => {
                    handleShowImageModal('Recommend')
                  }}
                >
                  <Icon
                    source={ImagesMajor}
                    color="base"
                  />
                </div>
              </Tooltip>

              {/* 上传 */}
              <Tooltip
                content="Upload"
                preferredPosition="above"
                dismissOnMouseOut
              >
                <div
                  className="Design-Options-Icon"
                  onClick={() => {
                    handleShowImageModal('Mine')
                  }}
                >
                  <Icon
                    source={FolderUpMajor}
                    color="base"
                  />
                </div>
              </Tooltip>

              <div className="Line"></div>

              {/* 颜色 */}
              <Tooltip
                content="Color"
                preferredPosition="above"
                dismissOnMouseOut
              >
                <ColorsPreview
                  variantData={productInfo.variantData}
                  onConfirm={handleColorsPreview}
                />
              </Tooltip>

              {/* 变体 */}
              <Tooltip
                content="Variant"
                preferredPosition="above"
                dismissOnMouseOut
              >
                <div
                  className="Design-Options-Icon"
                  onClick={handleShowVariantModal}
                >
                  <Icon
                    source={VariantMajor}
                    color="base"
                  />
                  {checkVariants.length > 0 && (
                    <div className="Design-Badge">{checkVariants.length}</div>
                  )}
                </div>
              </Tooltip>
            </div>

            {/* 绘图区域 */}
            <div className="Design-Canvas">
              <canvas id="PringCanvas"></canvas>
            </div>

            {/* 底部操作工具栏 */}
            <div className="Design-Tool">
              <div
                className="Design-Tool-box"
                style={{ width: '232px' }}
              >
                <Tooltip
                  content="Hold ALT key to drag"
                  preferredPosition="above"
                  dismissOnMouseOut
                >
                  <div
                    className={isDrag ? 'Drag-Hand-Active' : 'Drag-Hand'}
                    onClick={handleDrag}
                  ></div>
                </Tooltip>
                <div
                  className="Enlarge"
                  onClick={() => {
                    handleZoom(-100)
                  }}
                ></div>
                <div
                  className="Narrow"
                  onClick={() => {
                    handleZoom(100)
                  }}
                ></div>
              </div>
              <div
                className="Line"
                style={{ transform: 'rotate(90deg)' }}
              ></div>
              <div
                className="Design-Tool-box"
                style={{ flex: 1 }}
              >
                <div
                  className="cursor-pointer"
                  style={{ opacity: _.isEmpty(historyUndo[currentView]) ? 0.3 : 1 }}
                  onClick={handleUndo}
                >
                  <Icon
                    source={UndoMajor}
                    color="base"
                  />
                </div>
                <div
                  className="cursor-pointer"
                  style={{ opacity: _.isEmpty(historyRedo[currentView]) ? 0.3 : 1 }}
                  onClick={handleRedo}
                >
                  <Icon
                    source={RedoMajor}
                    color="base"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="Design-Right">
            <Tabs
              tabs={tabs}
              selected={currentTab}
              onSelect={handleTabChange}
              fitted
            >
              <div className="Design-TabContent">
                {/* 属性(Attribute) */}
                {currentTab === 0 && (
                  <div style={{ padding: '20px' }}>
                    {/* 文本框 */}
                    <div
                      className="Design-Tab-Item"
                      style={{
                        display: !currentLayer || currentLayer.type === 'i-text' ? 'block' : 'none',
                      }}
                    >
                      <TextField
                        label="Text"
                        value={attribute.textValue}
                        onChange={handleTextChange}
                        multiline={4}
                        maxHeight={100}
                        maxLength={30}
                        autoComplete="off"
                        onBlur={handleTextBlur}
                        disabled={!currentLayer || currentLayer.type !== 'i-text'}
                      />
                    </div>
                    {/* 字体及颜色 */}
                    <div
                      className="Design-Tab-Item row"
                      style={{
                        display: !currentLayer || currentLayer.type === 'i-text' ? 'grid' : 'none',
                      }}
                    >
                      <div className="col-6">
                        <h6>Font</h6>
                        <Autocomplete
                          options={allFonts}
                          selected={[attribute.fontFamily]}
                          onSelect={handleFontChange}
                          textField={
                            <Autocomplete.TextField
                              onChange={handleUpdateText}
                              value={attribute.fontFamily}
                              placeholder="Search Font"
                              disabled={!currentLayer || currentLayer.type !== 'i-text'}
                            />
                          }
                        />
                      </div>
                      <div className="Color-Picker col-6">
                        <h6>Color</h6>
                        <ColorPicker
                          color={attribute.fontColor}
                          onChange={handleColorChange}
                          onMouseUp={updateLayerHistory}
                          disabled={!currentLayer || currentLayer.type !== 'i-text'}
                        />
                      </div>
                    </div>
                    {/* 旋转 */}
                    <div
                      className="Design-Tab-Item"
                      onMouseUp={updateLayerHistory}
                    >
                      <RangeSlider
                        output
                        label="Rotate"
                        min={0}
                        max={360}
                        value={attribute.angle}
                        onChange={handleRotateChange}
                        suffix={<p>{attribute.angle}</p>}
                        disabled={!currentLayer}
                      />
                    </div>
                    {/* 缩放 */}
                    <div
                      className="Design-Tab-Item"
                      onMouseUp={updateLayerHistory}
                    >
                      <RangeSlider
                        output
                        label="Scale"
                        min={0}
                        max={10}
                        step={0.1}
                        value={attribute.scale}
                        onChange={handleScaleChange}
                        suffix={<p>{attribute.scale}</p>}
                        disabled={!currentLayer}
                      />
                    </div>
                    {/* Align */}
                    <div className="Design-Tab-Item">
                      <h3>Align</h3>
                      <div className="row">
                        <div className="Design-Tab-IconBox col-5">
                          <div onClick={handleAlignLeft}>
                            <LeftIcon />
                          </div>
                          <div onClick={handleAlignCenterH}>
                            <HCenterIcon />
                          </div>
                          <div onClick={handleAlignRight}>
                            <RightIcon />
                          </div>
                        </div>
                        <div className="col-2"></div>
                        <div className="Design-Tab-IconBox col-5">
                          <div onClick={handleAlignTop}>
                            <TopIcon />
                          </div>
                          <div onClick={handleAlignCenterV}>
                            <VCenterIcon />
                          </div>
                          <div onClick={handleAlignBottom}>
                            <BottomIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 位置 */}
                    <div className="Design-Tab-Item">
                      <div className="row">
                        <div className="col-6">
                          <h3>Position left</h3>
                          <TextField
                            type="number"
                            autoComplete="off"
                            suffix="%"
                            max={100}
                            min={0}
                            value={attribute.left}
                            onChange={handleLeftChange}
                            disabled={!currentLayer}
                          />
                        </div>
                        <div className="col-6">
                          <h3>Position top</h3>
                          <TextField
                            type="number"
                            autoComplete="off"
                            suffix="%"
                            max={100}
                            min={0}
                            value={attribute.top}
                            onChange={handleTopChange}
                            disabled={!currentLayer}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 翻转 */}
                    <div className="Design-Tab-Item">
                      <h3>Filp</h3>
                      <div className="row">
                        <div className="col-6">
                          <Button
                            fullWidth
                            onClick={() => {
                              handleFlip('X')
                            }}
                            disabled={!currentLayer}
                          >
                            Horizontally
                          </Button>
                        </div>
                        <div className="col-6">
                          <Button
                            fullWidth
                            onClick={() => {
                              handleFlip('Y')
                            }}
                            disabled={!currentLayer}
                          >
                            Vertically
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* 图层(Layer) */}
                {currentTab === 1 && (
                  <div className="Design-Tab-Layer">
                    <LayersComponent
                      items={layers}
                      currentLayer={currentLayer}
                      onSortEnd={handleOnSort}
                      handleClickLayer={handleClickLayer}
                      handleView={handleView}
                      handleDuplicate={handleDuplicate}
                      handleLock={handleLock}
                      handleUndoObj={handleUndoObj}
                      handleDelLayer={handleDelLayer}
                    />
                  </div>
                )}
              </div>
            </Tabs>
          </div>
        </div>
      </Card>
      {/* 消息提示 */}
      {toastMarkup}

      {/* 图库 */}
      <ImageLibrary
        isShow={isShowImageModal}
        tabId={imageTab}
        onClose={handleShowImageModal}
        onConfirm={handleAddImage}
      />

      {/* 变体 */}
      <Variant
        isShow={isShowVariantModal}
        data={productInfo.variantData}
        entry="design"
        checkVariants={checkVariants}
        imageUrl={defaultImage} // 默认显示图片
        country={productInfo.country} // 国家
        moneyFormat={productInfo.moneyFormat} // 货币符号
        onClose={handleShowVariantModal}
        onConfirm={handleCheckVariant}
      />

      {/* 离开当前页拦截弹窗 */}
      <LeavePage
        isShow={isShowLeavePage} // 控制弹窗显示
        onClose={handleCloseLeavePageModal} // 关闭回调
        onConfirm={handleConfirmLeavePage} // 确认回调
      />
    </div>
  )
}

export default ConfugureDesign
