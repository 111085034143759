/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-12-03 17:37:05
 * @LastEditTime: 2022-12-21 19:38:54
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\ProductContentEdit\component\EditImage\index.jsx
 */
import { useState, useEffect } from 'react'
import { Modal, TextContainer } from '@shopify/polaris'
import _ from 'lodash'
import './index.scss'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Array} allImage 所有变体图片
 * @param {Object} variant 当前点选的变体
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const EditImage = ({ isShow, allImage, variant, onClose, onConfirm }) => {
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [imageList, setImageList] = useState([])

  useEffect(() => {
    if (variant) {
      const list = _.filter(allImage, (item) => {
        return _.toLower(variant.optionOne) === _.toLower(item.color)
      })
      const index = _.findIndex(list, (item) => {
        return variant.skuImage === item.src
      })
      setCurrentIndex(index)
      setImageList(list)
    }
  }, [isShow, variant])

  // 关闭弹窗
  const closeModal = () => {
    setCurrentIndex(-1)
    setImageList([])
    onClose()
  }

  // 点击确认
  const handleConfirm = () => {
    onConfirm(imageList[currentIndex])
    closeModal()
  }

  return (
    <div>
      <Modal
        open={isShow}
        onClose={closeModal}
        title="Update variants image"
        primaryAction={{
          content: 'Done',
          onAction: handleConfirm,
          disabled: currentIndex < 0,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: onClose,
          },
        ]}
        footer={<TextContainer>You can only choose images as variant meida</TextContainer>}
      >
        <Modal.Section>
          <TextContainer>
            {!_.isEmpty(imageList) && (
              <div className="row">
                {_.map(imageList, (item, index) => {
                  return (
                    <div
                      className="col-4 image-mark"
                      onClick={() => setCurrentIndex(index)}
                      key={index}
                    >
                      <div className="image-item">
                        <img
                          src={item.url}
                          alt=""
                        />
                        {index === currentIndex && (
                          <img
                            src="/images/icon/checked.svg"
                            alt=""
                            className="checkedImg"
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            {_.isEmpty(imageList) && (
              <p>The current variant has no images, Update variant to recover variant images</p>
            )}
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  )
}
export default EditImage
