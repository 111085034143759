import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useGetState } from 'ahooks'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import {
  Page,
  Card,
  Layout,
  ContextualSaveBar,
  TextField,
  IndexTable,
  TextStyle,
  useIndexResourceState,
  Button,
  ButtonGroup,
  Badge,
  Toast,
  RadioButton,
} from '@shopify/polaris'
// 骨架屏
import LoadingPage from './component/LoadingPage'
// 全屏loading
import FullScreenLoading from '@/components/FullScreenLoading'
// 富文本编辑器
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// 产品图片列表
import ProductImages from '@/components/ProductImages'
// 删除变体弹窗
import DeleteVariant from './component/DeleteVariant'
// 复制产品弹窗
import CopyProduct from './component/CopyProduct'
// 产品定价编辑
import EditPrice from './component/EditPrice'
// 产品库存编辑
import EditInventory from './component/EditInventory'
// 产品利润编辑
import EditProfit from './component/EditProfit'
// 删除产品弹窗
import DeleteProduct from './component/DeleteProduct'
// product organization
import ProductOrganization from './component/ProductOrganization'
// 变体弹窗
import Variant from '@/components/Variant'
// 修改变体图弹窗
import EditImage from './component/EditImage'
import Image from '@/components/Image'
// 新产品弹窗
import NewProduct from './component/NewProduct'
// 离开页面弹窗
import LeavePage from '@/components/LeavePage'
// 切换颜色组件
import { CirclePicker } from 'react-color'
// 请求商品详情
import { getCatelogOrDesignDetails } from '@/api/catelog'
import { uploadOSS } from '@/api/settings'
// 引入oss请求
import {
  setOssImageUrl,
  ossSignatureUrl,
  getClient,
  putObject,
  base64toFile,
  checkWebp,
} from '@/utils/utils'
// 新增成品接口
import { productDesignInsert } from '@/api/confugureDesign'
// 拖拽组件
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import {
  getProductContent,
  ckeditorConfig,
  getProductSave,
  getQueryVolume,
  getProductDelete,
} from '@/api/productContentEdit/index'
import './index.scss'

const ProductContentEdit = (props) => {
  const router = useHistory()
  const entryRef = useRef() // 入口【design: 从产品设计跳转】【myProducts：从产品列表进入】
  const isCheckWebp = checkWebp() // 浏览器是否支持webp图片

  const [isLoading, setIsLoading] = useState(false) // 页面是否加载中
  const [loadingSave, setLoadingSave] = useState(false) // 保存loading
  const [isError, setIsError] = useState(false) // 接口返回错误

  // media图片
  const [radioValue, setRadioValue, getRadioValue] = useGetState('allimages')
  const [loadNum, setLoadNum] = useState(5) // 默认只展示5张
  const [allImageList, setAllImageList] = useState([]) // 所有图片

  // 商品或成品详情
  const [finishData, setFinishData] = useState({}) // 成品详情
  const [productInfo, setProductInfo] = useState({}) // 商品详情

  // 商品或成品属性
  const [proStatus, setProStatus] = useState('') // 成品状态
  const [country, setCountry] = useState('') // 国家
  const [moneyFormat, setMoneyFormat] = useState('') // 货币符号
  const [titleValue, setTitleValue] = useState('') // 标题
  const [description, setDescription] = useState('') // 描述
  const [vendorData, setVendorData] = useState('') // 供应商
  const [tableData, setTableData] = useState([]) // 变体表格数据
  const [checkTableData, setCheckTableData] = useState([]) // 已勾选的变体列表
  const [viewPrice, setViewPrice, getViewPrice] = useGetState({
    frontPrice: 0, // 前视图打印价格
    realPrice: 0, // 后视图打印价格
  })
  const [canvasImage, setCanvasImage, getCanvasImage] = useGetState({
    frontName: '', // 前视图oss图片名称
    frontUrl: '', // 前视图url
    backName: '', // 后视图oss图片名称
    backUrl: '', // 后视图url
  })

  // 复制产品
  const [isShowDuplicate, setIsShowDuplicate] = useState(false)

  // 更新变体弹窗
  const [showUpdateVarModal, setShowUpdateVarModal] = useState(false)
  const [variantData, setVariantData] = useState([]) // 所有变体列表
  const [checkVariants, setCheckVariants, getCheckVariants] = useGetState([]) // 勾选的变体列表
  const [defaultImage, setDefaultImage] = useState('') // 默认展示前视图第一张

  // 修改变体图片
  const [showVarintImgModal, setShowVarintImgModal] = useState(false)
  const [curVariantItem, setCurVariantItem] = useState(null) // 当前点选的变体

  // 删除变体弹窗
  const [isShowDeleteVariant, setIsShowDeleteVariant] = useState(false)

  // 编辑价格弹窗
  const [isShowPriceModal, setIsShowPriceModal] = useState(false) // 编辑价格弹窗显示

  // 编辑库存弹窗
  const [isShowInventoryModal, setIsShowInventoryModal] = useState(false) // 编辑利润弹窗显示

  // 编辑利润弹窗
  const [isShowProfitModal, setIsShowProfitModal] = useState(false) // 编辑利润弹窗显示

  // 删除产品弹窗
  const [isShowDeleteProduct, setIsShowDeleteProduct] = useState(false)

  // 保存为新产品
  const [isShowNewProduct, setIsShowNewProduct] = useState(false)

  // 离开当前页
  const [isShowLeavePage, setIsShowLeavePage] = useState(false)
  const [lastRouterPath, setLastRouterPath] = useState('')

  // Toast提示
  const [showToast, setShowToast] = useState(false)
  const [toastContent, setToastContent] = useState('')

  // 商品类型
  const [product, setProduct] = useState('')
  const [productType, setProductType] = useState([])

  const [collectionsDataList, setCollectionsDataList] = useState([])
  const [collectionsData, setCollectionsData] = useState([])

  // 标签
  const [tags, setTags] = useState([])
  const [tagsData, setTagsData] = useState([])

  useEffect(() => {
    if (router.location.state.entry) {
      entryRef.current = router.location.state.entry
    }

    initData()

    // 路由拦截
    router.block((param) => {
      if (entryRef.current === 'design' && param.pathname !== '/confugureDesign') {
        setLastRouterPath(param.pathname)
        setIsShowLeavePage(true)
        return false
      }
      return true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  // 初始化数据
  const initData = async (finishedId) => {
    setIsLoading(true)
    // 从产品设计跳转
    if (entryRef.current === 'design') {
      const productInfo = await getProductData()
      setProductInfo(productInfo) // 商品详情

      // 如果是重新设计的，则需要请求成品详情
      let finishData = null
      if (router.location.state.finishedId) {
        finishData = await getFinishedProduct()
        setFinishData(finishData)
      }

      setViewPrice(productInfo.printingPrice) // 印刷价格
      setCountry(productInfo.country) // 国家
      setMoneyFormat(productInfo.moneyFormat) // 货币符号

      setProStatus(finishData ? finishData.status : 'Unpublished') // 设置产品状态
      setTitleValue(finishData ? finishData.title : productInfo.title) // 标题
      setDescription(finishData ? finishData.description : productInfo.descriptionShow) // 描述
      setProduct(finishData ? finishData.type : '') // type
      setVendorData(finishData ? finishData.vendor : '') //product vendor
      setCollectionsDataList(finishData ? finishData.collections : [])

      setProductType(productInfo.typeMap) // type下拉列表
      setCollectionsData(productInfo.collectionsMap) // Collections

      handleSetCanvasImage() // 设置前后视图
      await createImage(productInfo.images, productInfo.variantData, true)
    } else {
      // 从产品列表过来，则直接获取成品详情
      const finishData = await getFinishedProduct(finishedId)
      setFinishData(finishData) // 成品详情

      setViewPrice(finishData.printingPrice) // 印刷价格
      setCountry(finishData.country) // 国家
      setMoneyFormat(finishData.moneyFormat) // 货币符号
      setProStatus(finishData.status) // 设置产品状态

      setTitleValue(finishData.title) // 设置标题
      setDescription(finishData.description) // 设置描述
      setProduct(finishData.type) // type
      setVendorData(finishData.vendor) //product vendor
      setCollectionsDataList(finishData.collections)

      setProductType(finishData.typeMap) // type下拉列表
      setCollectionsData(finishData.collectionsMap) // Collections
      setTags(finishData.tag) //tag
      setTagsData(finishData.tagMap) //tags

      handleSetImageList(finishData.image) // 设置展示的图片
      handleSetCanvasImage(finishData.frontData.frontUrl, finishData.rearData.frontUrl) // 设置前后视图
      handleSetTableData(finishData.variantsData) // 变体数据
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }

  /**
   * 获取成品详情
   * @param {*} id 成品id
   */
  const getFinishedProduct = async (id) => {
    console.log('props', props)
    let finishedId = id || props.location.state.finishedId
    if (!finishedId) {
      setToastContent('FinishedId is Null')
      return
    }
    const params = {
      finishedId,
      isReview: props.location.state.filterStatus === 'Exception' ? 2 : '',
    }
    try {
      return await getProductContent(params)
    } catch (error) {
      setToastContent('Server error')
      setIsError(true)

      return {}
    }
  }

  // 获取商品详情
  const getProductData = async () => {
    const id = props.location.state.productId
    const res = await getCatelogOrDesignDetails({ id })
    return res || {}
  }

  /**
   * 获取所有背景图
   * @param {Object} images 接口返回的设计图，包含前后视图
   * @returns {Array} 返回拼接好的背景图数组
   */
  const getBackgroundImages = (images) => {
    const imageList = []
    _.forIn(images, (value, key) => {
      if (key === 'front' || key === 'rear') {
        let images = []
        // 是否存在webp图片
        const isHaveWebpImage = !!_.find(value, (fileName) => {
          return _.includes(_.toLower(fileName), '.webp')
        })
        // 如果支持webp，则用webp格式图片
        if (isCheckWebp && isHaveWebpImage) {
          images = _.filter(value, (fileName) => {
            return _.includes(_.toLower(fileName), '.webp')
          })
        } else {
          images = _.filter(value, (fileName) => {
            return _.includes(_.toLower(fileName), '.png')
          })
        }
        _.forEach(images, (item) => {
          imageList.push({
            src: item,
            url: ossSignatureUrl(item),
            type: key,
          })
        })
      }
    })
    return imageList
  }

  /**
   * 生成图片
   * @param {Object} imageDesign 设计所需的背景图
   * @param {Array} variantData 所有变体
   * @param {Boolean} isInit 是否第一次加载，如果是，则默认从路由中获取颜色列表
   */
  const createImage = async (imageDesign, variantData, isInit = false) => {
    // 获取所有背景图
    const backgroundImages = getBackgroundImages(imageDesign)
    let colors = []
    if (isInit) {
      colors = router.location.state.colors
    } else {
      const variants = getCheckVariants()
      colors = _.uniq(_.map(variants, 'color'))
    }

    // 删除变体时，清除变体中没有的颜色图片
    const allImages = _.filter(allImageList, (item) => {
      return _.includes(colors, item.color)
    })

    // 根据颜色，将背景图与设计图合并。已有的不再重新生成
    const newImages = await mergeImage(backgroundImages, colors)

    let images = _.concat(allImages, newImages)
    _.forEach(images, (item) => {
      item.sort = _.findIndex(colors, (color) => {
        return color === item.color
      })
    })
    images = _.sortBy(images, 'sort')

    setAllImageList(images)

    initTableData(images, variantData, isInit) // 初始化变体表格数据
  }

  /**
   * 合并图片
   * @param {Array} backgroundImages 所有背景图
   * @param {Array} colors 所有颜色
   * @return {Array} 返回所有生成好的图片
   */
  const mergeImage = async (backgroundImages, colors) => {
    // 获取设计图
    let frontUrl
    let backUrl
    if (entryRef.current === 'design') {
      frontUrl = router.location.state.canvasImage.Front
      backUrl = router.location.state.canvasImage.Back
    } else {
      const designUrl = getDesignImage()
      frontUrl = designUrl.front
      backUrl = designUrl.back
    }

    const imageList = []

    // 加载前后视图设计图
    const frontImage = await imgLoad(frontUrl)
    const backImage = await imgLoad(backUrl)
    const productId = props.location.state.productId || productInfo.id

    for (let i = 0; i < backgroundImages.length; i++) {
      const item = backgroundImages[i]
      const bgImage = await imgLoad(item.url) // 加载背景图

      for (let k = 0; k < colors.length; k++) {
        const color = colors[k]
        const curItem = _.find(allImageList, (o) => {
          return o.color === color
        })
        if (_.isEmpty(curItem)) {
          const canvas = document.createElement('canvas')
          canvas.width = 1000
          canvas.height = 1000

          const ctx = canvas.getContext('2d')
          ctx.fillStyle = color // 填充颜色
          ctx.fillRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(bgImage, 0, 0)

          const designImage = item.type === 'front' ? frontImage : backImage
          if (designImage) {
            const imgX = (1000 - designImage.width) / 2 // 设计图居中
            const imgY = (1000 - designImage.height) / 2 // 设计图居中
            ctx.drawImage(designImage, imgX, imgY)
          }

          imageList.push({
            color,
            url: canvas.toDataURL(),
            src: setOssImageUrl({
              module: 'products',
              type: 'image/jpg',
              productId,
            }),
            type: item.type,
          })
        }
      }
    }
    return imageList
  }

  // 加载img标签图片
  const imgLoad = (url) => {
    const img = document.createElement('img')
    img.setAttribute('crossOrigin', 'Anonymous')
    img.src = url
    if (img.complete) {
      return img
    } else {
      return new Promise((resolve) => {
        img.onload = () => {
          resolve(img)
        }
        img.onerror = () => {
          resolve(null)
        }
      })
    }
  }

  /**
   * 从产品设计跳转，初始化变体表格数据。新增或者删除变体，也会更新表格数据
   * @param {Array} images 所有media图片
   * @param {Array} variants 所有变体
   * @param {Boolean} isInit // 是否第一次加载
   */
  const initTableData = (images, variants, isInit = false) => {
    let data = []
    if (isInit) {
      data = _.filter(variants, (item) => {
        return _.includes(router.location.state.variantIds, item.variantId)
      })
    } else {
      const checkedIds = _.map(getCheckVariants(), 'variantId')
      data = _.filter(variants, (item) => {
        return _.includes(checkedIds, item.variantId)
      })
    }

    const viewPrice = getViewPrice()
    const canvasImage = getCanvasImage()
    const newData = _.map(data, (el) => {
      let price = el.cost
      // console.log('cost', el.cost)
      // console.log('canvasImage', canvasImage)
      // console.log('viewPrice', viewPrice)
      if (canvasImage.frontUrl) {
        price = _.round(price + viewPrice.frontPrice, 2)
      }
      if (canvasImage.backUrl) {
        price = _.round(price + viewPrice.realPrice, 2)
      }
      // console.log('price', price)
      const frontImage = _.find(images, (item) => {
        return el.color === item.color && item.type === 'front'
      })
      const rearImage = _.find(images, (item) => {
        return el.color === item.color && item.type === 'rear'
      })
      const oleRow = _.find(tableData, (item) => {
        return el.variantId === item.podVariantId
      })
      // console.log('olerow', oleRow)
      return {
        cost: _.get(oleRow, 'cost') || price,
        inventory: _.get(oleRow, 'inventory') || el.inventory,
        optionOne: el.color,
        optionTwo: el.size,
        podVariantId: el.variantId,
        statement: el.statement,
        price: _.get(oleRow, 'price') || price,
        profit: _.get(oleRow, 'profit') || 0,
        sku: _.get(oleRow, 'sku'),
        skuImage: _.get(frontImage, 'src') || _.get(oleRow, 'skuImage'),
        skuImageUrl: _.get(frontImage, 'url') || _.get(oleRow, 'skuImageUrl'),
        frontImage: _.get(frontImage, 'src') || _.get(oleRow, 'frontImage'),
        realImage: _.get(rearImage, 'src') || _.get(oleRow, 'realImage'),
        variantId: _.get(oleRow, 'variantId'),
      }
    })
    setTableData(newData)
  }

  /**
   * 从产品列表进入，设置变体表格数据
   * @param {Array} variants 变体列表
   */
  const handleSetTableData = async (variants) => {
    const data = _.cloneDeep(variants)
    _.forEach(data, (item) => {
      if (item.skuImage) {
        item.skuImageUrl = ossSignatureUrl(item.skuImage, {
          'x-oss-process': 'image/resize,w_40',
        })
      }
    })
    setTableData(data)
  }

  /**
   * 设置前后视图设计图
   * @param {String} frontUrl 前视图oss名称
   * @param {String} rearUrl 后视图oss名称
   */
  const handleSetCanvasImage = async (frontName = null, backName = null) => {
    let frontUrl
    let backUrl
    const productId = props.location.state.productId || productInfo.id
    if (entryRef.current === 'design') {
      frontUrl = router.location.state.canvasImage.Front
      backUrl = router.location.state.canvasImage.Back
      if (frontUrl) {
        frontName = setOssImageUrl({
          module: 'products',
          type: 'image/png',
          productId,
        })
      }
      if (backUrl) {
        backName = setOssImageUrl({
          module: 'products',
          type: 'image/png',
          productId,
        })
      }
    } else {
      if (frontName) {
        frontUrl = ossSignatureUrl(frontName)
      }
      if (backName) {
        backUrl = ossSignatureUrl(backName)
      }
    }
    // console.log('frontName', frontName)
    // console.log('frontUrl', frontUrl)
    // console.log('backName', backName)
    // console.log('backUrl', backUrl)
    setCanvasImage({
      frontName, // 前视图oss图片名称
      frontUrl, // 后视图oss图片名称
      backName, // 设计区域前视图url
      backUrl, // 设计区域后视图url
    })
  }

  // 加载成品设计图
  const getDesignImage = () => {
    let frontUrl
    let backUrl
    if (finishData.frontData && finishData.frontData.frontUrl) {
      frontUrl = ossSignatureUrl(finishData.frontData.frontUrl)
    }
    if (finishData.rearData && finishData.rearData.frontUrl) {
      backUrl = ossSignatureUrl(finishData.rearData.frontUrl)
    }
    return {
      front: frontUrl,
      back: backUrl,
    }
  }

  /**
   * 重新设计
   */
  const handleEditDesign = () => {
    router.push({
      pathname: '/confugureDesign',
      state: {
        ...router.location.state,
        entry: 'myProducts',
      },
    })
  }

  // 获取所有成品图
  const handleAllColor = (_checked, value) => {
    setRadioValue(value)
  }

  // 加载更多成品图
  const handleLoadMoreImg = () => {
    setLoadNum(loadNum + 5)
  }

  // 成品图颜色切换
  const handleColor = (hex) => {
    setRadioValue(hex)
    setLoadNum(5)
  }

  // 修改颜色hover提示
  const handleColorHover = (color, event) => {
    const title = _.get(
      _.find(tableData, (item) => {
        return (
          _.toLower(item.color) === _.toLower(color.hex) ||
          _.toLower(item.optionOne) === _.toLower(color.hex)
        )
      }),
      'statement'
    )
    event.target.title = title
  }

  // 图片拖动
  const moveCard = useCallback(({ image, oldIndex, newIndex }) => {
    setAllImageList((prevCards) => {
      const radioValue = getRadioValue()
      if (radioValue === 'allimages') {
        return update(prevCards, {
          $splice: [
            [oldIndex, 1],
            [newIndex, 0, prevCards[oldIndex]],
          ],
        })
      } else {
        const index = _.findIndex(prevCards, (item) => {
          return _.toLower(item.color) === _.toLower(image.color)
        })
        return update(prevCards, {
          $splice: [
            [index + oldIndex, 1],
            [index + newIndex, 0, prevCards[index + oldIndex]],
          ],
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 删除media图片
   * @param currentUrl 当前图片url
   */
  const handleDelImage = async (src) => {
    // 更新media图片
    const allImages = _.filter([...allImageList], (item) => {
      return item.src !== src
    })
    setAllImageList(allImages)

    // 更新变体sku图片
    const data = [...tableData]
    _.forEach(data, (item) => {
      if (item.skuImage === src) {
        item.skuImage = ''
        item.skuImageUrl = ''
      }
    })
    setTableData(data)

    setToastContent('Image removed')
    setShowToast(true)
  }

  /**
   * 设置展示的图片
   * @param {Array} images 图片列表
   */
  const handleSetImageList = (images) => {
    const imageList = _.compact(images)
    const list = []
    _.forEach(imageList, (item) => {
      const obj = {
        color: item.color,
        src: item.src,
        url: ossSignatureUrl(item.src),
        alt: item.alt,
        type: item.type,
      }
      list.push(obj)
    })

    setAllImageList(list)
  }

  // 修改库存/定价
  const changeTableData = (value, id, type) => {
    const data = [...tableData]
    _.forEach(data, (item) => {
      if (item.podVariantId === id && type === 'price') {
        item.price = value
        item.profit = _.round(value - item.cost, 2)
      }
      if (item.podVariantId === id && type === 'inventory') {
        item.inventory = value
      }
    })
    setTableData(data)
  }

  // 价格输入框blur事件
  const handleBlur = (e, item, type) => {
    if (e.target.value === '') {
      const data = [...tableData]
      _.forEach(data, (el) => {
        if (el.podVariantId === item.podVariantId) {
          if (type === 'price') {
            item.price = 0
          } else if (type === 'inventory') {
            item.inventory = 0
          }
        }
      })
      setTableData(data)
    }
  }

  /**
   * 打开复制产品弹窗
   */
  const onDuplicate = () => {
    setIsShowDuplicate(true)
  }

  /**
   * 复制产品
   * @param {*} copyFinishedId 所复制产品的id
   */
  const handleCopyProduct = (id) => {
    setToastContent('Product copied')
    initData(id)
    setShowToast(true)
  }

  // 打开删除产品弹窗
  const onProductDelete = () => {
    setIsShowDeleteProduct(true)
  }

  // 关闭删除产品弹窗
  const closeDeleteProduct = () => {
    setIsShowDeleteProduct(false)
  }

  // 确认删除产品
  const handleDeleteProduct = async () => {
    try {
      await getProductDelete({ finishedId: [finishData.finishedId] })
      setIsShowDeleteProduct(false) // 关闭弹窗
      router.push('/myProducts')
    } catch (err) {
      setToastContent('Product Delete failed')
      setShowToast(true)
    }
  }

  // 回调获取选中的产品类型数据
  const getProductTypeValueFn = (value) => {
    if (!_.isEmpty(value)) {
      setProduct(value)
    }
  }

  // 打开修改变体图片弹窗
  const openEditImage = (e, item) => {
    e.stopPropagation()
    if (finishData.isHistory) {
      return
    }
    setCurVariantItem(item)
    setShowVarintImgModal(true)
  }

  // 关闭修改变体图片弹窗
  const closeEditImage = () => {
    setShowVarintImgModal(false)
  }

  // 确认修改变体图片
  const handleChangeVariantImage = (imageItem) => {
    const data = [...tableData]
    _.forEach(data, (item) => {
      if (item.podVariantId === curVariantItem.podVariantId) {
        item.skuImage = imageItem.src
        item.skuImageUrl = imageItem.url
      }
    })
    setTableData(data)
  }

  // 打开变体弹窗
  const handleUpdateVariants = () => {
    let variants = [] // 所有变体列表
    let checkedData = checkVariants // 已勾选的变体
    const variantIds = _.map(tableData, 'podVariantId')

    if (entryRef.current === 'design') {
      // 从产品设计跳转
      variants = productInfo.variantData
      if (isCheckWebp) {
        // 查找webp设计图
        const firstWebp = _.find(productInfo.images.front, (o) => {
          return _.includes(_.toLower(o), '.webp')
        })
        if (firstWebp) {
          setDefaultImage(firstWebp)
        } else {
          setDefaultImage(productInfo.images.front[0])
        }
      } else {
        setDefaultImage(productInfo.images.front[0])
      }
    } else {
      variants = finishData.productVariant
      setDefaultImage(finishData.imagesDesign.front[0])
    }
    checkedData = _.filter(variants, (item) => {
      if (_.includes(variantIds, item.variantId)) {
        item.checked = true
      } else {
        item.checked = false
      }
      return item.checked
    })
    setVariantData(variants)
    setCheckVariants(checkedData)

    setShowUpdateVarModal(true)
  }

  // 关闭变体弹窗
  const handleCloseVariantModal = () => {
    setShowUpdateVarModal(false)
  }

  // 变体操作显示
  const resourceIDResolver = (products) => {
    return products.podVariantId
  }

  // 勾选变体列表
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    tableData,
    { resourceIDResolver }
  )

  // 打开删除变体弹窗
  const openDelteVariant = () => {
    const data = _.filter(tableData, (item) => {
      return _.includes(selectedResources, item.podVariantId)
    })
    setCheckTableData(data)
    setIsShowDeleteVariant(true)
  }

  // 关闭删除变体弹窗
  const closeDelteVariant = () => {
    setIsShowDeleteVariant(false)
  }

  // 确定删除变体
  const handleDeleteVariant = () => {
    const ids = _.map(checkTableData, 'podVariantId')
    const data = _.filter([...tableData], (item) => {
      return !_.includes(ids, item.podVariantId)
    })
    setTableData(data)
  }

  // 打开编辑价格弹窗
  const openEditPrice = () => {
    const data = _.filter(tableData, (item) => {
      return _.includes(selectedResources, item.podVariantId)
    })
    setCheckTableData(data)
    setIsShowPriceModal(true)
  }

  // 关闭编辑价格弹窗
  const closeEditPrice = () => {
    setIsShowPriceModal(false)
  }

  // 确认修改变体价格
  const handleChangePrice = (list) => {
    const data = [...tableData]
    _.forEach(list, (item) => {
      const curItem = _.find(data, (el) => {
        return el.podVariantId === item.podVariantId
      })
      if (curItem) {
        curItem.price = item.price
        curItem.profit = _.round(_.toNumber(item.price) - _.toNumber(item.cost), 2).toString()
      }
    })
    setTableData(data)
  }

  // 打开编辑库存弹窗
  const openEditInventory = () => {
    const data = _.filter(tableData, (item) => {
      return _.includes(selectedResources, item.podVariantId)
    })
    setCheckTableData(data)
    setIsShowInventoryModal(true)
  }

  // 关闭编辑库存弹窗
  const closeEditInventory = () => {
    setIsShowInventoryModal(false)
  }

  // 确认修改变体库存
  const handleChangeInventory = (list) => {
    const data = [...tableData]
    _.forEach(list, (item) => {
      const curItem = _.find(data, (el) => {
        return el.podVariantId === item.podVariantId
      })
      if (curItem) {
        curItem.inventory = item.inventory
      }
    })
    setTableData(data)
  }

  // 打开编辑利润弹窗
  const openEditProfit = () => {
    const data = _.filter(tableData, (item) => {
      return _.includes(selectedResources, item.podVariantId)
    })
    setCheckTableData(data)
    setIsShowProfitModal(true)
  }

  // 关闭编辑利润弹窗
  const closeEditProfit = () => {
    setIsShowProfitModal(false)
  }

  // 确认修改变体利润
  const handleChangeProfit = (list) => {
    const data = [...tableData]
    _.forEach(list, (item) => {
      const curItem = _.find(data, (el) => {
        return el.podVariantId === item.podVariantId
      })
      if (curItem) {
        curItem.price = _.round(_.toNumber(item.cost) + _.toNumber(item.profit), 2).toString()
        curItem.profit = item.profit
      }
    })
    setTableData(data)
  }

  /**
   * 判断是否弹出引导好评公共方法
   */
  const checkFavorableCommond = (type) => {
    const { checkFavorable } = props.location.state
    let status = 3
    if (!_.isNil(checkFavorable)) {
      // 首次完成设计弹出引导好评
      if (checkFavorable.isFirstFinishedProduct && type === 'editItLater') {
        status = 0
      }
      // if (checkFavorable.isFirstPublished && type === 'publish') { //发布情况2.0需求存在问题
      //   status = 1
      // }
    }
    router.push({
      pathname: '/myProducts',
      params: {
        status: status,
      },
    })
  }

  // 关闭新产品弹窗
  const handleCloseNewProductModal = () => {
    setIsShowNewProduct(false)
  }

  // 保存为新产品
  const handleSaveNewProduct = async () => {
    setLoadingSave(true)
    await handleAddNewProduct()
    setLoadingSave(false)
  }

  // 关闭拦截弹窗
  const handleCloseLeavePageModal = () => {
    setIsShowLeavePage(false)
  }

  // 确认离开当前页
  const handleConfirmLeavePage = () => {
    router.block(() => true)
    router.push(lastRouterPath)
  }

  /**
   * 保存
   * @param {*} type 【1 保存并发布】【2 仅保存】
   */
  const handleSave = async (type) => {
    setLoadingSave(true)
    if (entryRef.current === 'design' && !router.location.state.finishedId) {
      await handleAddProduct(type) // 新增成品
    } else {
      await handleEditProduct(type) // 编辑成品
    }
    setLoadingSave(false)
  }

  // 新增成品
  const handleAddProduct = async (statusType = 1) => {
    let viewType = 1 // 【1：只有正视图】【2：只有背视图】【3：正视图和背视图都有】
    if (
      !_.isEmpty(router.location.state.canvasJson.Front) &&
      !_.isEmpty(router.location.state.canvasJson.Back)
    ) {
      viewType = 3
    } else if (!_.isEmpty(router.location.state.canvasJson.Front)) {
      viewType = 1
    } else if (!_.isEmpty(router.location.state.canvasJson.Back)) {
      viewType = 2
    }
    // media图片
    const images = _.map(allImageList, (item) => {
      return {
        alt: item.alt,
        src: item.src,
        color: item.color,
        type: item.type,
      }
    })
    // 变体数据
    const variantData = _.map(tableData, (item) => {
      return {
        cost: item.cost,
        inventory: item.inventory,
        optionOne: item.optionOne,
        optionTwo: item.optionTwo,
        podVariantId: item.podVariantId,
        price: item.price,
        profit: item.profit,
        sku: item.sku,
        skuImage: item.skuImage,
        frontImage: item.frontImage,
        realImage: item.realImage,
        statement: item.statement,
        variantId: item.variantId,
      }
    })
    const canvasImage = getCanvasImage()
    const params = {
      id: router.location.state.productId,
      frontData: {
        frontUrl: canvasImage.frontName, // 前视设计图
        type: '1',
        design: router.location.state.canvasJson.Front,
      },
      rearData: {
        frontUrl: canvasImage.backName, // 后视设计图
        type: '2',
        design: router.location.state.canvasJson.Back,
      },
      viewType,
      image: images, // media图片
      statusType,
      title: titleValue,
      description,
      descriptionType: finishData.finishedId ? 1 : '',
      type: product,
      vendor: vendorData,
      collections: collectionsDataList,
      variantData,
    }

    try {
      await uploadImages()
      await productDesignInsert(params)
      router.block(() => true)

      if (statusType === 1) {
        checkFavorableCommond('publish')
      } else {
        checkFavorableCommond('editItLater')
      }

      //router.push('/myProducts')
    } catch (e) {
      const msg = e.data && e.data.message ? e.data.message : 'Server Error'
      setIsError(true)
      setToastContent(msg)
      setShowToast(true)
    }
  }

  // 保存为新产品
  const handleAddNewProduct = async () => {
    if (entryRef.current === 'design') {
      handleAddProduct(2)
    } else {
      try {
        let viewType = 1 // 【1：只有正视图】【2：只有背视图】【3：正视图和背视图都有】
        if (finishData.frontData.frontUrl && finishData.rearData.frontUrl) {
          viewType = 3
        } else if (finishData.frontData.frontUrl) {
          viewType = 1
        } else if (finishData.rearData.frontUrl) {
          viewType = 2
        }
        // images media图片
        const images = _.map(allImageList, (item) => {
          return {
            alt: item.alt,
            src: item.src,
            color: item.color,
            type: item.type,
          }
        })
        // 变体数据
        const variantData = _.map(tableData, (item) => {
          return {
            cost: item.cost,
            inventory: item.inventory,
            optionOne: item.optionOne,
            optionTwo: item.optionTwo,
            podVariantId: item.podVariantId,
            price: item.price,
            profit: item.profit,
            sku: item.sku,
            skuImage: item.skuImage,
            frontImage: item.frontImage,
            realImage: item.realImage,
            statement: item.statement,
            variantId: item.variantId,
          }
        })
        const params = {
          id: finishData.productId,
          viewType,
          statusType: 2, // 产品状态
          title: titleValue, // 标题
          description, // 描述
          descriptionType: 1, // 如果类型为1，则后端不生成尺码表
          type: product, // 类型
          vendor: vendorData,
          collections: collectionsDataList,
          tag: tags,
          variantData,
          image: images, // media图片
          frontData: finishData.frontData,
          rearData: finishData.rearData,
        }
        await productDesignInsert(params)
        router.block(() => true)
        router.push('/myProducts')
      } catch (e) {
        const msg = e.data && e.data.message ? e.data.message : 'Server Error'
        setIsError(true)
        setToastContent(msg)
        setShowToast(true)
      }
    }
  }

  // 编辑成品
  const handleEditProduct = async (type) => {
    try {
      const order = await handleGetOrder()
      // 如果订单数大于0，则保存为一个新产品
      if (order > 0 && entryRef.current === 'design') {
        setIsShowNewProduct(true)
        return
      }
      const canvasImage = getCanvasImage()
      // images media图片
      const images = _.map(allImageList, (item) => {
        return {
          alt: item.alt,
          src: item.src,
          color: item.color,
          type: item.type,
        }
      })
      // 变体数据
      const variantsData = _.map(tableData, (item) => {
        return {
          cost: item.cost,
          inventory: item.inventory,
          optionOne: item.optionOne,
          optionTwo: item.optionTwo,
          podVariantId: item.podVariantId,
          price: item.price,
          profit: item.profit,
          sku: item.sku,
          skuImage: item.skuImage,
          frontImage: item.frontImage,
          realImage: item.realImage,
          statement: item.statement,
          variantId: item.variantId,
        }
      })
      const params = {
        finishedId: finishData.finishedId, // 成品id
        statusType: type, // 产品状态
        title: titleValue, // 标题
        description, // 描述
        type: product, // 类型
        vendor: vendorData,
        collections: collectionsDataList,
        tag: tags,
        variantsData,
        image: images, // media图片
        frontData: {
          frontUrl: canvasImage.frontName, // 前视设计图
          type: '1',
          design: router.location.state.canvasJson
            ? router.location.state.canvasJson.Front
            : finishData.frontData.design,
        },
        rearData: {
          frontUrl: canvasImage.backName, // 后视设计图
          type: '2',
          design: router.location.state.canvasJson
            ? router.location.state.canvasJson.Back
            : finishData.rearData.design,
        },
      }
      await uploadImages() // 上传图片
      await getProductSave(params)
      if (type === 1) {
        checkFavorableCommond('publish')
      }
      router.block(() => true)
      router.push('/myProducts')
    } catch (e) {
      const msg = e.data && e.data.message ? e.data.message : 'Server Error'
      setIsError(true)
      setToastContent(msg)
      setShowToast(true)
    }
  }

  // 批量上传media图片到oss
  const uploadImages = async () => {
    const res = await uploadOSS()
    const client = getClient(res)

    // 上传media图片
    const requestList = []

    _.forEach(allImageList, (item) => {
      if (_.includes(item.url, 'data:')) {
        const file = base64toFile(item.url, +new Date().valueOf())
        requestList.push(putObject(item.src, file, client)) // 上传media图片到oss
      }
    })

    // 上传设计区域图片
    const canvasImage = getCanvasImage()
    if (canvasImage.frontUrl && _.includes(canvasImage.frontUrl, 'data:')) {
      const frontFile = base64toFile(canvasImage.frontUrl, +new Date().valueOf())
      requestList.push(putObject(canvasImage.frontName, frontFile, client)) // 上传图片到oss
    }
    if (canvasImage.backUrl && _.includes(canvasImage.backUrl, 'data:')) {
      const rearFile = base64toFile(canvasImage.backUrl, +new Date().valueOf())
      requestList.push(putObject(canvasImage.backName, rearFile, client)) // 上传图片到oss
    }

    await Promise.all(requestList)
  }

  /**
   * 查看成品有无订单
   */
  const handleGetOrder = async () => {
    const res = await getQueryVolume({ finishedId: finishData.finishedId })
    return res
  }

  // 添加或删除变体列表
  const handleChangeVariant = (value) => {
    setCheckVariants(value)
    setTimeout(() => {
      if (entryRef.current === 'design') {
        createImage(productInfo.images, variantData)
      } else {
        createImage(finishData.imagesDesign, variantData)
      }
    })
  }

  // 修改标题
  const onTitleChange = async (newValue) => {
    setTitleValue(newValue)
  }

  // 修改描述
  const onDiscriptChange = (newValue) => {
    setDescription(newValue)
  }

  // 总库存
  const totalInventory = useMemo(() => {
    const data = _.cloneDeep(tableData)
    _.forEach(data, (item) => {
      item.inventory = _.toNumber(item.inventory)
    })
    const inventory = _.sumBy(data, 'inventory')
    return inventory
  }, [tableData])

  // 颜色列表
  const circleColors = useMemo(() => {
    const colors = _.uniq(_.map(tableData, 'optionOne'))
    return colors
  }, [tableData])

  // 产品状态背景色
  const badgeColor = useMemo(() => {
    let status
    switch (proStatus) {
      case 'Active':
        status = 'success'
        break
      case 'Unpublished':
        status = ''
        break
      case 'Exception':
        status = 'critical'
        break
      case 'Publishing':
        status = 'attention'
        break
      default:
        break
    }
    return status
  }, [proStatus])

  // 比较变体数据是否修改过
  const customizer = (newData, oldData) => {
    if (newData.length !== oldData.length) {
      return false
    } else {
      let flag = true
      // 需要比较的字段
      const fields = ['skuImage', 'profit', 'price', 'inventory']
      for (let i = 0; i < newData.length; i++) {
        const newItem = newData[i]
        const oldItem = oldData[i]
        for (let k = 0; k < fields.length; k++) {
          const field = fields[k]
          if (newItem[field] != oldItem[field]) {
            flag = false
            break
          }
        }
      }
      return flag
    }
  }

  // 比较media图片是否修改过
  const customizerImage = (newData, oldData) => {
    if (newData.length !== oldData.length) {
      return false
    } else {
      let flag = true
      // 需要比较的字段
      const fields = ['src', 'color']
      for (let i = 0; i < newData.length; i++) {
        const newItem = newData[i]
        const oldItem = oldData[i]
        for (let k = 0; k < fields.length; k++) {
          const field = fields[k]
          if (newItem[field] != oldItem[field]) {
            flag = false
            break
          }
        }
      }
      return flag
    }
  }

  // 是否显示顶部修改栏
  const isShowSaveBar = useMemo(() => {
    if (_.isEmpty(finishData)) {
      return false
    }
    let flag = false
    // 比较标题是否有改动
    if (titleValue !== finishData.title) {
      flag = true
    }
    // 比较描述是否有改动
    if (description !== finishData.description) {
      flag = true
    }
    // 比较type是否有改动
    if (product !== finishData.type) {
      flag = true
    }
    // 比较Vendor是否有改动
    if (vendorData !== finishData.vendor) {
      flag = true
    }
    // 比较Collections是否有改动
    if (!_.isEqual(collectionsDataList, finishData.collections)) {
      flag = true
    }
    // 比较media图片是否有改动
    if (
      !_.isEqualWith(allImageList, finishData.image, customizerImage) &&
      entryRef.current !== 'design'
    ) {
      flag = true
    }
    if (
      !_.isEqualWith(tableData, finishData.variantsData, customizer) &&
      entryRef.current !== 'design'
    ) {
      // 比较变体表格数据是否有改动
      flag = true
    }
    return flag
  }, [
    titleValue,
    description,
    product,
    vendorData,
    collectionsDataList,
    allImageList,
    tableData,
    finishData,
  ])

  // media显示的图片
  const imageList = useMemo(() => {
    if (radioValue === 'allimages') {
      return _.take(allImageList, loadNum)
    } else {
      const list = _.filter(allImageList, (item) => {
        return _.toLower(item.color) === _.toLower(radioValue)
      })
      return _.take(list, loadNum)
    }
  }, [allImageList, radioValue, loadNum])

  // 是否显示加载更多
  const isShowMore = useMemo(() => {
    if (radioValue === 'allimages') {
      return allImageList.length > loadNum
    } else {
      const list = _.filter(allImageList, (item) => {
        return _.toLower(item.color) === _.toLower(radioValue)
      })
      return list.length > loadNum
    }
  }, [allImageList, radioValue, loadNum])

  // Toast提示
  const toggleImgActive = () => {
    setShowToast(!showToast)
  }

  // Toast提示
  const toastMarkup = showToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleImgActive}
      duration={1200}
      error={isError}
    />
  ) : null

  const renderCard = (item, index) => {
    return (
      <ProductImages
        key={index}
        index={index}
        image={item}
        isDel={!finishData.isHistory}
        moveCard={moveCard}
        onDelete={handleDelImage}
      />
    )
  }

  const rowMarkup = _.map(tableData, (item, index) => {
    return (
      <IndexTable.Row
        id={item.podVariantId}
        key={item.podVariantId}
        selected={selectedResources.includes(item.podVariantId)}
        position={index}
      >
        <IndexTable.Cell>
          <TextStyle variation="strong">
            <div className="variants_sku ">
              <div
                className="variants_sku_image"
                onClick={(e) => openEditImage(e, item)}
              >
                <Image
                  src={item.skuImageUrl}
                  alt={item.statement}
                  size="40"
                />
              </div>
              <div className="variants_sku_title">
                <span>{item.statement + ' / ' + item.optionTwo}</span>
                <br />
                <span style={{ fontWeight: '400', whiteSpace: 'pre-wrap' }}>{item.sku}</span>
              </div>
            </div>
          </TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {country} {moneyFormat} {item.cost}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ width: '100px' }}>
            {country} {moneyFormat} {item.profit}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="variants_retial">
            {/* 单个修改定价 */}
            <div
              className="edit_price_list_item_price"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <TextField
                prefix={`${country} ${moneyFormat}`}
                value={`${item.price}`}
                onChange={(e) =>
                  changeTableData(
                    (e = e.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')),
                    item.podVariantId,
                    'price'
                  )
                }
                onBlur={(e) => {
                  handleBlur(e, item, 'price')
                }}
                align="right"
                autoComplete="off"
              />
            </div>
            <br />
            {/* 单个修改库存 */}
            <div
              className="edit_price_list_item_price"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <TextField
                type="number"
                prefix="INV"
                value={`${item.inventory}`}
                onChange={(value) =>
                  changeTableData(
                    (value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')),
                    item.podVariantId,
                    'inventory'
                  )
                }
                onBlur={(e) => {
                  handleBlur(e, item, 'inventory')
                }}
                align="right"
                autoComplete="off"
              />
            </div>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  })

  let mediaActions = [
    {
      content: 'Edit design',
      onAction: handleEditDesign,
    },
  ]
  if (
    finishData &&
    (finishData.salesVolume > 0 || finishData.status === 'Publishing' || finishData.isHistory)
  ) {
    mediaActions = []
  }

  const pageContent = (
    <div className="productContentEdit_page">
      <Page
        fullWidth
        breadcrumbs={[
          {
            content: 'Orders',
            onAction: () => {
              router.push('/myProducts', { favoraStatus: 1 })
            },
          },
        ]}
        titleMetadata={<Badge status={badgeColor}>{proStatus}</Badge>}
        title={titleValue}
        actionGroups={[
          {
            title: 'More actions',
            actions: [
              {
                content: 'Publish',
                onAction: () => handleSave(1),
              },
              {
                content: 'Duplicate',
                disabled: entryRef.current === 'design',
                onAction: () => onDuplicate(),
              },
            ],
          },
        ]}
        pagination={{
          hasPrevious: finishData.previousId,
          onPrevious: () => {
            initData(finishData.previousId)
          },
          hasNext: finishData.nextId,
          onNext: () => {
            initData(finishData.nextId)
          },
        }}
      >
        {isShowSaveBar && (
          <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
              onAction: () => handleSave(2),
              loading: false,
              disabled: false,
            }}
            discardAction={{
              onAction: () => router.push('/myProducts'),
            }}
          />
        )}
        <Card
          title="Media"
          actions={mediaActions}
        >
          <div className="_line_one"></div>
          <Card.Section>
            <div>
              <RadioButton
                label="All images"
                checked={radioValue === 'allimages'}
                id="allimages"
                name="accounts"
                onChange={handleAllColor}
              />
            </div>
            <CirclePicker
              width="100%"
              colors={circleColors}
              className="updateVarColor"
              circleSize={24}
              onChange={(color) => {
                handleColor(color.hex)
              }}
              onSwatchHover={handleColorHover}
            />
            <div className="edit_media">
              {/* 设计完成区域 */}
              <DndProvider backend={HTML5Backend}>
                {imageList.map((item, i) => renderCard(item, i))}
              </DndProvider>
              {/* 加载更多按钮 */}
              {isShowMore && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '325px',
                  }}
                >
                  <Button
                    plain
                    onClick={handleLoadMoreImg}
                  >
                    Load more files
                  </Button>
                </div>
              )}
            </div>
          </Card.Section>
        </Card>
        <Card title="Title">
          <Card.Section>
            <TextField
              // label="Store name"
              value={titleValue}
              onChange={(value) => onTitleChange(value)}
              autoComplete="off"
              id="titleValue"
            />
          </Card.Section>

          <Card.Section>
            <h2
              className="Polaris-Heading"
              style={{ paddingBottom: '1rem' }}
            >
              Description
            </h2>
            <CKEditor
              onChange={(event, editor) => {
                const data = window.editor.getData()
                onDiscriptChange(data)
              }}
              editor={ClassicEditor}
              config={ckeditorConfig}
              data={description}
            />
          </Card.Section>
        </Card>
        <br />
        <Layout>
          <Layout.Section>
            <div className="left_card">
              <Card
                sectioned
                title="Variants"
                actions={
                  finishData && finishData.isHistory
                    ? []
                    : [
                        {
                          content: 'Update Variants',
                          onAction: handleUpdateVariants,
                        },
                      ]
                }
              >
                <div className="variants_box">
                  <IndexTable
                    headings={[
                      {
                        title:
                          entryRef.current === 'design' ? 'Color / Size' : 'Color / Size / SKU',
                      },
                      { title: 'Cost' },
                      { title: 'Profit' },
                      { title: 'Retail price / Inventory' },
                    ]}
                    itemCount={tableData.length}
                    selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
                    onSelectionChange={handleSelectionChange}
                    promotedBulkActions={[
                      {
                        content: 'Edit prices',
                        onAction: openEditPrice,
                      },
                      {
                        content: 'Delete',
                        onAction: openDelteVariant,
                      },
                    ]}
                    bulkActions={[
                      {
                        content: 'Edit quantities',
                        onAction: openEditInventory,
                      },
                      {
                        content: 'Edit Profit',
                        onAction: openEditProfit,
                      },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </div>
                <div className="variants_total">Total: {totalInventory} available</div>
              </Card>
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <Card
              title="Product organization"
              sectioned
            >
              <ProductOrganization
                productType={productType}
                product={product}
                setProduct={setProduct}
                vendorData={vendorData}
                vendorCurrent={vendorData}
                setVendorData={setVendorData}
                setProductType={setProductType}
                collectionsDataList={collectionsDataList}
                setCollectionsDataList={setCollectionsDataList}
                collectionsData={collectionsData}
                setCollectionsData={setCollectionsData}
                tagsData={tagsData}
                tags={tags}
                setTags={setTags}
                setTagsData={setTagsData}
                getProductTypeValueFn={getProductTypeValueFn}
              />
            </Card>
          </Layout.Section>
        </Layout>
        <br />
        <div className="product_footer_button">
          {finishData.finishedId && (
            <div style={{ color: '#bf0711', float: 'left' }}>
              <Button
                monochrome
                outline
                onClick={() => onProductDelete()}
              >
                Delete product
              </Button>
            </div>
          )}
          <div style={{ float: 'right' }}>
            <ButtonGroup>
              {entryRef.current === 'design' &&
                !_.includes(['Publishing', 'Active'], finishData.status) && (
                  <Button onClick={() => handleSave(2)}>Save as Unpublished</Button>
                )}
              <Button
                primary
                onClick={() => handleSave(1)}
              >
                {entryRef.current === 'myProducts' ? 'Save and Publish' : 'Publish'}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Page>
      {toastMarkup}

      {/* 复制产品弹窗 */}
      <CopyProduct
        isShow={isShowDuplicate}
        finishedId={finishData.finishedId}
        titleValue={titleValue}
        onConfirm={handleCopyProduct}
        setDuplicate={setIsShowDuplicate}
        setTitleValue={setTitleValue}
      />

      {/* 删除产品弹窗 */}
      <DeleteProduct
        isShow={isShowDeleteProduct} // 控制弹窗显示
        titleValue={titleValue} // 标题
        onClose={closeDeleteProduct} // 关闭回调
        onConfirm={handleDeleteProduct} // 确认回调
      />

      {/* 删除变体弹窗 */}
      <DeleteVariant
        isShow={isShowDeleteVariant} // 控制弹窗显示
        onClose={closeDelteVariant} // 关闭回调
        onConfirm={handleDeleteVariant} // 确认回调
      />

      {/* 产品定价编辑 */}
      <EditPrice
        isShow={isShowPriceModal} // 控制弹窗显示
        country={country} // 国家
        moneyFormat={moneyFormat} // 货币符号
        selectTableRow={checkTableData} // 已勾选的变体列表
        onClose={closeEditPrice} // 关闭回调
        onConfirm={handleChangePrice} // 确认回调
      />

      {/* 产品库存编辑 */}
      <EditInventory
        isShow={isShowInventoryModal} // 控制弹窗显示
        selectTableRow={checkTableData} // 已勾选的变体列表
        onClose={closeEditInventory} // 关闭回调
        onConfirm={handleChangeInventory} // 确认回调
      />

      {/* 产品利润编辑 */}
      <EditProfit
        isShow={isShowProfitModal} // 控制弹窗显示
        country={country} // 国家
        moneyFormat={moneyFormat} // 货币符号
        selectTableRow={checkTableData} // 已勾选的变体列表
        onClose={closeEditProfit} // 关闭回调
        onConfirm={handleChangeProfit} // 确认回调
      />

      {/* 修改变体图 */}
      <EditImage
        isShow={showVarintImgModal}
        allImage={allImageList}
        variant={curVariantItem}
        onClose={closeEditImage}
        onConfirm={handleChangeVariantImage}
      />

      {/* 变体 */}
      <Variant
        isShow={showUpdateVarModal} // 控制弹窗显示
        data={variantData} // 所有数据
        checkVariants={checkVariants} // 已勾选的数据
        imageUrl={defaultImage} // 默认显示图片
        country={country} // 国家
        moneyFormat={moneyFormat} // 货币符号
        onClose={handleCloseVariantModal} // 关闭回调
        onConfirm={handleChangeVariant} // 确认回调
      />

      {/* 如果有订单时，保存为一个新产品 */}
      <NewProduct
        isShow={isShowNewProduct} // 控制弹窗显示
        onClose={handleCloseNewProductModal} // 关闭回调
        onConfirm={handleSaveNewProduct} // 确认回调
      />

      {/* 离开当前页拦截弹窗 */}
      <LeavePage
        isShow={isShowLeavePage} // 控制弹窗显示
        onClose={handleCloseLeavePageModal} // 关闭回调
        onConfirm={handleConfirmLeavePage} // 确认回调
      />

      {/* 保存loading */}
      <FullScreenLoading showLoading={loadingSave} />
    </div>
  )
  return isLoading ? <LoadingPage /> : pageContent
}

export default ProductContentEdit
