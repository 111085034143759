/*
 * @Description: 搜索loading
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-05 17:09:47
 * @LastEditTime: 2023-01-05 17:15:21
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\LoadingSearch\index.jsx
 */
import { Spinner } from '@shopify/polaris'
import './index.scss'

/**
 * 参数说明
 * @param {String} size 图标尺寸，和polaris一致
 */
const LoadingSearch = ({ width, height, size }) => {
  return (
    <div
      className="Loading-Search"
      style={{ width: width || '100%', height: height || '400px' }}
    >
      <Spinner size={size} />
    </div>
  )
}

export default LoadingSearch
