/*
 * @Description: 钱包 - 相关接口
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-03 10:46:14
 * @LastEditTime: 2023-01-11 15:36:35
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\api\settings\billing\index.js
 */
import { request } from '../../request'

export default class BillingService {
  /**
   * 钱包 - 获取余额
   * @returns
   */
  static async getBalance(data) {
    return request({
      url: '/api/v1/order/wallet',
      method: 'POST',
      data,
    })
  }

  /**
   * 钱包 - 账单列表
   * @returns
   */
  static async getWalletList(data) {
    return request({
      url: '/api/v1/wallet/index',
      method: 'POST',
      data,
    })
  }

  /**
   * 钱包 - 充值
   * @returns
   */
  static async apiWalletRecharge(data) {
    return request({
      url: '/api/v1/wallet/walletRecharge',
      method: 'POST',
      data,
    })
  }

  /**
   * 钱包 - 是否充值成功
   * @returns
   */
  static async apiMonitorStatus(data) {
    return request({
      url: '/api/v1/wallet/monitorStatus',
      method: 'POST',
      data,
    })
  }

  /**
   * 钱包 - 提现
   * @returns
   */
  static async apiWithdrawal(data) {
    return request({
      url: '/api/v1/wallet/withdrawal',
      method: 'POST',
      data,
    })
  }
}
