/*
 * @Description: 推荐图片
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-22 14:03:05
 * @LastEditTime: 2023-01-17 16:00:19
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\RecommendImage\index.jsx
 */
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useGetState } from 'ahooks'
import { ChoiceList, Card, Filters } from '@shopify/polaris'
import _ from 'lodash'
// 滚动加载
import InfiniteScroll from 'react-infinite-scroll-component'
// 空数据组件
import PodEmptyState from '@/components/PodEmptyState'
// 搜索loading
import LoadingSearch from '@/components/LoadingSearch'
// 公共方法
import { ossSignatureUrl } from '@/utils/utils'
// api
import { getMaterialImage, getCategory, addHistory } from '@/api/images'
// 样式文件
import './index.scss'

const RecommendImage = ({ onConfirm, onClose }) => {
  const [queryValue, setQueryValue, getQueryValue] = useGetState(null) // 搜索的标题
  const [accountStatus, setAccountStatus, getAccountStatus] = useGetState(null) // 所选类目
  const [imageList, setImageList, getImageList] = useGetState([]) // 图片列表
  const [categoryList, setCategoryList] = useState([]) // 类目列表
  const [isSpinner, setIsSpinner] = useState(false) // 搜索加载
  const [page, setPage, getPage] = useGetState({
    pageNum: 1,
    pageSize: 15,
    total: 0,
  })

  const appliedFilters = []

  useEffect(() => {
    setIsSpinner(true)
    getImageData()
    getCategoryList()
  }, [])

  // 滚动加载
  const fetchMoreData = async () => {
    setPage((preSatate) => ({
      ...preSatate,
      pageNum: preSatate.pageNum + 1,
    }))
    setTimeout(() => {
      getImageData()
    })
  }

  // 查询列表
  const getImageData = _.debounce(async () => {
    const currentPage = getPage()
    const title = _.trim(getQueryValue())

    const params = {
      pageNum: currentPage.pageNum,
      perPage: currentPage.pageSize,
      title,
      categoryId: _.head(getAccountStatus()),
    }
    let dom //控制collection节点样式
    if (document.getElementsByClassName('recommendChoiceNod')[0]) {
      if (params.categoryId !== undefined) {
        dom =
          document.getElementsByClassName('recommendChoiceNod')[0].parentNode.parentNode.parentNode
            .parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
        // dom.style.top = '310.5px'
        dom.style.display = 'none'
      }
    }
    const res = await getMaterialImage(params)
    if (res.list.length === 0 && dom) {
      dom.style.display = 'none'
    }
    _.forEach(res.list, (item) => {
      if (item.imgUrl) {
        item.src = ossSignatureUrl(item.imgUrl) // 读取OSS资源
      }
    })
    setImageList((preSatate) => {
      const images = title && currentPage.pageNum === 1 ? [] : [...preSatate]
      if (images.length > 0) {
        const nextData = _.concat(images, res.list)
        return _.uniqBy(nextData, 'id')
      } else {
        return res.list
      }
    })
    setPage((preSatate) => ({
      ...preSatate,
      total: res.count,
    }))
    setIsSpinner(false)
  }, 300)

  // 是否还有数据
  const hasMore = useMemo(() => {
    const images = getImageList()
    if (page.total === 0 || images.length < page.total) {
      return true
    } else {
      return false
    }
  }, [imageList, page])

  // 获取管理后台素材库类目
  const getCategoryList = async () => {
    const res = await getCategory({
      type: 2,
    })
    if (res) {
      setCategoryList(res)
    }
  }

  // 点击图片
  const handleClick = (item) => {
    handleAddHistory(item)
    onConfirm(item.src)
    onClose()
  }

  // 点击时加入历史
  const handleAddHistory = async (item) => {
    await addHistory({
      id: item.id,
      type: 1, // 1：素材 2：用户图片
    })
  }

  // 输入框内容改变时，自动搜索
  const handleFiltersQueryChange = useCallback(async (value) => {
    setIsSpinner(true)
    await setQueryValue(value)
    await resetData() // 重置
    getImageData()
  }, [])

  // 清空输入框
  const handleQueryValueRemove = useCallback(async () => {
    setIsSpinner(true)
    await setQueryValue(null)
    await resetData() // 重置
    getImageData()
  }, [])

  // 选中类目时，重新搜索数据
  const handleAccountStatusChange = useCallback(async (value) => {
    setIsSpinner(true)
    await setAccountStatus(value)
    await resetData() // 重置
    getImageData()
  }, [])

  // 删除类目
  const handleAccountStatusRemove = useCallback(async () => {
    setIsSpinner(true)
    await setAccountStatus(null)
    await resetData() // 重置
    getImageData()
  }, [])

  // 清空筛选条件
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove()
    handleQueryValueRemove()
    resetData() // 重置
  }, [handleAccountStatusRemove, handleQueryValueRemove])

  // 重置
  const resetData = useCallback(async () => {
    await setImageList([])
    await setPage((preSatate) => ({
      ...preSatate,
      pageNum: 1,
      total: 0,
    }))
  }, [])

  // 图片加载失败
  const handleOnError = (e) => {
    e.target.parentNode.style.display = 'none'
  }

  const filters = [
    {
      key: 'accountStatus',
      label: 'Collection',
      filter: (
        <div className="recommendChoiceNod">
          <ChoiceList
            className="acacacac"
            title="Collection"
            titleHidden
            choices={categoryList}
            selected={accountStatus || []}
            onChange={handleAccountStatusChange}
          />
        </div>
      ),
      shortcut: true,
    },
  ]

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case 'accountStatus':
        const checkList = _.map(value, (id) => {
          const categoryItem = _.find(categoryList, (el) => {
            return el.value === id
          })
          return categoryItem
        })
        return _.join(
          _.map(checkList, (category) => {
            return category.label
          }),
          ', '
        )
      default:
        return value
    }
  }

  if (!_.isEmpty(accountStatus)) {
    const key = 'accountStatus'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    })
  }

  return (
    <Card>
      <Card.Section>
        <div className="Recommend-Filter">
          <Filters
            queryPlaceholder="filter images"
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </div>
        {isSpinner && <LoadingSearch />}
        {!isSpinner &&
          (imageList.length > 0 ? (
            <InfiniteScroll
              dataLength={imageList.length}
              next={fetchMoreData}
              hasMore={hasMore}
              height={450}
            >
              <div className="Flex-Image">
                {_.map(imageList, (item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleClick(item)
                      }}
                    >
                      <img
                        src={item.src}
                        alt={item.title}
                        onError={handleOnError}
                      />
                    </div>
                  )
                })}
              </div>
            </InfiniteScroll>
          ) : (
            <PodEmptyState
              heading={'No images found'}
              image={'/images/empty.svg'}
              action={false}
              paper={<p>Try changing thhe filters or search term</p>}
            />
          ))}
      </Card.Section>
    </Card>
  )
}

export default RecommendImage
