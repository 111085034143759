import { useCallback } from 'react'
import { Modal } from '@shopify/polaris'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const DeleteVariant = ({ isShow, onClose, onConfirm }) => {
  // 关闭弹窗
  const closeModal = useCallback(() => {
    onClose()
  }, [])

  return (
    <Modal
      open={isShow}
      onClose={closeModal}
      title="Delete product variant"
      primaryAction={{
        content: 'Delete',
        destructive: true,
        onAction: () => {
          onConfirm()
          onClose()
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <div style={{ padding: '2rem' }}>
        Are you sure to delete the variant? This can not be undone. And can not to production.
      </div>
    </Modal>
  )
}
export default DeleteVariant
