/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-07-26 11:33:35
 * @LastEditors: huang-chao hc18174419502@163.com
 * @LastEditTime: 2023-02-07 14:28:07
 * @FilePath: \pod-app-react\src\components\PodNotices\ChildrenCom\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import store from '@/redux/store'
import { createGetMessage } from '@/redux/action'
import _ from 'lodash'
// import { Toast } from '@shopify/polaris'
import NoticesService from '@/api/notices'

const NoticesItem = ({ tipsNum, getNewTipsNumFn }) => {
  const [msgData, setMsgData] = useState([])
  const router = useHistory()
  useEffect(() => {
    const unSubscribe = store.subscribe(() => {
      const messageList = store.getState().messageList
      if (_.isEmpty(msgData)) {
        setMsgData(messageList)
      } else {
        const cloneData = _.cloneDeep(msgData)
        const newMsg = _.concat(messageList, cloneData)
        setMsgData(newMsg)
      }
    })
    return () => {
      unSubscribe()
    }
  }, [])

  // 获取消息列表
  const getMessageList = async () => {
    const res = await NoticesService.getNoticeList()
    if (res) {
      store.dispatch(createGetMessage(res))
    }
  }

  // 点击消息通知右下角 check click
  const checkOrderClick = async (item) => {
    await NoticesService.readMessage({ id: item.id })
    if (tipsNum > 0) {
      const newTipsNum = tipsNum - 1
      getNewTipsNumFn(newTipsNum)
    }
    getMessageList()
    // setActive(true)
    router.push({
      pathname: '/myOrders',
    })
  }

  return (
    <div className="NoticesItem_Container">
      {msgData.length > 0 ? (
        msgData.map((item, index) => (
          <div key={index}>
            <div className="Notices_Container_content">
              <div style={{ wordBreak: 'break-all' }}>
                <p>{item.title}</p>
                <div
                  dangerouslySetInnerHTML={{ __html: item.content }}
                  style={{ wordBreak: 'break-all' }}
                ></div>
              </div>
              {item.type > 0 && item.isRead === 1 && (
                <div>
                  <img
                    src="/images/dot.png"
                    alt="print"
                    style={{ width: '20px', height: '20px' }}
                  />
                </div>
              )}
            </div>

            <div className="Notices_footer">
              <p style={{ color: 'black' }}>{item.time}</p>
              {item.type > 0 && (
                <p
                  onClick={() => {
                    checkOrderClick(item)
                  }}
                >
                  Check order {'->'}
                </p>
              )}
            </div>
          </div>
        ))
      ) : (
        <div style={{ padding: '2rem', fontWeight: '500' }}>There is no news yet.</div>
      )}
      {/* {toastMarkup} */}
    </div>
  )
}

export default NoticesItem
