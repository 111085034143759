// 用户验证模块
import { request } from '../request'

/**
 * 获取地址信息，包含My account 里面的 Information数据
 * @returns
 */
export async function reqUserAuthInstall(data) {
  return request({
    url: '/shopify/app',
    method: 'GET',
    params: data,
  })
}
