import { useState, useEffect } from 'react'
import { Card, Checkbox, Toast } from '@shopify/polaris'
// 接口
import NotificationService from '@/api/settings/notification'
// 骨架屏
import LoadingPage from './components/LoadingPage'
// 样式文件
import './index.scss'

const Notification = () => {
  const [loading, setLoading] = useState(false)
  // new Email
  const [newEmailChecked, setNewEmailChecked] = useState(1)
  // new App
  const [newAppChecked, setNewAppChecked] = useState(1)
  // fulEmail
  const [fulEmailChecked, setFulEmailChecked] = useState(1)
  // fulApp
  const [fulAppChecked, setFulAppChecked] = useState(1)

  const [orderItem, setOrderItem] = useState({})
  const [orderFul, setOrderFul] = useState({})

  // Toast
  const [isShowToast, setIsShowToast] = useState(false)
  const [toastContent, setToastContent] = useState('')

  useEffect(() => {
    getNotificationData()
  }, [])

  // 初始化加载 notification 数据
  const getNotificationData = async () => {
    setLoading(true)
    const res = await NotificationService.getNotifyData()
    if (res) {
      const { orderItems, orderFulfilled } = res
      setOrderItem(orderItems)
      setOrderFul(orderFulfilled)
      // orderItems  订单消息通知
      setNewEmailChecked(orderItems.Email)
      setNewAppChecked(orderItems.App)
      // orderFulfilled  订单物流消息通知
      setFulEmailChecked(orderFulfilled.Email)
      setFulAppChecked(orderFulfilled.App)
    }
    setLoading(false) // 关闭 loadingpage
  }

  /**
   * 通用保存通知项函数
   * @param {*} value 是否选中 0:未选中、1:选中
   * @param {*} type 保存的是什么类型数据
   * @param {*} content toast 保存成功后的提示文案
   * 1. 设置 Orders / Shipments 保存项
   */
  const handleCommoned = async (obj, type, content) => {
    try {
      const params = {}
      // 如果 接收到的是newOrder的值，那么就替换掉对应的new值
      if (type === 'new') {
        params.orderItems = obj
        params.orderFulfilled = orderFul
      }
      if (type === 'ful') {
        params.orderFulfilled = obj
        params.orderItems = orderItem
      }
      const result = await NotificationService.notifySave(params)
      result || setToastContent(content)
      // 保存成功提示
      result || setIsShowToast(true)
    } catch (error) {
      // setToastContent(error)
      setIsShowToast(true)
    }
  }

  const handleNewEmailChange = () => {
    let isChecked = 0
    let content = ''
    if (newEmailChecked === 1) {
      isChecked = 0
      content = 'New order Email notification canceled'
    } else {
      isChecked = 1
      content = 'New order Email notification saved'
    }
    setNewEmailChecked(isChecked)
    orderItem.Email = isChecked
    handleCommoned(orderItem, 'new', content)
  }

  const handleNewAPPChange = () => {
    let isChecked = 0
    let content = ''
    if (newAppChecked === 1) {
      isChecked = 0
      content = 'New order App notification canceled'
    } else {
      isChecked = 1
      content = 'New order App notification saved'
    }
    setNewAppChecked(isChecked)
    orderItem.App = isChecked
    handleCommoned(orderItem, 'new', content)
  }

  const handleFulEmailChange = () => {
    let isChecked = 0
    let content = ''
    if (fulEmailChecked === 1) {
      isChecked = 0
      content = 'Order fulfilled Email notification canceled'
    } else {
      isChecked = 1
      content = 'Order fulfilled Email notification saved'
    }
    setFulEmailChecked(isChecked)
    orderFul.Email = isChecked
    handleCommoned(orderFul, 'ful', content)
  }

  const handleFulAPPChange = () => {
    let isChecked = 0
    let content = ''
    if (fulAppChecked === 1) {
      isChecked = 0
      content = 'Order fulfilled App notification canceled'
    } else {
      isChecked = 1
      content = 'Order fulfilled App notification saved'
    }
    setFulAppChecked(isChecked)
    orderFul.App = isChecked
    handleCommoned(orderFul, 'ful', content)
  }

  // 控制 Toast 显示与隐藏
  const toggleToast = () => {
    setIsShowToast(!isShowToast)
  }

  // Toast提示
  const toastMarkup = isShowToast ? (
    <Toast
      content={toastContent}
      duration={2500}
      onDismiss={toggleToast}
    />
  ) : null

  const pageContent = loading ? (
    <LoadingPage />
  ) : (
    <Card>
      <Card.Section>
        <table className="Notification-Table">
          <thead>
            <tr>
              <th align="left">Orders / Shipments</th>
              <th align="left">
                <div>Email</div>
              </th>
              <th align="left">
                <div>APP</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width={450}>New orders</td>
              <td>
                <Checkbox
                  checked={newEmailChecked === 1 ? true : false}
                  onChange={handleNewEmailChange}
                />
              </td>
              <td>
                <Checkbox
                  checked={newAppChecked === 1 ? true : false}
                  onChange={handleNewAPPChange}
                />
              </td>
            </tr>
            <tr>
              <td width={450}>Order fulfilled</td>
              <td>
                <Checkbox
                  checked={fulEmailChecked === 1 ? true : false}
                  onChange={handleFulEmailChange}
                />
              </td>
              <td>
                <Checkbox
                  checked={fulAppChecked === 1 ? true : false}
                  onChange={handleFulAPPChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Card.Section>
    </Card>
  )
  return (
    <>
      {/* 内容主题区域 */}
      {pageContent}

      {/* Toast 提示 区域 */}
      {toastMarkup}
    </>
  )
}

export default Notification
