import React, { Component } from 'react'
import {
  Page,
  Card,
  Tabs,
  Filters,
  ChoiceList,
  // Select,
  Button,
  Popover,
  ActionList,
  SkeletonPage,
  Icon,
  Layout,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  // TextField,
} from '@shopify/polaris'
import { SortMinor } from '@shopify/polaris-icons'
import PodTable from '@/components/PodTable'
import OrderTypeSelectModal from '../Orders/ChildrenPages/OrderPayment/components/OrderTypeSelectModal'

import FavorableComm from '@/components/FavorableComm'
// import { setStore } from '@/utils/utils'
import { getCollections } from '@/api/myproducts/index'

// import { setStore } from '@/utils/utils'
import './index.scss'
import _ from 'lodash'

class MyProducts extends Component {
  state = {
    queryValue: '', //关键词内容搜索
    tabSelected: 0, //顶部tabs selected
    selectKey: '', //记录当前点击cell
    isSearching: '0',
    filterStatus: [], // 类型搜索
    filterStatusSelected: [],

    filterType: [],
    filterTypeSelected: [],

    sortBy: '',
    sortByValue: 0,
    // moreDataSwitch: false,   // 开关，是否允许开启更多数据选择
    // isAllowSelectAllItems: false,  // 是否选择更多的数据 (未显示的)
    // allResourcesSelected: false,  // (当前页面,并且包括未显示的) 全选
    // selectedResources: [],
    show: false,
    popoverActive: false,

    isSkeleton: false, // 是否显示骨架屏
    listData: [], // 子组件产品集合数据handleTaggedCollectionChange
    taggedType: null,

    sourceCollection: [], // shopfiy分类（数据源）
    collectionList: [], // shopfiy分类

    isShowFavora: false,
    mark: 0, // 引导好评弹窗标识
  }

  //获取shopfiy分类
  getShopfiyCollections = async () => {
    const response = await getCollections()
    const filterData = _.reject(response, (item) => {
      return !item.value
    }) //过滤掉空值
    this.setState({
      collectionList: filterData,
      sourceCollection: filterData,
    })
  }

  componentDidMount() {
    // 第一次设计完成从产品编辑页过来弹出
    const routeState = this.props.history.location.params
    // console.log('edittolater', routeState)
    if (routeState !== undefined && routeState !== null) {
      switch (routeState.status) {
        case 0: // 点击产品编辑 Edit it later
          this.setState(
            {
              // isShowFavora: true,
              mark: 0,
            },
            () => {
              if (_.isNil(window.isShowFavora)) {
                window.isShowFavora = true
              }
            }
          )
          break

        case 1: // 点击产品编辑 Publish
          this.setState(
            {
              // isShowFavora: true,
              mark: 1,
            },
            () => {
              if (_.isNil(window.isShowFavora)) {
                window.isShowFavora = true
              }
            }
          )
          break

        default:
          break
      }
    }
    this.getShopfiyCollections()
  }

  // 回调关闭引导好评弹窗
  closeFavorableComm = () => {
    window.isShowFavora = false
    this.setState({
      isShowFavora: false,
    })
  }

  //搜索
  handleFiltersQueryChange = (value) => {
    this.setState({
      queryValue: value,
      // page: 1
    })
  }

  handleQueryValueRemove = () => {
    this.setState({
      queryValue: '',
    })
  }

  handleFiltersClearAll = () => {}

  // 产品状态tabs change
  handleTabChange = (selectedTabIndex) => {
    let status = []
    switch (selectedTabIndex) {
      case 0:
        status = []
        break
      case 1:
        status = ['Active']
        break
      case 2:
        status = ['Unpublished']
        break
      case 3:
        status = ['Exception']
        break
      default:
    }
    this.setState({
      filterStatus: status,
      tabSelected: selectedTabIndex,
    })
  }

  handleOnNavigation = (id) => {
    this.setState({
      selectKey: id,
    })
  }

  handleOnShowTypeSelect = () => {
    this.setState({
      show: true,
    })
  }
  handleOnShowTypeSelectOff = () => {
    this.setState({
      show: false,
    })
  }
  togglePopoverActive = () => {
    this.setState({
      popoverActive: !this.state.popoverActive,
    })
  }

  // 多状态选择
  handleChangePaymentStatus = (value) => {
    let tabIndex = 0
    if (value[0] === 'Active') {
      tabIndex = 1
    }
    if (value[0] === 'Unpublished') {
      tabIndex = 2
    }
    this.setState({
      filterStatusSelected: value,
      filterStatus: value,
      tabSelected: tabIndex,
    })
  }

  //fiterStatus 产品状态选项移除
  handleFilterStatusRemove = () => {
    this.setState({
      filterStatus: [],
      filterStatusSelected: [],
      tabSelected: 0,
      // page: 1
    })
  }

  // type选择
  handleChangeType = (value) => {
    this.setState({
      filterTypeSelected: value,
      filterType: value,
    })
  }

  //fiterType 产品状态选项移除
  handleFilterTypeRemove = () => {
    this.setState({
      filterTypeSelected: [],
      filterType: [],
    })
  }

  // 回调子组件数据列表集合
  getInitData = (listData, isFilter) => {
    this.setState({
      listData,
      isFilter,
    })
  }

  // 排序sort change
  handleSortByChange = (value) => {
    let sortByVal = 0
    switch (value[0]) {
      case 'Product title A-Z':
        sortByVal = 1
        break

      case 'Product title Z-A':
        sortByVal = 2
        break

      case 'Created (oldest first)':
        sortByVal = 3
        break

      case 'Created (newest first)':
        sortByVal = 4
        break

      case 'Updated (oldest first)':
        sortByVal = 5
        break

      case 'Updated (newest first)':
        sortByVal = 6
        break

      default:
        break
    }
    this.setState({
      sortBy: value,
      sortByValue: sortByVal,
    })
  }

  // 回调页面是否处于loading状态
  callBackSkeleton = (isLoadingPage) => {
    this.setState({
      isSkeleton: isLoadingPage,
    })
  }

  handleCreatePro = () => {
    window.currentMenu = {
      selectKey: 0,
      path: '/catalog',
    }
    window.listenerMenuClick()
    this.props.history.push({
      pathname: '/catalog',
    })
  }

  // 调起 Collection
  // handleTaggedTypeChange = (value) => {
  //   this.setState(
  //     {
  //       taggedType: value,
  //     },
  //     () => {
  //       const { collectionList, sourceCollection } = this.state
  //       if (_.isEmpty(value)) {
  //         this.setState({
  //           collectionList: sourceCollection,
  //           taggedType: '',
  //         })
  //         return
  //       } else {
  //         const filterRegex = new RegExp(value, 'i')
  //         // const result = _.filter(collectionList, ['value', value])
  //         const result = collectionList.filter((option) => option.label.match(filterRegex))
  //         this.setState({
  //           collectionList: result,
  //         })
  //       }
  //     }
  //   )
  // }

  render() {
    const {
      queryValue,
      isSearching,
      tabSelected,
      isSkeleton,
      filterStatus,
      filterType,
      show,
      popoverActive,
      sortByValue,
      // taggedType,
    } = this.state

    const TabSelectArray = [
      {
        id: 'all-customers-1',
        content: 'All',
        accessibilityLabel: 'All customers',
        panelID: 'all-customers-content-1',
      },
      {
        id: 'accepts-marketing-1',
        content: 'Active',
        panelID: 'accepts-marketing-content-1',
      },
      {
        id: 'repeat-customers-1',
        content: 'Unpublished',
        panelID: 'repeat-customers-content-1',
      },
      {
        id: 'prospects-1',
        content: 'Exception',
        panelID: 'prospects-content-1',
      },
    ]

    const statusList = [
      { label: 'Active', value: 'Active' },
      { label: 'Unpublished', value: 'Unpublished' },
      { label: 'Exception', value: 'Exception' },
    ]
    const currentStatusList = _.filter(statusList, (item) => {
      if (_.isEmpty(filterStatus)) {
        return true
      }
      return _.includes(filterStatus, item.label)
    })

    const filters = [
      {
        key: 'filterStatus',
        label: 'Status',
        filter: (
          <ChoiceList
            title="Payment status"
            titleHidden
            choices={currentStatusList}
            selected={this.state.filterStatusSelected || []}
            onChange={this.handleChangePaymentStatus}
            allowMultiple
          />
        ),
        shortcut: true,
        hideClearButton: false,
      },
      {
        key: 'type',
        label: 'Type',
        filter: (
          <div className="typeTextField">
            {this.state.collectionList.length > 0 ? (
              <ChoiceList
                allowMultiple
                titleHidden
                choices={this.state.collectionList}
                selected={this.state.filterTypeSelected || []}
                onChange={this.handleChangeType}
              />
            ) : (
              'No types yet'
            )}
          </div>
        ),
        shortcut: true,
        hideClearButton: false,
      },
      {
        key: 'Sort-By',
        label: (
          <div className="sort_by_label">
            <Icon
              source={SortMinor}
              color="base"
            />
            Sort By
          </div>
        ),
        filter: (
          <ChoiceList
            title="Sort By"
            // titleHidden
            choices={[
              { label: 'Product title A-Z', value: 'Product title A-Z' },
              { label: 'Product title Z-A', value: 'Product title Z-A' },
              { label: 'Created (oldest first)', value: 'Created (oldest first)' },
              { label: 'Created (newest first)', value: 'Created (newest first)' },
              { label: 'Updated (oldest first)', value: 'Updated (oldest first)' },
              { label: 'Updated (newest first)', value: 'Updated (newest first)' },
            ]}
            // selected={orderFilterStatus || []}
            selected={this.state.sortBy}
            onChange={this.handleSortByChange}
          />
        ),
        shortcut: true,
        hideClearButton: true,
      },
    ]

    const appliedFilters = []
    if (!isEmpty(this.state.filterStatus)) {
      const key = 'filterStatus'
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, this.state.filterStatus),
        onRemove: this.handleFilterStatusRemove,
      })
    }

    if (!isEmpty(this.state.filterType)) {
      const key = 'type'
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, this.state.filterType),
        onRemove: this.handleFilterTypeRemove,
      })
    }

    const activator = (
      <Button
        onClick={this.togglePopoverActive}
        disclosure
      >
        Create Order
      </Button>
    )

    // 初始化加载数据前的状态
    const SkeletonExample = (
      <SkeletonPage
        primaryAction
        fullWidth={true}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonBodyText />
                </TextContainer>
              </Card.Section>
            </Card>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </Card.Section>
            </Card>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    )

    const pageContent = isSkeleton ? (
      SkeletonExample
    ) : (
      <Page
        title="My POD Products"
        fullWidth
        primaryAction={{
          content: 'Create Product',
          external: true,
          onAction: (e) => this.handleCreatePro(),
        }}
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{ position: 'absolute', right: '13rem', bottom: '2rem', marginRight: '1.6rem' }}
          >
            <Popover
              active={popoverActive}
              activator={activator}
              autofocusTarget="first-node"
              onClose={this.togglePopoverActive}
            >
              <ActionList
                actionRole="menuitem"
                items={[
                  {
                    content: 'Sample order',
                    onAction: () => {
                      this.props.history.push({
                        pathname: '/orderPayment',
                        state: {
                          type: 'sample',
                          id: undefined,
                        },
                      })
                    },
                  },
                  {
                    content: 'Basic order',
                    onAction: () => {
                      this.props.history.push({
                        pathname: '/orderPayment',
                        state: {
                          type: 'basic',
                          id: undefined,
                        },
                      })
                    },
                  },
                ]}
              />
            </Popover>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Card>
              <Tabs
                tabs={TabSelectArray}
                selected={tabSelected}
                onSelect={this.handleTabChange}
              >
                <Card>
                  <Card.Section>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '100%' }}>
                        <Filters
                          queryPlaceholder="filter products"
                          queryValue={queryValue}
                          filters={filters}
                          appliedFilters={appliedFilters}
                          onQueryChange={this.handleFiltersQueryChange}
                          onQueryClear={this.handleQueryValueRemove}
                          onClearAll={this.handleFiltersClearAll}
                        />
                      </div>
                    </div>
                  </Card.Section>

                  <div>
                    <PodTable
                      queryValue={queryValue}
                      isSearching={isSearching}
                      filterStatus={filterStatus}
                      filterType={filterType}
                      getInitData={this.getInitData}
                      sortByValue={sortByValue}
                      callBackSkeleton={this.callBackSkeleton}
                    />
                  </div>
                </Card>
              </Tabs>
            </Card>
          </div>
        </div>

        <OrderTypeSelectModal
          show={show}
          handleOnShowTypeSelectOff={this.handleOnShowTypeSelectOff}
        />
        {/*  引导好评 */}
        {/* {this.state.isShowFavora && <FavorableComm mark={this.state.mark} />} */}
        <FavorableComm
          mark={this.state.mark}
          // isShowFavora={this.state.isShowFavora}
          isShowFavora={window.isShowFavora}
          closeFavorableComm={this.closeFavorableComm}
        />
      </Page>
    )

    return <div>{pageContent}</div>

    function disambiguateLabel(key, value) {
      switch (key) {
        case 'type':
          return `Type is ${value}`
        default:
          return `Status is ${value}`
      }
    }
    function isEmpty(value) {
      if (Array.isArray(value)) {
        return value.length === 0
      } else {
        return value === '' || value == null
      }
    }
  }
}

export default MyProducts
