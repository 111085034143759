import {
  Layout,
  Card,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
} from '@shopify/polaris'

const LoadingPage = () => {
  return (
    <Layout>
      <Layout.Section>
        <Card sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
            <SkeletonBodyText lines={2} />
            <SkeletonBodyText lines={2} />
          </TextContainer>
        </Card>
        <Card sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={4} />
            <SkeletonBodyText lines={4} />
            <SkeletonBodyText lines={4} />
            <SkeletonBodyText lines={4} />
            <SkeletonBodyText lines={4} />
          </TextContainer>
        </Card>
      </Layout.Section>
    </Layout>
  )
}

export default LoadingPage
