import { useEffect, useState, useCallback } from 'react'
import store from '@/redux/store'
import { useHistory } from 'react-router-dom'
import { Page, Card, Icon } from '@shopify/polaris'
import {
  ProfileMajor,
  FolderMajor,
  BillingStatementDollarMajor,
  NotificationMajor,
  LegalMajor,
} from '@shopify/polaris-icons'
import _ from 'lodash'
// 引入组件
import MyAccount from '@/pages/Setting/MyAccount'
import MyFiles from '@/pages/Setting/MyFiles'
import Billing from '@/pages/Setting/Billing'
import Notification from '@/pages/Setting/Notification'
import TermsOfService from '@/pages/Setting/TermsOfService'
// 引入样式
import './index.scss'

const menuList = [
  {
    id: 0,
    name: 'My account',
    path: '/setting/myAccount',
    module: MyAccount,
    icon: ProfileMajor,
  },
  { id: 1, name: 'My files', path: '/setting/myFiles', module: MyFiles, icon: FolderMajor },
  {
    id: 2,
    name: 'Billing',
    path: '/setting/billing',
    module: Billing,
    icon: BillingStatementDollarMajor,
  },
  {
    id: 3,
    name: 'Notification',
    path: '/setting/notification',
    module: Notification,
    icon: NotificationMajor,
  },
  {
    id: 4,
    name: 'Terms of service',
    path: '/setting/termsOfService',
    module: TermsOfService,
    icon: LegalMajor,
  },
]

const Setting = () => {
  const storeSatate = store.getState()
  const userInfo = storeSatate.userInfo
  const router = useHistory()

  // 当前菜单
  const [currentMneu, setCurrentMneu] = useState({})
  // 当前右侧组件
  const [pageContent, setPageContent] = useState('')

  useEffect(() => {
    // 监听路由变化，根据路由变化切换菜单
    const currentMneu = _.find(menuList, (item) => {
      return item.path === router.location.pathname
    })
    if (currentMneu) {
      setCurrentMneu(currentMneu)
      setPageContent(<currentMneu.module />)
    }
  }, [router.location])

  // 切换菜单
  const handleClick = useCallback(
    (menu) => {
      router.push(menu.path)
    },
    [router]
  )

  return (
    <Page
      className="aaa"
      fullWidth
    >
      <div className="Setting row">
        <div className="col-3">
          <Card>
            <Card.Section subdued>
              <div className="Card-Title">
                <h3>{userInfo.name}</h3>
                <p>{userInfo.shopName}</p>
              </div>
            </Card.Section>
            <Card.Section flush>
              <div className="Card-Menu">
                <ul>
                  {_.map(menuList, (menu) => {
                    return (
                      <li
                        className={
                          currentMneu.id === menu.id ? 'Card-Menu-Item active' : 'Card-Menu-Item'
                        }
                        key={menu.id}
                        onClick={() => {
                          handleClick(menu)
                        }}
                      >
                        <div style={{ display: 'inline-block', marginRight: '1rem' }}>
                          <Icon
                            source={menu.icon}
                            color={currentMneu.id === menu.id ? 'primary' : 'base'}
                          />
                        </div>
                        <span>{menu.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </Card.Section>
          </Card>
        </div>
        <div className="Page-Content col-9">
          <div className="Page-Content-Title">{currentMneu.name}</div>
          {pageContent}
        </div>
      </div>
    </Page>
  )
}

export default Setting
