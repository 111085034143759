/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-11 16:36:31
 * @LastEditTime: 2023-01-19 17:02:32
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Setting\MyFiles\components\RemoveImage\index.jsx
 */
import { useState, useEffect, useCallback } from 'react'
import { Modal } from '@shopify/polaris'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Array} data 变体列表
 * @param {Array} checkVariants 所选变体列表
 * @param {String} country 国家
 * @param {String} moneyFormat 货币符号
 * @param {String} imageUrl 图片地址
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const RemoveImage = ({ isShow, onClose, onConfirm }) => {
  const [active, setActive] = useState(isShow) // 是否显示弹窗

  useEffect(() => {
    setActive(isShow)
  }, [isShow])

  // 打开或关闭弹窗
  const closeModal = useCallback(() => {
    onClose()
    setActive(!active)
  }, [active, onClose])

  return (
    <Modal
      title="Delete file"
      open={active}
      onClose={closeModal}
      primaryAction={{
        content: 'Delete',
        onAction: () => {
          onConfirm()
          onClose()
        },
        destructive: true,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        This can’t be undone. The file won't be removed from all places it’s being used in the
        product.
      </Modal.Section>
    </Modal>
  )
}

export default RemoveImage
