import { fabric } from 'fabric'

const delIcon =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"

// 创建del节点
const deleteImg = document.createElement('img')
deleteImg.src = delIcon

const drawCircleIcon = (ctx, left, top, __styleOverride, fabricObject) => {
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  ctx.beginPath()
  ctx.lineCap = 'round'
  ctx.lineWidth = 5
  ctx.arc(0, 0, 5, 0, 2 * Math.PI)
  ctx.strokeStyle = '#3782F7'
  ctx.stroke()
  ctx.fillStyle = '#ffffff'
  ctx.fill()
  ctx.restore()
}

/**
 * 全局自定义操作轴的样式
 */
fabric.Object.prototype.transparentCorners = false
fabric.Object.prototype.cornerColor = '#20bf6b'
fabric.Object.prototype.cornerStyle = 'circle'
fabric.Object.prototype.borderColor = '#008060'
fabric.Object.prototype.cornerSize = 12
fabric.Object.prototype.borderScaleFactor = 2.75
fabric.Object.prototype.borderOpacityWhenMoving = 0
fabric.Object.prototype.borderOpacity = 1

// 这里需要把元素中间控制节点隐藏
fabric.Object.prototype.setControlsVisibility({
  mb: false,
  ml: false,
  mr: false,
  mt: false,
})

const drawVerticalLineIcon = (ctx, left, top, _styleOverride, fabricObject) => {
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  drawRectRounded(ctx, -2, -14, 4, 18, 2)
  ctx.lineWidth = 4
  ctx.strokeStyle = '#3782F7'
  ctx.stroke()
  ctx.fillStyle = '#ffffff'
  ctx.fill()
  ctx.restore()
}

const drawRectRounded = (ctx, x, y, w, h, radius) => {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.arcTo(x + w, y, x + w, y + h, radius)
  ctx.arcTo(x + w, y + h, x, y + h, radius)
  ctx.arcTo(x, y + h, x, y, radius)
  ctx.arcTo(x, y, x + w, y, radius)
  ctx.closePath()
}

const drawHorizontalLineIcon = (ctx, left, top, _styleOverride, fabricObject) => {
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  drawRectRounded(ctx, -14, -2, 18, 4, 2)
  ctx.lineWidth = 4
  ctx.strokeStyle = '#3782F7'
  ctx.stroke()
  ctx.fillStyle = '#ffffff'
  ctx.fill()
  ctx.restore()
}

const drawRotateIcon = (ctx, left, top, _styleOverride, _fabricObject) => {
  const radius = 6
  let lineWidth = radius / 3
  let arrowWidth = radius / 2
  const center = {
    x: left,
    y: top,
  }
  let arrow1 = {
    startAngle: (1 / 2) * Math.PI + 0.6,
    endAngle: (3 / 2) * Math.PI,
  }

  let arrow2 = {
    startAngle: (3 / 2) * Math.PI + 0.6,
    endAngle: (1 / 2) * Math.PI,
  }
  const draw = (startAngle, endAngle) => {
    ctx.beginPath()
    ctx.shadowBlur = 0

    ctx.arc(center.x, center.y, radius, startAngle, endAngle)
    ctx.lineWidth = lineWidth
    ctx.strokeStyle = '#000000'
    ctx.stroke()

    ctx.beginPath()
    let arrowTop = getPointOnCircle(center, radius, endAngle + 0.4)

    ctx.moveTo(arrowTop.x, arrowTop.y)

    let arrowLeft = getPointOnCircle(center, radius - arrowWidth, endAngle)
    ctx.lineTo(arrowLeft.x, arrowLeft.y)

    let arrowRight = getPointOnCircle(center, radius + arrowWidth, endAngle)
    ctx.lineTo(arrowRight.x, arrowRight.y)
    ctx.fillStyle = '#000000'

    ctx.closePath()
    ctx.fill()
  }

  const getPointOnCircle = (center, radius, angle) => {
    let pX = center.x + Math.cos(angle) * radius
    let pY = center.y + Math.sin(angle) * radius
    return { x: pX, y: pY }
  }

  ctx.save()
  ctx.translate(0, 0)

  ctx.beginPath()
  ctx.arc(center.x, center.y, radius + 6, 0, Math.PI * 2)
  ctx.fillStyle = '#ffffff'
  ctx.shadowBlur = 2
  ctx.shadowColor = 'black'
  ctx.fill()
  ctx.closePath()
  draw(arrow1.startAngle, arrow1.endAngle)
  draw(arrow2.startAngle, arrow2.endAngle)
  ctx.restore()
}

fabric.Object.prototype.controls.tr = new fabric.Control({
  x: 0.5,
  y: -0.5,
  actionHandler: fabric.controlsUtils.scalingEqually,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: drawCircleIcon,
  cornerSize: 28,
  withConnection: true,
})

fabric.Object.prototype.controls.tl = new fabric.Control({
  x: -0.5,
  y: -0.5,
  actionHandler: fabric.controlsUtils.scalingEqually,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: drawCircleIcon,
  cornerSize: 28,
  withConnection: true,
})

fabric.Object.prototype.controls.bl = new fabric.Control({
  x: -0.5,
  y: 0.5,
  actionHandler: fabric.controlsUtils.scalingEqually,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: drawCircleIcon,
  cornerSize: 28,
  withConnection: true,
})

fabric.Object.prototype.controls.br = new fabric.Control({
  x: 0.5,
  y: 0.5,
  actionHandler: fabric.controlsUtils.scalingEqually,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: drawCircleIcon,
  cornerSize: 28,
  withConnection: true,
})
fabric.Object.prototype.controls.ml = new fabric.Control({
  x: -0.5,
  y: 0,
  actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: drawVerticalLineIcon,
  cornerSize: 28,
  withConnection: true,
})

fabric.Object.prototype.controls.mt = new fabric.Control({
  x: 0,
  y: -0.5,
  actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: drawHorizontalLineIcon,
  cornerSize: 28,
  withConnection: true,
})

fabric.Object.prototype.controls.mb = new fabric.Control({
  x: 0,
  y: 0.5,
  actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: drawHorizontalLineIcon,
  cornerSize: 28,
  withConnection: true,
})

fabric.Object.prototype.controls.mr = new fabric.Control({
  x: 0.5,
  y: 0,
  actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
  cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
  actionName: fabric.controlsUtils.scaleOrSkewActionName,
  render: drawVerticalLineIcon,
  cornerSize: 28,
  withConnection: true,
})

fabric.Object.prototype.controls.mtr = new fabric.Control({
  x: 0,
  y: -0.5,
  offsetY: -40,
  actionHandler: fabric.controlsUtils.rotationWithSnapping,
  cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
  actionName: 'rotate',
  render: drawRotateIcon,
  cornerSize: 28,
  withConnection: false,
})

const deleteObject = (event, transform) => {
  var target = transform.target
  window.delObject({ canvasObj: target, event })
}

const renderIcon = (icon) => {
  return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    var size = this.cornerSize
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
    ctx.drawImage(icon, -size / 2, -size / 2, size, size)
    ctx.restore()
  }
}

fabric.Object.prototype.controls.deleteControl = new fabric.Control({
  x: 0.5,
  y: -0.5,
  offsetY: -16,
  offsetX: 16,
  cursorStyle: 'pointer',
  mouseUpHandler: deleteObject,
  render: renderIcon(deleteImg),
  cornerSize: 24,
  hoverCursor: 'pointer',
})
