import { Modal } from '@shopify/polaris'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {String} titleValue 标题
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const DeleteProduct = ({ isShow, titleValue, onClose, onConfirm }) => {
  return (
    <Modal
      open={isShow}
      onClose={onClose}
      title="Delete product"
      primaryAction={{
        content: 'Delete',
        destructive: true,
        onAction: onConfirm,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <div style={{ padding: '2rem' }}>
        Are you sure to delete the product ‘{titleValue}’? This can not be undone.
      </div>
    </Modal>
  )
}
export default DeleteProduct
