import React from 'react'
import { Card } from '@shopify/polaris'
import DOMPurify from 'isomorphic-dompurify'

const Description = (props) => {
  const { descShow } = props
  return (
    <div className="row Description_container">
      <div className="col-12">
        <Card
          title="Description"
          sectioned
        >
          <div className="Description_content">
            <p
              className="content-html dg-html"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(descShow),
              }}
            ></p>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Description
