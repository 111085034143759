import CryptoJS from 'crypto-js'
import Config from '@/utils/config'
import OSS from 'ali-oss'
import _ from 'lodash'
import qs from 'qs'
import store from '@/redux/store'
/**
 * CryptoJS加密
 */
var Encrypt = function (data) {
  //加密
  let encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(Config.AesKey), {
    iv: CryptoJS.enc.Utf8.parse(Config.AesVi),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  return encrypted.toString()
}

/**
 * CryptoJS解密
 */
var Decrypt = function (data) {
  //解密
  let decrypt = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(Config.AesKey), {
    iv: CryptoJS.enc.Utf8.parse(Config.AesVi),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  return decrypt.toString(CryptoJS.enc.Utf8)
}

var getSign = function (domain, timeStr) {
  //解密
  let sign = Encrypt(domain + '-' + timeStr)
  return sign
}

/**
 * 存储localStorage
 */
var setStore = (name, content) => {
  try {
    if (!name) return
    if (typeof content !== 'string') {
      content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
  } catch (error) {
    console.log('Local cache exists', error)
  }
}

/**
 * 获取localStorage
 */
var getStore = (name) => {
  try {
    if (!name) return
    return window.localStorage.getItem(name)
  } catch (error) {
    console.log('Local cache exists', error)
  }
}

/**
 * bytes --> mb
 * @param {*} bytes
 * @returns
 */
var sizeFilter = (bytes) => {
  let size = ''
  size = (bytes / (1024 * 1024)).toFixed(2) + 'MB'
  // size=size.replace("MB","")
  return parseFloat(size)
}

/**
 * 不重复随机数
 * @returns
 */
const algorithm = () => {
  let abc = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'g',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]
  let [max, min] = [
    Math.floor(Math.random() * (10 - 7 + 1) + 1),
    Math.floor(Math.random() * (17 - 10 + 1) + 17),
  ]
  abc = abc
    .sort(() => 0.4 - Math.random())
    .slice(max, min)
    .slice(0, 8)
    .join('')
  var a = new Date().getTime() + abc
  return a
}

/**
 * 生成guid
 * @returns
 */
const getGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * base64转文件流file
 * @param {*} dataurl
 * @param {*} filename
 * @returns
 */
const base64toFile = (base64Data, filename) => {
  let arr = base64Data.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  })
}

/**
 * 获取设备的DPI
 */
function getDPI() {
  var arrDPI = []
  if (window.screen.deviceXDPI !== undefined) {
    arrDPI[0] = window.screen.deviceXDPI
    arrDPI[1] = window.screen.deviceYDPI
  } else {
    var tmpNode = document.createElement('DIV')
    tmpNode.style.cssText =
      'width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden'
    document.body.appendChild(tmpNode)
    arrDPI[0] = parseInt(tmpNode.offsetWidth)
    arrDPI[1] = parseInt(tmpNode.offsetHeight)
    tmpNode.parentNode.removeChild(tmpNode)
  }
  return arrDPI
}

/**
 * 设置上传oss 路径
 * @param {String} module 模块名（必须）
 * @param {String} type 文件类型（必须）
 * @param {String} fileName 文件名称（非必须）
 * @param {Number} finishedId 成品id（非必须）
 * @returns {String} 返回目录 + 文件名
 */
const setOssImageUrl = ({ module, type, fileName, productId }) => {
  const redux = store.getState()
  const userInfo = redux.userInfo
  let catalog = ''
  // oss资源目录分类，通过 app/userid/module 区分，目前暂时只有myfile以及产品内容编辑有上传功能，如果有新增的模块，则通过module区分
  if (_.includes(['home', 'myfile'], module)) {
    catalog = `app/${userInfo.userId}/${module}/${fileName}`
  } else {
    const name = getFileName(type)
    let month = new Date().getMonth() + 1
    if (month < 10) {
      month = '0' + month
    }
    let day = new Date().getDate()
    if (day < 10) {
      day = '0' + day
    }
    const date = new Date().getFullYear() + '-' + month + '-' + day // 年月日
    catalog = `app/${userInfo.userId}/${module}/${productId}/${date}/${name}`
  }
  console.log('catalog', catalog)
  return catalog
}

/**
 * 获取随机文件名（上传oss所用）
 * @param {String} type 文件类型
 */
const getFileName = (type) => {
  const suffix = type.split('/')
  const x = '0123456789qwertyuioplkjhgfdsazxcvbnm'
  let tmp = ''
  for (let i = 0; i < 10; i++) {
    tmp += x.charAt(Math.ceil(Math.random() * 100000000) % x.length)
  }
  const fileName = Date.now().toString() + tmp + '.' + suffix[1]
  return fileName
}

/**
 * 获取oss资源
 * @param {*} result
 * @returns
 */
const getClient = (result) => {
  return new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
    region: result.regionData,
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: result.AccessKeyId,
    accessKeySecret: result.AccessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: result.SecurityToken,
    // 填写Bucket名称。
    bucket: result.bucket,
  })
}

/**
 * 读取OSS图片资源
 * @param {String} imageUrl 图片名称
 * @param {Object} pop
 * @returns
 */
const ossSignatureUrl = (imageUrl, pop = {}) => {
  return `${process.env.REACT_APP_OSS_SERVER}/${imageUrl}?${qs.stringify(pop)}`
}

/**
 * 图片上传到oss
 * @param {*} imageUrl
 * @param {*} data
 * @param {*} client
 */
const putObject = async (imageUrl, data, client) => {
  const headers = {
    'Access-Control-Allow-Origin': '*',
  }
  try {
    await client.put(
      imageUrl, //上传到oss的图片名称 imageUrl，这个需要给到服务端
      data,
      { headers }
    )
    //生成图片签名
    const url = client.signatureUrl(imageUrl)
    // 获取图片
    const img = ossSignatureUrl(imageUrl)
    if (_.isNil(img) || _.isEmpty(img)) {
      return url
    }
    return img
  } catch (e) {}
}

/**
 * 文件上传公共校验方法
 * @param {*} fileName 文件名称
 * @param {*} fileSize 文件大小
 * @param {*} fileType 文件类型
 * @returns
 */
const handleUploadValidate = (fileSize, fileType) => {
  console.log('fileType', fileType)
  if (fileSize > 10) {
    return 'The image size should not exceed 10M, and must a JPG or PNG or JPEG'
  }
  if (
    fileType.indexOf('.jpg') < 0 &&
    fileType.indexOf('.jpeg') < 0 &&
    fileType.indexOf('.png') < 0
  ) {
    return 'The image must a JPG or PNG or JPEG'
  }
}

/**
 * 获取上传图片的宽高
 * @param {File} file 上传的图片文件
 * @returns {Object} {width: number, height: number}
 */
const getImageFileWidthAndHeight = (file) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise((resolve) => {
    reader.onload = (e) => {
      // 加载图片获取图片真实宽度和高度
      const image = document.createElement('img')
      image.src = e.target.result
      image.onload = () => {
        const width = image.width
        const height = image.height
        resolve({
          width,
          height,
        })
      }
      image.onerror = () => {
        resolve({
          width: 0,
          height: 0,
        })
      }
    }
  })
}

/**
 * 浏览器是否支持webp格式图片
 */
const checkWebp = () => {
  try {
    return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0
  } catch (err) {
    return false
  }
}

export {
  Encrypt,
  Decrypt,
  getSign,
  setStore,
  getStore,
  sizeFilter,
  algorithm,
  getGuid,
  base64toFile,
  getDPI,
  setOssImageUrl,
  getClient,
  putObject,
  ossSignatureUrl,
  handleUploadValidate,
  getImageFileWidthAndHeight,
  checkWebp,
}
