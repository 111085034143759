import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Button, Checkbox, Spinner } from '@shopify/polaris'
import { getProductVariants, selVariantsAdd } from '../../../../api/myproducts/index'
import Image from '@/components/Image'
import { useMemo } from 'react'
import _ from 'lodash'
import './index.scss'
import { ossSignatureUrl } from '@/utils/utils'
const SelVariantModal = (props) => {
  const { isShowSelVariantModal, variantsId, orderType } = props

  const [list, setList] = useState([])
  const [childLength, setChildLength] = useState(0)
  const [variantIdList, setVariantIdList] = useState([])

  const history = useHistory()
  // 点击 Add 保存数据 进入到 订单支付页面
  const handleProductAdd = async () => {
    const params = {
      variantId: variantIdList,
      orderType: orderType === 'basic' ? 1 : 2,
    }
    if (variantIdList.length > 0) {
      const res = await selVariantsAdd(params)
      history.push({
        pathname: '/orderPayment',
        state: {
          id: res.id,
          type: 'basic',
        },
      })
    }
  }

  const handleChange = () => {
    _.forEach(list, (item) => {
      item.checked = false
      _.forEach(item.variantData, (variant) => {
        variant.checked = false
      })
    })
    setList(list)
    props.callShowModalFn()
  }

  const handleHeadingChange = (id) => {
    let data = list.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: !item.checked,
          variantData: item.variantData.map((child) => {
            return { ...child, checked: !item.checked }
          }),
        }
      } else {
        return item
      }
    })
    setList(data)
  }

  // 点击子级
  const handleChildrenChange = (fatherId, childId) => {
    // 遍历父级
    let data = list.map((item, key1) => {
      if (key1 === fatherId) {
        // 选择子项数量
        let count = 0

        let childData = item.variantData.map((child, cKey) => {
          if (cKey === childId) {
            if (!child.checked) count++
            return { ...child, checked: !child.checked }
          } else {
            if (child.checked) count++
            return child
          }
        })
        // 父级checked状态
        if (count > 0) {
          item.checked = 'indeterminate'
        }
        // count数量=子项的长度 父级复选为true
        if (item.variantData.length === count) {
          item.checked = true
        }
        // count数量为0 父级复选为false
        if (count === 0) {
          item.checked = false
        }

        return {
          ...item,
          variantData: childData,
        }
      } else {
        return item
      }
    })
    setList(data)
  }

  const [showSpinner, setShowSpinnerstate] = useState(false)
  const getData = async () => {
    try {
      const res = await getProductVariants({ id: variantsId })
      _.forEach(res.listData, (item) => {
        if (item.image) {
          item.image = ossSignatureUrl(item.image) // 读取OSS资源
        }
      })
      setList(res.listData)
      setTimeout(() => {
        setShowSpinnerstate(false)
      }, 1000)
    } catch (error) {}
  }

  useMemo(() => {
    if (variantsId) {
      setShowSpinnerstate(true)
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantsId])

  useEffect(() => {
    let isMount = true
    if (isMount && list) {
      //  子选择数量
      let childL = []
      // 变体Id
      let vId = []
      list.length > 0 &&
        list.map((item) => {
          return item.variantData.filter((data) => {
            if (data.checked) {
              vId.push(data.variantId)
              return childL.push(data.checked)
            }
            return data.checked
          })
        })
      setVariantIdList(vId)
      setChildLength(childL.length)
    }
    return () => (isMount = false)
  }, [isShowSelVariantModal, list])

  const handleCloseModal = () => {
    props.callShowModalFn()
  }

  return (
    <Modal
      title="Select variant"
      open={isShowSelVariantModal}
      onClose={handleCloseModal}
    >
      <div>
        <div className="select_product_scroll">
          {showSpinner ? (
            <Spinner
              accessibilityLabel="Spinner example"
              size="small"
            />
          ) : list.length > 0 ? (
            list.map((item, ind) => (
              <div
                key={ind}
                className="product_tree"
              >
                <div
                  className="product_heading"
                  style={{ paddingBottom: '2rem', fontWeight: 500 }}
                >
                  <div
                    className="product_variant_variant"
                    style={{ paddingLeft: '30px' }}
                  >
                    Variant
                  </div>
                  <div className="product_variant_inventory">Inventory</div>
                  <div className="product_variant_price">Cost</div>
                </div>
                <div className="product_heading">
                  <Checkbox
                    checked={item.checked}
                    onChange={() => handleHeadingChange(item.id)}
                  />
                  <div className="product_variant_image">
                    {/* <img src={item.image} alt={item.title} />  */}
                    <Image
                      src={item.image}
                      alt={item.title}
                      size="40"
                    />
                  </div>
                  <div className="product_variant_title">{item.title}</div>
                </div>

                {item.variantData.map((data, inds) => (
                  <div
                    key={inds}
                    className="product_variant_child"
                  >
                    <div className="product_children">
                      <Checkbox
                        checked={data.checked}
                        onChange={() => handleChildrenChange(ind, inds)}
                      />
                      {/* <div className="product_variant_variant">{data.color + '/' + data.size}</div> */}
                      <div className="product_variant_variant">
                        {data.statement + '/' + data.size}
                      </div>
                      <div className="product_variant_inventory">{data.inventory}</div>
                      <div className="product_variant_price">
                        {data.country} {data.moneyFormat} {data.cost}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div style={{ padding: '2rem' }}>No results were found for ""</div>
          )}
        </div>
      </div>
      {/* </Modal.Section> */}
      <Modal.Section>
        <div className="select_button_variant">
          <span style={{ fontWeight: 500, fontSize: '18px', color: 'rgba(32, 34, 35, 0.5)' }}>
            {childLength} variant selected
          </span>
          {!_.isEmpty(list) && (
            <div className="select_button_variant_r">
              <Button onClick={() => handleChange()}>Cancel</Button>
              <Button
                primary
                onClick={() => handleProductAdd()}
              >
                Done
              </Button>
            </div>
          )}
        </div>
      </Modal.Section>
    </Modal>
  )
}

export default SelVariantModal
