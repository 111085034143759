/*
 * @Description: 订单支付
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-18 15:33:40
 * @LastEditTime: 2023-01-19 16:39:01
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Orders\ChildrenPages\OrderPaymentNew\index.jsx
 */
import { useEffect, useState } from 'react'
import { Page, Badge, Card } from '@shopify/polaris'
import { useHistory } from 'react-router'
// 订单商品列表
import Product from './components/Product'
// 引入接口
import { getProductOrderData } from '@/api/orderBuild'
import './index.scss'

const OrderPayment = () => {
  const router = useHistory()

  const [ruleFrom, setRuleForm] = useState({}) // 订单详情

  useEffect(() => {
    initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 初始化数据
  const initData = () => {
    getOrderDetail()
  }

  // 获取订单详情
  const getOrderDetail = async () => {
    const res = await getProductOrderData({ id: router.location.state.id })
    if (res) {
      setRuleForm(res)
    }
  }

  return (
    <Page
      breadcrumbs={[
        {
          content: 'Back',
          onAction: () => {
            router.push('/myOrders')
          },
        },
      ]}
      title="test"
      titleMetadata={<Badge status="success">Unpaid</Badge>}
      secondaryActions={[
        {
          content: 'Duplicate',
          onAction: () => {},
        },
        {
          content: 'Refund',
          onAction: () => {},
        },
      ]}
      pagination={{
        hasPrevious: true,
        hasNext: true,
        onPrevious: () => {},
        onNext: () => {},
      }}
      fullWidth
    >
      <div className="OrderPayment">
        <h6>Updated on Jan 18, 16:55</h6>
        <div className="OrderPayment-Box row">
          {/* Product */}
          <div className="col-8">
            <Card>
              <Card.Section>
                <h3>Product</h3>
              </Card.Section>
              <Card.Section flush>
                <Product data={ruleFrom.orderDetailData} />
              </Card.Section>
            </Card>
          </div>

          {/* Customer */}
          <div className="col-4">
            <Card>
              <Card.Section>
                <h3>Customer</h3>
              </Card.Section>
              <Card.Section>
                <h3>Customer</h3>
              </Card.Section>
            </Card>
          </div>
        </div>

        <div className="OrderPayment-Box row">
          {/* Product */}
          <div className="col-8">
            <Card>
              <Card.Section>
                <h3>Payment</h3>
              </Card.Section>
              <Card.Section>
                <h3>Payment</h3>
              </Card.Section>
            </Card>
          </div>

          {/* Customer */}
          <div className="col-4">
            <Card>
              <Card.Section>
                <h3>Shipping</h3>
              </Card.Section>
              <Card.Section>
                <h3>Shipping</h3>
              </Card.Section>
            </Card>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default OrderPayment
