/*
 * @Description: 通知 - 相关接口
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-03 10:46:14
 * @LastEditTime: 2023-01-04 10:10:17
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\api\settings\notification\index.js
 */
import { request } from '../../request'

export default class NotificationService {
  /**
   * 获取通知信息 orderItems、orderFulfilled
   * @returns
   */
  static async getNotifyData() {
    return request({
      url: '/api/v1/settings/notifyData',
      method: 'POST',
    })
  }

  /**
   * 修改通知信息（包括订单消息通知、订单物流消息通知）
   * @param {*} params
   * @returns
   */
  static async notifySave(params) {
    return request({
      url: '/api/v1/settings/notifySave',
      method: 'POST',
      data: params,
    })
  }
}
