import React, { Component } from 'react'
import { withRouter, Prompt } from 'react-router-dom'
// import { throttle } from 'lodash'
import {
  Page,
  Card,
  TextField,
  ButtonGroup,
  Button,
  Icon,
  Heading,
  Toast,
  Badge,
} from '@shopify/polaris'
import { SearchMinor, MobilePlusMajor, MobileCancelMajor } from '@shopify/polaris-icons'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import LoadingPage from '@/pages/Orders/ChildrenPages/OrderPayment/components/LoadingPage'
import CustomerModal from '@/pages/Orders/ChildrenPages/OrderPayment/components/CustomerModal'
import SelProductModal from '@/pages/Orders/ChildrenPages/OrderPayment/components/SelProductModal'
import ShippingModal from '@/pages/Orders/ChildrenPages/OrderPayment/components/ShippingModal'
// import PaypalModal from '@/pages/Orders/ChildrenPages/OrderPayment/components/PaypalModal'
import TaxModal from '@/pages/Orders/ChildrenPages/OrderPayment/components/TaxModal'
import Image from '@/components/Image'
import {
  getProductOrderData,
  getProductOrderShippingMethodSave,
  getProductOrderSkuCount,
  getProductOrderDelete,
  getProductOrderCopy,
  getProductOrderSave,
  getOrderSampleAddress,
  getProductOrderId,
  getProductOrderPay,
  listenerPayStatus,
  defaultAddress,
  delOrder,
} from '../../../../api/orderBuild/index'
import { conditionsFavor } from '@/api/favorable/index'
import store from '@/redux/store'
import { createCheckFavorable } from '@/redux/action'
import './index.scss'
import _ from 'lodash'
import { ossSignatureUrl } from '@/utils/utils'
import countryList from '@/components/CountryArray/index'
// 离开页面弹窗
import LeavePage from '@/components/LeavePage'

let unSubscribe = null
class OrderPayment extends Component {
  state = {
    loadingPage: false,
    searchProductProps: '',
    isShowCustomerModal: false,
    isShowSelProductModal: false,
    isShowShippingProductModal: false,
    isShowTaxModal: false,
    ShippingCheckedVal: 'select a shipping way',
    orderLists: [], //订单详情
    // variantIds: [], //产品订单变体集合
    paymentData: null, //支付详情
    customerData: null, // 地址详情
    updateAddress: false, // 更新地址
    podOrderId: 1, //订单id
    copyOrderId: '', //复制的订单id
    id: '', // 页面id
    note: '', //备注
    shippingData: [], //物流选项
    shippingId: 0,
    skuId: 1, //订单sku id
    draft: false, //保存为草稿
    showToast: false, //更新toast
    showToastText: 'success',
    showToastIserror: 'error',
    paypalShow: false, //支付弹窗显示
    paymentStatus: 'Unpaid', //支付状态 未支付不许删除
    type: '', // 订单类型
    updatedTime: '', //订单修改时间
    urlType: 'basic',

    previousId: 0, // 上一个的订单id
    nextId: 0, // 下一个的订单id

    hasPrevious: false,
    hasNext: false,

    showDuplicateBtn: true, // 是否展示复制按钮
    isShowPaypalBtn: false,

    checkFavorable: {}, //引导好评条件对象
    loading: false,

    provinceLabel: '',
    isHoldUpRouter: true, // when的属性值，初始化必须为true，否则阻塞不了路由跳转。因为setState是异步操作
    isShowLeavePage: false,
    whichPathUrlWillTo: '', // 存储即将要跳转的pathUrl
    whichWillToFunc: null, // 存储即将要执行的方法名
  }

  getInfo = async () => {
    const res = await defaultAddress()
    this.setState({
      customerData: res,
    })
  }

  // 判断改产品是否符合引导好评弹窗条件
  checkFavorIsShowModal = async () => {
    const response = await conditionsFavor()
    store.dispatch(createCheckFavorable(response))
  }

  componentDidMount() {
    this.checkFavorIsShowModal()
    unSubscribe = store.subscribe(() => {
      // console.log('orderPayment')
      const checkFavorable = store.getState().favorable
      if (!_.isEmpty(checkFavorable)) {
        this.setState({
          checkFavorable,
        })
      }
    })
    if (this.props.location.state !== undefined) {
      const { type, id } = this.props.location.state
      if (id === undefined) {
        if (type === 'sample') {
          this.setState(
            {
              urlType: 'sample',
            },
            () => {
              this.getInfo()
            }
          )
        } else {
          this.setState({
            urlType: 'basic',
          })
        }

        // 从订单列表create order直接进来
        this.setState({
          showDuplicateBtn: false,
          isShowPaypalBtn: true,
        })
      }
      if (type !== undefined && id !== undefined) {
        // 从产品列表或者订单列表过来
        this.OrdersfetchData(type, id)
      }
    } else {
      const windowParams = new URLSearchParams(window.location.search)
      /**
       * type 1: basic、2: sample
       */
      let detailVal = windowParams.get('type') ? windowParams.get('type') : ''
      let detailId = windowParams.get('order') ? windowParams.get('order') : ''
      let oId = detailId
      if (detailId !== '') {
        this.OrdersfetchData(detailVal, oId)
      }
      if (detailVal === 'sample') {
        this.setState({
          urlType: detailVal,
          // updateAddress:true
        })
      }
    }
  }

  componentWillUnmount() {
    return () => unSubscribe()
  }

  orderAddress = async () => {
    const addressData = await getOrderSampleAddress()
    this.setState({
      customerData: addressData,
    })
  }
  OrdersfetchData = async (val, oId, flag) => {
    const windowParams = new URLSearchParams(window.location.search)
    let detailVal = windowParams.get('type') ? windowParams.get('type') : ''
    this.setState({
      id: oId,
    })
    let params
    switch (val) {
      case 'basic':
        params = { id: oId }
        break
      case 'sample':
        params = { id: oId }
        break
      default:
        params = { id: oId }
        break
    }
    if (flag !== 'noRefresh') {
      this.setState({
        loadingPage: true,
      })
    }
    const res = await getProductOrderData(params)
    if (res.previousId === null || res.previousId === undefined) {
      this.setState({
        hasPrevious: false,
      })
    } else {
      this.setState({
        hasPrevious: true,
      })
    }
    if (res.nextId === null || res.nextId === undefined) {
      this.setState({
        hasNext: false,
      })
    } else {
      this.setState({
        hasNext: true,
      })
    }
    if (res.shippingData.length > 0) {
      for (let index = 0; index < res.shippingData.length; index++) {
        if (res.shippingData[index].selected === 1) {
          setTimeout(() => {
            this.setState({
              ShippingCheckedVal: res.shippingData[index].ShippingMethod,
              // previousId: res.previousId,
              // nextId: res.nextId,
            })
          }, 0)
        }
      }
    }
    this.setState({
      previousId: res.previousId,
      nextId: res.nextId,
    })

    if (detailVal === 'sample') {
      this.orderAddress()
      this.setState({
        urlType: detailVal,
      })
    }

    // const variantIds = []
    // _.forEach(res.orderDetailData, (item) => {
    //   variantIds.push(item.variantId)
    // })
    // this.setState({
    //   variantIds,
    // })
    _.forEach(res.orderDetailData, (item) => {
      if (item.imageUrl) {
        item.imageUrl = ossSignatureUrl(item.imageUrl) // 读取OSS资源
      }
    })

    this.setState({
      loadingPage: false,
      orderLists: res.orderDetailData,
      paymentData: res.paymentData,
      customerData: res.customerData,
      podOrderId: res.podOrderId,
      copyOrderId: res.copyOrderId,
      id: res.id,
      note: res.note,
      shippingData: res.shippingData,
      shippingId: res.shippingId,
      type: res.type,
      paymentStatus: res.paymentStatus,
      updatedTime: res.updatedTime,
    })
  }

  // 跳转至产品详情
  toDetail = (data) => {
    this.props.history.push({
      pathname: '/productContentEdit',
      state: {
        finishedId: data.finishedId,
      },
    })
  }

  handleShowCustomerModal = (value) => {
    if (_.isEmpty(this.state.orderLists)) {
      this.setState({
        isShowCustomerModal: false,
      })
      this.toggleToast('Please add a product first', true)
      return false
    }
    this.setState(
      {
        isShowCustomerModal: true,
      },
      () => {
        switch (value) {
          case 1:
            this.setState({
              updateAddress: true,
            })
            break
          case 2:
            this.setState({
              updateAddress: false,
            })
            break

          default:
            this.setState({
              updateAddress: false,
            })
            break
        }
      }
    )
    // this.setState({
    //   isShowCustomerModal: true,
    // })
  }

  callCustomerFn = (isShow) => {
    this.setState({
      isShowCustomerModal: isShow,
    })
  }

  handleShowSelProductModal = () => {
    this.setState({
      isShowSelProductModal: true,
    })
  }

  callSelProductFn = (isShow) => {
    this.setState({
      isShowSelProductModal: isShow,
    })
  }

  handleShowTaxModal = () => {
    this.setState({
      isShowTaxModal: true,
    })
  }

  callTaxModalFn = (isShow) => {
    this.setState({
      isShowTaxModal: isShow,
    })
  }

  handleShowShippingModal = () => {
    this.setState({
      isShowShippingProductModal: true,
    })
  }

  // 产品搜索
  handleInputOrderSearchProps = (value) => {
    this.setState({
      searchProductProps: value,
      isShowSelProductModal: true,
    })
  }

  //  清空输入框
  handleClearButtonClickProps = () => {
    this.setState({
      searchProductProps: '',
    })
  }

  // 回调Shipping子组件数据
  callShippingFn = async (isShow, data) => {
    let params = {
      shippingId: this.state.shippingId,
      podOrderId: this.state.id,
      shippingMethod: data,
    }
    let res
    if (data.length > 0) {
      // getShippingMethod()
      res = await getProductOrderShippingMethodSave(params)
      // this.OrdersfetchData('basic');
    }
    // const getShippingMethod = async () => {
    //   return await getProductOrderShippingMethodSave(params)
    // }
    this.setState({
      isShowShippingProductModal: isShow,
    })
    return res
  }

  closeShippingModal = () => {
    this.setState({
      isShowShippingProductModal: false,
    })
  }

  //  类组件中通过绑定this，保证不同生命周期中handleOnChange的引用不变
  handleChange = (ind, value, skuId) => {
    // console.log('ind', ind, value, skuId)
    this.setState(
      {
        skuId: skuId,
      },
      () => {
        this.handleOnChange(ind, value)
      }
    )
  }

  componentWillMount() {
    window.addEventListener('beforeunload', this.beforeunload) //阻止未保存数据就刷新页面
  }
  beforeunload = (ev) => {
    if (ev && this.state.draft) {
      ev.returnValue = ''
    }
  }

  handleOnChange = async (index, value) => {
    // 显示保存按钮
    this.onShowSaveButton(true)
    //  具体的业务逻辑
    let newVal = 1
    if (value > 1) {
      newVal = value
    }
    let params = {
      detailId: this.state.skuId,
      // quantity: value,
      quantity: newVal,
    }

    let data = this.state.orderLists.map((item, ind) => {
      if (ind === index) {
        // return { ...item, quantity: value }
        return { ...item, quantity: newVal, total: item.cost * newVal }
      } else {
        return item
      }
    })

    this.setState({
      orderLists: data,
    })

    for (let index = 0; index < this.state.orderLists.length; index++) {
      if (this.state.orderLists[index].detailId === this.state.skuId) {
        this.setState({
          val: value,
        })
      }
    }

    this.getOrderSkuCount(params)
  }

  getOrderSkuCount = _.debounce(async (params) => {
    // this.setState({
    //   loading: true,
    // })
    await getProductOrderSkuCount(params)
    this.OrdersfetchData('basic', this.state.id, 'noRefresh') // quantity 参数避免change一次loading一次
  }, 600)

  // 新增地址
  onModificationAdd = () => {
    // const res = await getProductOrderData({
    //   id: this.state.id,
    // })
    // if (res) {
    //   this.setState({
    //     customerData: res.customerData,
    //   })
    // }
    getProductOrderData({ id: this.state.id }).then((res) => {
      this.setState({
        customerData: res.customerData,
      })
      this.OrdersfetchData('basic', this.state.id, 'noRefresh')
    })
  }
  // 编辑地址
  onModificationEdit = (data) => {
    this.setState({
      customerData: data,
    })
    this.OrdersfetchData('', this.state.id, 'noRefresh')
  }
  // 编辑备注
  handleNoteChange = (value) => {
    this.setState({
      note: value,
    })
    this.onShowSaveButton(true)
  }
  // 订单复制
  onDuplicate = async () => {
    let params = {
      id: this.state.id,
    }
    const res = await getProductOrderCopy(params)
    if (typeof res !== 'undefined') {
      const windowParams = new URLSearchParams(window.location.search)
      let detailVal = windowParams.get('type') ? windowParams.get('type') : ''
      this.OrdersfetchData(detailVal, res)
      this.toggleToast('success', false)
    } else {
      this.toggleToast('Server Error', true)
    }
  }
  // 订单退款
  onRefund = () => {
    window.$crisp.push(['do', 'chat:open'])
    window.$crisp.push(['set', 'message:text', ['I would like a refund']])
  }
  //  保存按钮显示方法
  onShowSaveButton = (value) => {
    this.setState({
      draft: value,
    })
  }
  //  订单存为草稿
  onOrderSave = async () => {
    let params = {
      id: this.state.id,
      note: this.state.note,
    }
    await getProductOrderSave(params)
    this.OrdersfetchData('', this.state.id)
    this.onShowSaveButton(false)
    this.toggleToast('success', false)
  }

  // 订单删除
  onOrderDelete = async (skuId) => {
    let data = this.state.orderLists.filter((item) => {
      if (item.detailId === skuId) {
        return !item
      } else {
        return item
      }
    })
    await getProductOrderDelete({ detailId: [skuId] })
    this.setState(
      {
        orderLists: data,
      },
      async () => {
        const res = await getProductOrderData({
          id: this.state.id,
        })
        this.setState({
          paymentData: res.paymentData,
        })
      }
    )
  }

  // 支付弹窗
  // onOrderPay = () => {
  //   if (this.state.orderLists.length > 0) {
  //     this.setState({
  //       paypalShow: true,
  //     })
  //   } else {
  //     this.toggleToast('not found order,please create order.', true)
  //   }
  // }

  // 隐藏支付弹框
  // onPaypalShow = () => {
  //   this.setState({
  //     paypalShow: false,
  //   })
  // }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState(({ showToast }) => ({
      showToast: !showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    }))
  }

  toggleActive = () => {
    this.setState({
      active: false,
    })
  }

  // 处理路由拦截
  handleRouterHoldUp = (location) => {
    if (!this.state.id) return
    const pathUrl = location.pathname + location.search // // location 携带的路径,即将要跳转的路径
    if (_.isEmpty(this.state.orderLists)) {
      this.setState({
        // 释放路由跳转权限
        isShowLeavePage: true,
        whichPathUrlWillTo: pathUrl, // 存储即将要跳转的pathUrl
      })
      return false
    }
    return true
  }

  // 处理保存信息提示弹窗的确认事件
  handleSaveModelOK = () => {
    this.setState(
      {
        isShowLeavePage: false, // 关闭自定义提示窗
        isHoldUpRouter: false, // 释放路由跳转权限
      },
      async () => {
        await delOrder({ id: this.state.id })
        if (this.state.whichPathUrlWillTo) {
          this.props.history.push(this.state.whichPathUrlWillTo) // 手动跳转，如果是手动跳转，必须放在这里执行，因为setState是异步的，如果不放回调里执行手动跳转，会陷入Prompt组件的死循环
        } else if (this.state.whichWillToFunc) {
          this.setState(
            {
              id: '',
            },
            () => {
              if (this.state.whichWillToFunc === 'handleOnNext') {
                this.handleOnNext(this.state.nextId)
              } else {
                this.handleOnPrevious(this.state.previousId)
              }
            }
          )
        }
        this.setState({
          // 内部路由变化，当跳转之后，还需要重新关闭路由跳转权限，实现下一次跳转路由的拦截(如果所在页面的组件已经完全销毁，则不需要重新关闭路由跳转权限)
          isHoldUpRouter: true,
        })
      }
    )
  }

  // 处理保存信息提示弹窗的取消事件
  handleSaveModelCancel = () => {
    this.setState({
      isShowLeavePage: false, // 关闭自定义提示窗
    })
  }

  async handleOnPrevious(params) {
    if (this.state.id && _.isEmpty(this.state.orderLists)) {
      this.setState({
        // 释放路由跳转权限
        isShowLeavePage: true,
        whichWillToFunc: 'handleOnPrevious', // 存储即将要跳转的pathUrl
      })
      return
    }
    let detailVal = ''
    if (this.props.location.state !== undefined) {
      const { type } = this.props.location.state // 从产品列表过来
      detailVal = type
    } else {
      const windowParams = new URLSearchParams(window.location.search)
      windowParams.set('order', params)
      detailVal = windowParams.get('type')
    }
    this.OrdersfetchData(detailVal, params)
  }

  async handleOnNext(params) {
    if (this.state.id && _.isEmpty(this.state.orderLists)) {
      this.setState({
        // 释放路由跳转权限
        isShowLeavePage: true,
        whichWillToFunc: 'handleOnNext', // 存储即将要跳转的pathUrl
      })
      return
    }
    let detailVal = ''
    if (this.props.location.state !== undefined) {
      const { type } = this.props.location.state // 从产品列表过来
      detailVal = type
    } else {
      const windowParams = new URLSearchParams(window.location.search)
      detailVal = windowParams.get('type')
      windowParams.set('order', params)
    }
    this.OrdersfetchData(detailVal, params)
  }

  async createOrder() {
    try {
      const res = await getProductOrderId({ id: [this.state.id], type: 1 })
      window.sessionStorage.setItem('paypalId', res?.payPalOrderId)
      return res?.payPalOrderId
    } catch (error) {}
  }

  validaCustomer = () => {
    if (this.state.customerData.id === 0) {
      this.setState({
        isShowCustomerModal: true,
      })
      return false
    }
  }

  quantityDis = () => {
    /**
     * type 为 shopfiy的不能修改库存、或者支付状态为已支付的不能修改库存
     */
    if (this.state.type === 1 || this.state.paymentStatus === 'Paid') {
      return true
    } else {
      return false
    }
  }

  badgeStatus = () => {
    switch (this.state.paymentStatus) {
      case 'Unpaid':
        return 'Warning'
      case 'Paid':
        return 'Success'
      case 'Renfunded':
        return ''
      case 'Unfulfilled':
        return 'Attention'
      case 'Pending':
        return 'Attention'
      default:
        break
    }
  }

  //是否禁用物流选择按钮
  disShippingBtn(type, paymentStatus) {
    // if (type === 1 || paymentStatus === 'Paid') {
    if (paymentStatus === 'Paid') {
      return true
    } else {
      return false
    }
  }

  /**
   * 判断是否弹出引导好评公共方法
   */
  checkFavorableCommond = () => {
    window.sessionStorage.setItem('paypalId', '')
    // 不管支付成功与否跳转只订单列表页
    this.props.history.push({
      pathname: '/myOrders',
      // state: {
      //   favoraStatus: this.state.checkFavorable.isFirstOrder ? 2 : 4, // 引导好评弹窗标识
      //   orderType: this.state.urlType,
      // },
    })
  }

  getOrderTitle = () => {
    const { updatedTime, podOrderId, copyOrderId } = this.state
    if (updatedTime) {
      if (copyOrderId) {
        return 'Copy of #POD' + podOrderId + '  '
      }
      return '#POD' + podOrderId + '  '
    } else {
      return 'Cancel'
    }
  }

  getProvinceLabel = (provinceLabel) => {
    //获取具体省份信息
    if (provinceLabel) {
      this.setState({
        provinceLabel: provinceLabel,
      })
    } else {
      this.setState({
        provinceLabel: '',
      })
    }
  }

  render() {
    const {
      searchProductProps,
      isShowCustomerModal,
      isShowSelProductModal,
      isShowShippingProductModal,
      isShowTaxModal,
      ShippingCheckedVal,
      orderLists,
      paymentData,
      customerData,
      note,
      shippingData,
      // paypalShow,
      type,
      paymentStatus,
      updatedTime,
      // podOrderId,
      // copyOrderId,
      urlType,
      id,
    } = this.state
    const head = [
      {
        id: 1,
        title: 'Product',
        align: 'left',
      },
      {
        id: 2,
        title: 'Cost',
        align: 'center',
      },
      {
        id: 3,
        title: 'Quantity',
        align: 'center',
      },
      {
        id: 4,
        title: 'Total',
        align: 'right',
      },
      {
        id: 5,
      },
    ]

    let pageActions = []
    if (this.state.showDuplicateBtn) {
      pageActions.push({
        content: 'Duplicate',
        onAction: () => this.onDuplicate(),
      })
    }
    if (_.includes(['Paid', 'Partially paid', 'Partially refunded'], this.state.paymentStatus)) {
      pageActions.push({
        content: 'Refund',
        onAction: () => this.onRefund(),
      })
    }

    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={() => this.toggleToast('')}
        error={this.state.showToastIserror}
        duration={1800}
      />
    ) : null
    if (this.state.loadingPage) {
      return <LoadingPage></LoadingPage>
    }

    return (
      <Page
        breadcrumbs={[
          {
            content: 'Back',
            onAction: () => {
              this.props.history.push('/myOrders')
            },
          },
        ]}
        // title={updatedTime ? ('#POD' + podOrderId + '  ') : 'Cancel'}
        title={this.getOrderTitle()}
        titleMetadata={<Badge status={this.badgeStatus()}>{paymentStatus}</Badge>}
        secondaryActions={pageActions}
        pagination={{
          hasPrevious: this.state.hasPrevious,
          hasNext: this.state.hasNext,
          onPrevious: () => {
            this.handleOnPrevious(this.state.previousId)
          }, // 上一个
          onNext: () => {
            this.handleOnNext(this.state.nextId)
          }, // 下一个
        }}
        fullWidth
      >
        <Prompt
          when={this.state.isHoldUpRouter}
          message={this.handleRouterHoldUp}
        />
        <div className="updatedTime">
          <span>{updatedTime}</span>
        </div>
        {/* 1 row */}
        <div className="row orders-row">
          {/* Shipping to */}
          <div className="col-8 order_box">
            <Card title="Product">
              <Card.Section>
                {this.state.paymentStatus === 'Unpaid' && this.state.type !== 1 && (
                  <TextField
                    id="searchProductProps"
                    value={searchProductProps}
                    onChange={(value) => this.handleInputOrderSearchProps(value)}
                    placeholder="search product"
                    prefix={<Icon source={SearchMinor} />}
                    clearButton
                    onClearButtonClick={this.handleClearButtonClickProps}
                    connectedRight={
                      <Button
                        size="medium"
                        onClick={this.handleShowSelProductModal}
                      >
                        Broswe
                      </Button>
                    }
                  />
                )}
                {orderLists.length >= 1 && (
                  <div className="order_product_box">
                    <div className="order_product_head">
                      {head.map((item) => (
                        <div
                          key={item.id}
                          style={{ textAlign: item.align }}
                        >
                          {item.title}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="order_product_list">
                  {orderLists.length >= 1 &&
                    orderLists.map((data, ind) => (
                      <div
                        key={ind}
                        className="order_product_items"
                      >
                        <div className="order_product_item">
                          <div className="order_product_item_variants">
                            <div className="order_product_item_image">
                              <Image
                                src={data.imageUrl}
                                alt={data.title}
                                size="40"
                              />
                            </div>
                            <div className="order_product_item_variants_box">
                              <div
                                className="order_product_item_title"
                                onClick={() => {
                                  this.toDetail(data)
                                }}
                              >
                                {data.title}
                              </div>
                              <div className="order_product_item_describe">
                                {data.color + ' / ' + data.size}
                              </div>
                              <div className="order_product_item_describe">SKU：{data.sku}</div>
                            </div>
                          </div>
                          <div className="order_product_item_price">{'$' + data.cost}</div>
                          <div className="order_product_item_quantity">
                            <TextField
                              type="number"
                              step={1}
                              // value={String(data.quantity)}
                              value={String(data.quantity)}
                              onChange={(value) => this.handleChange(ind, value, data.detailId)}
                              autoComplete="off"
                              disabled={this.quantityDis()}
                              // onFocus={
                              //   ()=>{
                              //     this.setState({
                              //       changeQuantityType:'focus'
                              //     })
                              //   }
                              // }
                            />
                          </div>
                          <div className="order_product_item_total">
                            ${_.round(data.total, 2)}
                            {/* {!this.state.loading && `$${_.round(data.total, 2)}`} */}
                            {/* <GlobalSpinner
                              isShow={this.state.loading}
                              size={'small'}
                            /> */}
                          </div>
                          {this.state.type !== 1 && paymentStatus === 'Unpaid' && (
                            <div
                              className="order_product_item_delete"
                              onClick={() => this.onOrderDelete(data.detailId)}
                            >
                              <MobileCancelMajor />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </Card.Section>
            </Card>
          </div>

          {/* Customer */}
          <div className="col-4 order_customer">
            <Card
              title="Customer"
              actions={
                this.props.location.state.type !== 'sample'
                  ? customerData?.id > 0 &&
                    this.state.type !== 1 && [
                      {
                        content: 'Edit',
                        disabled: this.state.paymentStatus === 'Paid' ? true : false,
                        onAction: () => this.handleShowCustomerModal(1),
                      },
                    ]
                  : orderLists.length > 0 &&
                    this.state.type !== 1 && {
                      content: 'Edit',
                      onAction: () => this.handleShowCustomerModal(1),
                    }
              }
            >
              {(customerData?.id <= 0 || customerData?.id === undefined) && (
                <div
                  className="not_address"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <Icon
                      source={MobilePlusMajor}
                      color="base"
                    />
                  </div>
                  <div style={{ marginLeft: '1rem', cursor: 'pointer' }}>
                    <Heading>
                      <div onClick={() => this.handleShowCustomerModal(2)}>
                        Online store dashboard
                      </div>
                    </Heading>
                  </div>
                </div>
              )}
              {customerData?.id > 0 && (
                <div className="customer_address_box">
                  <div className="customer_address_top">
                    {/* <div>{customerData?.customerName}</div> */}
                    <div>{customerData?.firstName + ' ' + customerData?.lastName}</div>
                    <div>{customerData?.email}</div>
                    <div>{customerData?.phoneNumber}</div>
                  </div>
                  <div className="customer_address_bottom">
                    <div className="address_name">
                      <div>{customerData?.firstName}</div>
                      <div>{customerData?.lastName}</div>
                    </div>
                    <div>{customerData?.address}</div>
                    <div className="address_city">
                      <div>{customerData?.city}</div>
                      <div>
                        {/* {customerData?.province} */}
                        {this.state.type !== 1 ? this.state.provinceLabel : customerData?.province}
                      </div>
                      <div>{customerData?.zip}</div>
                    </div>
                    <div>
                      {this.state.type !== 1
                        ? _.get(
                            _.find(countryList, (item) => {
                              return item.value === customerData?.country
                            }),
                            'label'
                          )
                        : customerData?.country}
                    </div>
                    <div className="address_phone">
                      <div>{customerData?.phone}</div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </div>

        {/* 2 row */}
        <div className="row Payment_Container">
          {/* Payment */}
          <div className="col-8 border">
            <Card title="Payment">
              <Card.Section>
                <div className="Felx_Container">
                  <div>Subtotal</div>
                  <div>
                    {paymentData?.subtotal ? '$' + paymentData?.subtotal : '$0.00'}
                    {/* {!this.state.loading &&
                      (paymentData?.subtotal ? '$' + paymentData?.subtotal : '$0.00')}
                    <GlobalSpinner
                      isShow={this.state.loading}
                      size={'small'}
                    /> */}
                  </div>
                </div>

                <div className="Felx_Container">
                  <div
                    style={{ color: '#2c6ecb', cursor: 'pointer' }}
                    onClick={() => {
                      this.handleShowTaxModal()
                    }}
                  >
                    Tax
                  </div>
                  <div>
                    <span>Not calculated</span>
                  </div>
                  <div>{paymentData?.tax ? '$' + paymentData?.tax : '$0.00'}</div>
                </div>

                <div className="Felx_Container">
                  <div>Freight</div>
                  <div>{paymentData?.freight ? '$' + paymentData?.freight : '$0.00'}</div>
                </div>
              </Card.Section>

              <Card.Section>
                <div className="Felx_Container">
                  <Heading>Cost total</Heading>
                  <Heading>
                    {paymentData?.total ? '$' + paymentData?.total : '$0.00'}
                    {/* {!this.state.loading &&
                      (paymentData?.total ? '$' + paymentData?.total : '$0.00')} */}
                    {/* <GlobalSpinner
                      isShow={this.state.loading}
                      size={'small'}
                    /> */}
                  </Heading>
                </div>
              </Card.Section>
            </Card>
          </div>
          {/* Shipping */}
          <div className="col-4 border">
            <Card title="Shipping ">
              <Card.Section>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <Button
                    onClick={this.handleShowShippingModal}
                    disabled={this.disShippingBtn(type, paymentStatus)}
                  >
                    {ShippingCheckedVal}
                  </Button>
                </div>
              </Card.Section>
            </Card>

            {/* Note */}
            <Card title="Note">
              <Card.Section>
                <TextField
                  value={note}
                  onChange={(value) => this.handleNoteChange(value)}
                  multiline={2}
                  autoComplete="off"
                />
              </Card.Section>
            </Card>
          </div>
        </div>
        {toastMarkup}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
          <ButtonGroup>
            {this.state.draft && <Button onClick={() => this.onOrderSave()}>Save draft</Button>}
            {this.state.isShowPaypalBtn
              ? orderLists.length > 0 && (
                  <PayPalScriptProvider
                    options={{
                      'client-id': process.env.REACT_APP_PAYPAL_CLINTID,
                    }}
                  >
                    <PayPalButtons
                      className="palpal_button"
                      style={{
                        shape: 'rect',
                        color: 'gold',
                        layout: 'horizontal',
                        height: 36,
                        width: 100,
                      }}
                      createOrder={(e) => this.createOrder()}
                      catchError={(error) => {
                        console.warn('error', error)
                      }}
                      onCancel={(data) => {
                        // Show a cancel page, or return to cart
                      }}
                      onClick={(e) => this.validaCustomer()}
                      onApprove={async (data, actions) => {
                        try {
                          let id = window.sessionStorage.getItem('paypalId')
                          await getProductOrderPay({ payPalOrderId: id })
                          await listenerPayStatus({ payPalOrderId: id })
                          // setTimeout(() => {
                          //   window.sessionStorage.setItem('paypalId', '')
                          //   // 不管支付成功与否跳转只订单列表页
                          //   this.props.history.push({
                          //     pathname: '/myOrders',
                          //     state: {
                          //       favoraStatus: 2, // 引导好评弹窗标识
                          //       orderType: urlType
                          //     }
                          //   })
                          // }, 1300)
                          this.checkFavorableCommond()
                        } catch (error) {}
                      }}
                    />
                  </PayPalScriptProvider>
                )
              : paymentStatus === 'Unpaid' && (
                  <PayPalScriptProvider
                    options={{
                      'client-id': process.env.REACT_APP_PAYPAL_CLINTID,
                    }}
                  >
                    <PayPalButtons
                      className="palpal_button"
                      style={{
                        shape: 'rect',
                        color: 'gold',
                        layout: 'horizontal',
                        height: 36,
                        width: 130,
                      }}
                      createOrder={(e) => this.createOrder()}
                      catchError={(error) => {
                        console.warn('error', error)
                      }}
                      onCancel={(data) => {
                        // Show a cancel page, or return to cart
                      }}
                      onClick={(e) => this.validaCustomer()}
                      onApprove={async (data, actions) => {
                        try {
                          let id = window.sessionStorage.getItem('paypalId')
                          await getProductOrderPay({ payPalOrderId: id })
                          await listenerPayStatus({ payPalOrderId: id })
                          this.checkFavorableCommond()
                          // setTimeout(() => {
                          //   window.sessionStorage.setItem('paypalId', '')
                          //   /**
                          //    * 跳转至订单列表页
                          //    * 1.引导好评条件
                          //    *    a.从订单模块弹出标识 2
                          //    *    b.改订单为sample订单
                          //    */
                          //   this.props.history.push({
                          //     pathname: '/myOrders',
                          //     state: {
                          //       favoraStatus: 2, // 引导好评弹窗标识
                          //       orderType: urlType
                          //     }
                          //   })
                          // }, 1300)
                        } catch (error) {}
                      }}
                    />
                  </PayPalScriptProvider>
                )}
          </ButtonGroup>
        </div>
        <div>
          <CustomerModal
            onShowSaveButton={this.onShowSaveButton}
            podOrderId={this.state.podOrderId}
            id={this.state.id}
            isShowCustomerModal={isShowCustomerModal}
            updateAddress={this.state.updateAddress}
            callCustomerFn={this.callCustomerFn}
            customerData={customerData}
            urlType={urlType}
            onModificationEdit={this.onModificationEdit}
            onModificationAdd={this.onModificationAdd}
            getProvinceLabel={this.getProvinceLabel}
          />
          <SelProductModal
            handleInputOrderSearchProps={this.handleInputOrderSearchProps}
            handleClearButtonClickProps={this.handleClearButtonClickProps}
            searchProductProps={searchProductProps}
            isShowSelProductModal={isShowSelProductModal}
            callSelProductFn={this.callSelProductFn}
            OrdersfetchData={this.OrdersfetchData}
            onShowSaveButton={this.onShowSaveButton}
            urlType={urlType}
            orderId={id}
            orderLists={orderLists}
            // variantIds={this.state.variantIds}
          />
          <ShippingModal
            id={this.state.id}
            isShowShippingProductModal={isShowShippingProductModal}
            shippingData={shippingData}
            OrdersfetchData={this.OrdersfetchData}
            callShippingFn={this.callShippingFn}
            closeShippingModal={this.closeShippingModal}
            onShowSaveButton={this.onShowSaveButton}
          />
          {/* <PaypalModal
            show={paypalShow}
            id={this.state.id}
            onPaypalShow={this.onPaypalShow}
            podOrderId={this.state.podOrderId}
          ></PaypalModal> */}
          <TaxModal
            isShowTaxModal={isShowTaxModal}
            callTaxModalFn={this.callTaxModalFn}
          ></TaxModal>

          {/* 离开当前页拦截弹窗 */}
          <LeavePage
            isShow={this.state.isShowLeavePage} // 控制弹窗显示
            content={'Leaving this page, the order will be removed!!!'} // 弹窗内容
            onClose={this.handleSaveModelCancel} // 关闭回调
            onConfirm={this.handleSaveModelOK} // 确认回调
          />
        </div>
      </Page>
    )
  }
}

export default withRouter(OrderPayment)
