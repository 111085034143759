import { useState, useCallback, useEffect } from 'react'
import { Button, Modal, TextField } from '@shopify/polaris'
import _ from 'lodash'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Array} selectTableRow 所选变体列表
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const EditInventory = ({ isShow, selectTableRow, onClose, onConfirm }) => {
  const [inputValue, setInputValue] = useState('')
  const [sourceData, setSourceData] = useState()
  const [isDisabled, setIsDisabled] = useState(true)
  const minValue = 0
  const maxValue = 99999
  const newData = _.cloneDeep(selectTableRow)

  useEffect(() => {
    const data = _.forEach(newData, (item) => {
      item.inventory = _.toString(item.inventory)
    })
    setSourceData(newData)
  }, [isShow])

  // 关闭弹窗
  const closeModal = useCallback(() => {
    setInputValue('')
    setIsDisabled(true)
    onClose()
  }, [])

  // 输入框文本事件
  const handleChangeText = (value) => {
    // console.log('value', value)
    setInputValue(value)
    if (value) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  // 输入框失去焦点事件
  const handleBlur = (e) => {
    const value = _.toNumber(e.target.value)
    if (value > maxValue) {
      e.target.value = _.toString(maxValue)
    }
    if (value < minValue) {
      e.target.value = _.toString(minValue)
    }
    setInputValue(e.target.value)
  }

  // 修改单个价格
  const handleChange = (value, id) => {
    const data = [...sourceData]
    _.forEach(data, (item) => {
      if (item.podVariantId === id) {
        item.inventory = value
      }
    })
    setSourceData(data)
  }

  // 单个输入框失去焦点事件
  const handleBlurItem = (e, id) => {
    const value = _.toNumber(e.target.value)
    if (value > maxValue) {
      e.target.value = _.toString(maxValue)
    }
    if (value < minValue || !value) {
      e.target.value = _.toString(minValue)
    }
    // console.log('value', e.target.value)
    const data = [...sourceData]
    _.forEach(data, (item) => {
      if (item.podVariantId === id) {
        item.inventory = e.target.value
      }
    })
    setSourceData(data)
  }

  // 修改所有价格
  const handleChangeAll = () => {
    const data = [...sourceData]
    _.forEach(data, (item) => {
      item.inventory = inputValue
    })
    setSourceData(data)
    setInputValue('')
    setIsDisabled(true)
  }

  return (
    <Modal
      open={isShow}
      onClose={closeModal}
      title="Edit quantities"
      primaryAction={{
        content: 'Save',
        onAction: () => {
          onConfirm(sourceData)
          onClose()
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: closeModal,
        },
      ]}
    >
      <div className="Edit_Batch">
        <div className="Edit_Batch_Apply">
          <div className="Edit_Batch_Apply_Input">
            <TextField
              type="number"
              min={minValue}
              max={maxValue}
              step={1}
              label="Apply a quantity to all variants"
              value={inputValue}
              onChange={handleChangeText}
              onBlur={handleBlur}
              autoComplete="off"
            />
          </div>
          <Button
            onClick={handleChangeAll}
            disabled={isDisabled}
          >
            Apply to all
          </Button>
        </div>
        <div className="Edit_Batch_List">
          {_.map(sourceData, (item, index) => (
            <div
              key={index}
              className="Edit_Batch_List_Item"
            >
              <div className="Edit_Batch_List_Item_Title">
                {item.statement} / {item.optionTwo}
              </div>
              <div className="Edit_Batch_List_Item_Input">
                <TextField
                  type="number"
                  min={minValue}
                  max={maxValue}
                  step={1}
                  value={item.inventory}
                  onChange={(e) => {
                    handleChange(e, item.podVariantId)
                  }}
                  onBlur={(e) => {
                    handleBlurItem(e, item.podVariantId)
                  }}
                  autoComplete="off"
                  align="right"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default EditInventory
