/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-29 09:16:04
 * @LastEditors: huang-chao hc18174419502@163.com
 * @LastEditTime: 2022-11-29 14:17:47
 * @FilePath: \pod-app-react\src\components\PodEmptyState\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'
import { EmptyState } from '@shopify/polaris'

const PodEmptyState = ({ heading, image, action, paper }) => {
  return (
    <div>
      <EmptyState
        heading={heading}
        action={action}
        image={image}
      >
        {!action && paper}
      </EmptyState>
    </div>
  )
}

export default PodEmptyState
