import React, { Component } from 'react'
import store from '@/redux/store'
import { createIconCardShowAction } from '@/redux/action'
import { withRouter } from 'react-router-dom'
import {
  SkeletonPage,
  SkeletonBodyText,
  Layout,
  Card,
  TextContainer,
  Checkbox,
  Badge,
  Icon,
  Button,
  Pagination,
  Toast,
  Modal,
  Tooltip,
} from '@shopify/polaris'
import {
  ComposeMajor,
  DuplicateMinor,
  MobileVerticalDotsMajor,
  RiskMajor,
  OrdersMajor,
  CircleDisabledMajor,
  CircleUpMajor,
} from '@shopify/polaris-icons'
import IconCardContainer from '@/containers/IconCard'
import PodEmptyState from '@/components/PodEmptyState'
import SelVariantModal from '@/components/IconCard/components/SelVariantModal'
import Delist from './components/Delist'
import Image from '@/components/Image'
import { getMyProductList, setMyProductStatus } from '@/api/myproducts'
import { getProductCopy, getProductDelete } from '@/api/productContentEdit/index'
import LazyLoad from 'react-lazyload'
import './index.scss'
import { copyImage } from '@/api/productContentEdit/index'
import _ from 'lodash'
import GlobalSpinner from '../GlobalSpinner'
import { ossSignatureUrl } from '@/utils/utils'

let unSubscribe = null
class PodTable extends Component {
  state = {
    myProductData: [], // 产品列表
    ShowCountNumber: 0,
    checkedCount: 0, // 已选中的个数
    checked: false, // 是否全选
    selectAllMoreChecked: false, // 选择数据库所有记录
    SourcingTableTrHtml: <tr></tr>, // 列表行数据
    isShow: false,
    item: [0, 1],
    index: '',
    loadingPage: false, //初始化骨架屏
    isSearching: false,
    pageSize: 20, // 每页条数
    pageIndex: 1, // 当前页
    total: 0, // 数据总数
    hasPrevious: false, // 上一页
    hasNext: true, // 下一页
    PageCount: 0, // 总页数
    // filterStatus:[]
    finishedBySort: 0, // 排序标识
    keyWord: '', // 输入框查询标识
    isWhereSearch: false, // 是否带条件搜索
    showToast: false,
    toastContent: '',
    isError: false,

    isSpinner: false,
    deleteModalActive: false,

    isShowSelVariantModal: false,
    curFinishedId: '',

    // Delist
    showDelist: false,
  }
  // 初始化获取产品列表数据集合
  getProductList = async (isSearch = false) => {
    const { queryValue, filterStatus, filterType, getInitData, keyWord } = this.props
    if (isSearch) {
      this.setState({
        isSearching: true,
      })
    } else {
      if (filterStatus.length > 0 || filterType.length > 0) {
        this.setState({
          isSpinner: true,
        })
      } else {
        this.setState({
          loadingPage: true,
        })
      }
    }
    console.log('filterStatus', filterStatus)

    const params = {
      perPage: this.state.pageSize,
      pageNum: this.state.pageIndex,
      title: this.state.keyWord,
      status: filterStatus,
      typeMap: filterType,
      finishedByStatus: this.state.finishedBySort,
    }
    // 查询异常产品
    if (params.status[0] && params.status[0] === 'Exception') {
      delete params.status
      params.isReview = 2
    }
    const { count, listData } = await getMyProductList(params)
    _.forEach(listData, (item) => {
      if (item.imageUrl) {
        item.imageUrl = ossSignatureUrl(item.imageUrl) // 读取OSS资源
      }
    })
    if (
      queryValue === '' &&
      JSON.stringify(filterStatus) === '[]' &&
      JSON.stringify(filterType) === '[]' &&
      (keyWord === '' || keyWord === undefined)
    ) {
      getInitData(listData, false)
      this.setState({
        isWhereSearch: false,
        isSpinner: false,
      })
    } else {
      this.setState({
        isWhereSearch: true,
      })
    }
    if (listData.length > 0) {
      this.setState(
        {
          myProductData: listData,
          isSearching: false,
          loadingPage: false,
          total: count,
        },
        () => {
          // 更新列表数据
          this.updatePageDetail()
        }
      )
      if (this.state.pageIndex > 1) {
        this.setState({
          hasPrevious: true,
        })
      } else {
        this.setState({
          hasPrevious: false,
        })
      }
      // 总页数
      const maxPage = Math.ceil(count / this.state.pageSize)
      if (this.state.pageIndex < maxPage) {
        this.setState({
          hasNext: true,
          PageCount: maxPage,
        })
      } else {
        this.setState({
          hasNext: false,
          PageCount: maxPage,
        })
      }

      setTimeout(() => {
        this.setState({
          isSpinner: false,
        })
      }, 1000)
    } else {
      this.setState({
        myProductData: [],
        loadingPage: false,
      })
    }
  }

  componentDidMount() {
    // this.props.callBackSkeleton(true)
    // 数据初始化
    this.getProductList()
    //重新订阅 render
    unSubscribe = store.subscribe(() => {
      this.setState({})
      const productStatus = store.getState().productStatusObj // 成品状态消息推送
      if (!_.isEmpty(productStatus)) {
        const newData = _.cloneDeep(this.state.myProductData)
        const newPro = newData.map((item) => {
          for (const key in productStatus) {
            if (Number(key) === item.finishedId) {
              return { ...item, status: productStatus[key].status }
            }
          }
          return item
        })
        this.setState(
          {
            myProductData: newPro,
          },
          () => {
            this.updatePageDetail()
          }
        )
      }
    })
    // 监听屏幕点击，隐藏弹窗
    document.body.addEventListener('click', () => {
      store.dispatch(createIconCardShowAction(false))
    })
  }

  componentWillUnmount() {
    return () => unSubscribe()
  }

  // 回调子组件修改状态后重新请求一下数据
  initDataFn = () => {
    this.getProductList()
  }
  /**
   * 监听父组件state传递的props
   * @param {*} nextProps 父组件传递的props：Object
   * 1.
   */
  componentWillReceiveProps(nextProps) {
    if (
      this.props.filterStatus !== nextProps.filterStatus ||
      this.props.filterType !== nextProps.filterType ||
      this.props.sortByValue !== nextProps.sortByValue ||
      this.props.queryValue !== nextProps.queryValue
    ) {
      this.setState(
        {
          finishedBySort: nextProps.sortByValue,
          keyWord: nextProps.queryValue,
          isSpinner: true,
        },
        () => {
          this.getProductList(true)
        }
      )
    }
  }

  // 全选
  handleSelectAllMoreChecked = (isChecked) => {
    this.setState({
      selectAllMoreChecked: isChecked,
    })
  }

  // 批量操作
  handleSelectOption = (type) => {
    const checkedList = this.state.myProductData.filter((item) => {
      return item.checked === 1
    })
    const ids = checkedList.map((item) => {
      return item.finishedId
    })
    // if (type === 'Delete') {
    //   this.handleDelProduct(ids) // 批量删除
    // } else {
    //   this.handleSetStatus(ids, type) // 批量设置产品状态
    // }
    this.handleSetStatus(ids, type) // 批量设置产品状态
  }

  handleSelectOptionDel = () => {
    // const { queryValue, filterStatus, keyWord } = this.props
    // if (queryValue === '' && JSON.stringify(filterStatus) === '[]' && (keyWord === '' || keyWord === undefined)) {
    //   this.setState({
    //     isWhereSearch: false,
    //     deleteModalActive: true
    //   })
    // } else {
    //   this.setState({
    //     isWhereSearch: true,
    //     deleteModalActive: true
    //   })
    // }
    this.setState({
      deleteModalActive: true,
    })
  }

  /**
   * @function 批量设置产品状态
   * @param {Array} ids id集合
   * @param {String} status 状态【Active、Draft】
   */
  handleSetStatus = async (ids, status) => {
    try {
      await setMyProductStatus({
        finishedId: ids,
        status,
      })
      this.getProductList() // 设置状态成功后刷新当前列表

      this.setState({
        showToast: true,
        toastContent: 'Product saved',
      })
    } catch (error) {
      this.setState({
        showToast: true,
        toastContent: 'Server error',
      })
    }
  }

  handleOnNavigation = (event, status, index) => {
    event.stopPropagation()
    if (this.state.index === index) {
      const iconCard = store.getState().iconCard
      store.dispatch(createIconCardShowAction(!iconCard))
    } else {
      store.dispatch(createIconCardShowAction(true))
    }
    this.setState(
      {
        // isShow: !this.state.isShow,
        index: index,
      },
      () => {
        // 更新列表数据
        this.updatePageDetail()
      }
    )
  }

  publishOrDraft = async (item) => {
    if (item.status !== 'Active') {
      await this.handleSetStatus([item.finishedId], 'Active')
      this.setState({
        toastContent: 'Success',
        showToast: true,
      })
    } else {
      this.setState({
        showDelist: true,
        curFinishedId: item.finishedId,
      })
    }
  }

  confirmDelist = async () => {
    await this.handleSetStatus([this.state.curFinishedId], 'Draft')
    this.setState({
      showDelist: false,
      toastContent: 'Success',
      showToast: true,
    })
  }

  updatePageDetail() {
    let data = this.state.myProductData
    let isCheckedAll = false
    let checkedCount = 0
    let sourcingTableTrHtmlContent = <tr></tr>
    if (data.length > 0) {
      sourcingTableTrHtmlContent = data.map((item, key) => {
        let trHtml = ''
        if (item.checked === 1) {
          checkedCount++
        }
        let badge = ''
        switch (item.status) {
          case 'Unpublished':
            badge = <Badge>{item.status}</Badge>
            break
          case 'Active':
            badge = <Badge status="success">{item.status}</Badge>
            break
          case 'Publishing':
            badge = <Badge status="attention">{item.status}</Badge>
            break
          case 'Exception':
            badge = (
              <div>
                <Badge status="critical">{item.status}</Badge>

                <div style={{ display: 'flex', marginTop: '6px' }}>
                  <div style={{ cursor: 'default' }}>
                    <Icon
                      style={{ margin: 0 }}
                      source={RiskMajor}
                      color="critical"
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <p style={{ color: '#D72C0D' }}>
                      Product exception, please check the fourth clause in our
                      <Button
                        plain
                        onClick={() => {
                          this.props.history.push({
                            pathname: '/setting/termsOfService',
                          })
                        }}
                      >
                        Terms of service
                      </Button>
                    </p>
                  </div>
                </div>
              </div>
            )
            break
          case 'Draft':
            badge = <Badge status="info">{item.status}</Badge>
            break
          default:
            break
        }

        trHtml = (
          <tr key={item.finishedId}>
            <td style={{ width: '5%' }}>
              <Checkbox
                label=""
                labelHidden
                checked={item.checked}
                onChange={this.handleSingleCheckboxChange.bind(this, key)}
              />
            </td>
            <td style={{ width: '5%' }}>
              <LazyLoad
                eight={200}
                once
                offset={200}
                //debounce={1000}
                overflow
                placeholder={
                  <GlobalSpinner
                    isShow={true}
                    size="small"
                  />
                }
              >
                <Image
                  // src={process.env.REACT_APP_ADMIN_API_SERVER + item.imageUrl}
                  src={item.imageUrl}
                  alt={item.imageUrl}
                  size="50"
                />
              </LazyLoad>
            </td>
            <td
              className="Product_Product"
              style={{ width: '15%' }}
            >
              {/* {item.name} */}
              <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <div>
                  <div
                    onClick={() => this.handleToProEdit(item.finishedId, item.podProductId)}
                    style={{ color: '#1558D6', cursor: 'pointer' }}
                  >
                    {item.title}
                  </div>
                </div>
              </div>
            </td>
            {this.props.filterStatus[0] === 'Exception' && (
              <td
                className="Product_Status"
                style={{ width: '10%' }}
              >
                <Badge status="critical">{item.point}</Badge>
              </td>
            )}
            <td
              className="Product_Status"
              style={{ width: '15%' }}
            >
              {badge}
            </td>
            {this.props.filterStatus[0] === 'Exception' && (
              <td
                className="Product_Status"
                style={{ width: '15%' }}
              >
                {item.remark}
              </td>
            )}
            {this.props.filterStatus[0] !== 'Exception' && (
              <td
                className="Product_Inventory"
                style={{ width: '15%' }}
              >
                {item.inventory | '0'} in stock for {item.variantCount} variants
              </td>
            )}
            <td
              className="Product_Sale"
              style={{ width: '5%' }}
            >
              {item.salesVolume | 0}
            </td>

            {/* 数据操作 */}
            <td
              className="Product_Operation"
              style={{ width: '15%' }}
            >
              {item.status !== 'Publishing' && item.status !== 'Exception' ? (
                <div className="operationBtn">
                  {item.salesVolume === 0 && !item.isHistory && (
                    <Tooltip
                      content="Design"
                      preferredPosition="above"
                      dismissOnMouseOut
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.handleRedesign(item.finishedId, item.podProductId)
                        }}
                      >
                        <Icon
                          source={ComposeMajor}
                          color="base"
                        />
                      </div>
                    </Tooltip>
                  )}
                  <Tooltip
                    content="Get sample"
                    preferredPosition="above"
                    dismissOnMouseOut
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.handleGetSample(item.finishedId)
                      }}
                    >
                      <Icon
                        source={OrdersMajor}
                        color="base"
                      />
                    </div>
                  </Tooltip>
                  {!item.isHistory && (
                    <Tooltip
                      content="Duplicate"
                      preferredPosition="above"
                      dismissOnMouseOut
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={this.handleCopyProduct.bind(this, item)}
                      >
                        <Icon
                          source={DuplicateMinor}
                          color="base"
                        />
                      </div>
                    </Tooltip>
                  )}
                  {this.props.filterStatus[0] !== 'Exception' && (
                    <Tooltip
                      content="More"
                      preferredPosition="above"
                      dismissOnMouseOut
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          this.handleOnNavigation(e, item.status, item.key)
                        }}
                      >
                        <Icon
                          source={MobileVerticalDotsMajor}
                          color="base"
                        />
                      </div>
                    </Tooltip>
                  )}
                  {this.props.filterStatus[0] === 'Exception' && (
                    <Tooltip
                      content={item.status !== 'Active' ? 'Puhlish' : 'Delist'}
                      preferredPosition="above"
                      dismissOnMouseOut
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.publishOrDraft(item)}
                      >
                        <Icon
                          source={item.status !== 'Active' ? CircleUpMajor : CircleDisabledMajor}
                          color="base"
                        />
                      </div>
                    </Tooltip>
                  )}
                  <div
                    style={{
                      position: 'absolute',
                      right: '2rem',
                      bottom: '2.8rem',
                      // backgroundColor: 'white',
                      zIndex: '9999',
                      display: this.state.index === item.key ? 'block' : 'none',
                    }}
                  >
                    <IconCardContainer
                      store={store}
                      id={item.key}
                      status={item.status}
                      onConfirm={this.handleConfirm.bind(this, item)}
                      initDataFn={this.initDataFn}
                    />
                  </div>
                </div>
              ) : (
                <div className="operationBtn">
                  <div style={{ opacity: '0.3' }}>
                    <Icon
                      source={ComposeMajor}
                      color="base"
                    />
                  </div>
                  <div style={{ opacity: '0.3' }}>
                    <Icon
                      source={DuplicateMinor}
                      color="base"
                    />
                  </div>
                  {this.props.filterStatus[0] !== 'Exception' && (
                    <div style={{ opacity: '0.3' }}>
                      <Icon
                        source={MobileVerticalDotsMajor}
                        color="base"
                      />
                    </div>
                  )}
                  {this.props.filterStatus[0] === 'Exception' && (
                    <div style={{ opacity: '0.3' }}>
                      <Icon
                        source={item.status !== 'Active' ? CircleUpMajor : CircleDisabledMajor}
                        color="base"
                      />
                    </div>
                  )}
                </div>
              )}
            </td>
          </tr>
        )

        return trHtml
      })
    }

    // 判断是否全选
    if (data.length === checkedCount && checkedCount !== 0) {
      isCheckedAll = true
    }

    this.setState({
      SourcingTableTrHtml: sourcingTableTrHtmlContent,
      ShowCountNumber: data.length,
      checked: isCheckedAll,
      checkedCount: checkedCount,
    })
  }

  //全选
  handleAllCheckboxChange = (value) => {
    let _data = this.state.myProductData
    let setCheckedValue = value ? 1 : 0
    for (let i = 0; i < _data.length; i++) {
      _data[i].checked = setCheckedValue
    }

    if (!value && this.state.selectAllMoreChecked) {
      this.setState({
        selectAllMoreChecked: false,
      })
    }
    this.setState(
      {
        checked: value,
      },
      () => {
        // 更新列表数据
        this.updatePageDetail()
      }
    )
  }

  // checkbox 单选
  handleSingleCheckboxChange = (RecordKey, value) => {
    let _data = this.state.myProductData
    _data[RecordKey].checked = value === true ? 1 : 0
    if (!value && this.state.selectAllMoreChecked) {
      this.setState({
        selectAllMoreChecked: false,
      })
    }
    this.setState(
      {
        myProductData: _data,
      },
      () => {
        this.updatePageDetail()
      }
    )
  }

  // 分页操作
  hasPreviousRequest = (type) => {
    switch (type) {
      case 'Previous':
        this.setState({ pageIndex: Number(this.state.pageIndex) - 1 }, () => {
          this.getProductList(true)
        })
        break
      case 'Next':
        this.setState({ pageIndex: Number(this.state.pageIndex) + 1 }, () => {
          this.getProductList(true)
        })
        break
      default:
        break
    }
  }

  // 重新设计
  handleRedesign(finishedId, productId) {
    this.props.history.push({
      pathname: '/confugureDesign',
      state: {
        entry: 'productList',
        finishedId,
        productId,
      },
    })
  }

  // 下单
  handleGetSample(id) {
    this.setState({
      curFinishedId: id,
      isShowSelVariantModal: true,
    })
  }

  //成品图单独复制
  getCopyFinishImg = async (copyFinishedId, finishedId) => {
    const data = {
      targetFinishedId: finishedId,
      copyFinishedId,
    }
    await copyImage(data)
  }

  // 复制商品
  handleCopyProduct = async (item) => {
    try {
      const params = {
        finishedId: item.finishedId,
        isImages: 1, // 1 复制图片 2 不复制图片
        isSkus: 1, // 1 复制sku 2 不复制
        isNumber: 1, // 1 复制库存 2 不复制库存
        isActive: 1, // 1 不发布 2 发布
      }
      const copyFinishedId = await getProductCopy(params)
      this.getCopyFinishImg(copyFinishedId, item.finishedId)
      this.getProductList()

      this.setState({
        toastContent: 'Product copied',
        showToast: true,
      })
    } catch (error) {
      this.setState({
        toastContent: 'Server error',
        isError: true,
        showToast: true,
      })
    }
  }

  // 单个删除确认事件
  handleConfirm = async (item, type) => {
    if (type === 'Delete') {
      this.handleDelProduct([item.finishedId])
    }
  }

  // 删除商品
  handleDelProduct = async (ids) => {
    try {
      await getProductDelete({
        finishedId: ids,
      })
      this.setState({
        showToast: true,
        toastContent: 'Product deleted',
        deleteModalActive: false,
      })
      this.getProductList()
    } catch (error) {
      this.setState({
        toastContent:
          error && error.data && error.data.message ? error.data.message : 'Server error',
        isError: true,
        showToast: true,
        deleteModalActive: false,
      })
    }
  }

  // 确认删除提交到服务器
  handleDelSave = () => {
    const checkedList = this.state.myProductData.filter((item) => {
      return item.checked === 1
    })
    const ids = checkedList.map((item) => {
      return item.finishedId
    })
    this.handleDelProduct(ids) // 批量删除
  }

  handleDelCloseModal = () => {
    this.setState({
      deleteModalActive: false,
    })
  }

  // 点击产品标题跳转到产品编辑页面
  handleToProEdit(finishedId, productId) {
    this.props.history.push({
      pathname: '/productContentEdit',
      state: {
        finishedId,
        productId,
        filterStatus: this.props.filterStatus[0],
        entry: 'myProducts',
      },
    })
  }

  // 关闭Toast
  toggleActive() {
    this.setState({
      showToast: !this.state.showToast,
    })
  }

  render() {
    const {
      ShowCountNumber,
      SourcingTableTrHtml,
      checkedCount,
      hasPrevious,
      hasNext,
      pageIndex,
      pageSize,
      total,
      isSpinner,
      deleteModalActive,
    } = this.state

    // 移除 Modal
    const deleteModal = (
      <Modal
        open={deleteModalActive}
        onClose={this.handleDelCloseModal}
        title="Delete product"
        primaryAction={{
          content: 'Delete',
          destructive: 'destructive',
          onAction: () => {
            this.handleDelSave()
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: this.handleDelCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Are you sure to delete these {checkedCount} product? From Shopify Admin, the Active
              products will be sync removed either.
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    )

    let headings = ''
    //判断是否有批量选择
    if (ShowCountNumber !== 0) {
      if (checkedCount !== 0) {
        // 全选本页面数据，如果数据库总量大于本页面显示数量，显示可选这全部数据
        let selectAllDataButton = <div></div>
        if (this.state.checked) {
          if (this.state.total > checkedCount) {
            if (this.state.selectAllMoreChecked) {
              selectAllDataButton = (
                <div className="Select_More">
                  <Button
                    onClick={this.handleSelectAllMoreChecked.bind(this, false)}
                    plain
                    textAlign="left"
                  >
                    {/* "All sourcing requests selected. Undo" */}
                  </Button>
                </div>
              )
            }
            // else {
            //   selectAllDataButton = (
            //     <div className="Select_More">
            //       <Button
            //         onClick={this.handleSelectAllMoreChecked.bind(this, true)}
            //         plain
            //         textAlign="left"
            //       >
            //         {'Select all ' + this.state.size + '+ sourcing requests'}
            //       </Button>
            //     </div>
            //   )
            // }
          }
        }

        headings = (
          <div className="headings_div__1 notifications">
            <div className="headings_div__2">
              <div
                className="Polaris-ButtonGroup Polaris-ButtonGroup--segmented"
                data-buttongroup-segmented="true"
              >
                <div className="Polaris-ButtonGroup__Item">
                  <div
                    className="Polaris-CheckableButton Polaris-CheckableButton__CheckableButton--selectMode Polaris-CheckableButton__CheckableButton--selected"
                    style={{ paddingLeft: '0.7rem' }}
                  >
                    <div className="headings_div__3">
                      <Checkbox
                        label=""
                        labelHidden
                        checked={this.state.checked ? true : 'indeterminate'}
                        onChange={this.handleAllCheckboxChange}
                      />
                    </div>
                    <span
                      style={{ color: '#2C6ECB' }}
                      className="Polaris-CheckableButton__Label"
                      onClick={this.handleAllCheckboxChange}
                    >
                      {this.state.selectAllMoreChecked
                        ? checkedCount + ' selected'
                        : checkedCount + ' selected'}
                    </span>
                  </div>
                </div>

                <div className="Polaris-ButtonGroup__Item">
                  <div className="Polaris-BulkActions__BulkActionButton">
                    <Button
                      onClick={() => this.handleSelectOption('Active')}
                      // disabled={isBulkOperationsRequest}
                    >
                      Set as active
                    </Button>
                  </div>
                </div>

                <div className="Polaris-ButtonGroup__Item">
                  <div className="Polaris-BulkActions__BulkActionButton">
                    <Button
                      onClick={() => this.handleSelectOption('Draft')}
                      // disabled={isBulkOperationsRequest}
                    >
                      Set as draft
                    </Button>
                  </div>
                </div>

                <div className="Polaris-ButtonGroup__Item">
                  <div className="Polaris-BulkActions__BulkActionButton">
                    <Button
                      onClick={() => this.handleSelectOptionDel()}
                      // disabled={isBulkOperationsRequest}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {selectAllDataButton}
          </div>
        )
      }
    }

    const tableTheadTrContent =
      headings !== '' ? (
        <tr>
          <th
            width="5%"
            colSpan="4"
            style={{ textAlign: 'left', padding: '14px 0px 14px 17px' }}
          >
            {headings}
          </th>
        </tr>
      ) : (
        <tr>
          <th
            width="5%"
            style={{ textAlign: 'left', padding: '20px 0px 20px 25px' }}
          >
            <Checkbox
              label="All"
              labelHidden
              checked={false}
              onChange={this.handleAllCheckboxChange}
            />
          </th>
          <th width="5%"></th>
          <th width="15%">Product</th>
          {this.props.filterStatus[0] === 'Exception' && <th width="10%">Point</th>}
          <th width="15%">Status</th>
          {this.props.filterStatus[0] === 'Exception' && <th width="15%">Reason</th>}
          {this.props.filterStatus[0] !== 'Exception' && <th width="15%">Inventory</th>}
          <th width="5%">Sale</th>
          <th
            width="15%"
            align="center"
          >
            <div style={{ padding: '0 20px', textAlign: 'right' }}>Action</div>
          </th>
        </tr>
      )

    var pageMsg = ''
    //超过数量
    if (this.state.total > 0) {
      const startNum = (pageIndex - 1) * pageSize + 1
      const endNum = pageIndex * pageSize > total ? total : pageIndex * pageSize
      pageMsg = 'Showing ' + startNum + ' - ' + endNum + ' of ' + total + ' results'
    }

    const SkeletonPageNode = (
      <div style={{ paddingBottom: '20px' }}>
        <SkeletonPage fullWidth={true}>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <TextContainer>
                  <SkeletonBodyText lines={2} />
                </TextContainer>
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      </div>
    )

    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.toastContent}
        onDismiss={() => this.toggleActive()}
        duration={2200}
        error={this.state.isError}
      />
    ) : null

    return (
      <section className="Sourcing_Table">
        {this.state.loadingPage && SkeletonPageNode}
        {!this.state.loadingPage &&
          this.state.myProductData.length > 0 &&
          (isSpinner ? (
            <GlobalSpinner
              isShow={isSpinner}
              size={'large'}
            />
          ) : (
            <>
              <table style={{ width: '100%' }}>
                <thead>{this.state.myProductData.length > 0 && tableTheadTrContent}</thead>
                <tbody>{SourcingTableTrHtml}</tbody>
              </table>
              {/* 分页区域 */}
              {this.state.myProductData.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
                  <Pagination
                    label={pageMsg}
                    hasPrevious={hasPrevious}
                    onPrevious={() => this.hasPreviousRequest('Previous')}
                    hasNext={hasNext}
                    onNext={() => this.hasPreviousRequest('Next')}
                  />
                </div>
              )}
            </>
          ))}

        {!this.state.loadingPage &&
          this.state.myProductData.length === 0 &&
          (this.state.isWhereSearch
            ? isSpinner && (
                <PodEmptyState
                  heading={'No products found'}
                  image={'/images/empty.svg'}
                  action={false}
                  paper={<p>Try changing thhe filters or search term</p>}
                />
              )
            : !isSpinner && (
                <PodEmptyState
                  heading={'There is no product yet'}
                  image={
                    'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'
                  }
                  action={{
                    content: 'Browse products',
                    onAction: () => {
                      this.props.history.push('/catalog')
                    },
                  }}
                  paper={<p>Try changing thhe filters or search term</p>}
                />
              ))}

        {/* {
          !this.state.loadingPage &&
          this.state.myProductData.length === 0 && (
            !isSpinner && (
              <PodEmptyState
                heading={'There is no product yet'}
                image={
                  'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'
                }
                action={{ content: 'Browse products', url: '/catalog' }}
              />
            )
          )
        }

        {
          !this.state.loadingPage &&
          this.state.isWhereSearch && (
            !isSpinner && (
              <PodEmptyState
                heading={'No products found'}
                image={'/images/empty.svg'}
                action={false}
              />
            )
          )
        } */}

        {toastMarkup}
        {deleteModal}

        {/* 下单 */}
        <SelVariantModal
          callShowModalFn={() =>
            this.setState({
              isShowSelVariantModal: false,
              curFinishedId: '',
            })
          }
          variantsId={this.state.curFinishedId}
          orderType="sample"
          isShowSelVariantModal={this.state.isShowSelVariantModal}
        />

        {/* Delist */}
        <Delist
          isShow={this.state.showDelist}
          onConfirm={this.confirmDelist}
          onCancel={() => this.setState({ showDelist: false })}
        />
      </section>
    )
  }
}

export default withRouter(PodTable)
