import { Modal } from '@shopify/polaris'
import { useEffect, useState } from 'react'

const Delist = ({ isShow, onConfirm, onCancel }) => {
  const [active, setActive] = useState(isShow)

  useEffect(() => {
    setActive(isShow)
  }, [isShow])

  const handleChange = () => {
    onCancel()
  }

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title="Delist product"
      primaryAction={{
        content: 'Delist',
        destructive: true,
        onAction: onConfirm,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <p>Are you sure to delist this product? This product will be hidden from sales.</p>
      </Modal.Section>
    </Modal>
  )
}

export default Delist
