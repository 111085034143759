/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-09-21 15:58:42
 * @LastEditTime: 2022-11-26 10:29:50
 * @LastEditors: huang-chao hc18174419502@163.com
 * @FilePath: \pod-app-react\src\components\PodNotices\index.jsx
 */
import React, { Component } from 'react'
import NoticesItem from './ChildrenCom'
import { Icon } from '@shopify/polaris'
import { MobileCancelMajor } from '@shopify/polaris-icons'
import './index.scss'

class PodNotices extends Component {
  state = {
    isShowNotices: false,
  }

  static getDerivedStateFromProps(props, state) {
    return { isShowNotices: props.isShowNotices }
  }

  // 点击关闭通知
  cancelNotice = () => {
    const { closeNotice } = this.props
    closeNotice(false)
  }

  // 控制点击通知列表外部 隐藏div
  componentDidMount() {
    const { closeNotice } = this.props
    document.addEventListener('click', (e) => {
      const noticeImg = document.getElementById('noticeImg')
      if (noticeImg === e.target) {
        closeNotice(true)
      } else {
        closeNotice(false)
      }
    })
  }

  render() {
    const { isShowNotices } = this.state
    return (
      <div
        className="Notices_Container"
        style={{ display: isShowNotices ? 'block' : 'none' }}
      >
        <div className="Notices_Container_title">
          <div>Updates</div>
          <div
            className="MobileCancelMajor"
            onClick={this.cancelNotice}
          >
            <Icon
              source={MobileCancelMajor}
              color="base"
            />
          </div>
        </div>
        <NoticesItem
          tipsNum={this.props.tipsNum}
          getNewTipsNumFn={this.props.getNewTipsNumFn}
        />
      </div>
    )
  }
}

export default PodNotices
