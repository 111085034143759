/*
 * @Description: 站内信
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-05 18:12:25
 * @LastEditTime: 2023-01-06 14:35:42
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\Mail\index.jsx
 */
import { useState, useEffect, useCallback } from 'react'
import { Modal } from '@shopify/polaris'
import _ from 'lodash'
import NoticesService from '@/api/notices'
import './index.scss'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Object} message 站内信内容
 * @param {Function} onClose 关闭弹窗回调
 */

const Mail = ({ isShow, message, onClose }) => {
  const [active, setActive] = useState(isShow) // 是否显示弹窗
  const [titleHidden, setTitleHidden] = useState(true) // 是否隐藏标题

  useEffect(() => {
    setActive(isShow)
    setTitleHidden(true)
  }, [isShow])

  // 打开或关闭弹窗
  const closeModal = useCallback(() => {
    onClose()
    setActive(!active)
  }, [active])

  // 打开站内信
  const handleOpen = async () => {
    if (_.isEmpty(message)) return
    setTitleHidden(false)
    await NoticesService.readMessage({ id: message.id })
  }

  // 关闭站内信
  const handleClose = async () => {
    if (_.isEmpty(message)) return
    onClose()
    await NoticesService.readMessage({ id: message.id, type: 1 })
  }

  return (
    <Modal
      title="To best POD’s customers"
      titleHidden={titleHidden}
      open={active}
      onClose={closeModal}
    >
      <div className="Mail">
        {/* 未打开站内信时 */}
        {titleHidden && (
          <div className="Mail-Close">
            <div className="Mail-Box-Left">
              <div className="Mail-Box-Left-top"></div>
              <div className="Mail-Box-Left-Bottom"></div>
            </div>
            <div
              className="Mail-Image"
              onClick={handleOpen}
            >
              <img
                src="/images/mail/postmark.png"
                alt=""
              />
            </div>
            <div className="Mail-Box-Right">
              <div className="Mail-Box-Right-top"></div>
              <div className="Mail-Box-Right-Bottom"></div>
            </div>
            <div className="Mail-Footer">
              <div
                className="Mail-Fotter-Button"
                onClick={handleClose}
              >
                Open the envelope later
              </div>
              <span>From POD</span>
            </div>
          </div>
        )}

        {/* 打开站内信时 */}
        {!titleHidden && (
          <div className="Mail-Open">
            <h3>{message.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
            <div className="Mail-Footer">
              <img
                src="/images/mail/cat-paws.png"
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default Mail
