/*
 * @Description: 变体弹窗
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-23 09:29:39
 * @LastEditTime: 2023-02-08 13:55:25
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\ProductContentEdit\component\NewProduct\index.jsx
 */
import React, { useState, useEffect, useCallback } from 'react'
import { Modal } from '@shopify/polaris'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const NewProduct = ({ isShow, onClose, onConfirm }) => {
  const [active, setActive] = useState(isShow) // 是否显示弹窗

  useEffect(() => {
    setActive(isShow)
  }, [isShow])

  // 打开或关闭弹窗
  const handleChange = useCallback(() => {
    onClose()
    setActive(!active)
  }, [active, onClose])

  return (
    <Modal
      title="Save as a new product"
      open={active}
      onClose={handleChange}
      primaryAction={{
        content: 'Done',
        onAction: () => {
          onConfirm()
          onClose()
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <div style={{ padding: '20px' }}>
        A new order for this product has been detected and the design cannot be modified. Save the
        modified product as a new product.
      </div>
    </Modal>
  )
}

export default NewProduct
