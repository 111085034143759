import React, { Component } from 'react'
import { reqUserAuthInstall } from '../../api/install/index'
import { AppProvider, Frame, Loading } from '@shopify/polaris'
class install extends Component {
  componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    this.UserAuthInstall(params)
  }

  // 重新渲染页面
  handleTabChange = (historyUrl) => {
    const { history } = this.props
    history.push(historyUrl)
  }

  //获取当前抓取状态
  UserAuthInstall = async (par) => {
    const params = {
      shop: par.get('shop'),
      hmac: par.get('hmac'),
      host: par.get('host'),
      locale: par.get('locale'),
      session: par.get('session'),
      timestamp: par.get('timestamp'),
    }

    const response = await reqUserAuthInstall(params)

    if (response.data.code === 301) {
      window.location.href = response.data.url
    } else if (response.data.code === 302) {
      this.handleTabChange(response.data.url)
    } else {
    }
  }

  render() {
    return (
      <AppProvider>
        <div style={{ height: '100px' }}>
          <Frame>
            <Loading />
          </Frame>
        </div>
      </AppProvider>
    )
  }
}

export default install
