/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-28 17:18:49
 * @LastEditors: huang-chao hc18174419502@163.com
 * @LastEditTime: 2022-11-28 18:03:09
 * @FilePath: \pod-app-react\src\components\GlobalSpinner\index.jsx
 * @Description: 全局Spinner加载
 */
import React from 'react'
import { Spinner } from '@shopify/polaris'

const GlobalSpinner = ({ isShow, size }) => {
  return (
    isShow && (
      <Spinner
        accessibilityLabel="Spinner example"
        size={size}
      />
    )
  )
}

export default GlobalSpinner
