/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-12-12 19:06:54
 * @LastEditTime: 2023-01-05 18:02:07
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Catalog\ChildrenPages\ConfugureDesign\components\Layers\index.jsx
 */
import { useEffect, useState } from 'react'
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc'
import { Tooltip } from '@shopify/polaris'
import _ from 'lodash'
import { DragIcon, HideIcon, DuplicateIcon, UndoIcon, LockIcon, DeleteIcon } from '../ViewComponent'
import './index.scss'

/**
 * 接受参数说明
 * @param {Array} items 图层列表
 * @param {Object} currentLayer 当前选中图层
 * @param {Function} onSortEnd 拖拽后的回调
 * @param {Function} handleClickLayer 点击图层
 * @param {Function} handleView 显示或隐藏图层
 * @param {Function} handleDuplicate 复制图层
 * @param {Function} handleUndoObj 撤销
 * @param {Function} handleLock 锁定图层
 * @param {Function} handleDelLayer 删除图层
 */
const LayersComponent = ({
  items,
  currentLayer,
  onSortEnd,
  handleClickLayer,
  handleView,
  handleDuplicate,
  handleUndoObj,
  handleLock,
  handleDelLayer,
}) => {
  const [layerList, setLayerList] = useState([])
  // console.log('items', items)
  useEffect(() => {
    setLayerList(items)
  }, [items])

  // 图层html
  const DragHandle = sortableHandle(() => <DragIcon />)
  const SortableItem = SortableElement(({ layer, index }) => (
    <li
      className="Design-Layer-Item"
      tabIndex={index}
      onClick={() => {
        handleClickLayer(layer.IText || layer.Image)
      }}
    >
      <div className="Design-Layer-Item-Box">
        {/* 图层active */}
        {currentLayer && currentLayer.id === layer.id && (
          <div className="Design-Layer-Active"></div>
        )}

        {/* 图层拖拽 */}
        <DragHandle />

        {/* 图层缩略图或文本内容 */}
        <div className="Design-Layer-thumbnail">
          {layer.type === 'Image' && <img src={layer.content} />}
          {layer.type === 'Text' && (
            <Tooltip
              content={layer.content}
              preferredPosition="above"
              dismissOnMouseOut
            >
              <span>{layer.content}</span>
            </Tooltip>
          )}
        </div>

        {/* 图层类型 */}
        <div className="Design-Layer-Type">{layer.type}</div>
      </div>
      <div
        className="Design-Layer-Item-Box"
        style={{ justifyContent: 'flex-end' }}
      >
        {/* 显示或隐藏图层 */}
        <div
          className="Design-Layer-Box-Icon"
          onClick={(e) => {
            handleView(layer, e)
          }}
        >
          <HideIcon
            isHide={layer.isHide}
            isLock={layer.isLock}
          />
        </div>

        {/* 复制图层 */}
        <div
          className="Design-Layer-Box-Icon"
          onClick={(e) => {
            handleDuplicate(layer, e)
          }}
        >
          <DuplicateIcon
            isHide={layer.isHide}
            isLock={layer.isLock}
          />
        </div>

        {/* 撤销图层 */}
        <div
          className="Design-Layer-Box-Icon"
          onClick={(e) => {
            handleUndoObj(layer, e)
          }}
        >
          <UndoIcon
            isHide={layer.isHide}
            isLock={layer.isLock}
          />
        </div>

        {/* 锁定图层 */}
        <div
          className="Design-Layer-Box-Icon"
          onClick={(e) => {
            handleLock(layer, e)
          }}
        >
          <LockIcon
            isHide={layer.isHide}
            isLock={layer.isLock}
          />
        </div>

        {/* 删除图层 */}
        <div
          className="Design-Layer-Box-Icon"
          onClick={(e) => {
            handleDelLayer({ layer, event: e })
          }}
        >
          <DeleteIcon
            isHide={layer.isHide}
            isLock={layer.isLock}
          />
        </div>
      </div>
    </li>
  ))

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {_.map(items, (layer, index) => {
          return (
            <SortableItem
              key={layer.id}
              index={index}
              layer={layer}
            />
          )
        })}
      </ul>
    )
  })
  return (
    <SortableList
      items={layerList}
      onSortEnd={onSortEnd}
      useDragHandle
    />
  )
}

export default LayersComponent
