import { useEffect, useState } from 'react'
import _ from 'lodash'
import './index.scss'

const PodAvatar = ({ name, onClick }) => {
  const [showName, setShowName] = useState('')

  useEffect(() => {
    if (name) {
      const list = _.split(name, ' ')
      const names = _.map(list, (item) => {
        return _.toUpper(_.slice(item, 0, 1))
      })
      setShowName(_.join(names, ''))
    } else {
      setShowName('')
    }
  }, [name])

  return (
    <div
      className="Pod-Avatar"
      onClick={onClick}
    >
      <span>{showName}</span>
    </div>
  )
}

export default PodAvatar
