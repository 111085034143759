/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-07-26 11:33:35
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2023-01-03 09:55:59
 * @FilePath: \pod-app-react\src\api\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import Config from '@/utils/config'
import { getStore } from '@/utils/utils'
import { getSessionToken } from '@shopify/app-bridge-utils'
import AppBridge from '../utils/appBridge'
export function request(config) {
  let url = ''
  let ContentType = ''
  if (config.type === 'upload') {
    url = process.env.REACT_APP_ADMIN_API_SERVER
  }
  if (!config.contentType) {
    ContentType = config.contentType
  }
  const instance = axios.create({
    baseURL: url || process.env.REACT_APP_API_SERVER,
    timeout: Config.timeout,
  })
  //request
  instance.interceptors.request.use(
    (config) => {
      config.headers = {
        'Content-Type': ContentType,
      }
      if (config.url.indexOf('/shopify/app') !== -1) {
        return config
      }
      if (AppBridge.app) {
        return (
          AppBridge.app &&
          getSessionToken(AppBridge.app).then((token) => {
            config.headers = {
              'Content-Type': ContentType,
              Authorization: `ShopifyBridge ${token}`,
            }
            return config
          })
        )
      } else {
        // // 如果是开发环境或者通过后台访问
        let authToken = getStore('authToken')
        if (!AppBridge.exist() && authToken) {
          config.headers = {
            'Content-Type': ContentType,
            Authorization: 'ShopifyAdmin ' + authToken,
          }
          return config
        }
      }
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )
  //response
  instance.interceptors.response.use(
    (res) => {
      const { code, data } = res.data
      if (code === '200' || code === 200) {
        return Promise.resolve(data)
      } else if (code === 301 || code === 302) {
        return Promise.resolve(res)
      }
      return Promise.reject(res)
    },
    (err) => {}
  )
  return instance(config)
}
