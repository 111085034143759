import React, { Component } from 'react'
import {
  Page,
  Card,
  Tabs,
  IndexTable,
  TextField,
  TextStyle,
  Button,
  Filters,
  ChoiceList,
  Icon,
  Pagination,
  Badge,
  Toast,
  SkeletonPage,
  Layout,
  TextContainer,
  SkeletonBodyText,
  Popover,
  ActionList,
  Spinner,
} from '@shopify/polaris'
import { SortMinor, Columns3Major } from '@shopify/polaris-icons'
import _ from 'lodash'
// 产品详情item
import ProductItems from './ChildrenPages/ProductItems'
// 批量下单
import CheckoutsBulk from './ChildrenPages/CheckoutsBulk'
// 支付
import Pay from './ChildrenPages/Pay'
import ShippingModal from '@/pages/Orders/ChildrenPages/OrderPayment/components/ShippingModal'
import ProductManageTag from '../ProductContentEdit/component/ProductManageTag'
import { getProductOrderShippingMethodSave } from '../../api/orderBuild/index'
import { getOrderList, getOrderTagsAdd, getOrderTagsDelete } from '../../api/orderList/index'
import FavorableComm from '@/components/FavorableComm'

import './index.scss'

const list = [
  { title: 'Order' },
  { title: 'Customer' },
  // { title: 'Total' },
  { title: 'Items' },
  { title: 'Fulfillment status' },
  { title: 'Cost' },
  { title: 'Tracking' },
  { title: 'Payment status' },
  { title: 'Delivery method' },
  { title: 'Tags' },
  { title: 'Type' },
  { title: 'Date' },
  { title: 'Payment status - Shopify' },
  { title: 'Fulfillment status - Shopify' },
  { title: 'Order - Shopify' },
  { title: '' },
]

class MyOrders extends Component {
  state = {
    initPage: true, // 是否初始化页面
    tabSelected: 0, // 当前Tab（索引）
    searchParam: {
      queryValue: '', //关键词内容搜索
      status: [],
      dateStatus: [],
      orderByStatus: [],
    },
    statusList: [
      // { label: 'Open', value: 0 },
      // { label: 'Archived', value: 1 },
      // { label: 'Canceled', value: 2 },
      { label: 'Unpaid', value: 'unpaid' },
      { label: 'Paid', value: 'paid' },
      { label: 'Refunded', value: 'refunded' },
    ],
    dateList: [
      { label: 'Yesterday', value: 1 },
      { label: 'Last 7 days', value: 2 },
      { label: 'Last 30 days', value: 3 },
      { label: 'Last 60 days', value: 4 },
      { label: 'Last 90 days', value: 5 },
    ],
    sortList: [
      { label: 'Customer A-Z', value: 1 },
      { label: 'Customer Z-A', value: 2 },
      { label: 'Date (oldest first)', value: 3 },
      { label: 'Date (newest first)', value: 4 },
      // { label: 'Total (low to high)', value: 5 },
      // { label: 'Total (high to low)', value: 6 },
      { label: 'Order (ascending)', value: 7 },
      { label: 'Order (descending)', value: 8 },
    ],
    promotedBulkList: [
      {
        content: 'Checkouts',
        onAction: () => this.handleCheckouts(),
      },
      {
        content: 'Add tags',
        onAction: () => this.handleSetManageTags(true),
      },
      {
        content: 'Remove tags',
        onAction: () => this.handleTagsDelete(),
      },
    ],
    page: 1,
    size: 20,
    total: 0,
    hasPrevious: false, // 是否存在上一页
    hasNext: true, // 是否存在下一页
    orderListData: [], //列表数据

    moreDataSwitch: false, // 开关，是否允许开启更多数据选择
    isAllowSelectAllItems: false, // 是否选择更多的数据 (未显示的)
    allResourcesSelected: false, // (当前页面,并且包括未显示的) 全选
    selectedResources: [],
    filterStatus: [],
    paymentTextStatus: '', //paymentStatus文本的颜色状态
    paymentTextStatusShopify: '', //paymentTextStatusShopify文本的颜色状态
    fulfillmentTextStatus: '', //fulfillmentTextStatus文本的颜色状态
    fulfillmentTextStatusShopify: '', //fulfillmentTextStatusShopify文本的颜色状态
    showToast: false, //更新toast
    showToastText: 'success',
    showToastIserror: 'error',
    orderFilterStatus: [], //订单状态
    listHead: list,
    ShippingCheckedVal: 'select a shipping way',
    defaultShow: [
      'Order',
      'Customer',
      // 'Total',
      'Items',
      'Fulfillment status',
      'Cost',
      'Tags',
      'Tracking',
      'Delivery method',
    ], //默认显示数据
    orderId: 0, //订单id
    shippingId: 0, //物流id
    shippingData: [], //物流方式数据
    isShowShippingProductModal: false, //物流方式弹窗
    draft: false, //保存为草稿,
    manageTags: false, // 标签弹窗
    tags: [],

    popoverActive: false,
    isSpinner: false,

    isShowFavora: false, // 引导好评弹窗

    // 批量下单
    showCheckout: false,
    checkList: [],
    // 支付弹窗
    showPay: false,
    totalPrice: 0,
  }

  componentDidMount() {
    // const routeState = this.props.history.location.state
    // if (routeState !== undefined && routeState !== null) {
    //   if (routeState.orderType === 'sample' && routeState.favoraStatus === 2) {
    //     this.setState({
    //       isShowFavora: true,
    //     })
    //   }
    // }
    this.getOrderListData()
    let allHead = list
    let selectHeadData = []
    for (let i = 0; i < allHead.length; i++) {
      for (let y = 0; y < this.state.defaultShow.length; y++) {
        if (allHead[i].title === this.state.defaultShow[y]) {
          selectHeadData.push({
            title: allHead[i].title,
          })
        }
      }
    }

    this.setState({
      listHead: selectHeadData,
    })
  }

  // 切换Tab
  handleTabChange = (index) => {
    let status = []
    switch (index) {
      case 0:
        status = ''
        break
      case 1:
        status = ['Unfulfilled']
        break
      case 2:
        status = ['Fulfilled']
        break
      default:
    }
    if (index === 2) {
      this.setState({
        promotedBulkList: [
          {
            content: 'Add tags',
            onAction: () => this.handleSetManageTags(true),
          },
          {
            content: 'Remove tags',
            onAction: () => this.handleTagsDelete(),
          },
        ],
      })
    } else {
      let clonePromotedBulkList = _.cloneDeep(this.state.promotedBulkList)
      if (clonePromotedBulkList.length < 3) {
        clonePromotedBulkList.unshift({
          content: 'Checkouts',
          onAction: () => this.handleCheckouts(),
        })
        this.setState({
          promotedBulkList: clonePromotedBulkList,
        })
      }
    }
    this.setState(
      {
        filterStatus: status,
        tabSelected: index,
        page: 1,
        searchParam: {
          queryValue: '',
          status: [],
          dateStatus: [],
          orderByStatus: [],
        },
        isSpinner: true,
      },
      () => {
        this.getOrderListData()
      }
    )
  }

  // 输入文本搜索
  handleFiltersQueryChange = (searchVal) => {
    const searchParam = this.state.searchParam
    searchParam.queryValue = searchVal
    this.setState(
      {
        searchParam,
      },
      () => {
        this.searchData()
      }
    )
  }

  // 清空输入框
  handleQueryValueRemove = () => {
    const searchParam = this.state.searchParam
    searchParam.queryValue = ''
    this.setState(
      {
        searchParam,
      },
      () => {
        this.searchData()
      }
    )
  }

  // 清空所有搜索条件
  handleFiltersClearAll = () => {}
  // 选择状态
  changeStatus = (value) => {
    let status = []
    switch (value[0]) {
      case 0:
        status = ['Open']
        break
      case 1:
        status = ['Archived']
        break
      case 2:
        status = ['Canceled']
        break
      default:
    }

    const searchParam = this.state.searchParam
    searchParam.status = value
    this.setState(
      {
        searchParam,
        filterStatus: status,
        isSpinner: true,
      },
      () => {
        this.searchData()
      }
    )
  }

  // 清空状态
  clearStatus = () => {
    const searchParam = this.state.searchParam
    searchParam.status = []
    this.setState(
      {
        searchParam,
        tabSelected: 0, // 清空后重置0，查询全部数据
        filterStatus: [],
        isSpinner: true,
      },
      () => {
        this.searchData()
      }
    )
  }

  // 选择时间段
  changeDate = (value) => {
    const searchParam = this.state.searchParam
    searchParam.dateStatus = value
    this.setState(
      {
        searchParam,
        isSpinner: true,
      },
      () => {
        this.searchData()
      }
    )
  }

  // 清空时间段
  clearDate = () => {
    const searchParam = this.state.searchParam
    searchParam.dateStatus = []
    this.setState(
      {
        searchParam,
      },
      () => {
        this.searchData()
      }
    )
  }

  // 选择排序方式
  changeOrder = (value) => {
    const searchParam = this.state.searchParam
    searchParam.orderByStatus = value
    this.setState(
      {
        searchParam,
        isSpinner: true,
      },
      () => {
        this.searchData()
      }
    )
  }

  // 搜索关键字、状态、日期、排序
  searchData = _.throttle(() => {
    this.setState(
      {
        page: 1,
        isSpinner: true,
      },
      () => {
        this.getOrderListData()
      }
    )
  }, 300)

  // 分页
  hasPreviousRequest = (type) => {
    switch (type) {
      case 'Previous':
        this.setState({ page: Number(this.state.page) - 1 }, () => {
          this.getOrderListData()
        })
        break
      case 'Next':
        this.setState({ page: Number(this.state.page) + 1 }, () => {
          this.getOrderListData()
        })
        break
      default:
        break
    }
  }

  // 获取列表数据
  getOrderListData = async () => {
    const searchParam = this.state.searchParam
    const params = {
      perPage: this.state.size,
      pageNum: this.state.page,
      title: searchParam.queryValue,
      status: searchParam.status.length > 0 ? searchParam.status[0] : '',
      dateStatus: searchParam.dateStatus.length > 0 ? searchParam.dateStatus[0] : '',
      orderByStatus: searchParam.orderByStatus.length > 0 ? searchParam.orderByStatus[0] : '',
      type: this.state.tabSelected + 1,
    }
    // 参数值为空则删除参数
    if (!params.title) {
      delete params.title
    }
    if (!params.status && params.status !== 0) {
      delete params.status
    }
    if (!params.dateStatus) {
      delete params.dateStatus
    }
    if (!params.orderByStatus) {
      delete params.orderByStatus
    }
    const res = await getOrderList(params)
    if (res && res.listData) {
      this.setState({
        orderListData: res.listData,
        total: res.count,
        initPage: false,
      })

      setTimeout(() => {
        this.setState({
          isSpinner: false,
        })
      }, 1000)

      // 设置上一页按钮是否可点击
      if (this.state.page > 1) {
        this.setState({
          hasPrevious: true,
        })
      } else {
        this.setState({
          hasPrevious: false,
        })
      }

      // 设置下一页按钮是否可点击
      const maxPage = Math.ceil(res.count / this.state.size)
      if (this.state.page < maxPage) {
        this.setState({
          hasNext: true,
        })
      } else {
        this.setState({
          hasNext: false,
        })
      }
    }
  }

  // 前往订单支付页面
  handleToDetail = (e, id, orderType) => {
    e.stopPropagation()
    const t = orderType === 2 ? 'sample' : 'basic'
    // this.props.history.push('orderPayment?type=' + t + '&order=' + id)
    this.props.history.push({
      pathname: '/orderPayment',
      state: {
        type: t,
        id,
      },
    })
  }

  // 前往新版订单支付页面
  handleToDetailNew = (e, id, orderType) => {
    e.stopPropagation()
    const t = orderType === 2 ? 'sample' : 'basic'
    // this.props.history.push('orderPayment?type=' + t + '&order=' + id)
    this.props.history.push({
      pathname: '/orderPaymentNew',
      state: {
        type: t,
        id,
      },
    })
  }

  // 显示买家信息及地址
  handleShowCustomerData(e, index) {
    e.stopPropagation()
    const list = this.state.orderListData
    list[index].isShowCustomer = !list[index].isShowCustomer
    this.setState({
      orderListData: list,
    })
  }

  // 设置订单物流状态
  handleTextStatus = (value) => {
    let _status = ''
    switch (value) {
      case 'Unpaid':
        _status = 'Warning'
        break
      case 'Paid':
        _status = 'Success'
        break
      case 'Renfunded':
        _status = ''
        break
      case 'Partially refunded':
        _status = ''
        break
      case 'Voided':
        _status = ''
        break
      case 'Partially paid':
        _status = 'Attention'
        break
      case 'Unfulfilled':
        _status = 'Attention'
        break
      case 'Fulfilled':
        _status = ''
        break
      case 'Partially fulfilled':
        _status = 'Success'
        break
      case 'Canseled':
        _status = ''
        break
      case 'Pending':
        _status = 'Attention'
        break
      case 'Producing':
        _status = 'Info'
        break
      default:
        _status = ''
        break
    }
    return _status
  }

  // 设置订单状态
  handleTextStatusShopify = (value) => {
    let _status_shopify = ''
    switch (value) {
      case 'Payment pending':
        _status_shopify = 'Warning'
        break
      case 'Paid':
        _status_shopify = ''
        break
      case 'Renfunded':
        _status_shopify = ''
        break
      case 'Partially refunded':
        _status_shopify = ''
        break
      case 'Voided':
        _status_shopify = ''
        break
      case 'Partially paid':
        _status_shopify = 'Attention'
        break
      default:
        _status_shopify = ''
        break
    }
    return _status_shopify
  }

  // 设置表格勾选
  handleSelectionChange = (SelectionType, toggleType, selection = 0) => {
    let data = this.state.orderListData
    let selectedData = this.state.selectedResources
    let isCheckedAllMore = false
    let isAllowSelectAll = false
    switch (SelectionType) {
      case 'single':
        if (toggleType) {
          selectedData.push(selection)
          if (this.state.moreDataSwitch) {
            if (selectedData.length === data.length) {
              isAllowSelectAll = true
            }
          }
        } else {
          let index = selectedData.indexOf(selection)
          if (index > -1) {
            selectedData.splice(index, 1)
          }
        }

        break

      case 'page':
        if (!toggleType) {
          selectedData.length = 0
        } else {
          selectedData.length = 0
          for (let i = 0; i < data.length; i++) {
            selectedData.push(data[i].id)
          }
          if (this.state.moreDataSwitch) {
            if (selectedData.length === data.length) {
              isAllowSelectAll = true
            }
          }
        }
        break

      case 'multi':
        if (selection.length !== undefined) {
          for (let i = selection[0]; i <= selection[1]; i++) {
            let inArrayKey = selectedData.indexOf(data[i].record)
            if (inArrayKey === -1) {
              selectedData.push(data[i].record)
            }
          }
          if (this.state.moreDataSwitch) {
            if (selectedData.length === data.length) {
              isAllowSelectAll = true
            }
          }
        }
        break

      case 'all':
        isCheckedAllMore = toggleType
        if (this.state.moreDataSwitch && toggleType) {
          isAllowSelectAll = true
        }
        break
      default:
    }

    this.setState({
      selectedResources: selectedData,
      allResourcesSelected: isCheckedAllMore,
      isAllowSelectAllItems: isAllowSelectAll,
    })
  }

  // 设置显示列
  handleChangeColumnsStatus = (selectColumns) => {
    this.setState(
      {
        defaultShow: selectColumns,
      },
      () => {
        let allHead = list
        let selectHeadData = []
        for (let i = 0; i < allHead.length; i++) {
          for (let y = 0; y < selectColumns.length; y++) {
            if (allHead[i].title === selectColumns[y]) {
              selectHeadData.push({
                title: allHead[i].title,
              })
            }
          }
        }

        this.setState({
          listHead: selectHeadData,
        })
      }
    )
  }

  // 提示弹窗
  toggleToast = (showToastText, showToastIserror = false) => {
    this.setState({
      showToast: !this.state.showToast,
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    })
  }

  // 物流方式修改
  handleShowShippingModal = (e, id, data, shippingId) => {
    e.stopPropagation()
    this.setState({
      orderId: id,
      shippingData: data,
      shippingId: shippingId,
      isShowShippingProductModal: true,
    })
  }

  // 物流方式弹窗 - Cancel
  callShippingFn = (isShow, data, foo) => {
    let params = {
      shippingId: this.state.shippingId,
      podOrderId: this.state.orderId,
      shippingMethod: data,
    }
    const getShippingMethod = async () => {
      return await getProductOrderShippingMethodSave(params)
    }
    if (data.length > 0) {
      if (foo === 1) {
        this.toggleToast('success', false)
      }
      getShippingMethod().then((res) => {
        this.OrdersfetchData()
      })
    }
    this.setState({
      isShowShippingProductModal: false,
    })
  }

  // 物流方式弹出 - Save
  OrdersfetchData = (val) => {
    this.getOrderListData()
    // this.toggleToast('success', false)
  }

  //  保存按钮显示方法
  onShowSaveButton = (value) => {
    this.setState({
      draft: value,
    })
  }

  // 打开或关闭批量下单弹窗
  handleCheckouts = () => {
    // 查询已勾选的订单，以及订单状态必须是未支付，且存在物流方式
    const list = _.filter(this.state.orderListData, (item) => {
      return (
        _.includes(this.state.selectedResources, item.id) &&
        item.paymentStatus === 'Unpaid' &&
        !_.isEmpty(item.deliveryMethod)
      )
    })
    this.setState({
      showCheckout: !this.state.showCheckout,
      checkList: list,
    })
  }

  // 打开或关闭支付弹窗
  handlePay = (price) => {
    this.setState({
      showPay: !this.state.showPay,
    })
    if (price) {
      this.setState({
        showPay: !this.state.showPay,
        totalPrice: price || 0,
      })
    }
  }

  // 支付成功回调
  handlePaySuccess = () => {
    this.getOrderListData()
    this.setState({
      showCheckout: false,
      showPay: false,
      selectedResources: [], //支付成功后取消勾选
    })
    this.toggleToast('Payment succeeded', false)
  }

  // 打开或关闭标签弹窗
  handleSetManageTags = (value) => {
    this.setState({
      manageTags: value,
    })
  }

  // 添加标签
  handleTagsAdd = (data) => {
    this.setState(
      {
        tags: data,
      },
      async () => {
        const params = {
          id: this.state.selectedResources,
          tag: data,
        }
        const res = await getOrderTagsAdd(params)
        try {
          if (typeof res !== 'undefined') {
            this.getOrderListData()
            this.setState({
              selectedResources: [],
            })
            this.toggleToast('success', false)
          } else {
            this.toggleToast('Server Error', true)
          }
        } catch {
          this.toggleToast('Server Error', true)
        }
      }
    )
  }

  // 删除标签
  handleTagsDelete = async () => {
    let params = {
      id: this.state.selectedResources,
    }
    await getOrderTagsDelete(params)
    setTimeout(() => {
      this.getOrderListData()
      this.setState({
        selectedResources: [],
      })
      this.toggleToast('success', false)
    }, 200)
  }

  // 显示Item项
  handleShowItems(item) {
    const data = [...this.state.orderListData]
    _.forEach(data, (el) => {
      if (el.id === item.id) {
        el.showItems = !el.showItems
      } else {
        el.showItems = false
      }
    })
    this.setState({
      orderListData: data,
    })
  }

  togglePopoverActive() {
    this.setState({
      popoverActive: !this.state.popoverActive,
    })
  }

  //是否禁用物流选择按钮
  disShippingBtn(type, paymentStatus) {
    // if (type === 'Shopify' || paymentStatus === 'Paid') {
    if (paymentStatus === 'Paid') {
      return true
    } else {
      return false
    }
  }

  closeFavorableComm = () => {
    this.setState({
      isShowFavora: false,
    })
  }
  render() {
    const {
      tabSelected,
      searchParam,
      statusList,
      dateList,
      sortList,
      orderListData,
      selectedResources,
      isAllowSelectAllItems,
      allResourcesSelected,
      listHead,
      defaultShow,
      initPage,
      orderId,
      shippingData,
      isShowShippingProductModal,
      tags,
      manageTags,
      isSpinner,
      showCheckout,
      checkList,
      showPay,
      totalPrice,
    } = this.state
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={() => this.toggleToast('')}
        error={this.state.showToastIserror}
        duration={1200}
      />
    ) : null
    const TabSelectArray = [
      {
        id: 'all-customers-1',
        content: 'All',
        accessibilityLabel: 'All customers',
        panelID: 'all-customers-content-1',
      },
      {
        id: 'unfulfilled-marketing-1',
        content: 'Unfulfilled',
        panelID: 'unfulfilled-marketing-content-1',
      },
      {
        id: 'fulfilled-marketing-1',
        content: 'Fulfilled',
        panelID: 'Fulfilled-marketing-content-1',
      },
    ]
    // const promotedBulkActions = [
    //   {
    //     content: 'Checkouts',
    //     onAction: this.handleCheckouts,
    //   },
    //   {
    //     content: 'Add tags',
    //     onAction: () => this.handleSetManageTags(true),
    //   },
    //   {
    //     content: 'Remove tags',
    //     onAction: () => this.handleTagsDelete(),
    //   },
    // ]
    const initSkeletonPage = (
      <>
        <SkeletonPage
          fullWidth={true}
          primaryAction
        >
          <Layout>
            <Layout.Section>
              <Card>
                <Card.Section>
                  <TextContainer>
                    <SkeletonBodyText lines={2} />
                  </TextContainer>
                </Card.Section>
              </Card>
              <Card sectioned>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={2} />
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      </>
    )
    const rowMarkup = orderListData.map((item, index) => (
      <React.Fragment key={item.id}>
        <IndexTable.Row
          id={item.id}
          selected={selectedResources.includes(item.id)}
          position={index}
        >
          <IndexTable.Cell>
            <TextStyle variation="strong">{'#' + item.order}</TextStyle>
          </IndexTable.Cell>
          {defaultShow.indexOf('Customer') !== -1 && (
            <IndexTable.Cell>
              <div
                style={{ height: '30px', lineHeight: '30px' }}
                onClick={(e) => {
                  this.handleShowCustomerData(e, index)
                }}
              >
                {item.customer}
              </div>
            </IndexTable.Cell>
          )}

          {/* {defaultShow.indexOf('Total') !== -1 && <IndexTable.Cell>{total}</IndexTable.Cell>} */}
          {defaultShow.indexOf('Items') !== -1 && (
            <IndexTable.Cell>
              <Popover
                fixed
                fluidContent
                preferredPosition="mostSpace"
                preferredAlignment="center"
                active={item.showItems}
                activator={
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      this.handleShowItems(item)
                    }}
                  >
                    {item.items || 0} items
                  </div>
                }
                autofocusTarget="first-node"
                onClose={() => this.handleShowItems(item)}
              >
                <ProductItems orderId={item.id} />
              </Popover>
            </IndexTable.Cell>
          )}
          {defaultShow.indexOf('Fulfillment status') !== -1 && (
            <IndexTable.Cell>
              <Badge status={this.handleTextStatus(item.fulfillmentStatus)}>
                {item.fulfillmentStatus}
              </Badge>
            </IndexTable.Cell>
          )}
          {defaultShow.indexOf('Cost') !== -1 && <IndexTable.Cell>${item.cost}</IndexTable.Cell>}
          {defaultShow.indexOf('Tracking') !== -1 && (
            <IndexTable.Cell>{item.tracking}</IndexTable.Cell>
          )}
          {defaultShow.indexOf('Payment status') !== -1 && (
            <IndexTable.Cell>
              <Badge status={this.handleTextStatus(item.paymentStatus)}>{item.paymentStatus}</Badge>
            </IndexTable.Cell>
          )}
          {defaultShow.indexOf('Delivery method') !== -1 && (
            <IndexTable.Cell>
              {item.deliveryMethod.length > 0 && (
                <div className="shipping_medthod_button">
                  <Button
                    disclosure
                    onClick={(e) =>
                      this.handleShowShippingModal(e, item.id, item.deliveryMethod, item.shippingId)
                    }
                    disabled={this.disShippingBtn(item.type, item.paymentStatus)}
                  >
                    {item.deliveryMethod.map((method) => {
                      if (method.selected === 1) {
                        return method.ShippingMethod
                      } else {
                        return false
                      }
                    })}
                  </Button>
                </div>
              )}
            </IndexTable.Cell>
          )}
          {defaultShow.indexOf('Tags') !== -1 && (
            <IndexTable.Cell>
              <div className="order_tags_list">
                {tags.length > 0 &&
                  tags.map((tag, ind) => (
                    <span
                      key={ind}
                      style={{ marginRight: '5px', marginTop: '3px' }}
                    >
                      <Badge status="warning">{tag}</Badge>
                    </span>
                  ))}
              </div>
            </IndexTable.Cell>
          )}
          {defaultShow.indexOf('Type') !== -1 && <IndexTable.Cell>{item.type}</IndexTable.Cell>}
          {defaultShow.indexOf('Date') !== -1 && <IndexTable.Cell>{item.date}</IndexTable.Cell>}
          {defaultShow.indexOf('Payment status - Shopify') !== -1 && (
            <IndexTable.Cell>
              {item.paymentStatusShopify ? (
                <Badge status={this.handleTextStatus(item.paymentStatusShopify)}>
                  {item.paymentStatusShopify}
                </Badge>
              ) : (
                ''
              )}
            </IndexTable.Cell>
          )}
          {defaultShow.indexOf('Fulfillment status - Shopify') !== -1 && (
            <IndexTable.Cell>
              {item.fulfillmentStatusShopify ? (
                <Badge status={this.handleTextStatusShopify(item.fulfillmentStatusShopify)}>
                  {item.fulfillmentStatusShopify}
                </Badge>
              ) : (
                ''
              )}
            </IndexTable.Cell>
          )}
          {defaultShow.indexOf('Order - Shopify') !== -1 && (
            <IndexTable.Cell>
              {item.orderShopify ? (
                <Badge status={this.handleTextStatusShopify(item.orderShopify)}>
                  {item.orderShopify}
                </Badge>
              ) : (
                ''
              )}
            </IndexTable.Cell>
          )}
          <IndexTable.Cell>
            <Button
              primary={
                item.paymentStatus === 'Paid' ||
                item.paymentStatus === 'Partially refunded' ||
                item.paymentStatus === 'Refunded'
                  ? false
                  : true
              }
              onClick={(e) => this.handleToDetail(e, item.id, item.orderType)}
            >
              {item.paymentStatus === 'Paid' ||
              item.paymentStatus === 'Partially refunded' ||
              item.paymentStatus === 'Refunded'
                ? 'Check'
                : 'Order'}
            </Button>
            {/* <Button
              primary={item.paymentStatus === 'Paid' ? false : true}
              onClick={(e) => this.handleToDetailNew(e, item.id, item.orderType)}
            >
              {item.paymentStatus === 'Paid' ? 'Check new' : 'Order new'}
            </Button> */}
          </IndexTable.Cell>
        </IndexTable.Row>
        <tr
          style={{
            display: item.isShowCustomer ? 'revert' : 'none',
            borderTop: '1px solid #d3d3d3',
          }}
        >
          <td colSpan={16}>
            <Page fullWidth>
              <Layout style={{ marginBottom: '20px' }}>
                <Layout.Section oneThird>
                  <TextField
                    label="Name"
                    type="text"
                    value={item.customerData.customerName}
                    disabled
                  />
                </Layout.Section>
                <Layout.Section oneThird>
                  <TextField
                    label="Phone number"
                    type="number"
                    value={item.customerData.phoneNumber}
                    disabled
                  />
                </Layout.Section>
                <Layout.Section oneThird>
                  <TextField
                    label="Email"
                    type="email"
                    value={item.customerData.email}
                    disabled
                  />
                </Layout.Section>
              </Layout>
              <Layout>
                <Layout.Section oneThird>
                  <TextField
                    label="Country/Region"
                    type="text"
                    value={item.customerData.country}
                    disabled
                  />
                </Layout.Section>
                <Layout.Section oneThird>
                  <TextField
                    label="Province"
                    type="text"
                    value={item.customerData.province}
                    disabled
                  />
                </Layout.Section>
                <Layout.Section oneThird>
                  <TextField
                    label="ZIP/Postal code"
                    type="text"
                    value={item.customerData.zip}
                    disabled
                  />
                </Layout.Section>
              </Layout>
              <Layout>
                <Layout.Section secondary>
                  <TextField
                    label="City"
                    type="text"
                    value={item.customerData.city}
                    disabled
                  />
                </Layout.Section>
                <Layout.Section>
                  <TextField
                    label="Street"
                    type="text"
                    value={item.customerData.address}
                    disabled
                  />
                </Layout.Section>
              </Layout>
              <Layout>
                <Layout.Section oneThird>
                  <TextField
                    label="Apartment,suite,etc.(optional)"
                    type="text"
                    value={item.customerData.suite}
                    disabled
                  />
                </Layout.Section>
                <Layout.Section oneThird></Layout.Section>
                <Layout.Section oneThird></Layout.Section>
              </Layout>
            </Page>
          </td>
        </tr>
      </React.Fragment>
    ))

    var footerContentMsg = ''
    //超过数量
    if (this.state.total > 0) {
      const startNum = (this.state.page - 1) * this.state.size + 1
      const endNum =
        this.state.page * this.state.size > this.state.total
          ? this.state.total
          : this.state.page * this.state.size
      footerContentMsg =
        'Showing ' + startNum + ' - ' + endNum + ' of ' + this.state.total + ' results'
    }

    const filters = [
      {
        key: 'filterStatus',
        label: 'Status',
        filter: (
          <ChoiceList
            choices={statusList}
            selected={searchParam.status || []}
            onChange={this.changeStatus}
          />
        ),
        shortcut: true,
      },
      {
        key: 'Date',
        label: 'Date',
        filter: (
          <ChoiceList
            choices={dateList}
            selected={searchParam.dateStatus || []}
            onChange={this.changeDate}
          />
        ),
        shortcut: true,
      },

      {
        key: 'Columns',
        label: (
          <div className="sort_by_label">
            <Icon source={Columns3Major}></Icon> Columns
          </div>
        ),
        filter: (
          <ChoiceList
            title="Columns"
            choices={[
              { label: 'Order', value: 'Order', disabled: true },
              { label: 'Customer', value: 'Customer' },
              // { label: 'Total', value: 'Total' },
              { label: 'Items', value: 'Items' },
              { label: 'Fulfillment status', value: 'Fulfillment status' },
              { label: 'Cost', value: 'Cost' },
              { label: 'Tracking', value: 'Tracking' },
              { label: 'Payment status', value: 'Payment status' },
              { label: 'Delivery method', value: 'Delivery method' },
              { label: 'Type', value: 'Type' },
              { label: 'Tags', value: 'Tags' },
              { label: 'Date', value: 'Date' },
              { label: 'Payment status - Shopify', value: 'Payment status - Shopify' },
              { label: 'Fulfillment status - Shopify', value: 'Fulfillment status - Shopify' },
              { label: 'Order - Shopify', value: 'Order - Shopify' },
            ]}
            selected={this.state.defaultShow || []}
            onChange={(value) => this.handleChangeColumnsStatus(value)}
            allowMultiple
          />
        ),
        shortcut: true,
        hideClearButton: true,
      },
      {
        key: 'Sort-By',
        label: (
          <div className="sort_by_label">
            <Icon
              source={SortMinor}
              color="base"
            />
            Sort By
          </div>
        ),
        filter: (
          <ChoiceList
            // titleHidden
            choices={sortList}
            selected={searchParam.orderByStatus || []}
            onChange={this.changeOrder}
          />
        ),
        shortcut: true,
        hideClearButton: true,
      },
    ]

    const appliedFilters = []
    // if (!isEmpty(this.state.filterStatus)) {
    //   const key = 'filterStatus'
    //   appliedFilters.push({
    //     key,
    //     label: disambiguateLabel(key, this.state.filterStatus),
    //     onRemove: () => {
    //       this.clearStatus()
    //     },
    //   })
    // }

    if (!isEmpty(searchParam.status)) {
      const key = 'filterStatus'
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, searchParam.status[0]),
        onRemove: () => {
          // this.clearDate()
          this.clearStatus()
        },
      })
    }

    // 绑定清空时间段方法
    if (searchParam.dateStatus && searchParam.dateStatus.length > 0) {
      const key = 'Date'
      appliedFilters.push({
        key,
        // label: 'Date',
        label: disambiguateLabel(key, searchParam.dateStatus[0]),
        onRemove: () => {
          this.clearDate()
        },
      })
    }

    const activator = (
      <Button
        onClick={() => this.togglePopoverActive()}
        disclosure
      >
        Create Order
      </Button>
    )

    // spinner 模块
    const sectionMarkup = (
      <Spinner
        accessibilityLabel="Loading..."
        hasFocusableParent={false}
        size="large"
      />
    )

    return (
      <div>
        {initPage ? (
          initSkeletonPage
        ) : (
          <Page
            fullWidth
            title="My POD Orders"
          >
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', right: '0rem', bottom: '1rem' }}>
                <Popover
                  active={this.state.popoverActive}
                  activator={activator}
                  autofocusTarget="first-node"
                  onClose={() => this.togglePopoverActive()}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[
                      {
                        content: 'Sample order',
                        onAction: () => {
                          // this.props.history.push('/orderPayment?type=sample')
                          this.props.history.push({
                            pathname: '/orderPayment',
                            state: {
                              type: 'sample',
                              id: undefined,
                            },
                          })
                        },
                      },
                      {
                        content: 'Basic order',
                        onAction: () => {
                          // this.props.history.push('/orderPayment?type=basic')
                          this.props.history.push({
                            pathname: '/orderPayment',
                            state: {
                              type: 'basic',
                              id: undefined,
                            },
                          })
                        },
                      },
                    ]}
                  />
                </Popover>
              </div>
            </div>
            <div className="row">
              <div className="col-12 edit_table_style">
                <Card sectioned>
                  <Tabs
                    tabs={TabSelectArray}
                    selected={tabSelected}
                    onSelect={(index) => this.handleTabChange(index)}
                  >
                    <Card.Section>
                      <div style={{ display: 'flex', padding: '2rem' }}>
                        <div
                          style={{ width: '100%' }}
                          className="sort_by_choice"
                        >
                          <Filters
                            queryPlaceholder="filter orders"
                            queryValue={searchParam.queryValue}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            onQueryChange={(value) => this.handleFiltersQueryChange(value)}
                            onQueryClear={this.handleQueryValueRemove}
                            onClearAll={this.handleFiltersClearAll}
                            // hideTags
                          />
                        </div>
                      </div>
                      {isSpinner ? (
                        sectionMarkup
                      ) : (
                        <div className="table_scroll">
                          <IndexTable
                            // resourceName={resourceName}
                            itemCount={orderListData.length}
                            selectedItemsCount={
                              allResourcesSelected ? 'All' : selectedResources.length
                            }
                            onSelectionChange={this.handleSelectionChange}
                            hasMoreItems={isAllowSelectAllItems}
                            // promotedBulkActions={promotedBulkActions}
                            promotedBulkActions={this.state.promotedBulkList}
                            headings={listHead}
                          >
                            {rowMarkup}
                          </IndexTable>
                        </div>
                      )}
                    </Card.Section>
                  </Tabs>
                  {orderListData.length > 0 && !isSpinner && (
                    <div className="order_pagination">
                      <Pagination
                        label={footerContentMsg}
                        hasPrevious={this.state.hasPrevious}
                        onPrevious={() => this.hasPreviousRequest('Previous')}
                        hasNext={this.state.hasNext}
                        onNext={() => this.hasPreviousRequest('Next')}
                      />
                    </div>
                  )}
                </Card>
              </div>
            </div>
            {toastMarkup}
          </Page>
        )}

        {/* 修改物流方式弹窗 */}
        <ShippingModal
          id={orderId}
          isShowShippingProductModal={isShowShippingProductModal}
          shippingData={shippingData}
          OrdersfetchData={this.OrdersfetchData}
          callShippingFn={this.callShippingFn}
          onShowSaveButton={this.onShowSaveButton}
        />

        {/* 批量下单 */}
        <CheckoutsBulk
          isShow={showCheckout}
          checkList={checkList}
          onClose={this.handleCheckouts}
          onConfirm={this.handlePay}
        />

        {/* 支付 */}
        <Pay
          isShow={showPay}
          price={totalPrice}
          checkList={checkList}
          onClose={() => {
            this.handleCheckouts()
            this.handlePay()
          }}
          onConfirm={this.handlePaySuccess}
        />

        {/* 修改标签 */}
        <ProductManageTag
          show={manageTags}
          setManageTags={this.handleSetManageTags}
          orderList={true}
          selectedOptionsTags={tags}
          setSelectedOptionsTags={this.handleTagsAdd}
          tagsData={[]}
        />

        {/* 引导好评组件 */}
        {
          <FavorableComm
            mark={2}
            isShowFavora={this.state.isShowFavora}
            closeFavorableComm={this.closeFavorableComm}
          />
        }
      </div>
    )

    function disambiguateLabel(key, value) {
      if (key === 'Date') {
        switch (value) {
          case 1:
            value = 'Yesterday'
            break
          case 2:
            value = 'Last 7 days'
            break
          case 3:
            value = 'Last 30 days'
            break
          case 4:
            value = 'Last 60 days'
            break
          case 5:
            value = 'Last 90 days'
            break
          default:
            break
        }
      }
      switch (key) {
        case 'taggedWith':
          return `Tagged with ${value}`
        case 'Date':
          return 'Date ' + value
        default:
          return 'Status is ' + value
      }
    }

    function isEmpty(value) {
      if (Array.isArray(value)) {
        return value.length === 0
      } else {
        return value === '' || value == null
      }
    }
  }
}

export default MyOrders
