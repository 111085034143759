import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Modal, TextContainer, Checkbox, TextField, Icon, Button } from '@shopify/polaris'
import { getProductSearch, getProductSearchAdd } from '../../../../../../api/orderBuild/index'
import Image from '@/components/Image'
import { SearchMinor } from '@shopify/polaris-icons'
import { debounce } from 'lodash'
import GlobalSpinner from '@/components/GlobalSpinner'
import _ from 'lodash'
import { ossSignatureUrl } from '@/utils/utils'
import { getProductOrderDelete } from '@/api/orderBuild/index'
const SelProductModal = (props) => {
  const [searchProduct, setSearchProduct] = useState('')
  const {
    isShowSelProductModal,
    callSelProductFn,
    onShowSaveButton,
    OrdersfetchData,
    searchProductProps,
    handleInputOrderSearchProps,
    handleClearButtonClickProps,
    urlType,
    orderId,
    orderLists,
    // variantIds, // 已添加的产品
  } = props
  const [list, setList] = useState([])
  const [childLength, setChildLength] = useState(0)
  const [variantIdList, setVariantIdList] = useState([])
  const [deleteIds, setDeleteIds] = useState([]) // 需要删除的变体id
  const [isShow, setIsShow] = useState(false)
  useEffect(() => {
    // const checkboxDOM = document.getElementsByClassName('product_heading')[0]
    // if (!_.isNil(checkboxDOM) && variantIds.length > 0) {
    //   checkboxDOM.getElementsByClassName('Polaris-Checkbox__Input')[0].className =
    //     'Polaris-Checkbox__Input Polaris-Checkbox__Input--indeterminate'
    // }
    let isMount = true
    if (isMount && list) {
      //  子选择数量
      let childL = []
      // 变体Id
      let vId = []
      list.length > 0 &&
        list.map((item) => {
          return item.variantData.filter((data) => {
            if (data.checked) {
              vId.push(data.variantId)
              return childL.push(data.checked)
            }
            return data.checked
          })
        })
      setVariantIdList(vId)
      setChildLength(childL.length)
    }
    return () => (isMount = false)
  }, [list])

  const handleChange = () => {
    callSelProductFn(false)
    setList([])
    setChildLength(0)
    setVariantIdList([])
    setSearchProduct('')
    // 清空父级文本
    handleClearButtonClickProps()
  }

  const handleBounce = useCallback(
    async (value) => {
      let params = {
        perPage: 20,
        pageNum: 1,
        title: value,
      }
      if (value !== '') {
        const res = await getProductSearch(params)
        // if (variantIds.length > 0) {
        //   res.listData.forEach((list) => {
        //     list.variantData.forEach((item) => {
        //       if (_.includes(variantIds, item.variantId)) {
        //         item.checked = true
        //       }
        //     })
        //   })
        // }
        // 订单在已有的商品
        const variantIds = _.map(orderLists, 'variantId')
        _.forEach(res.listData, (item) => {
          if (item.image) {
            item.image = ossSignatureUrl(item.image) // 读取OSS资源
          }
          // 已有商品需要默认选中
          _.forEach(item.variantData, (el) => {
            if (_.includes(variantIds, el.variantId)) {
              el.checked = true
            } else {
              el.checked = false
            }
          })
          // 已有商品父级需要选中
          const checkedList = _.filter(item.variantData, (o) => {
            return o.checked
          })
          if (checkedList.length === 0) {
            item.checked = false
          } else if (checkedList.length === item.variantData.length) {
            item.checked = true
          } else {
            item.checked = 'indeterminate'
          }
        })

        setList(res.listData)
        setIsShow(false)
      } else {
        setList([])
        setIsShow(false)
        return
      }
    },
    [orderLists]
  )

  const debouncedSearch = useMemo(
    () =>
      debounce((val) => {
        handleBounce(val)
      }, 750),
    [handleBounce]
  )
  const handleInputChange = useCallback(
    (value) => {
      setIsShow(true)

      setSearchProduct(value)
      debouncedSearch(value)
      handleInputOrderSearchProps(value)
    },
    [debouncedSearch, handleInputOrderSearchProps]
  )

  //文本清空
  const handleClearButtonClick = () => {
    setSearchProduct('')
    handleClearButtonClickProps()
    setList([])
  }

  // 父级传的文本框值
  useEffect(() => {
    let isMount = true
    if (isMount && searchProductProps !== '') {
      setSearchProduct(searchProductProps)
      handleInputChange(searchProductProps)
    }
    return () => (isMount = false)
  }, [searchProductProps, handleInputChange])

  // 父级选中
  const handleHeadingChange = (id) => {
    let data = list.map((item) => {
      if (item.id === id) {
        const variantIds = _.map(orderLists, 'variantId')
        let ids = [...deleteIds]
        return {
          ...item,
          checked: !item.checked,
          variantData: item.variantData.map((child) => {
            // 已有商品取消勾选时，需要删除商品
            if (child.checked && _.includes(variantIds, child.variantId)) {
              ids.push(child.variantId)
              setDeleteIds(ids)
            } else {
              ids = _.filter(ids, (variantId) => {
                return variantId !== child.variantId
              })
              setDeleteIds(ids)
            }
            return { ...child, checked: !item.checked }
          }),
        }
      } else {
        return item
      }
    })
    setList(data)
  }
  // 子项选中
  const handleChildrenChange = (fatherId, childId) => {
    // 遍历父级
    let data = list.map((item, key1) => {
      if (key1 === fatherId) {
        // 选择子项数量
        let count = 0
        const variantIds = _.map(orderLists, 'variantId')
        let ids = [...deleteIds]

        let childData = item.variantData.map((child, cKey) => {
          // 订单已有商品如果取消勾选，需要将其删除
          if (child.checked && _.includes(variantIds, child.variantId)) {
            ids.push(child.variantId)
          } else {
            // 订单已有商品如果勾选，不需要删除
            ids = _.filter(ids, (variantId) => {
              return variantId !== child.variantId
            })
          }
          setDeleteIds(ids)
          if (cKey === childId) {
            if (!child.checked) count++
            return { ...child, checked: !child.checked }
          } else {
            if (child.checked) count++
            return child
          }
        })
        // 父级checked状态
        if (count > 0) {
          item.checked = 'indeterminate'
        }
        // count数量=子项的长度 父级复选为true
        if (item.variantData.length === count) {
          item.checked = true
        }
        // count数量为0 父级复选为false
        if (count === 0) {
          item.checked = false
        }

        return {
          ...item,
          variantData: childData,
        }
      } else {
        return item
      }
    })
    setList(data)
  }
  // 产品添加
  const handleProductUpdate = async () => {
    let params = {
      id: orderId,
      variantId: variantIdList,
      orderType: urlType === 'basic' ? 1 : 2,
    }

    // 订单已有商品取消勾选，需要将其删除
    if (!_.isEmpty(deleteIds)) {
      const detailIds = _.filter(orderLists, (item) => {
        return _.includes(deleteIds, item.variantId)
      })
      await getProductOrderDelete({ detailId: _.map(detailIds, 'detailId') })
    }

    const variantIds = _.map(orderLists, 'variantId')
    params.variantId = _.difference(variantIdList, variantIds)
    // 订单中没有的商品才可进行添加
    if (!_.isEmpty(params.variantId)) {
      const res = await getProductSearchAdd(params)
      OrdersfetchData('basic', res.id)
    } else {
      OrdersfetchData('basic', orderId)
    }
    onShowSaveButton(true)
    handleChange()
  }

  return (
    <Modal
      open={isShowSelProductModal}
      onClose={handleChange}
      title="Select product"
    >
      <div>
        <div style={{ padding: '2rem 2rem 0 2rem' }}>
          <TextContainer>
            <TextField
              id="searchProduct"
              value={searchProduct}
              onChange={(e) => handleInputChange(e)}
              placeholder="search product"
              prefix={<Icon source={SearchMinor} />}
              clearButton
              autoFocus
              onClearButtonClick={handleClearButtonClick}
            />
          </TextContainer>
        </div>
        <GlobalSpinner
          isShow={isShow}
          size={'small'}
        />
        {!isShow && (
          <div className="select_product_scroll">
            <div
              className="product_heading"
              style={{ fontWeight: 500, borderTop: '0.1rem solid var(--p-divider)' }}
            >
              <div
                className="product_variant_variant"
                style={{ paddingLeft: '30px' }}
              >
                Variant
              </div>
              <div className="product_variant_inventory">Inventory</div>
              <div className="product_variant_price">Cost</div>
            </div>
            {list.length > 0 ? (
              list.map((item, ind) => (
                <div
                  key={ind}
                  className="product_tree"
                >
                  <div className="product_heading">
                    <Checkbox
                      checked={item.checked}
                      onChange={() => handleHeadingChange(item.id)}
                    />
                    <div className="product_variant_image">
                      {/* <img src={item.image} alt={item.title} />  */}
                      <Image
                        src={item.image}
                        alt={process.env.REACT_APP_ADMIN_API_SERVER + item.title}
                        size="40"
                      />
                    </div>
                    <div className="product_variant_title">{item.title}</div>
                  </div>

                  {item.variantData.map((data, inds) => (
                    <div
                      key={inds}
                      className="product_variant_child"
                    >
                      <div className="product_children">
                        <Checkbox
                          checked={data.checked}
                          onChange={() => handleChildrenChange(ind, inds)}
                        />
                        <div className="product_variant_variant">
                          {data.color + ' / ' + data.size}
                        </div>
                        <div className="product_variant_inventory">{data.inventory}</div>
                        <div className="product_variant_price">
                          {data.country} {data.moneyFormat} {data.cost}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div style={{ padding: '2rem' }}>No results were found for "{searchProduct}"</div>
            )}
          </div>
        )}
      </div>
      {/* </Modal.Section> */}

      <Modal.Section>
        <div className="select_button_variant">
          <span style={{ fontWeight: 500, fontSize: '18px', color: 'rgba(32, 34, 35, 0.5)' }}>
            {childLength} variant selected
          </span>
          <div className="select_button_variant_r">
            <Button onClick={() => handleChange()}>Cancel</Button>
            <Button
              primary
              onClick={() => handleProductUpdate()}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  )
}

export default SelProductModal
