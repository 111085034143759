/*
 * @Description: 产品内容编辑图片列表
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-10-22 10:19:18
 * @LastEditTime: 2023-01-17 18:03:13
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\ProductImages\index.jsx
 */

import React from 'react'
import {
  Icon,
  // Spinner
} from '@shopify/polaris'
import { DeleteMajor } from '@shopify/polaris-icons'
// import LazyLoad from 'react-lazyload'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import './index.scss'

const ProductImages = ({ index, isDel, image, moveCard, onDelete }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'image',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard({ image, oldIndex: dragIndex, newIndex: hoverIndex })
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => {
      return { id: index, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const handleDelFinishedImg = (src) => {
    onDelete(src)
  }

  return (
    <div className="finishedImg">
      <img
        style={{
          width: '100%',
          height: '100%',
          opacity,
        }}
        src={image.url}
        alt=""
        ref={ref}
        data-handler-id={handlerId}
      />

      {isDel && (
        <div
          className="delIcon"
          onClick={() => handleDelFinishedImg(image.src)}
        >
          <Icon
            source={DeleteMajor}
            color="base"
          />
        </div>
      )}
    </div>
  )
}

export default ProductImages
