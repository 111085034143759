/*
 * @Description: Q & A 折叠面板项
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-11 14:42:48
 * @LastEditTime: 2023-01-11 14:46:30
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Home\components\CollapsibleItem\index.jsx
 */
import { Icon, Collapsible, TextContainer } from '@shopify/polaris'
import { ChevronDownMinor, ChevronUpMinor } from '@shopify/polaris-icons'

const CollapsibleItem = ({ item, onClick }) => {
  return (
    <div className="Question-Collapsible">
      <div
        className="Question-Title"
        onClick={onClick}
      >
        <div className="Question-Title-Text">
          <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
            <Icon
              source={item.icon}
              color="base"
            />
          </div>
          <span>{item.question}</span>
        </div>
        <div style={{ display: 'inline-block', float: 'right' }}>
          <Icon
            source={item.open ? ChevronUpMinor : ChevronDownMinor}
            color="base"
          />
        </div>
      </div>
      <Collapsible
        open={item.open}
        id="basic-collapsible"
        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
        expandOnPrint
      >
        <TextContainer>
          <p
            style={{ marginTop: '1rem' }}
            dangerouslySetInnerHTML={{ __html: item.answers }}
          ></p>
        </TextContainer>
      </Collapsible>
    </div>
  )
}

export default CollapsibleItem
