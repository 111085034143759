import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Image from '@/components/Image'
import { Checkbox } from '@shopify/polaris'
// 引入oss请求
import { ossSignatureUrl } from '@/utils/utils'
import './index.scss'

/**
 * 参数说明
 * @param {Array} data 变体列表
 * @param {Array} checkVariants 所选变体列表
 * @param {String} country 国家
 * @param {String} moneyFormat 货币符号
 * @param {String} imageUrl 图片地址
 * @param {Function} onChange 点击勾选的回调
 */
const VariantsTable = ({ data, country, moneyFormat, imageUrl, checkVariants, onChange }) => {
  // 将变体数据深拷贝一次，以防污染整体变体数据
  const variantData = _.cloneDeep(data)
  _.forEach(variantData, (item) => {
    item.checked = !!_.get(
      _.find(checkVariants, (el) => {
        return el.variantId === item.variantId
      }),
      'checked'
    )
    return item
  })
  const [isCheckedAll, setIsCheckedAll] = useState(false) // 是否全选
  const [checkCount, setCheckCount] = useState(0) // 所选数量
  const [tableData, setTableData] = useState(variantData) // 表格数据
  const [defaultImage, setDefaultImage] = useState(imageUrl)

  useEffect(() => {
    const fetchData = async () => {
      if (defaultImage) {
        const image = ossSignatureUrl(defaultImage, {
          'x-oss-process': 'image/resize,w_48',
        })
        setDefaultImage(image)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const checkList = _.filter(tableData, (item) => {
      return item.checked
    })
    setCheckCount(checkList.length) // 设置所选数量
    setIsCheckedAll(!!checkList.length) // 是否全选
    initData()
    // eslint-disable-next-line
  }, [tableData])

  // 点击全选
  const handleAllCheckboxChange = () => {
    // 半选
    if (isCheckedAll === 'indeterminate') {
      setIsCheckedAll(true)
      const newData = tableData.map((item) => {
        item.checked = true
        return item
      })
      setTableData(newData)
      setCheckCount(tableData.length)
    } else {
      setIsCheckedAll(!isCheckedAll)
      const newData = _.map(tableData, (item) => {
        item.checked = !isCheckedAll
        return item
      })
      setTableData(newData)
      setCheckCount(!isCheckedAll && tableData ? tableData.length : 0)
    }
    const list = _.filter(tableData, (item) => {
      return item.checked
    })
    onChange(list) // 勾选时向父组件传值
  }

  // 点击行选择
  const handleSingleCheckboxChange = (index, checked) => {
    // 设置行勾选状态
    tableData[index].checked = !checked
    setTableData([...tableData])

    // 设置所选数量
    const checkList = tableData.filter((item) => {
      return item.checked
    })
    setCheckCount(checkList.length)

    // 设置全选按钮状态
    if (checkList.length === 0) {
      setIsCheckedAll(false)
    } else if (checkList.length > 0 && checkList.length < tableData.length) {
      setIsCheckedAll('indeterminate')
    } else {
      setIsCheckedAll(true)
    }
    const list = _.filter(tableData, (item) => {
      return item.checked
    })
    onChange(list) // 勾选时向父组件传值
  }

  const headings = (
    <tr>
      <td
        width="10%"
        colSpan="2"
      >
        <div
          className="Polaris-ButtonGroup__Item"
          style={{ margin: 0 }}
        >
          <div className="Polaris-CheckableButton Polaris-CheckableButton__CheckableButton--selectMode Polaris-CheckableButton__CheckableButton--selected">
            <div className="headings_div__3">
              <Checkbox
                label=""
                labelHidden
                checked={isCheckedAll}
                onChange={() => {
                  handleAllCheckboxChange()
                }}
              />
            </div>
            <span
              style={{ color: '#2C6ECB' }}
              className="Polaris-CheckableButton__Label"
              onClick={() => {
                handleAllCheckboxChange()
              }}
            >
              {checkCount + ' selected'}
            </span>
          </div>
        </div>
      </td>
      <td
        colSpan="4"
        style={{ textAlign: 'left' }}
      >
        <span style={{ marginLeft: '10px' }}>Shopify variants max 100</span>
      </td>
    </tr>
  )
  const tableHeaderContent = (
    <tr style={{ display: tableData && tableData.length === checkCount ? 'none' : 'table-row' }}>
      <th style={{ width: '10%' }}>
        <Checkbox
          label="All"
          labelHidden
          checked={isCheckedAll}
          onChange={() => {
            handleAllCheckboxChange()
          }}
        />
      </th>
      <th style={{ width: '10%' }}></th>
      <th style={{ width: '20%' }}>Color</th>
      <th style={{ width: '20%' }}>Size</th>
      <th style={{ width: '20%' }}>Price</th>
      <th style={{ width: '20%' }}>Inventory</th>
    </tr>
  )

  let tableTbodyContent = <tr></tr>

  const initData = () => {
    if (tableData && tableData.length > 0) {
      tableTbodyContent = _.map(tableData, (item, key) => {
        const trNode = (
          <tr key={key}>
            <td width="10%">
              <Checkbox
                labelHidden
                checked={item.checked}
                onChange={() => {
                  handleSingleCheckboxChange(key, item.checked)
                }}
              />
            </td>
            <td width="10%">
              <Image
                src={defaultImage}
                alt=""
                backgroundColor={defaultImage ? item.color : ''}
                size="48"
              />
            </td>
            <td width="20%">{item.statement || item.color}</td>
            <td width="20%">{item.size}</td>
            <td width="20%">{`${country} ${moneyFormat} ${item.cost}`}</td>
            <td width="20%">{item.inventory}</td>
          </tr>
        )
        return trNode
      })
    }
  }

  initData()

  return (
    <div className="Variant-Box">
      <table className="Variant-Table">
        <thead>{tableHeaderContent}</thead>
        <tbody>
          {tableData && tableData.length === checkCount && headings}
          {tableTbodyContent}
        </tbody>
      </table>
      <div className="Variant-Checked-Num">{`${checkCount} variants selected`}</div>
    </div>
  )
}

export default VariantsTable
