import { useEffect, useState, useCallback, useMemo } from 'react'
import {
  Card,
  Filters,
  IndexTable,
  useIndexResourceState,
  Pagination,
  DropZone,
  Toast,
  Button,
  EmptySearchResult,
  // Tooltip,
} from '@shopify/polaris'
import { useGetState } from 'ahooks'
// 骨架屏
import LoadingPage from './components/LoadingPage'
// 搜索loading
import LoadingSearch from '@/components/LoadingSearch'
// 空数据时
import EmptyFiles from './components/EmptyFiles'
// 缩略图组件
import Image from '@/components/Image'
// 图片预览
import { PhotoProvider, PhotoView } from 'react-photo-view'
// 删除图片弹窗
import RemoveImage from './components/RemoveImage'
import 'react-photo-view/dist/react-photo-view.css'
import _ from 'lodash'
import { getGalleryList, uploadOSS, uploadPOD, delFileUpload } from '@/api/settings'
import {
  ossSignatureUrl,
  setOssImageUrl,
  putObject,
  getClient,
  getImageFileWidthAndHeight,
} from '@/utils/utils'
import './index.scss'

const MyFiles = () => {
  const [loading, setLoading, getLoading] = useGetState(true) // 是否显示骨架屏
  const [loadingSearch, setLoadingSearch] = useState(false) // 搜索loading
  const [pageNum, setPageNum, getPageNum] = useGetState(1) // 当前页
  const [pageSize] = useState(15) // 分页条数
  const [total, setTotal] = useState(0) // 总条数
  const [queryValue, setQueryValue, getQueryValue] = useGetState('') // 输入框搜索值
  const [tableData, setTableData] = useState([]) // 表格数据

  // 删除弹窗
  const [showRemoveModal, setShowRemoveModal] = useState(false)

  // 上传附件
  const [openFileDialog, setOpenFileDialog] = useState(false)

  // Toast提示
  const [active, setActive] = useState(false)
  const [error, setError] = useState(false)
  const [toastContent, setToastContent] = useState('')

  useEffect(() => {
    getFileList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 上一页
  const handlePrev = async () => {
    await setPageNum((preState) => preState - 1)
    getFileList()
  }

  // 下一页
  const handleNext = async () => {
    await setPageNum((preState) => preState + 1)
    getFileList()
  }

  // 查询列表数据
  const getFileList = _.debounce(async () => {
    const res = await getGalleryList({
      pageNum: getPageNum(),
      perPage: pageSize,
      name: getQueryValue(),
    })

    if (res) {
      setTotal(res.count)
      _.forEach(res.dataList, (item) => {
        item.size = _.round(item.size / 1024)
      })
      setTableData(res.dataList)
    }
    if (getLoading()) {
      setLoading(false)
    } else {
      setTimeout(() => {
        setLoadingSearch(false)
      }, 100)
    }
  }, 300)

  // 输入值搜索
  const handleFiltersQueryChange = useCallback(async (value) => {
    if (value.length <= 30) {
      setLoadingSearch(true)
      await setQueryValue(value)
      getFileList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 清空输入框
  const handleQueryValueRemove = useCallback(async () => {
    setLoadingSearch(true)
    await setQueryValue('')
    getFileList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 打开上传附件弹窗
  const toggleOpenFileDialog = useCallback(
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    []
  )

  // 上传附件校验
  const handleValidator = useCallback((file) => {
    // max的单位为m
    const typeList = [
      { type: 'image/jpeg', max: 10 },
      { type: 'image/jpg', max: 10 },
      { type: 'image/png', max: 10 },
    ]
    const item = _.find(typeList, (item) => {
      return item.type === file.type
    })
    if (item) {
      if (file.size / 1024000 > item.max) {
        return false
      } else {
        return true
      }
    }
    return false
  }, [])

  // 上传附件
  const handleDropZoneDrop = useCallback(async (dropFiles, _acceptedFiles, _rejectedFiles) => {
    if (dropFiles && dropFiles.length > 5) {
      setActive(true)
      setToastContent('Only 5 attachments can be uploaded at a time')
      return
    }
    if (!_.isEmpty(_rejectedFiles)) {
      setActive(true)
      setToastContent('The image size should not exceed 10M, and must a JPG or PNG or JPEG')
      return
    }

    const res = await uploadOSS()
    const client = getClient(res)
    const requestList = []
    const imageList = []
    for (let i = 0; i < dropFiles.length; i++) {
      const file = dropFiles[i]
      // 获取图片的尺寸（宽和高）
      const fileSize = await getImageFileWidthAndHeight(file)
      const name = setOssImageUrl({
        module: 'myfile',
        type: file.type,
        fileName: file.name,
      })
      imageList.push({
        name,
        size: file.size,
        wide: fileSize.width,
        long: fileSize.height,
      })
      requestList.push(putObject(name, file, client))
    }
    await uploadPOD({ data: imageList })
    await Promise.all(requestList)
    getFileList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 批量删除图片
  const handleDelete = async () => {
    try {
      await delFileUpload({ id: selectedResources })
      getFileList()
      setActive(true)
      setToastContent('Delete succeeded')
    } catch (e) {
      const msg = e.data ? e.data.message : 'Server error'
      setActive(true)
      setError(true)
      setToastContent(msg)
    }
  }

  // 获取图片名称
  const getFileName = (item) => {
    if (item.name) {
      const filename = _.slice(item.name, item.name.lastIndexOf('/myfile/') + 8, item.name.length)
      const data = _.truncate(filename, {
        length: 80,
      }).replace(/,/g, '')
      return data
    }
    return ''
  }
  // 获取图片全称
  const getFileAllName = (item) => {
    if (item.name) {
      return String(
        _.slice(item.name, item.name.lastIndexOf('/myfile/') + 8, item.name.length)
      ).replace(/,/g, '')
    }
    return ''
  }

  // 获取图片大小
  const getSize = (item) => {
    if (item.size > 1000) {
      return _.round(item.size / 1024, 2) + 'MB'
    }
    return item.size + 'KB'
  }

  // 总条数
  const pageMsg = useMemo(() => {
    const startNum = (pageNum - 1) * pageSize + 1
    const endNum = pageNum * pageSize > total ? total : pageNum * pageSize
    return `Showing ${startNum} - ${endNum} of ${total} results`
  }, [pageNum, pageSize, total])

  // 是否存在上一页
  const hasPrevious = useMemo(() => {
    const maxPageNum = _.ceil(total / pageSize)
    if (pageNum > 1 && pageNum <= maxPageNum) {
      return true
    } else if (pageNum === 1) {
      return false
    }
  }, [total, pageNum, pageSize])

  // 是否存在下一页
  const hasNext = useMemo(() => {
    const maxPageNum = _.ceil(total / pageSize)
    if (_.inRange(pageNum, 1, maxPageNum)) {
      return true
    } else if (pageNum === maxPageNum) {
      return false
    }
  }, [total, pageNum, pageSize])

  // 显示Toast提示
  const toggleActive = useCallback(() => setActive((active) => !active), [])

  // Toast Content
  const toastMarkup = active ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActive}
      error={error}
    />
  ) : null

  // 表格勾选事件
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(tableData)

  // 表格全选后操作按钮
  const promotedBulkActions = [
    {
      content: 'Delete',
      onAction: () => {
        setShowRemoveModal(true)
      },
    },
  ]

  // 表格空数据时显示内容
  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No file found'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  )

  const rowMarkup = _.map(tableData, (item, index) => {
    return (
      <IndexTable.Row
        id={item.id}
        key={index}
        selected={selectedResources.includes(item.id)}
      >
        <IndexTable.Cell>
          <div
            className="File-Image"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <PhotoView
              key={index}
              src={ossSignatureUrl(item.name)}
            >
              <span>
                <Image
                  src={ossSignatureUrl(item.name)}
                  size={64}
                />
              </span>
            </PhotoView>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div
            title={getFileAllName(item)}
            style={{ maxWidth: '450px', whiteSpace: 'normal' }}
          >
            {getFileName(item)}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {item.wide && item.long ? `${item.wide} * ${item.long}` : ''}
        </IndexTable.Cell>
        <IndexTable.Cell>{getSize(item)}</IndexTable.Cell>
      </IndexTable.Row>
    )
  })

  return (
    <div className="My-Files">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          {!_.isEmpty(tableData) && (
            <div className="Upload-File">
              <Button
                primary
                onClick={toggleOpenFileDialog}
              >
                Upload files
              </Button>
            </div>
          )}
          <Card>
            <Card.Section>
              <Filters
                queryValue={queryValue}
                filters={[]}
                appliedFilters={[]}
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={handleQueryValueRemove}
                queryPlaceholder="filter images"
              />
            </Card.Section>
            <Card.Section flush>
              {/* 搜索loading */}
              {loadingSearch && <LoadingSearch size="large" />}

              {!loadingSearch &&
                (_.isEmpty(tableData) && !queryValue ? (
                  <EmptyFiles onUpload={toggleOpenFileDialog} />
                ) : (
                  <>
                    <IndexTable
                      itemCount={tableData.length}
                      selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
                      onSelectionChange={handleSelectionChange}
                      promotedBulkActions={promotedBulkActions}
                      headings={[
                        { title: '' },
                        { title: 'File name' },
                        { title: 'Width & Height' },
                        { title: 'Size' },
                      ]}
                      emptyState={emptyStateMarkup}
                    >
                      <PhotoProvider maskOpacity={0.5}>{rowMarkup}</PhotoProvider>
                    </IndexTable>
                    <div className="Pagination-Box">
                      <Pagination
                        label={pageMsg}
                        hasPrevious={hasPrevious}
                        hasNext={hasNext}
                        onPrevious={handlePrev}
                        onNext={handleNext}
                      />
                    </div>
                  </>
                ))}
            </Card.Section>
          </Card>
        </>
      )}

      {/* 删除图片弹窗 */}
      <RemoveImage
        isShow={showRemoveModal}
        onClose={() => {
          setShowRemoveModal(false)
        }}
        onConfirm={handleDelete}
      />

      {/* 图片上传组件 */}
      <DropZone
        openFileDialog={openFileDialog}
        onDrop={handleDropZoneDrop}
        onFileDialogClose={toggleOpenFileDialog}
        customValidator={handleValidator}
        variableHeight={true}
        outline={false}
      ></DropZone>

      {/* Toast提示 */}
      {toastMarkup}
    </div>
  )
}

export default MyFiles
