/*
 * @Description: 历史图片
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-22 14:03:05
 * @LastEditTime: 2023-01-17 15:55:34
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\HistoryImage\index.jsx
 */
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useGetState } from 'ahooks'
import { Card, Filters } from '@shopify/polaris'
import _ from 'lodash'
// 滚动加载
import InfiniteScroll from 'react-infinite-scroll-component'
// 空数据组件
import PodEmptyState from '@/components/PodEmptyState'
// 搜索loading
import LoadingSearch from '@/components/LoadingSearch'
// api
import { getHistory } from '@/api/images'
// 公共方法
import { ossSignatureUrl } from '@/utils/utils'
// 样式文件
import './index.scss'

const HistoryImage = ({ onConfirm, onClose }) => {
  const [queryValue, setQueryValue, getQueryValue] = useGetState(null) // 搜索的标题
  const [imageList, setImageList, getImageList] = useGetState([]) // 图片列表
  const [isSpinner, setIsSpinner] = useState(false) // 搜索加载
  const [page, setPage, getPage] = useGetState({
    pageNum: 1,
    pageSize: 15,
    total: 0,
  })

  useEffect(() => {
    setIsSpinner(true)
    getImageData()
  }, [])

  // 滚动加载
  const fetchMoreData = async () => {
    setPage((preSatate) => ({
      ...preSatate,
      pageNum: preSatate.pageNum + 1,
    }))
    setTimeout(() => {
      getImageData()
    })
  }

  // 查询列表
  const getImageData = _.debounce(async () => {
    const currentPage = getPage()
    const title = _.trim(getQueryValue())

    const params = {
      pageNum: currentPage.pageNum,
      perPage: currentPage.pageSize,
      title,
    }
    const res = await getHistory(params)
    _.forEach(res.list, (item) => {
      if (item.image) {
        item.src = ossSignatureUrl(item.image) // 读取OSS资源
      }
    })
    setImageList((preSatate) => {
      const images = title && currentPage.pageNum === 1 ? [] : [...preSatate]
      if (images.length > 0) {
        const nextData = _.concat(images, res.list)
        return _.uniqBy(nextData, 'image')
      } else {
        return res.list
      }
    })
    setPage((preSatate) => ({
      ...preSatate,
      total: res.count,
    }))
    setIsSpinner(false)
  }, 300)

  // 是否还有数据
  const hasMore = useMemo(() => {
    const iamges = getImageList()
    if (page.total === 0 || iamges.length < page.total) {
      return true
    }
    return false
  }, [imageList, page])

  // 点击图片
  const handleClick = (item) => {
    onConfirm(item.src)
    onClose()
  }

  // 输入框内容改变时，自动搜索
  const handleFiltersQueryChange = useCallback(async (value) => {
    setIsSpinner(true)
    await setQueryValue(value)
    await resetData() // 重置
    getImageData()
  }, [])

  // 清空输入框
  const handleQueryValueRemove = useCallback(async () => {
    setIsSpinner(true)
    await setQueryValue(null)
    await resetData() // 重置
    getImageData()
  }, [])

  // 重置
  const resetData = useCallback(async () => {
    await setImageList([])
    await setPage((preSatate) => ({
      ...preSatate,
      pageNum: 1,
      total: 0,
    }))
  }, [])

  // 图片加载失败
  const handleOnError = (e) => {
    e.target.parentNode.style.display = 'none'
  }

  return (
    <Card>
      <Card.Section>
        <div className="History-Filter">
          <Filters
            queryPlaceholder="filter images"
            queryValue={queryValue}
            filters={[]}
            appliedFilters={[]}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
          />
        </div>
        {isSpinner && <LoadingSearch />}
        {!isSpinner &&
          (imageList.length > 0 ? (
            <InfiniteScroll
              dataLength={imageList.length}
              next={fetchMoreData}
              hasMore={hasMore}
              height={450}
            >
              <div className="Flex-Image">
                {_.map(imageList, (item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleClick(item)
                      }}
                    >
                      <img
                        src={item.src}
                        alt={item.title}
                        onError={handleOnError}
                      />
                    </div>
                  )
                })}
              </div>
            </InfiniteScroll>
          ) : (
            <PodEmptyState
              heading={'No images found'}
              image={'/images/empty.svg'}
              action={false}
              paper={<p>Try changing thhe filters or search term</p>}
            />
          ))}
      </Card.Section>
    </Card>
  )
}

export default HistoryImage
