/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-07-26 11:33:35
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2023-01-17 08:45:57
 * @FilePath: \pod-app-react\src\pages\Catalog\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useState } from 'react'
import { Page } from '@shopify/polaris'
import _ from 'lodash'
// 骨架屏
import LoadingPage from './components/LoadingPage'
// 目录列表
import ProductCateList from './components/ProductCateList'
// 流程引导
import BeginnerGuide from '@/layout/components/BeginnerGuide/index'
// 获取目录列表接口
import { getProductCate } from '@/api/catelog/index'
// 获取oss图片路径方法
import { ossSignatureUrl } from '@/utils/utils'
import './index.scss'

const Catalog = () => {
  const [productCate, setProductCate] = useState([])
  const [loadingPage, setLoadingPage] = useState(true)

  // 获取目录列表
  const getProductCateList = async () => {
    const res = await getProductCate()
    setLoadingPage(false)
    if (res) {
      _.forEach(res.listData, (item) => {
        if (item.picturesImage) {
          item.picturesImage = ossSignatureUrl(item.picturesImage)
        }
      })
      setProductCate(res.listData)
    }
  }

  useEffect(() => {
    getProductCateList()
  }, [])

  return (
    <Page
      fullWidth
      title="Product Catalog"
    >
      {loadingPage ? <LoadingPage /> : <ProductCateList list={productCate} />}

      {/* 流程引导 */}
      <BeginnerGuide />
    </Page>
  )
}

export default Catalog
