// 引入react相关
import { useEffect, useState } from 'react'
import { useHistory, Switch, Route, Redirect } from 'react-router-dom'
import store from '@/redux/store'
// 引入组件
import install from '@/pages/Install/index'
// 首页
import Home from '@/pages/Home'
// 设置
import Setting from '@/pages/Setting'
// 目录
import Catalog from '@/pages/Catalog'
import ConfugureDesign from '@/pages/Catalog/components/ConfugureDesign'
import DesignDetails from '@/pages/Catalog/components/DesignDetails'
// 产品
import MyProducts from '@/pages/MyProducts'
import ProductContentEdit from '@/pages/ProductContentEdit'
// 订单
import MyOrders from '@/pages/Orders'
import OrderPayment from '@/pages/Orders/ChildrenPages/OrderPayment'
import OrderPaymentNew from '@/pages/Orders/ChildrenPages/OrderPaymentNew'
import NotFound from '@/pages/NotFound'
// 站内信
import Mail from '@/components/Mail'
// 引入api
import AppBridge from '@/utils/appBridge'
import { setStore, getStore } from '@/utils/utils'
import { createGetUserInfo, createGetMessage } from '@/redux/action'
import { getUserInfo } from '@/api/userInfo/index'
import NoticesService from '@/api/notices'
import _ from 'lodash'

const Router = () => {
  const history = useHistory()
  const [userInfo, setUserInfo] = useState({}) // 用户信息
  const [showMail, setShowMail] = useState(false) // 是否显示站内信弹窗
  const [mailMessage, setMailMessage] = useState({}) // 站内信数据

  useEffect(() => {
    // 路由拦截
    history.block(() => {
      getMailData() // 查询站内信消息
      return true
    })
  }, [history])

  const initData = () => {
    initUser() // 查询用户信息
    getMessageList() // 查询消息列表
    getMailData() // 查询站内信消息
  }

  // 获取当前用户信息
  const initUser = async () => {
    const res = await getUserInfo()
    window.userId = res.userId
    if (res) {
      setUserInfo(res)
      store.dispatch(createGetUserInfo(res))
      // willdesk聊天工具初始化
      const visitorNo = new Date().getTime() + Math.ceil(Math.random() * 1000)
      const bootData = {
        user_name: res.shopOwner || 'POD_visitor', // 必须
        user_email: res.email || 'POD_visitor', // 必须
        user_id: 'POD_' + res.userId, // 必须
        appUserId: 'POD_' + res.userId, // 必须
        fromApp: 'POD',
        shopUrl: res.shopName ? res.shopName : 'POD_visitor' + visitorNo,
        shopifyPlan: res.level,
        plan: res.level || 0,
        reviewName: res.username || 'no review_name',
        review: res.review || 'no review',
        first_name: res.username,
      }

      if (typeof window.Willdesk == 'object') {
        window.Willdesk.boot(bootData)
      } else {
        let load = (window.WilldeskLoad = false)
        Object.defineProperty(window, 'WilldeskLoad', {
          get() {
            return load
          },
          set(newVal) {
            load = newVal
            if (newVal) {
              window.Willdesk.boot(bootData)
            }
            return load
          },
        })
      }

      // window.Willdesk.show()

      // // 初始化客服聊天插件
      // // 设置 CRISP_TOKEN_ID，这里我用设置的 accessToken 拼接用户的 user_id
      // window.CRISP_TOKEN_ID = res.accessToken + res.userId

      // // 设置邮箱和昵称
      // window.$crisp.push(['set', 'user:email', res.email])
      // window.$crisp.push(['set', 'user:nickname', res.name])

      // // 设置会话数据
      // window.$crisp.push([
      //   'set',
      //   'session:data',
      //   [
      //     [
      //       ['name', res.name],
      //       ['user_id', res.userId],
      //       ['email', res.email],
      //       ['username', res.username],
      //       ['shop_name', res.shopName],
      //       ['level', res.level],
      //       ['createdat', res.createdAt],
      //       ['level', res.level],
      //       ['from_app', 'POD'],
      //     ],
      //   ],
      // ])
    }
  }

  // 获取消息列表
  const getMessageList = async () => {
    const res = await NoticesService.getNoticeList()
    if (res) {
      store.dispatch(createGetMessage(res))
    }
  }

  // 获取站内信
  const getMailData = async () => {
    const res = await NoticesService.getMail()
    if (!_.isEmpty(res)) {
      setShowMail(true)
      setMailMessage(res[0])
    }
  }

  // 关闭站内信弹窗
  const handleCloseMail = () => {
    setShowMail(false)
  }

  const requireAuth = (Layout, props) => {
    // 登录验证
    const params = new URLSearchParams(props.location.search)
    // let locale = params.get("locale");
    let host = params.get('host')
    let shop = params.get('shop')

    if (!host || !shop) {
      // 如果存在这个东西表示是从我们超管后台进来的，不执行搭桥
      let authToken = params.get('authToken')
      if (_.isEmpty(userInfo)) {
        initData()
      }
      if (authToken && authToken.length >= 32) {
        setStore('authToken', authToken)
        return <Layout {...props} />
      }
      authToken = getStore('authToken')
      if (authToken && authToken.length >= 32) {
        return <Layout {...props} />
      }
    }

    if (shop && shop.length > 5 && host && host.length > 5) {
      initAppBridge(shop, host)
    }
    if (_.isEmpty(userInfo)) {
      initData()
    }

    return <Layout {...props} />
  }

  const initAppBridge = (shop, host) => {
    if (shop && shop.length > 5 && host && host.length > 5 && !AppBridge.exist()) {
      AppBridge.set(host)
    }
  }
  return (
    <div className="container">
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/catalog" />}
        ></Route>
        <Route
          path="/auth/install"
          component={install}
        />
        <Route
          path="/home"
          render={(props) => requireAuth(Home, props)}
        />
        <Route
          path="/catalog"
          render={(props) => requireAuth(Catalog, props)}
        />
        <Route
          path="/confugureDesign"
          render={(props) => requireAuth(ConfugureDesign, props)}
        />
        <Route
          path="/designDetails"
          render={(props) => requireAuth(DesignDetails, props)}
        />
        <Route
          path="/myProducts"
          render={(props) => requireAuth(MyProducts, props)}
        />
        <Route
          path="/productContentEdit"
          render={(props) => requireAuth(ProductContentEdit, props)}
        />
        <Route
          path="/myOrders"
          render={(props) => requireAuth(MyOrders, props)}
        />
        <Route
          path="/orderPayment"
          render={(props) => requireAuth(OrderPayment, props)}
        />
        <Route
          path="/orderPaymentNew"
          render={(props) => requireAuth(OrderPaymentNew, props)}
        />
        <Route
          path="/setting/:id"
          render={(props) => requireAuth(Setting, props)}
        />

        <Route render={NotFound} />
      </Switch>

      {/* 站内信弹窗 */}
      <Mail
        isShow={showMail}
        message={mailMessage}
        onClose={handleCloseMail}
      />
    </div>
  )
}

export default Router
