/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-09-21 15:58:43
 * @LastEditTime: 2023-02-17 09:33:01
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Orders\ChildrenPages\OrderPayment\components\CustomerModal\index.jsx
 */
import { useState, useEffect } from 'react'
import {
  Modal,
  TextContainer,
  TextField,
  Subheading,
  Select,
  Button,
  Toast,
} from '@shopify/polaris'
import _ from 'lodash'
import CountryFlag from '@/components/CountryFlag'
import {
  getProductOrderCountry,
  getProductOrderAddressAdd,
} from '../../../../../../api/orderBuild/index'
import countryList from '../../../../../../components/CountryArray/index'
import './index.scss'

const CustomerModal = (props) => {
  const {
    isShowCustomerModal,
    callCustomerFn,
    customerData,
    updateAddress,
    podOrderId,
    onModificationAdd,
    onModificationEdit,
    onShowSaveButton,
    urlType,
    getProvinceLabel,
  } = props
  // console.log(customerData)
  const [active, setActive] = useState(false)
  const handleChange = () => {
    if (props.id === '') {
      callCustomerFn(false)
      return
    }
    if (customerData.id === 0) {
      setFirstName('')
      setLastName('')
      setEmial('')
      setPhoneNumber('')
      setCountry('')
      setPhone('')
      setStreet('')
      setSuite('')
      setCity('')
      setZip('')
      setStateList('')
      setErrorMessage({})
    }
    let params = {
      podOrderId,
      customerName: customerData?.firstName + customerData?.lastName,
      firstName: customerData?.firstName,
      lastName: customerData?.lastName,
      email: customerData?.email,
      phoneNumber: customerData?.phoneNumber,
      address: customerData?.address,
      phone: customerData?.phone,
      city: customerData?.city,
      zip: customerData?.zip,
      province: customerData?.province,
      country: customerData?.country,
      suite: customerData?.suite,
      id: customerData.id,
    }
    // console.log(params)
    if (props.id > 0) {
      onModificationEdit(params)
    }
    callCustomerFn(false)
  }
  const toggleActive = () => {
    setActive(!active)
  }
  // 表单数据变量
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmial] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [street, setStreet] = useState('')
  const [suite, setSuite] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [stateList, setStateList] = useState([])
  const [oldState, setOldState] = useState('')
  const [oldCountry, setOldCountry] = useState('')
  const [addressData, setAddressData] = useState(null)
  const [errorMessage, setErrorMessage] = useState({})
  const onAddressEdit = (data) => {
    setAddressData(data)
  }

  const onModification = async (type, value) => {
    switch (type) {
      case 'firstName':
        setFirstName(value)
        // value === '' || isRequired()
        onAddressEdit({ ...addressData, firstName: value })
        break
      case 'lastName':
        setLastName(value)
        // value === '' || isRequired()
        onAddressEdit({ ...addressData, lastName: value })
        break
      case 'email':
        setEmial(value)
        // value === '' || isRequired()
        onAddressEdit({ ...addressData, email: value })
        break
      case 'phoneNumber':
        setPhoneNumber(value)
        // value === '' || isRequired()
        onAddressEdit({ ...addressData, phoneNumber: value })
        break
      case 'country':
        for (let index = 0; index < countryList.length; index++) {
          if (countryList[index].value === value) {
            setCountry(countryList[index].value)
            setOldCountry(countryList[index].value)
            onAddressEdit({ ...addressData, country: countryList[index].value })
          }
        }
        const res = await getProductOrderCountry({ countryCode: value })
        setStateList(res)
        const firstState = res[0] || {}
        setState(firstState.value)
        setOldState(firstState.value)
        onAddressEdit({ ...addressData, province: firstState.label })

        break
      case 'phone':
        setPhone(value)
        onAddressEdit({ ...addressData, phone: value })
        break
      case 'street':
        setStreet(value)
        // value === '' || isRequired()
        onAddressEdit({ ...addressData, address: value })
        break
      case 'suite':
        setSuite(value)
        // value === '' || isRequired()
        onAddressEdit({ ...addressData, suite: value })
        break
      case 'city':
        setCity(value)
        // value === '' || isRequired()
        onAddressEdit({ ...addressData, city: value })
        break
      case 'state':
        for (let index = 0; index < stateList.length; index++) {
          if (stateList[index].value === value) {
            setState(stateList[index].value)
            setOldState(stateList[index].value)

            onAddressEdit({ ...addressData, province: stateList[index].label })
          }
        }
        break
      case 'zip':
        setZip(value)
        // value === '' || isRequired()
        onAddressEdit({ ...addressData, zip: value })
        break
      default:
        onShowSaveButton(true)
        break
    }
  }

  // 必填校验
  const isRequired = () => {
    const firstNameValue = updateAddress ? addressData?.firstName : firstName
    const lastNameValue = updateAddress ? addressData?.lastName : lastName
    const emailValue = updateAddress ? addressData?.email : email
    const PhonenumberValue = updateAddress ? addressData?.phoneNumber : phoneNumber
    const addressValue = updateAddress ? addressData.address : street
    // const suiteValue = updateAddress ? addressData.suite : suite
    const citeValue = updateAddress ? addressData.city : city
    const zipValue = updateAddress ? addressData.zip : zip
    const errorMessage = {
      firstName: !firstNameValue ? 'First name is required' : '',
      lastName: !lastNameValue ? 'Last name is required' : '',
      Email: !emailValue ? 'Email is required' : '',
      Phonenumber: !PhonenumberValue ? 'Phone number is required' : '',
      address: !addressValue ? 'address is required' : '',
      // suite: !suiteValue ? 'Fill in the detailed address' : '',
      city: !citeValue ? 'City is required' : '',
      zip: !zipValue ? 'Zip is required' : '',
    }
    let flag = true
    for (const key in errorMessage) {
      if (errorMessage[key]) {
        flag = false
      }
    }

    setErrorMessage(errorMessage)
    return flag
  }

  // 数据正则校验
  const isValidate = () => {
    const reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
    const emailValue = updateAddress ? addressData?.email : email
    const errorMessage = {
      Email: !reg.test(emailValue) ? 'Email format error' : '',
    }
    let flag = true
    for (const key in errorMessage) {
      if (errorMessage[key]) {
        flag = false
      }
    }
    setErrorMessage(errorMessage)
    return flag
  }

  const [toastActive, setToastActive] = useState(false)
  const toggleToastActive = () => {
    setToastActive(!toastActive)
  }

  // 保存成功后的toast提示
  const toastMarkup = toastActive ? (
    <Toast
      content="Success"
      onDismiss={toggleToastActive}
      duration={1000}
    />
  ) : null

  const onModificationSave = () => {
    const saveIng = isRequired()
    if (!saveIng) {
      return false
    }

    const validate = isValidate()
    if (!validate) {
      return false
    }

    onShowSaveButton(true)
    // console.log(addressData)
    const params = {
      podOrderId,
      customerName:
        firstName === '' || lastName === ''
          ? customerData.firstName + customerData.lastName
          : firstName + lastName,
      firstName: firstName === '' ? customerData.firstName : firstName,
      lastName: lastName === '' ? customerData.lastName : lastName,
      email: email === '' ? customerData.email : email,
      phoneNumber: phoneNumber === '' ? customerData.phoneNumber : phoneNumber,
      address: street === '' ? customerData.address : street,
      phone: phone === '' ? customerData.phone : phone,
      city: city === '' ? customerData.city : city,
      zip: zip === '' ? customerData.zip : zip,
      // province: state === '' ? customerData?.province : state,
      province: addressData.province,

      country: oldCountry === '' ? customerData?.country : oldCountry,
      suite: suite === '' ? customerData.suite : suite,
    }
    // const getAddress = async () => {
    //   if (customerData?.id) {
    //     await getProductOrderAddressAdd({ ...params, addressId: customerData.id })
    //     onModificationEdit({ ...params, id: customerData.id })
    //     setToastActive(true)
    //   } else {
    //     await getProductOrderAddressAdd(params)
    //     onModificationAdd({ ...params })
    //     setToastActive(true)
    //   }
    // }
    // getAddress()
    if (customerData?.id) {
      getProductOrderAddressAdd({ ...params, addressId: customerData.id }).then((res) => {
        onModificationEdit({ ...params, id: customerData.id })
        setToastActive(true)
      })
    } else {
      getProductOrderAddressAdd(params).then((res) => {
        onModificationAdd({ ...params })
        setToastActive(true)
      })
    }

    handleChange()
  }

  useEffect(() => {
    let isMount = true
    if (isMount && customerData?.id > 0) {
      setAddressData(customerData)
      for (let index = 0; index < countryList.length; index++) {
        if (countryList[index].value === customerData.country) {
          setOldCountry(countryList[index].value)
          const getState = async () => {
            const res = await getProductOrderCountry({ countryCode: countryList[index].value })
            if (res) {
              const state =
                _.find(res, (item) => {
                  // return item.label === customerData.province
                  return item.value === customerData.province
                }) || {}
              getProvinceLabel(state.label)
              setOldState(state.value)
              // setOldState(state.label)
              setStateList(res)
            }
          }
          getState()
        }
      }
    } else {
      setOldCountry(countryList[0].value)
    }
    return () => (isMount = false)
  }, [customerData, getProvinceLabel, urlType])
  return (
    <>
      <Modal
        open={isShowCustomerModal}
        onClose={handleChange}
        title="Customer"
        primaryAction={{
          content: 'Save',
          onAction: () => onModificationSave(),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <div className="row">
              <div className="col-6">
                <TextField
                  label="First name"
                  value={updateAddress ? addressData?.firstName : firstName}
                  error={errorMessage.firstName}
                  autoComplete="off"
                  onChange={(value) => onModification('firstName', value)}
                />
              </div>
              <div className="col-6">
                <TextField
                  label="Last name"
                  autoComplete="off"
                  value={updateAddress ? addressData?.lastName : lastName}
                  error={errorMessage.lastName}
                  onChange={(value) => onModification('lastName', value)}
                />
              </div>
            </div>
          </TextContainer>
        </Modal.Section>

        <div style={{ margin: '1.5rem 0 0 1.5rem' }}>
          <Subheading>CONTACT</Subheading>
        </div>

        <Modal.Section>
          <TextContainer>
            <div className="row">
              <div className="col-6">
                <TextField
                  label="Email"
                  autoComplete="off"
                  value={updateAddress ? addressData?.email : email}
                  error={errorMessage.Email}
                  onChange={(value) => onModification('email', value)}
                />
              </div>
              <div className="col-6">
                <TextField
                  label="Phone number"
                  autoComplete="off"
                  value={updateAddress ? addressData?.phoneNumber : phoneNumber}
                  error={errorMessage.Phonenumber}
                  onChange={(value) => onModification('phoneNumber', value)}
                />
              </div>
            </div>
          </TextContainer>
        </Modal.Section>

        <div style={{ margin: '1.5rem 0 0 1.5rem' }}>
          <Subheading>SHIPPING ADDRESS</Subheading>
        </div>

        <Modal.Section>
          <TextContainer>
            <div className="row">
              <div className="col-5">
                <Select
                  multiple
                  // id={unitSelectID}
                  label="Country/Region"
                  placeholder=""
                  value={updateAddress ? oldCountry : country}
                  onChange={(value) => onModification('country', value)}
                  options={countryList}
                  // value={unit}
                  // onChange={handleUnitChange}
                  // error={Boolean(!unit && weight)}
                />
              </div>
              <div className="col-5">
                <TextField
                  label="Phone"
                  autoComplete="off"
                  value={updateAddress ? addressData?.phone : phone}
                  onChange={(value) => onModification('phone', value)}
                />
              </div>
              <div className="col-2 selCountry">
                <div>
                  <Button onClick={toggleActive}>
                    <CountryFlag country={updateAddress ? oldCountry : oldCountry} />
                  </Button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <TextField
                  label="Street"
                  autoComplete="off"
                  value={updateAddress ? addressData?.address : street}
                  error={errorMessage.address}
                  onChange={(value) => onModification('street', value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <TextField
                  label="Apartment, suite, etc. (optional)"
                  autoComplete="off"
                  value={updateAddress ? addressData?.suite : suite}
                  // error={errorMessage.suite}
                  onChange={(value) => onModification('suite', value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <TextField
                  label="City"
                  autoComplete="off"
                  value={updateAddress ? addressData?.city : city}
                  onChange={(value) => onModification('city', value)}
                  error={errorMessage.city}
                />
              </div>
              {stateList.length > 0 && (
                <div className="col-4">
                  <Select
                    // id={unitSelectID}
                    multiple
                    label="State"
                    value={updateAddress ? oldState : state}
                    options={stateList}
                    onChange={(value) => onModification('state', value)}
                    placeholder=""
                    // error={Boolean(!unit && weight)}
                  />
                </div>
              )}
              <div className="col-4">
                <TextField
                  label="ZIP/Postal code"
                  autoComplete="off"
                  value={updateAddress ? addressData?.zip : zip}
                  error={errorMessage.zip}
                  onChange={(value) => onModification('zip', value)}
                />
              </div>
            </div>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </>
  )
}

export default CustomerModal
