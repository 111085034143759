import { request } from "../request";

// 首页商品品类获取
export async function getProductCate() {
  return request({
    url: '/api/v1/product/index',
    method: 'POST',
  });
}

/**
 * 获取产品品类详情数据
 * @param {*} id 品类主键Id
 * @returns 
 */
export async function getCatelogOrDesignDetails(id){
  return request({
    url:'/api/v1/product/show',
    method: 'POST',
    data: id
  })
}

/**
 * 获取运输方式列表
 * @param {*} data 品类Id、国家编码
 * @returns 
 */
export async function getShippings(data){
  return request({
    url:'/api/v1/product/weightShipping',
    method:'POST',
    data
  })
}