/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-17 17:14:04
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2023-01-05 15:45:25
 * @FilePath: \pod-app-react\src\components\FavorableComm\components\StarCom.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'
const Star = ({ marked, starId }) => {
  return (
    <img
      role="button"
      star-id={starId}
      key={starId}
      src={marked ? '/images/stars/star_fill.png' : '/images/stars/star.png'}
      alt={''}
      className="starImg"
    />
  )
}

export default Star
