import { useState, useEffect, useCallback } from 'react'
import { Modal, TextField, Button, Toast } from '@shopify/polaris'
import _ from 'lodash'
// paypal按钮
import { Paypal } from '@/components/Buttons'
// 接口
import BillingService from '@/api/settings/billing'
import './index.scss'

const Charge = ({ isShow, onClose, onConfirm }) => {
  const [active, setActive] = useState(isShow)
  const [loading, setLoading] = useState(false)
  const [ruleForm, setRuleForm] = useState({
    money: '',
  })
  const [timer, setTimer] = useState(null) // 定时器

  // Toast提示
  const [showToast, setShowToast] = useState(false)
  const [toastContent, setToastContent] = useState('')
  const toggleActive = useCallback(() => setShowToast((active) => !active), [])

  useEffect(() => {
    setActive(isShow)
    // 关闭弹窗清除定时器
    if (!isShow) {
      clearTimeout(timer)
    }
  }, [isShow, timer])

  // 关闭弹窗
  const closeModal = () => {
    setRuleForm({
      money: '',
    })
    onClose()
  }

  // 修改文本
  const handleChange = (value) => {
    setRuleForm((preState) => ({ ...preState, money: _.round(_.toNumber(value), 2).toString() }))
  }

  // 确认
  const handleConfirm = async () => {
    if (!ruleForm.money || _.toNumber(ruleForm.money) < 10) {
      setShowToast(true)
      setToastContent('Amount cannot be empty and greater than 10')
      return
    }
    setLoading(true)
    const res = await BillingService.apiWalletRecharge(ruleForm)
    if (res) {
      const width = 500
      const height = 590
      const top = (window.screen.availHeight - 30 - height) / 2
      const left = (window.screen.availWidth - 30 - width) / 2
      window.open(
        res.url,
        'Paypal',
        `width=${width}, height=${height}, top=${top}, left=${left}, menubar=0, scrollbars=1, resizable=1, status=1, titlebar=0, toolbar=0, location=1`
      )
      querySuccess(res.id)
    }
    setLoading(false)
  }

  // 查询是否充值成功
  const querySuccess = async (id) => {
    const res = await BillingService.apiMonitorStatus({ id })
    // 充值成功，关闭弹窗，刷新余额
    if (res && res.status) {
      onConfirm() // 刷新钱包
      closeModal() // 关闭弹窗
      clearTimeout(timer) // 清除定时器
    } else {
      const timer = setTimeout(() => {
        querySuccess(id)
      }, 1000)
      setTimer(timer)
    }
  }

  // Toast提示
  const toastMarkup = showToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActive}
    />
  ) : null

  return (
    <Modal
      open={active}
      onClose={closeModal}
      title="Wallet recharge"
    >
      <Modal.Section>
        <div className="Charge">
          <TextField
            requiredIndicator
            label="Amount (USD)"
            type="number"
            min={0}
            prefix="$"
            value={ruleForm.money}
            onChange={handleChange}
            autoComplete="off"
            placeholder="10.00"
            helpText="minimum 10"
          />
          <div className="Charge-Group">
            <Button onClick={closeModal}>Cancel</Button>
            <Paypal
              primary
              loading={loading}
              onClick={handleConfirm}
            />
          </div>
        </div>
      </Modal.Section>

      {/* Toast提示 */}
      {toastMarkup}
    </Modal>
  )
}

export default Charge
