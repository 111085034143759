/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-07-26 11:33:35
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2022-12-13 16:43:35
 * @FilePath: \pod-app-react\src\redux\constant.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 *  @constructor redux 常量块
 */
const ICONCARDSHOW = 'IconCardShow'
const GETUSERINFO = 'getUserInfo'
const GETMESSAGE = 'getMessage'
const CHECKFAVORABLE = 'checkFavorable'
const GETPRODUCTSTATUS = 'getProductStatus'
export { ICONCARDSHOW, GETUSERINFO, GETMESSAGE, CHECKFAVORABLE, GETPRODUCTSTATUS }
