/*
 * @Description: 批量支付支付弹窗
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-04 18:24:34
 * @LastEditTime: 2023-02-06 10:55:05
 * @LastEditors: huang-chao hc18174419502@163.com
 * @FilePath: \pod-app-react\src\pages\Orders\ChildrenPages\Pay\index.jsx
 */
import { useState, useEffect, useCallback } from 'react'
import { Modal, Button, Toast } from '@shopify/polaris'
// 引入paypal js
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
// 引入自定义按钮
import { Paypal, Wallet } from '@/components/Buttons'
// 引入支付相关接口
import { getProductOrderId, getProductOrderPay, listenerPayStatus } from '@/api/orderBuild'
// 钱包接口
import BillingService from '@/api/settings/billing'
import './index.scss'
import _ from 'lodash'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Number} price 总价
 * @param {Array} checkList 订单列表
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const Pay = ({ isShow, price, checkList, onClose, onConfirm }) => {
  const [active, setActive] = useState(isShow) // 是否显示弹窗
  const [currentType, setCurrentType] = useState('paypal') // 默认支付方式
  const [myBalance, setMyBalance] = useState(0) // 我的钱包余额
  const [loadingCheckout, setLoadingCheckout] = useState(false) // 支付中按钮loading状态

  // Toast
  const [showToast, setShowToast] = useState(false)
  const [isError, setIsError] = useState(false)
  const [toastContent, setToastContent] = useState('')

  useEffect(() => {
    setActive(isShow)
    getMyBalance()
  }, [isShow])

  // 获取钱包余额
  const getMyBalance = async () => {
    const res = await BillingService.getBalance()
    if (res) {
      setMyBalance(res.money)
    }
  }

  // 打开或关闭弹窗
  const handleChange = useCallback(() => {
    onClose()
    setActive(!active)
  }, [active])

  // 切换支付方式
  const handleClick = (type) => {
    setCurrentType(type)
  }

  // 钱包支付
  const handlePayment = async () => {
    setLoadingCheckout(true)
    try {
      const params = {
        id: _.map(checkList, 'id'),
        type: 2, // 1 单笔支付 2批量支付
        status: 2, // 1 paypal支付 2 钱包支付
      }
      await getProductOrderId(params)
      setLoadingCheckout(false)
      onConfirm()
    } catch (e) {
      setLoadingCheckout(false)
      const msg = e.data ? e.data.message : 'Payment failed, please try again'
      setShowToast(true)
      setIsError(true)
      setToastContent(msg)
    }
  }

  // Paypal支付
  const createOrder = async () => {
    try {
      const params = {
        id: _.map(checkList, 'id'),
        type: 2, // 1 单笔支付 2批量支付
        status: 1, // 1 paypal支付 2 钱包支付
      }
      const res = await getProductOrderId(params)
      if (res) {
        window.sessionStorage.setItem('paypalId', res?.payPalOrderId)
        return res?.payPalOrderId
      }
    } catch (e) {}
  }

  // Paypal支付成功或失败
  const onApprove = async () => {
    const id = window.sessionStorage.getItem('paypalId')
    await getProductOrderPay({ payPalOrderId: id })
    await listenerPayStatus({ payPalOrderId: id })
    onConfirm()
  }

  // 是否显示Toast
  const toggleActive = useCallback(() => setShowToast((active) => !active), [])

  // Toast
  const toastMarkup = showToast ? (
    <Toast
      content={toastContent}
      error={isError}
      onDismiss={toggleActive}
    />
  ) : null

  return (
    <Modal
      title={
        <div className="Checkouts-Pay-Title">
          <span>Checkouts</span>
          {currentType === 'wallet' &&
            (myBalance >= price ? (
              <span className="Checkouts-Pay-Title-Balance">Balance: $ {myBalance}</span>
            ) : (
              <span
                className="Checkouts-Pay-Title-Balance"
                style={{ color: 'red' }}
              >
                Insufficient balance: $ {myBalance}
              </span>
            ))}
        </div>
      }
      open={active}
      onClose={handleChange}
    >
      <Modal.Section flush>
        <div className="Pay">
          <div className="Pay-Content">
            <span style={{ fontWeight: 500 }}>Payment method type:</span>
            <div className="Pay-Button-Group">
              <Paypal
                active={currentType === 'paypal'}
                onClick={() => {
                  handleClick('paypal')
                }}
              />
              <Wallet
                active={currentType === 'wallet'}
                onClick={() => {
                  handleClick('wallet')
                }}
              />
            </div>
          </div>
          <div className="Pay-Total">Total: $ {price}</div>
          {currentType === 'wallet' && myBalance < price && (
            <div style={{ textAlign: 'right', color: 'rgb(153 149 149)', marginRight: '12px' }}>
              Instead of{' '}
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setCurrentType('paypal')}
              >
                PayPal
              </span>
            </div>
          )}

          <div className="Pay-Fotter">
            {/* <span>

            </span> */}
            <Button onClick={onClose}>Cancel</Button>
            {currentType === 'paypal' && (
              <PayPalScriptProvider
                options={{
                  'client-id': process.env.REACT_APP_PAYPAL_CLINTID,
                }}
              >
                <PayPalButtons
                  className="palpal_button"
                  style={{
                    shape: 'rect',
                    color: 'gold',
                    layout: 'horizontal',
                    height: 36,
                    width: 100,
                  }}
                  createOrder={createOrder}
                  catchError={() => {}}
                  onCancel={() => {}}
                  onClick={() => {}}
                  onApprove={onApprove}
                />
              </PayPalScriptProvider>
            )}
            {currentType === 'wallet' && (
              <Button
                primary
                loading={loadingCheckout}
                onClick={() => {
                  handlePayment()
                }}
                disabled={myBalance < price}
              >
                Checkout
              </Button>
            )}
          </div>
        </div>
      </Modal.Section>
      {/* Toast提示 */}
      {toastMarkup}
    </Modal>
  )
}

export default Pay
