/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-07-26 11:33:35
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2022-12-13 16:46:23
 * @FilePath: \pod-app-react\src\redux\store.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 *  创建 store
 */
import thunk from 'redux-thunk'
import {
  iconCardReducer,
  userInfoReducer,
  messageReducer,
  favorableReducer,
  productStatusReducer,
} from '@/redux/reducers'
import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux'

const mainReduce = combineReducers({
  iconCard: iconCardReducer,
  userInfo: userInfoReducer,
  messageList: messageReducer,
  favorable: favorableReducer,
  productStatusObj: productStatusReducer,
})

//中间件处理异步action

export default createStore(mainReduce, applyMiddleware(thunk))
