import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Popover, ActionList, Icon } from '@shopify/polaris'
import { HomeMajor } from '@shopify/polaris-icons'
import _ from 'lodash'
import PodAvatar from '@/components/PodAvatar'
import PodNotices from '@/components/PodNotices'
import BeginnerGuide from '@/layout/components/BeginnerGuide/index'
import store from '@/redux/store'
import './index.scss'
import { createGetMessage, createGetProductStatus } from '@/redux/action'
let unSubscribe = null
class TopBarMenu extends Component {
  state = {
    active: false,
    isShowContent: false,
    menusList: [
      {
        id: 0,
        href: '/catalog',
        content: 'Catalog',
        logo: '/images/navigation/catalog.svg',
        logo_active: '/images/navigation/catalog_active.svg',
        children: [
          {
            id: 0,
            href: '/confugureDesign',
            content: 'ConfugureDesign',
          },
          {
            id: 0,
            href: '/designDetails',
            content: 'DesignDetails',
          },
        ],
      },
      {
        id: 1,
        href: '/myProducts',
        content: 'My products',
        logo: '/images/navigation/myProduct.svg',
        logo_active: '/images/navigation/myProduct_active.svg',
        children: [
          {
            id: 1,
            href: '/productContentEdit',
            content: 'ProductContentEdit',
          },
        ],
      },
      {
        id: 2,
        href: '/myOrders',
        content: 'Orders',
        logo: '/images/navigation/order.svg',
        logo_active: '/images/navigation/order_active.svg',
        children: [
          {
            id: 2,
            href: '/orderPayment',
            content: 'OrderPayment',
          },
        ],
      },
    ],
    selectKey: -1,
    isShowNotices: false,
    userInfo: {},
    showGuide: false,

    newsMessageCount: 0, // 消息推送数据总数
    newMessageList: [], // 消息推送过来的订单数据
  }

  goHome = () => {
    this.props.history.push('/home')
  }

  // 切换设置弹窗显示状态
  toggleActive = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  // 点击菜单
  menuClick = (id, path) => {
    window.currentMenu = {
      selectKey: id,
      path,
    }

    this.setState({
      selectKey: id,
    })
    this.props.history.push(path)
  }

  showNotices = () => {
    this.setState({
      isShowNotices: !this.state.isShowNotices,
    })
  }

  handleMobileIconClick = () => {
    this.setState({
      isShowContent: !this.state.isShowContent,
    })
  }
  handleMobileCancelClick = () => {
    this.setState({
      isShowContent: false,
    })
  }

  // 切换菜单
  handleChangeMenu = (id) => {
    this.props.history.push(`/setting/${id}`, { id })
    this.toggleActive()
  }

  handleGuide = () => {
    this.setState({
      showGuide: true,
    })
  }

  // 回调关闭通知按钮事件
  closeNotice = (isClose) => {
    this.setState({
      isShowNotices: isClose,
    })
  }

  eventMenuClick = () => {
    // const currentMenu = JSON.parse(getStore('currentMenu'))
    const currentMenu = window.currentMenu

    if (this.props.history.location.pathname === '/') {
      window.currentMenu = {
        selectKey: 0,
        path: '/catalog',
      }
      return
    }
    // 通过url直接进入页面
    if (currentMenu && currentMenu.path !== this.props.history.location.pathname) {
      let key
      const item =
        this.state.menusList.find((item) => {
          if (item.childrens !== undefined && item.childrens.length > 0) {
            // 存在子级菜单
            item.childrens.forEach((child) => {
              if (currentMenu.selectKey === child.id) {
                key = child.id // 避免浏览器刷新后item项目置空
              }
            })
          } else {
            return item.href === this.props.history.location.pathname
          }
          return key
        }) || {}
      this.setState({
        selectKey: key,
      })
      window.currentMenu = {
        selectKey: key,
        path: item.href,
      }
    } else if (currentMenu) {
      this.setState({
        selectKey: currentMenu.selectKey,
      })
    }
  }

  // WebSocket 消息推送 获取订单消息
  tidingMessage = (userInfo) => {
    const ws = new WebSocket(process.env.REACT_APP_SOCKET) // todo... 地址是 app 服务器地址
    ws.open = () => {
      console.log('连接成功', userInfo.userId)
      ws.send(userInfo.userId)
    }
    ws.onmessage = (response) => {
      let result = JSON.parse(response.data)
      if (result.type === 'ping') {
        ws.send(userInfo.userId)
      }
      if (result.type === 'announce') {
        //订单
        // 新消息推送 将新消息插入message中显示
        const { tipsNum, message } = result
        // setStore('tipsNum', tipsNum)
        window.tipsNum = tipsNum
        this.setState(
          {
            // newsMessageCount: Number(getStore('tipsNum')),
            newsMessageCount: window.tipsNum,
          },
          () => {
            store.dispatch(createGetMessage(message))
          }
        )
      }
      if (result.type === 'finishedProductUpdate') {
        //产品状态
        const productStatus = JSON.parse(result.data)
        if (!_.isEmpty(productStatus)) {
          store.dispatch(createGetProductStatus(productStatus))
        }
      }
    }
  }

  // 点击checkOrder 更新
  getNewTipsNumFn = (newTipsNum) => {
    this.setState({
      newsMessageCount: newTipsNum,
    })
  }

  componentDidMount() {
    this.eventMenuClick()
    window.listenerMenuClick = this.eventMenuClick
    // 从redux获取用户信息
    unSubscribe = store.subscribe(() => {
      const userInfo = store.getState().userInfo
      // 当userinfo的值发生改变时，才去改变state
      if (!_.isEqual(userInfo, this.state.userInfo)) {
        this.setState(
          {
            userInfo,
          },
          () => {
            // this.tidingMessage(userInfo)
          }
        )
      }
    })
  }
  componentWillUnmount() {
    return () => unSubscribe()
  }
  shouldComponentUpdate() {
    const menu = this.getRoute(this.props.history.location.pathname)
    if (menu.id !== this.state.selectKey) {
      this.setState({
        selectKey: menu.id,
      })
      return true
    }
    return true
  }

  // 获取路由
  getRoute = (path) => {
    let curItem = {}
    const fn = (data) => {
      if (_.isArray(data)) {
        _.forEach(data, (item) => {
          if (item.href === path) {
            curItem = item
          } else if (item.children) {
            fn(item.children)
          }
        })
      }
    }
    fn(this.state.menusList)
    return curItem
  }

  onClose = () => {
    this.setState({
      showGuide: false,
    })
  }

  render() {
    const { menusList, isShowNotices, userInfo, selectKey, newsMessageCount } = this.state
    const activator = (
      <PodAvatar
        name={userInfo.shopOwner}
        onClick={this.toggleActive}
      />
    )
    const topBarliItem = menusList.map((menu, key) => {
      return (
        <li
          key={key}
          className={`${selectKey === key ? 'li_active' : 'li_default'}`}
        >
          <img
            src={`${selectKey === key ? menu.logo_active : menu.logo}`}
            alt=""
          />
          <span
            // style={{ color: this.state.selectKey === menu.id ? '#9CD4F7' : '' }}
            onClick={() => this.menuClick(menu.id, menu.href)}
          >
            {menu.content}
          </span>
        </li>
      )
    })

    return (
      <div style={{ position: 'relative' }}>
        <div className="Print_TopBar_Container">
          {/* <div className="Print_TopBar_Logo">
            <img
              src="/images/Printing.svg"
              alt="print"
            />
          </div> */}
          <div
            style={{ cursor: 'pointer' }}
            onClick={this.goHome}
          >
            <Icon
              source={HomeMajor}
              color={this.props.history.location.pathname === '/home' ? 'primary' : 'base'}
            />
          </div>
          <div className="Print_TopBar_Options">
            <ul className="Print_TopBar_ul">{topBarliItem}</ul>
          </div>

          <div className="Print_TopBar_IconOpt">
            {/* 通知入口 */}
            {newsMessageCount > 0 ? (
              <img
                src="/images/unreadBell.png"
                alt="Print"
                onClick={this.showNotices}
                id="noticeImg"
              />
            ) : (
              <img
                src="/images/smallbell.png"
                alt="Print"
                onClick={this.showNotices}
                id="noticeImg"
              />
            )}
            {/* 消息推送总数 */}
            {newsMessageCount > 0 && <span className="noticeCount">{newsMessageCount}</span>}

            {/* 点击查看个人信息模块 */}
            <Popover
              fixed
              active={this.state.active}
              activator={activator}
              onClose={this.toggleActive}
            >
              <Popover.Pane>
                <ActionList
                  actionRole="menuitem"
                  sections={[
                    {
                      title: userInfo.shopOwner,
                      items: [
                        {
                          content: 'My Account',
                          onAction: () => {
                            this.handleChangeMenu('myAccount')
                          },
                        },
                        {
                          content: 'My files',
                          onAction: () => {
                            this.handleChangeMenu('myFiles')
                          },
                        },
                        {
                          content: 'Billing',
                          onAction: () => {
                            this.handleChangeMenu('billing')
                          },
                        },
                        {
                          content: 'Notification',
                          onAction: () => {
                            this.handleChangeMenu('notification')
                          },
                        },
                        {
                          content: 'Guide',
                          onAction: () => {
                            this.handleGuide()
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Popover.Pane>
            </Popover>
          </div>
        </div>
        {/* 通知 自定义组件
         */}
        <PodNotices
          isShowNotices={isShowNotices}
          closeNotice={this.closeNotice}
          tipsNum={Number(window.tipsNum)}
          getNewTipsNumFn={this.getNewTipsNumFn}
        />
        {this.state.showGuide && (
          <BeginnerGuide
            showGuide={this.state.showGuide}
            onClose={this.onClose}
          />
        )}
      </div>
    )
  }
}

export default withRouter(TopBarMenu)
