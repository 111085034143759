/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-09-19 09:14:19
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2022-11-07 17:44:57
 * @FilePath: \pod-app-react\src\api\confugureDesign\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { request } from '../request'

export async function designSave(params) {
  return request({
    url: 'api/v1/product/designSave',
    method: 'POST',
    data: params,
  })
}

export async function designShow(id) {
  const data = {
    id,
  }
  return request({
    url: 'api/v1/product/designShow',
    method: 'POST',
    data,
  })
}

export async function productDesignInsert(data) {
  return request({
    url: '/api/v1/product/productDesignInsert',
    method: 'POST',
    data,
  })
}

/**
 * 上传设计完成的图片
 * @param {*} params
 * @returns
 */
export async function finishedUpload(params) {
  return request({
    url: 'api/v1/finished/upload',
    type: 'upload', // 走管理端接口
    method: 'POST',
    data: params,
    contentType: 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
  })
}
