/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-17 15:23:18
 * @LastEditors: huang-chao hc18174419502@163.com
 * @LastEditTime: 2022-11-21 09:17:58
 * @FilePath: \pod-app-react\src\api\favorable\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { request } from '../request'

/**
 * 添加引导好评
 * @param {*} data 参数
 * @returns
 */
export async function reviewStore(data) {
  return request({
    url: '/api/v1/review/store',
    method: 'POST',
    data,
  })
}

/**
 * 是否符合引导好评弹窗条件
 * @returns
 */
export async function conditionsFavor() {
  return request({
    url: '/api/v1/review/check',
    method: 'GET',
  })
}
