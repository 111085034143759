/*
 * @Description: 加载中页面
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-12-06 16:38:06
 * @LastEditTime: 2022-12-10 14:19:10
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\ProductContentEdit\component\LoadingPage\index.jsx
 */
import React from 'react'
import { Card, Layout, SkeletonPage, SkeletonBodyText } from '@shopify/polaris'

const LoadingPage = () => {
  return (
    <SkeletonPage
      primaryAction
      fullWidth={true}
    >
      <Layout>
        <Layout.Section>
          <div className="row">
            <div className="col-12">
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
              </Card>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
              </Card>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
              </Card>
            </div>
          </div>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

export default LoadingPage
