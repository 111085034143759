/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-09-21 15:58:43
 * @LastEditTime: 2023-01-05 15:35:07
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\NotFound\index.jsx
 */
import React from 'react'
import { AppProvider, Layout, Page } from '@shopify/polaris'
import './index.scss'
const NotFound = () => {
  return (
    <AppProvider>
      <Page>
        <Layout>
          <Layout.Section oneThird>
            <div className="page-not-found">
              <div className="not-found-title">"The page you’re looking for couldn’t be found"</div>
              <div className="not-found-msg">"Check the web address and try again."</div>
              {/* <Button primary size="large" url="/admin/dashboard">"Go to Drop Ship Home"</Button> */}
            </div>
          </Layout.Section>

          <Layout.Section oneThird>
            <div className="not-img">
              <img
                src="/images/404.svg"
                alt="page not found"
                className="not-found-img"
              ></img>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    </AppProvider>
  )
}

export default NotFound
