/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-07-26 11:33:35
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2022-12-13 16:44:05
 * @FilePath: \pod-app-react\src\redux\action.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {
  ICONCARDSHOW,
  GETUSERINFO,
  GETMESSAGE,
  CHECKFAVORABLE,
  GETPRODUCTSTATUS,
} from '@/redux/constant'

const createIconCardShowAction = (data) => ({ type: ICONCARDSHOW, data })
const createGetUserInfo = (data) => ({ type: GETUSERINFO, data })
const createGetMessage = (data) => ({ type: GETMESSAGE, data })
const createCheckFavorable = (data) => ({ type: CHECKFAVORABLE, data })
const createGetProductStatus = (data) => ({ type: GETPRODUCTSTATUS, data })
export {
  createIconCardShowAction,
  createGetUserInfo,
  createGetMessage,
  createCheckFavorable,
  createGetProductStatus,
}
