/*
 * @Description: 图片相关接口
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-22 17:10:11
 * @LastEditTime: 2022-11-28 16:23:34
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\api\images\index.js
 */
import { request } from '../request'

/**
 * 获取素材库推荐图片
 * @param {Object} params 请求参数
 * @returns
 */
export async function getMaterialImage(params) {
  return request({
    url: '/api/v1/material',
    method: 'GET',
    params,
  })
}

/**
 * 获取素材库类目下拉
 * @param {Object} data 请求参数
 * @returns
 */
export async function getCategory(params) {
  return request({
    url: '/api/v1/category',
    method: 'GET',
    params,
  })
}

/**
 * 记录用户历史图片
 * @param {*} params 请求参数
 * @returns
 */
export async function addHistory(params) {
  return request({
    url: '/api/v1/image/click',
    method: 'GET',
    params,
  })
}

/**
 * 获取历史图片
 * @param {Object} data 请求参数
 * @returns
 */
export async function getHistory(params) {
  return request({
    url: '/api/v1/image/history',
    method: 'GET',
    params,
  })
}
