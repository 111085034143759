/*
 * @Description: 产品设计 - 图库
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-22 10:14:26
 * @LastEditTime: 2022-12-21 16:20:31
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Catalog\ChildrenPages\ConfugureDesign\components\ImageLibrary\index.jsx
 */
import React, { useState, useEffect, useCallback } from 'react'
import { Modal, Tabs } from '@shopify/polaris'
import _ from 'lodash'
// 推荐图片
import RecommendImage from '@/components/RecommendImage'
// 历史图片
import HistoryImage from '@/components/HistoryImage'
// 我的
import MineFiles from '@/components/MineFiles'

// 引入样式文件
import './index.scss'

const ImageLibrary = ({ isShow, tabId, onClose, onConfirm }) => {
  const [active, setActive] = useState(isShow) // 是否显示弹窗
  const [selected, setSelected] = useState(0) // 当前tab

  const tabs = [
    {
      id: 'Recommend',
      content: 'Recommend',
    },
    {
      id: 'History',
      content: 'History',
    },
    {
      id: 'Mine',
      content: 'Mine',
    },
  ]

  useEffect(() => {
    setActive(isShow)
    const index = _.findIndex(tabs, (item) => {
      return item.id === tabId
    })
    setSelected(index)
  }, [isShow])

  // 打开或关闭弹窗
  const handleChange = useCallback(() => {
    onClose()
    setActive(!active)
  }, [active, onClose])

  // 切换TAB
  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), [])

  return (
    <div id="ImageLibrary">
      <Modal
        title="Image library"
        large
        open={active}
        onClose={handleChange}
        noScroll={true}
      >
        <Tabs
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
        >
          {/* 推荐图片 */}
          {selected === 0 && (
            <RecommendImage
              onConfirm={onConfirm}
              onClose={onClose}
            />
          )}
          {/* 历史图片 */}
          {selected === 1 && (
            <HistoryImage
              onConfirm={onConfirm}
              onClose={onClose}
            />
          )}
          {/* MineFile */}
          {selected === 2 && (
            <MineFiles
              onConfirm={onConfirm}
              onClose={onClose}
            />
          )}
        </Tabs>
      </Modal>
    </div>
  )
}

export default ImageLibrary
