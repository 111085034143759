/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-07-26 11:33:35
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2023-01-04 18:02:46
 * @FilePath: \pod-app-react\src\pages\Orders\ChildrenPages\OrderPayment\components\ShippingModal\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from 'react'
import { Modal, RadioButton, Icon } from '@shopify/polaris'
import { SortMinor } from '@shopify/polaris-icons'

const ShippingModal = (props) => {
  const {
    isShowShippingProductModal,
    callShippingFn,
    closeShippingModal,
    shippingData,
    onShowSaveButton,
    OrdersfetchData,
  } = props
  const [lists, setLists] = useState(shippingData)
  useEffect(() => {
    let isMount = true
    if (isMount && shippingData) {
      setLists(shippingData)
    }
    return () => (isMount = true)
  }, [shippingData])
  const handleChange = () => {
    callShippingFn(false, shippingData, 0)
    closeShippingModal()
    setLists(shippingData)
    onShowSaveButton(false)
  }
  const handleRadioChange = (ind) => {
    let data = shippingData.map((item, inds) => {
      if (inds === ind) {
        return { ...item, selected: 1 }
      } else {
        return { ...item, selected: 0 }
      }
    })
    setLists(data)
    onShowSaveButton(true)
  }

  const handleSaveAction = () => {
    // 将radio值派发给父组件,并关闭弹窗
    callShippingFn(false, lists, 1).then((res) => OrdersfetchData('basic', props.id, 'noRefresh'))
  }

  return (
    <Modal
      open={isShowShippingProductModal}
      onClose={handleChange}
      title="Shipping methods"
      primaryAction={{
        content: 'Save',
        onAction: handleSaveAction,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleChange,
        },
      ]}
    >
      {/* <Modal.Section> */}
      <div className="shipping_methods">
        <div className="shipping_head">
          <div>{'COMPANY'}</div>
          <div>
            {'EDT'}
            <Icon
              source={SortMinor}
              color="base"
            />
          </div>
          <div>
            {'COST'}
            <Icon
              source={SortMinor}
              color="base"
            />
          </div>
        </div>
        <div className="shiping_content">
          {lists.map((item, ind) => (
            <div key={ind}>
              <div>
                <RadioButton
                  label={item.ShippingMethod}
                  name="accounts"
                  id={item.ShippingMethod + ind}
                  checked={item.selected === 1}
                  onChange={() => handleRadioChange(ind)}
                />
              </div>
              <div>{item.DeliveryTime}</div>
              <div>
                $ {item.Cost} {item.currency_code}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* </Modal.Section> */}
    </Modal>
  )
}

export default ShippingModal
