/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-07-26 11:33:35
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2022-12-13 16:43:46
 * @FilePath: \pod-app-react\src\redux\reducers.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {
  ICONCARDSHOW,
  GETUSERINFO,
  GETMESSAGE,
  CHECKFAVORABLE,
  GETPRODUCTSTATUS,
} from '@/redux/constant'

/**
 * 动态切换iconCard显示与隐藏
 * @param {*} preState 上一个state
 * @param {*} action action 传递过来的 actions 对象
 */
export function iconCardReducer(preState = false, action) {
  const { type, data } = action
  switch (type) {
    case ICONCARDSHOW:
      return data
    default:
      return preState
  }
}

// 用户信息
export function userInfoReducer(preState = {}, action) {
  const { type, data } = action
  switch (type) {
    case GETUSERINFO:
      return data
    default:
      return preState
  }
}

// 消息列表
export function messageReducer(preState = [], action) {
  const { type, data } = action
  switch (type) {
    case GETMESSAGE:
      return data
    default:
      return preState
  }
}

/**
 * 判断是否弹出引导好评
 * @param {*} preState
 * @param {*} action
 * @returns
 */
export function favorableReducer(preState = {}, action) {
  const { type, data } = action
  switch (type) {
    case CHECKFAVORABLE:
      return data
    default:
      return preState
  }
}

/**
 * 成品状态
 * @param {*} preState
 * @param {*} action
 * @returns
 */
export function productStatusReducer(preState = {}, action) {
  const { type, data } = action
  switch (type) {
    case GETPRODUCTSTATUS:
      return data
    default:
      return preState
  }
}
