import { useState, useCallback, useEffect } from 'react'
import { Button, Modal, TextField } from '@shopify/polaris'
import _ from 'lodash'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {String} country 国家
 * @param {String} moneyFormat 货币符号
 * @param {Array} selectTableRow 所选变体列表
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const EditProfit = ({ isShow, country, moneyFormat, selectTableRow, onClose, onConfirm }) => {
  const [inputValue, setInputValue] = useState('')
  const [sourceData, setSourceData] = useState()
  const [isDisabled, setIsDisabled] = useState(true)
  const newData = _.cloneDeep(selectTableRow)

  useEffect(() => {
    _.forEach(newData, (item) => {
      item.profit = _.toString(item.profit)
    })
    setSourceData(newData)
  }, [isShow])

  // 关闭弹窗
  const closeModal = useCallback(() => {
    setInputValue('')
    setIsDisabled(true)
    onClose()
  }, [])

  // 输入框文本事件
  const handleChangeText = (value) => {
    value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
    setInputValue(value)
    if (value) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  // 修改单个价格
  const handleChange = (value, id) => {
    value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
    const data = [...sourceData]
    _.forEach(data, (item) => {
      if (item.podVariantId === id) {
        item.profit = value
      }
    })
    setSourceData(data)
  }

  // 修改所有价格
  const handleChangeAll = () => {
    const data = [...sourceData]
    _.forEach(data, (item) => {
      item.profit = inputValue
    })
    setSourceData(data)
    setInputValue('')
    setIsDisabled(true)
  }

  // 输入框失去焦点时
  const handleBlur = (e, item) => {
    if (e.target.value === '') {
      const data = [...sourceData]
      _.forEach(data, (el) => {
        if (el.podVariantId === item.podVariantId) {
          item.profit = '0'
        }
      })
      setSourceData(data)
    }
  }

  return (
    <Modal
      open={isShow}
      onClose={closeModal}
      title="Edit Profit"
      primaryAction={{
        content: 'Save',
        onAction: () => {
          onConfirm(sourceData)
          onClose()
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: closeModal,
        },
      ]}
    >
      <div className="Edit_Batch">
        <div className="Edit_Batch_Apply">
          <div className="Edit_Batch_Apply_Input">
            <TextField
              prefix={`${country} ${moneyFormat}`}
              label="Apply a profit to all variants"
              value={inputValue}
              onChange={handleChangeText}
              autoComplete="off"
            />
          </div>
          <Button
            onClick={handleChangeAll}
            disabled={isDisabled}
          >
            Apply to all
          </Button>
        </div>
        <div className="Edit_Batch_List">
          {_.map(sourceData, (item, index) => (
            <div
              key={index}
              className="Edit_Batch_List_Item"
            >
              <div className="Edit_Batch_List_Item_Title">
                {item.statement} / {item.optionTwo}
              </div>
              <div className="Edit_Batch_List_Item_Input">
                <TextField
                  prefix={`${country} ${moneyFormat}`}
                  value={item.profit}
                  onChange={(e) => {
                    handleChange(e, item.podVariantId)
                  }}
                  onBlur={(e) => {
                    handleBlur(e, item)
                  }}
                  autoComplete="off"
                  align="right"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default EditProfit
