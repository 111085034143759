/*
 * @Description: 订单商品列表
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-19 11:17:50
 * @LastEditTime: 2023-01-19 15:40:30
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Orders\ChildrenPages\OrderPaymentNew\components\Product\index.jsx
 */
import { TextField, Icon } from '@shopify/polaris'
import { MobileCancelMajor } from '@shopify/polaris-icons'
// 缩略图
import Image from '@/components/Image'
// 引入公共方法
import { ossSignatureUrl } from '@/utils/utils'
import _ from 'lodash'
import './index.scss'

const Product = ({ data }) => {
  return (
    <div className="OrderPayment-Product">
      <div className="Product-Header">
        <div className="Product-Title">Product</div>
        <div className="Product-Cost">Cost</div>
        <div className="Product-Quantity">Quantity</div>
        <div className="Product-Total">Total</div>
        <div className="Product-Option"></div>
      </div>
      <div className="Product-Main">
        {_.map(data, (item, index) => (
          <div
            className="Product-Row"
            key={index}
          >
            <div className="Product-Title">
              <div className="Product-Title-Image">
                <Image
                  src={ossSignatureUrl(item.imageUrl, {
                    'x-oss-process': 'image/resize,w_40',
                  })}
                  alt={item.title}
                  size="40"
                />
              </div>
              <div className="Product-Title-Box">
                <div>{item.title}</div>
                <div>
                  {item.color} / {item.size}
                </div>
                <div>SKU: {item.sku}</div>
              </div>
            </div>
            <div className="Product-Cost">$ {item.cost}</div>
            <div className="Product-Quantity">
              <TextField
                type="number"
                value={String(item.quantity)}
                onChange={() => {}}
                autoComplete="off"
              />
            </div>
            <div className="Product-Total">$ {item.total}</div>
            <div className="Product-Option">
              <Icon
                source={MobileCancelMajor}
                color="base"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Product
