import { useState, useCallback, useEffect, useMemo } from 'react'
import { Card, ContextualSaveBar, TextField, Button, Select, Toast } from '@shopify/polaris'
// 骨架屏
import LoadingPage from './components/LoadingPage'
// 全屏loading
import FullScreenLoading from '@/components/FullScreenLoading'
import _ from 'lodash'
import { getInformation, addRessSave, getSettingProvince } from '@/api/settings'
import Config from '@/utils/config'
import './index.scss'

const MyAccount = () => {
  // loading
  const [loadingInit, setLoadingInit] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  // 所有数据
  const [ruleForm, setRuleForm] = useState({})
  // Information
  const [information, setInformation] = useState({
    email: '',
    phone: '',
  })
  // Address for sample product shipping
  const [sampleAddress, setSampleAddress] = useState({
    country: '',
    code: '',
    street: '',
    suite: '',
    city: '',
    state: '',
  })
  // Address for refund shipping
  const [refundAddress, setRefundAddress] = useState({
    country: '',
    code: '',
    street: '',
    suite: '',
    city: '',
    state: '',
  })
  // stateList
  const [stateList, setStateList] = useState({
    sample: [],
    refund: [],
  })
  // Toast
  const [active, setActive] = useState(false)
  const [isError, setIsError] = useState(false)
  const [toastContent, setToastContent] = useState('')

  const toggleActive = useCallback(() => setActive((active) => !active), [])
  const toastMarkup = active ? (
    <Toast
      content={toastContent}
      error={isError}
      onDismiss={toggleActive}
    />
  ) : null

  useEffect(() => {
    initData()
    // eslint-disable-next-line
  }, [])

  // 初始化页面数据
  const initData = async () => {
    setLoadingInit(true)
    await getData()
    setLoadingInit(false)
  }

  // 获取地址
  const getData = async () => {
    const res = await getInformation()
    if (res) {
      setRuleForm(res)
      handleSetData(res) //设置页面显示数据
    }
  }

  // 设置页面显示数据
  const handleSetData = async (data) => {
    // Information
    setInformation(data.information || {})
    // Address for sample product shipping
    const sample =
      _.find(data.address, (item) => {
        return item.type === 1
      }) || {}
    setSampleAddress(sample)
    // Address for refund shipping
    const refund =
      _.find(data.address, (item) => {
        return item.type === 2
      }) || {}
    setRefundAddress(refund)
    // 获取state列表
    if (sample.country) {
      await getStateList('sample', sample.country)
    }
    if (refund.country) {
      await getStateList('refund', refund.country)
    }
  }

  // 获取State列表
  const getStateList = async (type, value) => {
    const list = await getSettingProvince(value)

    setStateList((preSatate) => ({
      ...preSatate,
      [type]: list,
    }))

    return list
  }

  // 保存
  const handleSave = async () => {
    try {
      setLoadingSave(true)
      const params = {
        information,
        productShipping: sampleAddress,
        refundShipping: refundAddress,
      }
      await addRessSave(params)
      // 保存成功后重新获取数据
      await getData()
      setActive(true)
      setToastContent('Successfully saved')
      setLoadingSave(false)
    } catch (e) {
      setLoadingSave(false)
      const msg = e.data && e.data.message ? e.data.message : 'Server Error'
      setActive(true)
      setIsError(true)
      setToastContent(msg)
    }
  }

  /**
   * 修改文本
   * @param {String} value 文本值
   * @param {String} type 类型
   * @param {String} field 字段名
   */
  const handleChange = useCallback(async (value, type, field) => {
    // console.log('value', value)
    // 修改country,实时查询state列表
    let state = ''
    if (field === 'country') {
      const stateList = await getStateList(type, value)

      // 切换country时，重置state的值
      if (!_.isEmpty(stateList)) {
        state = _.get(stateList[0], 'value')
      }
    }
    if (type === 'information') {
      setInformation((preSatate) => ({
        ...preSatate,
        [field]: value,
      }))
    } else if (type === 'sample') {
      setSampleAddress((preSatate) => {
        const obj = {
          ...preSatate,
          [field]: value,
        }
        // 切换country时，重置state的值
        if (field === 'country') {
          obj.state = state
        }
        return obj
      })
    } else {
      setRefundAddress((preSatate) => {
        const obj = {
          ...preSatate,
          [field]: value,
        }
        // 切换country时，重置state的值
        if (field === 'country') {
          obj.state = state
        }
        return obj
      })
    }
  }, [])

  // 是否有修改
  const isChange = useMemo(() => {
    // console.log(ruleForm)

    if (!_.isNil(ruleForm.information) || !_.isNil(ruleForm.address)) {
      // 比较Information是否有变动
      if (!_.isEqual(information, ruleForm.information)) {
        return true
      }
      // 比较sample address是否有变动
      const sample = _.find(ruleForm.address, (item) => {
        return item.type === 1
      })
      if (!_.isEmpty(sampleAddress) && !_.isNil(sample)) {
        if (!_.isEqual(sampleAddress, sample)) {
          return true
        }
      }
      // 比较refund address是否有变动
      const refund = _.find(ruleForm.address, (item) => {
        return item.type === 2
      })
      if (!_.isEmpty(refundAddress) && !_.isNil(refund)) {
        if (!_.isEqual(refundAddress, refund)) {
          return true
        }
      }
      return false
    }
    return false
  }, [ruleForm, information, sampleAddress, refundAddress])

  return (
    <div className="My-Account">
      {/* 骨架屏 */}
      {loadingInit && <LoadingPage />}

      {/* 内容区域 */}
      {!loadingInit && (
        <>
          {/* 顶部保存栏 */}
          {isChange && (
            <ContextualSaveBar
              message="Unsaved changes"
              saveAction={{
                onAction: handleSave,
                loading: false,
                disabled: false,
              }}
              discardAction={{
                onAction: () => handleSetData(ruleForm),
              }}
            />
          )}

          {/* 内容区域 */}
          <Card>
            <Card.Section>
              <h3>Information</h3>
            </Card.Section>
            <Card.Section>
              <div className="row">
                <div className="col-6">
                  <TextField
                    label="Email"
                    value={information.email}
                    onChange={(value) => {
                      handleChange(value, 'information', 'email')
                    }}
                    helpText="POD uses this to contact you"
                    autoComplete="off"
                  />
                </div>
                <div className="col-6">
                  <TextField
                    label="Phone"
                    value={information.phone}
                    onChange={(value) => {
                      handleChange(value, 'information', 'phone')
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Card.Section>
          </Card>

          <Card>
            <Card.Section>
              <h3>Address for sample product shipping</h3>
            </Card.Section>
            <Card.Section>
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Country/Region"
                    options={Config.CountryArrayConfig}
                    value={sampleAddress.country}
                    onChange={(value) => {
                      handleChange(value, 'sample', 'country')
                    }}
                  />
                </div>
                <div className="col-6">
                  <TextField
                    label="ZIP/Postal code"
                    value={sampleAddress.code}
                    onChange={(value) => {
                      handleChange(value, 'sample', 'code')
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TextField
                    label="Street"
                    value={sampleAddress.street}
                    onChange={(value) => {
                      handleChange(value, 'sample', 'street')
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TextField
                    label="Apartment, suite, etc"
                    value={sampleAddress.suite}
                    onChange={(value) => {
                      handleChange(value, 'sample', 'suite')
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <TextField
                    label="City"
                    value={sampleAddress.city}
                    onChange={(value) => {
                      handleChange(value, 'sample', 'city')
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="col-6">
                  {!_.isEmpty(stateList.sample) && (
                    <Select
                      multiple
                      label="State"
                      options={stateList.sample}
                      value={sampleAddress.state}
                      onChange={(value) => {
                        handleChange(value, 'sample', 'state')
                      }}
                    />
                  )}
                </div>
              </div>
            </Card.Section>
          </Card>

          <Card>
            <Card.Section>
              <h3>Address for refund shipping</h3>
            </Card.Section>
            <Card.Section>
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Country/Region"
                    options={Config.CountryArrayConfig}
                    value={refundAddress.country}
                    onChange={(value) => {
                      handleChange(value, 'refund', 'country')
                    }}
                  />
                </div>
                <div className="col-6">
                  <TextField
                    label="ZIP/Postal code"
                    value={refundAddress.code}
                    onChange={(value) => {
                      handleChange(value, 'refund', 'code')
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TextField
                    label="Street"
                    value={refundAddress.street}
                    onChange={(value) => {
                      handleChange(value, 'refund', 'street')
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TextField
                    label="Apartment, suite, etc"
                    value={refundAddress.suite}
                    onChange={(value) => {
                      handleChange(value, 'refund', 'suite')
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <TextField
                    label="City"
                    value={refundAddress.city}
                    onChange={(value) => {
                      handleChange(value, 'refund', 'city')
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="col-6">
                  {!_.isEmpty(stateList.refund) && (
                    <Select
                      multiple
                      label="State"
                      options={stateList.refund}
                      value={refundAddress.state}
                      onChange={(value) => {
                        handleChange(value, 'refund', 'state')
                      }}
                    />
                  )}
                </div>
              </div>
            </Card.Section>
          </Card>
          <div className="Setting-Save">
            <Button
              primary
              disabled={!isChange}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </>
      )}

      {/* Toast提示 */}
      {toastMarkup}

      {/* 保存loading */}
      <FullScreenLoading showLoading={loadingSave} />
    </div>
  )
}

export default MyAccount
