// 引入组件
import { AppProvider, Frame } from '@shopify/polaris'
import { BrowserRouter } from 'react-router-dom'
import TopBarMenu from './components/TopBarMenu/index'
import Router from '@/router/Router'
// 引入配置
import translations from '@shopify/polaris/locales/en.json'
// 引入shopify样式
import '@shopify/polaris/build/esm/styles.css'

const Layout = () => {
  return (
    <AppProvider i18n={translations}>
      <BrowserRouter>
        <Frame topBar={<TopBarMenu />}>
          <Router />
        </Frame>
      </BrowserRouter>
    </AppProvider>
  )
}

export default Layout
