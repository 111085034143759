/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-12-12 13:55:00
 * @LastEditTime: 2022-12-17 14:11:24
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\ColorPicker\index.jsx
 */
import { useState, useEffect } from 'react'
import { ChromePicker } from 'react-color'
import { Button } from '@shopify/polaris'
import _ from 'lodash'
import './index.scss'

/**
 * 接收参数说明
 * @param {String} color 颜色
 * @param {Boolean} disabled 是否可点击
 * @param {Function} onChange 改变颜色时的回调
 * @param {Function} onMouseUp 鼠标事件
 */

const ColorPicker = ({ color, disabled, onChange, onMouseUp }) => {
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setIsShow(false)
    })
  }, [])

  return (
    <div
      id="ColorPicker"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Button
        fullWidth
        icon={
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                className="Color-Icon"
                style={{
                  background: color,
                }}
              ></div>
              <span>{_.toUpper(color)}</span>
            </div>
          </>
        }
        onClick={() => {
          setIsShow(!isShow)
        }}
        disabled={disabled}
      ></Button>
      {isShow && (
        <div
          className="Color-Picker-Modal"
          onMouseUp={onMouseUp}
        >
          <ChromePicker
            color={color}
            onChange={onChange}
            disableAlpha={true}
          />
        </div>
      )}
    </div>
  )
}

export default ColorPicker
