/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-08-15 10:18:36
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2022-12-21 19:51:16
 * @FilePath: \pod-app-react\src\pages\ProductContentEdit\component\CopyProduct\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Modal, TextField, Checkbox, RadioButton, Toast } from '@shopify/polaris'
import { useEffect, useState, useCallback } from 'react'
import { getProductCopy } from '@/api/productContentEdit/index'

const CopyProduct = (props) => {
  const [titleValue, setTitleValue] = useState('')

  useEffect(() => {
    setTitleValue(props.titleValue)
  }, [props])

  const handleChange = useCallback(() => {
    setQuantitiesStatus(false)
    setRadioStatus(1)
    props.setDuplicate(!props.isShow)
  }, [props])
  const handleChange2 = useCallback((newValue) => setTitleValue(newValue), [props])
  // 库存选择
  const [quantitiesStatus, setQuantitiesStatus] = useState(false)
  const handleQuantitiesChange = useCallback(
    () => setQuantitiesStatus(!quantitiesStatus),
    [quantitiesStatus]
  )
  // 状态修改
  const [radioStatus, setRadioStatus] = useState(1)
  const handleStatusChange = (id) => {
    setRadioStatus(id)
  }

  const [showToast, setShowToast] = useState(false)
  const toggleActive = () => {
    setShowToast(!showToast)
  }

  const toastMarkup = showToast && (
    <Toast
      content="Product copied"
      onDismiss={toggleActive}
      duration={1200}
    />
  )

  // 复制按钮
  const handleDuplicate = async () => {
    let params = {
      finishedId: props.finishedId,
      isImages: 1,
      isSkus: 1,
      isNumber: quantitiesStatus ? 1 : 2,
      isActive: radioStatus,
      title: titleValue,
    }
    try {
      const res = await getProductCopy(params)
      handleChange()
      props.onConfirm(res)
      // setShowToast(true)
    } catch (error) {}
  }
  // 取消按钮
  const onCancel = () => {
    handleChange()
  }
  return (
    <Modal
      open={props.isShow}
      // open={false}
      onClose={onCancel}
      title="Duplicate product"
      primaryAction={{
        content: 'Duplicate',
        onAction: () => handleDuplicate(),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onCancel,
        },
      ]}
    >
      <div className="product_copy_modal">
        <TextField
          label="Title"
          value={titleValue}
          onChange={handleChange2}
          autoComplete="off"
        />
        <div className="product_copy_content">
          <div className="product_copy_select">
            <p>
              Select details to copy. All other details except 3D models and videos will be copied
              from the original product and any variants.
            </p>
            <Checkbox
              label="Images"
              checked={true}
              disabled
              // onChange={handleChange}
            />
            <Checkbox
              label="SKUs"
              checked={true}
              disabled
              // onChange={handleChange}
            />
            <Checkbox
              label="Inventory quantities"
              checked={quantitiesStatus}
              onChange={() => handleQuantitiesChange()}
            />
          </div>
          <div className="product_copy_radio">
            <RadioButton
              label={'Set as unpublished'}
              checked={radioStatus === 1}
              name="accounts"
              onChange={() => handleStatusChange(1)}
            />
            <RadioButton
              label={'Set as active'}
              checked={radioStatus === 2}
              name="accounts"
              onChange={() => handleStatusChange(2)}
            />
          </div>
        </div>
      </div>
      {toastMarkup}
    </Modal>
  )
}

export default CopyProduct
