import { request } from '../request'

// 产品查询
export async function getProductSearch(params) {
  return request({
    url: '/api/v1/order/finishedList',
    method: 'POST',
    data: params,
  })
}

// 添加查询产品
export async function getProductSearchAdd(params) {
  return request({
    url: '/api/v1/order/insert',
    method: 'POST',
    data: params,
  })
}

// 进入订单页面请求接口
export async function getProductOrderData(params) {
  return request({
    url: '/api/v1/order/show',
    method: 'POST',
    data: params,
  })
}

// sku数量修改
export async function getProductOrderSkuCount(params) {
  return request({
    url: '/api/v1/order/updateQuantity',
    method: 'POST',
    data: params,
  })
}

// 订单删除（单个sku）
export async function getProductOrderDelete(params) {
  return request({
    url: '/api/v1/order/delOrderShow',
    method: 'POST',
    data: params,
  })
}

// 订单复制
export async function getProductOrderCopy(params) {
  return request({
    url: '/api/v1/order/copy',
    method: 'POST',
    data: params,
  })
}

// 订单存为草稿
export async function getProductOrderSave(params) {
  return request({
    url: '/api/v1/order/save',
    method: 'POST',
    data: params,
  })
}

// 国家、州联调
export async function getProductOrderCountry(params) {
  return request({
    url: '/api/v1/order/getProvinceData',
    method: 'POST',
    data: params,
  })
}

// 订单地址添加
export async function getProductOrderAddressAdd(params) {
  return request({
    url: '/api/v1/order/addressInsert',
    method: 'POST',
    data: params,
  })
}

// 订单地址修改
export async function getProductOrderAddressSave(params) {
  return request({
    url: '/api/v1/order/addressSave',
    method: 'POST',
    data: params,
  })
}

// 选择物流方式
export async function getProductOrderShippingMethodSave(params) {
  return request({
    url: '/api/v1/order/shippingMethod',
    method: 'POST',
    data: params,
  })
}

// 订单id生成
export async function getProductOrderId(params) {
  return request({
    url: '/api/v1/order/payment',
    method: 'POST',
    data: params,
  })
}

// 订单支付完成
export async function getProductOrderPay(params) {
  return request({
    url: '/api/v1/order/payPalTransaction',
    method: 'POST',
    data: params,
  })
}

// 样品订单地址获取
export async function getOrderSampleAddress(params) {
  return request({
    url: '/api/v1/order/getAddress',
    method: 'POST',
    data: params,
  })
}

/**
 * 监听订单支付状态
 * @param {*} params
 */
export async function listenerPayStatus(params) {
  return request({
    url: '/api/v1/order/paymentProcess',
    method: 'POST',
    data: params,
  })
}

/**
 * 默认样品订单地址
 * @returns
 */
export async function defaultAddress() {
  return request({
    url: '/api/v1/order/showDefaultAddress',
    method: 'POST',
  })
}

/**
 * 删除订单
 * @returns
 */
export async function delOrder(data) {
  return request({
    url: '/api/v1/order/delOrder',
    method: 'POST',
    data,
  })
}
