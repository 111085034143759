import { request } from '../request'

/**
 * 国家、州联调
 * @param {*} params 国家
 * @returns
 */
export async function getSettingProvince(params) {
  const data = {
    countryCode: params,
  }
  return request({
    url: '/api/v1/order/getProvinceData',
    method: 'POST',
    data,
  })
}

/**
 * 获取地址信息，包含My account 里面的 Information数据
 * @returns
 */
export async function getInformation() {
  return request({
    url: '/api/v1/settings/address',
    method: 'POST',
  })
}

/**
 * 修改地址信息，包括修改 Information 数据
 * @param {*} params
 * @returns
 */
export async function addRessSave(params) {
  return request({
    url: '/api/v1/settings/addressSave',
    method: 'POST',
    data: params,
  })
}

/**
 * 修改设置shipping
 * @param {*} params
 * @returns
 */
export async function shippingSave(params) {
  return request({
    url: '/api/v1/settings/shippingSave',
    method: 'POST',
    data: params,
  })
}

/**
 * 获取设置信息数据包括（shippingData、notifyOrder、notifyProduct、主键Id）
 * @returns
 */
export async function shippingDetail() {
  return request({
    url: '/api/v1/settings/shipping',
    method: 'POST',
  })
}

/**
 * 获取用户图库列表
 * @param {*} params name 图片名称
 * @returns
 */
export async function getGalleryList(data) {
  return request({
    url: '/api/v1/settings/myFile',
    method: 'POST',
    data,
  })
}

/**
 * 图库图片上传（多个）
 * @param {*} file 资源
 * @param {*} uid uid
 * @returns
 */
export async function upload(data) {
  return request({
    // url: '/api/v1/upload',
    url: '/api/v1/uploadImages',
    method: 'POST',
    type: 'upload', // 走管理端接口
    data,
    contentType: 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
  })
}

export async function uploadPOD(data) {
  return request({
    url: '/api/v1/settings/addImages',
    method: 'POST',
    data,
  })
}

/**
 * 上传至 OSS
 * @returns
 */
export async function uploadOSS() {
  return request({
    // url: '/api/v1/upload',
    url: '/api/v1/test/ossSignature',
    method: 'POST',
    type: 'upload', // 走管理端接口
  })
}

/**
 * 移除图片
 * @param {*} data
 * @returns
 */
export async function delFileUpload(data) {
  return request({
    url: '/api/v1/settings/delFile',
    method: 'POST',
    // type: 'upload', // 走管理端接口
    data,
  })
}

/**
 * 图库图片上传（单个）
 * @param {*} file 资源
 * @param {*} uid uid
 * @returns
 */
export async function signleupload(data) {
  return request({
    url: '/api/v1/upload',
    method: 'POST',
    type: 'upload', // 走管理端接口
    data,
    contentType: 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
  })
}

/**
 * 移除图库图片
 * @param {*} params uid、imageUrl
 * @returns
 */
export async function delFile(data) {
  return request({
    url: '/api/v1/delFile',
    method: 'POST',
    type: 'upload',
    data,
  })
}
