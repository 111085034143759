/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-12-26 17:26:14
 * @LastEditTime: 2023-01-16 18:31:03
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Catalog\components\LoadingPage\index.jsx
 */
import { SkeletonPage, Layout, Card, SkeletonBodyText } from '@shopify/polaris'
import _ from 'lodash'
import { useEffect } from 'react'
import './index.scss'

const LoadingPage = () => {
  useEffect(() => {
    const width = document.body.clientWidth
    console.log('width', width)
  }, [])
  return (
    <div
      id="catalogLoadingPage"
      className="skeletonExample"
    >
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <div className="row">
              {_.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], (item) => (
                <div
                  key={item}
                  className="col-3 col-md-3 col-xs-4 col-sm-6"
                >
                  <Card sectioned>
                    <SkeletonBodyText />
                    <br />
                    <SkeletonBodyText />
                    <br />
                    <SkeletonBodyText />
                    <br />
                    <SkeletonBodyText />
                    <br />
                    <SkeletonBodyText />
                    <br />
                    <SkeletonBodyText />
                  </Card>
                </div>
              ))}
            </div>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </div>
  )
}

export default LoadingPage
