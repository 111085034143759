/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-18 16:07:37
 * @LastEditors: huang-chao hc18174419502@163.com
 * @LastEditTime: 2022-11-18 16:32:34
 * @FilePath: \pod-app-react\src\components\FavorableComm\components\writePaper.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'

const WritePaper = (props) => {
  return (
    <div>
      <div style={{ marginTop: '20px' }}>{props.write}</div>

      <div className="selling">{props.paper}</div>
    </div>
  )
}

export default WritePaper
