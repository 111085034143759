/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-08-19 09:24:07
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2023-02-16 10:39:08
 * @FilePath: \pod-app-react\src\pages\Catalog\components\DesignDetails\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Card,
  Tabs,
  Select,
  Modal,
  IndexTable,
  TextContainer,
  Icon,
  Tooltip,
  Heading,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  // SkeletonDisplayText,
} from '@shopify/polaris'
import _ from 'lodash'
import {
  CircleAlertMajor,
  MobileBackArrowMajor,
  ChevronLeftMinor,
  ChevronDownMinor,
} from '@shopify/polaris-icons'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
// 切换颜色组件
import { CirclePicker } from 'react-color'
import PodSize from '@/components/PodSize'
// import ReactCountryFlag from 'react-country-flag'
import CountryFlag from '@/components/CountryFlag'
import Config from '@/utils/config'
import { getCatelogOrDesignDetails, getShippings } from '@/api/catelog'
import { ossSignatureUrl } from '@/utils/utils'
import Description from './components/Description'
import './index.scss'

// 变体图配置
const globalConfig = {
  showPlayButton: false,
  showFullscreenButton: false,
}

const tabs = [
  {
    id: 'character-1',
    content: 'Character',
    accessibilityLabel: 'Character',
    panelID: 'character-content-1',
  },
  {
    id: 'shipping-1',
    content: 'Shipping',
    panelID: 'shipping-content-1',
  },
]

const DesignDetails = (props) => {
  const {
    location: { state: id },
  } = props // 解构品类id
  const [hex, setHex] = useState('')
  const [selected, setSelected] = useState(0)

  const handleTabChange = (tabIndex) => {
    setSelected(tabIndex)
  }

  const [descShow, setDescShow] = useState('') // 描述
  // const [pics, setPics] = useState([]) // 轮播图
  // const [variants, setVariants] = useState([])
  //

  const [productInfo, setProductInfo] = useState({}) // 产品详情
  const [proTitle, setProTitle] = useState('') // 产品标题
  const [moneyFormat, setMoneyFormat] = useState('') // 货币符号
  const [proPrice, setProPrice] = useState('') // 产品价格
  const [shiptime, setShipTime] = useState('') // 运输时长
  const [currentImages, setCurrentImages] = useState([]) //组合前后视图+轮播图

  const [inches, setInches] = useState([]) // 尺码表数据
  const [centimes, setCentimes] = useState([]) // 英尺表数据

  const [styleDesc, setStyleDesc] = useState('')
  const [techquieDesc, setTechquieDesc] = useState('')
  const [materialDesc, setMaterialDesc] = useState('')
  const [detailDesc, setDetailDesc] = useState('')
  const [sizeList, setSizeList] = useState([]) // 尺码集合
  const [colorList, setColorList] = useState([]) // 颜色集合
  const [countryFlag, setCountryFlag] = useState('') // 国家数组数据集
  const [selectCountry, setSelectCountry] = useState('AF')

  // 选择shipping国家
  const handleSelectCountryChange = (value) => {
    setSelectCountry(value)
    getWeightShippings(value)
  }
  // 国家数组数据集
  const countryOptions = Config.CountryArrayConfig
  /**
   * 根据国家获取运输方式
   * 1.在进入页面后就调用一次，默认第一次country值为：AF
   * @param {*} country 国家
   */
  const [weightShips, setWeightShips] = useState([])
  const [firstWeightship, setFirstWeightship] = useState({})
  const [pictures, setPictures] = useState([])
  const getWeightShippings = useCallback(
    async (country) => {
      const id = props.location.state.id
      const data = {
        id,
        country,
      }
      const result = await getShippings(data)
      if (result.length > 0) {
        setWeightShips(result)
        setFirstWeightship(result[0])
      } else {
        setWeightShips([])
        setFirstWeightship({})
      }
    },
    [props.location.state.id]
  )

  //初始化品类详情数据
  const initData = useCallback(async () => {
    const response = await getCatelogOrDesignDetails(id)
    const {
      centimeData,
      sizesData,
      color,
      country,
      size,
      // sizes,
      description,
      descriptionShow,
      images,
      pictures,
      title,
      moneyFormat,
      price,
      time,
      // variantData,
    } = response
    setProductInfo(response)
    setSelectCountry(country)
    getWeightShippings(country) // 获取物流数据
    setPictures(pictures) // 轮播图
    setCountryFlag(country)
    setCentimes(centimeData) // 英尺表数据
    setInches(sizesData) // 尺码表数据
    setColorList(color)
    setSizeList(size)
    setProTitle(title) // 产品标题
    setMoneyFormat(moneyFormat)
    setProPrice(price) // 产品价格
    // setProFreight(freight)
    setShipTime(time) // 运输时长
    setStyleDesc(description[0].style) // 描述数组数据
    setTechquieDesc(description[0].printTechquie)
    setMaterialDesc(description[0].material)
    setDetailDesc(description[0].detail)

    setDescShow(descriptionShow) // 描述

    if (!_.isEmpty(pictures)) {
      _.forEach(pictures, (item) => {
        const result = ossSignatureUrl(item)
        const imgs = {
          original: result,
          thumbnail: result,
          originalHeight: 450,
          thumbnailHeight: 90, // 缩略图高度
        }
        currentImages.push(imgs)
      })
      setCurrentImages(currentImages)
    }
  }, [currentImages, getWeightShippings, id])

  // 页面是否处于loading状态
  const [active, setActive] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  useEffect(() => {
    setIsLoadingPage(true) // 开启loadingPage
    initData()
    setTimeout(() => {
      setIsLoadingPage(false) // 关闭loadingPage
    }, 800)
  }, [initData])

  // 开启Size Chart Modal
  const handleChartOpenModal = () => {
    setActive(!active)
  }

  const [selectSized, setSelectSized] = useState(0)

  const handleSizeTabChange = (sizeIndex) => {
    setSelectSized(sizeIndex)
  }

  const sizeTabs = [
    {
      id: 'inches-1',
      content: 'Inches',
      accessibilityLabel: 'Inches',
      panelID: 'inches-content-1',
    },
    {
      id: 'centimeters-1',
      content: 'Centimeters',
      panelID: 'centimeters-content-1',
    },
  ]

  const inchesList = [
    {
      id: '3411',
      sizeLabel: 'S',
      Length: '28',
      Width: '26',
    },
    {
      id: '2561',
      sizeLabel: 'M',
      Length: '28',
      Width: '26',
    },
  ]

  const centimetersList = [
    {
      id: '3411',
      sizeLabel: 'XL',
      Length: '28',
      Width: '26',
    },
    {
      id: '2561',
      sizeLabel: '2XL',
      Length: '28',
      Width: '26',
    },
  ]

  // 尺码列表表数据渲染
  const rowInches = inches.map(({ optionOne, length, width }, index) => (
    <IndexTable.Row
      id={index}
      key={index}
      position={index}
    >
      <IndexTable.Cell>{optionOne}</IndexTable.Cell>
      <IndexTable.Cell>{length}</IndexTable.Cell>
      <IndexTable.Cell>{width}</IndexTable.Cell>
    </IndexTable.Row>
  ))

  // 尺码英尺列表表数据渲染
  const rowCentimeters = centimes.map(({ optionOne, length, width }, index) => (
    <IndexTable.Row
      id={index}
      key={index}
      position={index}
    >
      <IndexTable.Cell>{optionOne}</IndexTable.Cell>
      <IndexTable.Cell>{length}</IndexTable.Cell>
      <IndexTable.Cell>{width}</IndexTable.Cell>
    </IndexTable.Row>
  ))

  const [moreShip, setMoreShip] = useState(false)

  const handleLookMoreShip = () => {
    setMoreShip(!moreShip)
  }

  // 面包屑返回到catelog列表
  const handleBack = () => {
    props.history.push('/catalog')
  }

  // 进入设计页面
  const handleDesign = () => {
    const id = props.location.state.id
    props.history.push({
      pathname: '/confugureDesign',
      state: {
        productId: id,
        color: hex,
      },
    })
  }

  // 切换颜色
  const handleColor = (hex) => {
    setHex(hex)
    // console.log(pictures)
    const gallery_imageDOM = document.getElementsByClassName('image-gallery-image')
    const thumbnail_imageDOM = document.getElementsByClassName('image-gallery-thumbnail-image')
    _.forEach(gallery_imageDOM, (gallery) => {
      gallery.style.backgroundColor = hex
    })
    _.forEach(thumbnail_imageDOM, (thumbnail) => {
      thumbnail.style.backgroundColor = hex
    })
  }

  // 修改颜色hover提示
  const handleColorHover = (color, event) => {
    // console.log('color', color, event)
    const title = _.get(
      _.find(productInfo.variantData, (item) => {
        return _.toLower(item.color) === _.toLower(color.hex)
      }),
      'statement'
    )
    event.target.title = title
  }

  // 展开shipping模块
  const handleMore = () => {
    setSelected(1)
  }

  // 初始化加载数据前的状态
  const SkeletonExample = (
    <SkeletonPage
      primaryAction
      fullWidth={true}
    >
      <Layout>
        <Layout.Section>
          <div className="row">
            <div className="col-5">
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
              </Card>
            </div>
            <div className="col-7">
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
              </Card>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
              </Card>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
              </Card>
            </div>
          </div>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )

  const pageContent = isLoadingPage ? (
    SkeletonExample
  ) : (
    <>
      <div style={{ marginTop: '35px' }}>
        <Button
          icon={MobileBackArrowMajor}
          onClick={handleBack}
        ></Button>
        <span style={{ margin: '0 0 0 1rem', lineHeight: '35px' }}>
          <Button
            plain
            onClick={handleBack}
          >
            Catalog
          </Button>{' '}
          / {proTitle}
        </span>
      </div>

      <div
        className="row"
        style={{ marginTop: '35px' }}
      >
        {/* 左边产品变体图片数据 */}
        <div className="col-6 galleryContainer">
          <ImageGallery
            items={currentImages}
            {...globalConfig}
          />
        </div>

        {/* 右边产品详情 */}
        <div className="col-6">
          <Heading>{proTitle}</Heading>
          <br />
          <h2
            className="Price"
            style={{ fontSize: '22px', color: '#f00' }}
          >
            {moneyFormat + ' ' + proPrice}
          </h2>
          <br />

          {/* 颜色选取部分 */}
          <span style={{ color: '#778ca3' }}>Color</span>
          <div style={{ marginTop: '10px' }}>
            <CirclePicker
              width="100%"
              colors={colorList}
              circleSize={28}
              onChange={(color) => {
                handleColor(color.hex)
              }}
              onSwatchHover={handleColorHover}
            />
          </div>

          {/* 尺码选取部分 */}
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '480px' }}>
            <div style={{ color: '#778ca3' }}>Size</div>
            <div>
              <Button
                plain
                onClick={handleChartOpenModal}
              >
                Size Chart
              </Button>
            </div>
          </div>
          <PodSize lable={sizeList} />

          {/* Estimated delivery to */}
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '480px',
                marginTop: '26px',
              }}
            >
              <div style={{ color: '#778ca3', position: 'relative' }}>
                <span>Estimated delivery to</span>
                <div style={{ position: 'absolute', left: '10em', bottom: '0' }}>
                  <Tooltip content="Production time is not included. Shipping times are approximate and may vary">
                    <Icon
                      source={CircleAlertMajor}
                      color="base"
                    />
                  </Tooltip>
                </div>
              </div>
              <div>
                <Button
                  plain
                  onClick={handleMore}
                >
                  More
                </Button>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '480px',
                marginTop: '2rem',
              }}
            >
              <CountryFlag country={countryFlag} />
              <div
                style={{
                  fontWeight: '500',
                  fontSize: '1.8rem',
                  margin: '1rem 10rem 1rem 0',
                }}
              >
                {shiptime}
              </div>
            </div>
            <div className="StartDesign_Btn">
              <Button
                primary
                onClick={() => handleDesign()}
              >
                Start Design
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* tabs */}
      <div className="row Tabs_Container">
        <div className="col-12">
          <Card>
            <Tabs
              tabs={tabs}
              selected={selected}
              onSelect={handleTabChange}
            >
              <Card.Section>
                {selected === 0 ? (
                  <div className="row Character_Container">
                    <div className="col-3">
                      <Heading>Print technique</Heading>
                      <div className="Character_Context ">
                        <div dangerouslySetInnerHTML={{ __html: techquieDesc }}></div>
                      </div>
                    </div>
                    <div className="col-3">
                      <Heading>Style</Heading>
                      <div className="Character_Context ">
                        <div dangerouslySetInnerHTML={{ __html: styleDesc }}></div>
                      </div>
                    </div>
                    <div className="col-3">
                      <Heading>Material</Heading>
                      <div className="Character_Context ">
                        <div dangerouslySetInnerHTML={{ __html: materialDesc }}></div>
                      </div>
                    </div>
                    <div className="col-3">
                      <Heading>Detail</Heading>
                      <div className="Character_Context ">
                        <div dangerouslySetInnerHTML={{ __html: detailDesc }}></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <table
                      className="shiping_table"
                      style={{ width: '100%' }}
                    >
                      <thead>
                        <tr>
                          <th
                            align="left"
                            width="20%"
                          >
                            Country
                          </th>
                          <th
                            align="left"
                            width="45%"
                          >
                            Services
                          </th>
                          <th
                            align="left"
                            width="13%"
                          >
                            Shipping_time
                          </th>
                          <th
                            align="left"
                            width="10%"
                          >
                            First
                          </th>
                          <th
                            align="left"
                            width="10%"
                          >
                            Additional
                          </th>
                          <th
                            align="center"
                            width="2%"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td align="left">
                            <Select
                              options={countryOptions}
                              onChange={handleSelectCountryChange}
                              value={selectCountry}
                            />
                          </td>
                          <td align="left">
                            <span>{!_.isEmpty(firstWeightship) ? firstWeightship.name : ''}</span>
                          </td>
                          <td align="left">
                            <span>{!_.isEmpty(firstWeightship) ? firstWeightship.time : ''}</span>
                          </td>
                          <td align="left">
                            <span>
                              {!_.isEmpty(firstWeightship)
                                ? `${firstWeightship.moneyFormat} ${firstWeightship.freight}`
                                : ''}
                            </span>
                          </td>
                          <td align="left">
                            <span>
                              {!_.isEmpty(firstWeightship)
                                ? `${firstWeightship.moneyFormat} ${firstWeightship.additional}`
                                : ''}
                            </span>
                          </td>
                          {
                            <td>
                              {
                                <div
                                  style={{
                                    width: '25px',
                                    height: '20px',
                                    cursor:
                                      weightShips && weightShips.length > 1 ? 'pointer' : 'default',
                                    textAlign: 'center',
                                  }}
                                  onClick={handleLookMoreShip}
                                >
                                  {weightShips && weightShips.length > 1 && moreShip && (
                                    <Icon
                                      source={ChevronDownMinor}
                                      color="base"
                                    />
                                  )}
                                  {weightShips && weightShips.length > 1 && !moreShip && (
                                    <Icon
                                      source={ChevronLeftMinor}
                                      color="base"
                                    />
                                  )}
                                </div>
                              }
                            </td>
                          }
                        </tr>
                        {moreShip === true &&
                          //点击查看更多运输方式数据
                          weightShips.length > 0 &&
                          weightShips.map((item, key) => {
                            return (
                              key >= 1 && (
                                <tr key={key}>
                                  <td></td>
                                  <td align="left">{item.name}</td>
                                  <td align="left">{item.time}</td>
                                  <td align="left">{item.moneyFormat + ' ' + item.freight}</td>
                                  <td align="left">{item.moneyFormat + ' ' + item.additional}</td>
                                </tr>
                              )
                            )
                          })}
                      </tbody>
                    </table>
                  </>
                )}
              </Card.Section>
            </Tabs>
          </Card>
        </div>
      </div>

      {/* Description */}
      <Description descShow={descShow} />
    </>
  )

  return (
    <div>
      {/* 主内容区域 */}
      {pageContent}

      {/* 尺码 Modal 区域 */}
      <Modal
        title="Size chart"
        // titleHidden={true}
        open={active}
        onClose={handleChartOpenModal}
      >
        <Modal.Section>
          <TextContainer>
            <Tabs
              tabs={sizeTabs}
              selected={selectSized}
              onSelect={handleSizeTabChange}
            >
              <Card.Section>
                {selectSized === 0 ? (
                  <IndexTable
                    itemCount={inchesList.length}
                    headings={[{ title: 'Size label' }, { title: 'Length' }, { title: 'Width' }]}
                    selectable={false}
                  >
                    {rowInches}
                  </IndexTable>
                ) : (
                  <IndexTable
                    itemCount={centimetersList.length}
                    headings={[{ title: 'Size label' }, { title: 'Length' }, { title: 'Width' }]}
                    selectable={false}
                  >
                    {rowCentimeters}
                  </IndexTable>
                )}
              </Card.Section>
            </Tabs>
          </TextContainer>
        </Modal.Section>
        <Modal.Section>
          <p style={{ color: '#747d8c' }}>Product measurements may vary by up to 2" (5 cm).</p>
        </Modal.Section>
      </Modal>
    </div>
  )
}

export default DesignDetails
