import { Button, Modal } from '@shopify/polaris';
import React from 'react';
import { useHistory } from 'react-router-dom'
const OrderTypeSelectModal = (props) => {
    const router = useHistory();
    const handleSampleChange = ()=>{
        router.push('/orderPayment?type=sample')
    };
    const handleBasicChange = ()=>{
        router.push('/orderPayment?type=basic')
    };
    return (
        <div className='order_type_modal'>
            <Modal
                open={props.show}
                title={'Please select an order type.'}
                onClose={()=>props.handleOnShowTypeSelectOff()}
            >
                <Modal.Section>
                    <div className='order_type_select'>
                        <Button primary onClick={() => handleSampleChange()}>{'Sample order'}</Button>
                        <Button primary onClick={() => handleBasicChange()}>{'Basic order'}</Button>
                    </div>
                </Modal.Section>
            </Modal>
        </div>
    );
}

export default OrderTypeSelectModal;
