import { Card, Layout, SkeletonBodyText, TextContainer } from '@shopify/polaris'

const LoadingPage = () => {
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>
            <TextContainer>
              <SkeletonBodyText lines={1} />
              <SkeletonBodyText lines={2} />
              <SkeletonBodyText lines={2} />
            </TextContainer>
          </Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  )
}

export default LoadingPage
