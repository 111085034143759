import React from 'react'
import { Spinner } from '@shopify/polaris'
import './index.scss'

const FullScreenLoading = ({ showLoading }) => {
  return (
    showLoading && (
      <div className="loading-mask">
        <Spinner
          accessibilityLabel="Spinner example"
          size="large"
        />
      </div>
    )
  )
}

export default FullScreenLoading
