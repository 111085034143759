/*
 * @Description:
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-02 14:09:48
 * @LastEditTime: 2022-12-14 15:28:01
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\utils\appBridge.js
 */
import { createApp } from '@shopify/app-bridge'

export default class AppBridge {
  /**
   * 判断是否存在该实例
   */
  static exist() {
    return this.app !== undefined
  }

  static set(host) {
    const apiKey = process.env.REACT_APP_APPID
    this.app = createApp({ apiKey, host })
  }
}
