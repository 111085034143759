import { useState, useCallback } from 'react'
import { RadioButton, Button, Toast } from '@shopify/polaris'
import _ from 'lodash'
import { getProductOrderShippingMethodSave } from '@/api/orderBuild/index'
import './index.scss'

/**
 * 参数说明
 * @param {String | Number} orderId 订单id
 * @param {String | Number} shippingId 物流id
 * @param {Array} data 物流列表
 * @param {Function} onClose 关闭回调
 * @param {Function} onConfirm 确认回调
 */
const Shipping = ({ orderId, shippingId, data, onClose, onConfirm }) => {
  const [tableData, setTableData] = useState(data) // 表格数据
  // Toast
  const [showToast, setShowToast] = useState(false)
  const [isError, setIsError] = useState(false)
  const [toastContent, setToastContent] = useState('')

  // 修改物流方式
  const handleChange = useCallback(
    (_checked, newValue) => {
      // console.log('value', newValue)
      const data = _.cloneDeep(tableData)
      _.forEach(data, (el) => {
        if (el.ShippingMethod === newValue) {
          el.selected = 1
        } else {
          el.selected = 0
        }
      })
      setTableData(data)
    },
    [tableData]
  )

  // 保存
  const handleSave = async () => {
    const params = {
      shippingId, // 物流id
      podOrderId: orderId, // 订单id
      shippingMethod: tableData, // 物流列表数据
    }
    try {
      await getProductOrderShippingMethodSave(params)
      onConfirm({
        orderId,
        shippingList: tableData,
      })
      onClose()
    } catch (e) {
      const msg = e.data ? e.data.message : 'Server Error'
      setShowToast(true)
      setIsError(true)
      setToastContent(msg)
    }
  }

  // 是否显示Toast
  const toggleActive = useCallback(() => setShowToast((active) => !active), [])

  // Toast
  const toastMarkup = showToast ? (
    <Toast
      content={toastContent}
      error={isError}
      onDismiss={toggleActive}
    />
  ) : null

  const rowMarkup = _.map(tableData, (item, index) => (
    <tr key={index}>
      <td>
        <RadioButton
          label={item.ShippingMethod}
          checked={item.selected === 1}
          id={item.ShippingMethod}
          name="accounts"
          onChange={handleChange}
        />
      </td>
      <td>{item.DeliveryTime}</td>
      <td>
        $ {item.Cost} {item.currency_code}
      </td>
    </tr>
  ))

  return (
    <div className="Shipping">
      <h3>Shipping methods</h3>
      <table className="Shipping-Table">
        <thead>
          <tr>
            <th style={{ width: '415px' }}>COMPANY</th>
            <th style={{ width: '96px' }}>EDT</th>
            <th>COST</th>
          </tr>
        </thead>
        <tbody>{rowMarkup}</tbody>
      </table>
      <div className="Shipping-Button-Group">
        <Button onClick={onClose}>Cancel</Button>
        <Button
          primary
          onClick={handleSave}
        >
          Save
        </Button>
      </div>

      {/* Toast提示 */}
      {toastMarkup}
    </div>
  )
}

export default Shipping
