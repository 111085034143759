import { request } from "../request";


// 订单列表
export async function getOrderList(params) {
  return  request({
    url: '/api/v1/order/index',
    method: 'POST',
    data: params,
  });
}
// 订单状态修改
export async function getOrderStatusEdit(params) {
  return  request({
    url: '/api/v1/order/status',
    method: 'POST',
    data: params,
  });
}

// 订单标签添加
export async function getOrderTagsAdd(params) {
  return  request({
    url: '/api/v1/order/addTag',
    method: 'POST',
    data: params,
  });
}
// 订单标签删除
export async function getOrderTagsDelete(params) {
  return  request({
    url: '/api/v1/order/removeTag',
    method: 'POST',
    data: params,
  });
}






