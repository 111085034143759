/*
 * @Description: 变体弹窗
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-11-23 09:29:39
 * @LastEditTime: 2022-12-21 18:02:57
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\Variant\index.jsx
 */
import React, { useState, useEffect, useCallback } from 'react'
import { Modal, TextContainer } from '@shopify/polaris'
import _ from 'lodash'
import VariantsTable from './components/VariantsTable'
import './index.scss'

/**
 * 参数说明
 * @param {Boolean} isShow 是否显示弹窗
 * @param {Array} data 变体列表
 * @param {Array} checkVariants 所选变体列表
 * @param {String} country 国家
 * @param {String} moneyFormat 货币符号
 * @param {String} imageUrl 图片地址
 * @param {Function} onClose 关闭弹窗回调
 * @param {Function} onConfirm 点击确认回调
 */

const Variant = ({
  isShow,
  data,
  country,
  moneyFormat,
  imageUrl,
  checkVariants,
  onClose,
  onConfirm,
}) => {
  const [active, setActive] = useState(isShow) // 是否显示弹窗
  const [checkList, setCheckList] = useState(checkVariants) // 所选变体列表

  useEffect(() => {
    setActive(isShow)
  }, [isShow])

  // 打开或关闭弹窗
  const handleChange = useCallback(() => {
    onClose()
    setActive(!active)
  }, [active])

  const onChange = useCallback((list) => {
    setCheckList(list)
  })

  return (
    <div id="Variant">
      <Modal
        title="Variants"
        open={active}
        onClose={handleChange}
        primaryAction={{
          content: 'Done',
          onAction: () => {
            onConfirm(checkList)
            onClose()
          },
          disabled: _.isEmpty(checkList),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: onClose,
          },
        ]}
        footer={
          <TextContainer>Shopify will create a new product when variants over 100</TextContainer>
        }
      >
        <VariantsTable
          data={data}
          country={country}
          moneyFormat={moneyFormat}
          imageUrl={imageUrl}
          checkVariants={checkVariants}
          onChange={onChange}
        />
      </Modal>
    </div>
  )
}

export default Variant
