/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-09 09:56:15
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2023-01-05 15:15:41
 * @FilePath: \pod-app-react\src\components\FavorableComm\index.jsx
 * @Description: 引导好评组件
 */
import React, { useState, useEffect } from 'react'
import { Modal, TextContainer, TextField, Toast } from '@shopify/polaris'
import _ from 'lodash'
import { reviewStore } from '@/api/favorable/index'
import StarRating from './components/StarRating'
import WritePaper from './components/WritePaper'
import './index.scss'

const FavorableComm = (props) => {
  // const [active, setActive] = useState(!window.isShowFavora ? false : true)
  const [active, setActive] = useState(props.isShowFavora ? true : false)

  const [toggleToast, setToggleToast] = useState(false)
  const [remarks, setRemarks] = useState('') // 评论内容数据
  const [place, setPlace] = useState('BAD') // 评论内容默认提示文字
  const [errorMsg, setErrorMsg] = useState('') // 不符合评论内容的错误信息提示
  const [isShowContent, setIsShowContent] = useState(false) //控制文本框、提交按钮显示与隐藏
  const [toastContent, setToastContent] = useState({}) // toast内容
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    // window.isShowFavora = false
  }, [])

  // 关闭弹窗
  const handleClose = () => {
    // setActive(false)
    // setStore('isShowFavora', false)
    // window.isShowFavora = false
    setActive(false)
    window.isShowFavora = false
    props.closeFavorableComm()
  }

  // 评论内容
  const handleRemarkChange = (value) => {
    setRemarks(value)
  }

  const toggleActive = (error) => {
    if (!_.isEmpty(error)) {
      setToastContent(
        error && error.data && error.data.message ? error.data.message : 'Server error'
      )
      setToggleToast(true)
      setIsError(true)
    }
  }

  const closeToggle = () => {
    setToggleToast(!toggleToast)
  }

  const toastMarkup = toggleToast ? (
    <Toast
      content={toastContent}
      onDismiss={closeToggle}
      error={isError}
    />
  ) : null

  const [starCount, setStarCount] = useState(0)

  // 回调用户勾选星星个数
  const getStarCountFn = (starNum) => {
    switch (Number(starNum)) {
      case 1:
        setIsShowContent(true)
        setStarCount(1)
        setPlace('BAD')
        break
      case 2:
        setStarCount(2)
        setIsShowContent(true)
        setPlace('NO-GOOD')
        break
      case 3:
        setStarCount(3)
        setIsShowContent(true)
        setPlace('AVERAGE')
        break
      case 4:
        setStarCount(4)
        setIsShowContent(true)
        setPlace('NOT BAD')
        break
      case 5:
        setIsShowContent(false)
        // setActive(false)
        const target = window.open('about:blank')
        target.location.href =
          'https://apps.shopify.com/printing-one-print-on-demand?locale=zh-CN&source=evaluate#modal-show=WriteReviewModal'
        break
      default:
        break
    }
  }

  /**
   * 提交好评数据
   * 1.校验内容字符限制
   * 2.符号校验清除 errorMsg
   */
  const [submitAfter, setSubmitAfter] = useState(false)
  const handleSubmit = async () => {
    try {
      if (_.size(remarks) < 12) {
        setErrorMsg('Please enter at least 12 characters')
        return false
      } else {
        setErrorMsg('')
        const data = {
          star: starCount,
          content: remarks,
        }
        await reviewStore(data)
        setIsShowContent(false)
        setSubmitAfter(true)
        // setStore('isShowFavora', false)
        // setActive(false)
      }
    } catch (error) {
      toggleActive(error)
    }
  }

  const [submitIcon] = useState('/images/icon/submit.svg')

  // 处理引导好评触发条件三种情况
  const judgSource = () => {
    let write, paper
    switch (props.mark) {
      case 0: // 代表是设计完成第一个产品点击Edit it later来
        write = `This is your first time completing a product in POD. I'm glad you and us witnessed it
        together!`
        paper = `Publish your product online and start selling`
        return (
          <WritePaper
            write={write}
            paper={paper}
          />
        )

      case 1: // 代表是设计完成第一个产品点击Publish来
        paper = `Customers can place orders and you are going to earn revenue.`
        return (
          <WritePaper
            write={
              <div className="afterSubmitToop">
                <img
                  src={submitIcon}
                  alt=""
                />
                <span>The product published</span>
              </div>
            }
            paper={paper}
          />
        )

      case 2: // 代表是从订单支付过来用户完成第一笔下单
        paper = `We will deliver the package to you as soon as possible`
        return (
          <WritePaper
            write={
              <div className="afterSubmitToop">
                <img
                  src={submitIcon}
                  alt=""
                />
                <span>Successfully ordered</span>
              </div>
            }
            paper={paper}
          />
        )
      default:
        break
    }
  }

  const submitBeforeContent = (
    <div>
      <Modal.Section>
        <TextContainer>{judgSource()}</TextContainer>
      </Modal.Section>

      <Modal.Section>
        <TextContainer>
          <div className="tool">How do you feel about the design tools & product tools?</div>
        </TextContainer>
        {/* 星级组件 */}
        <StarRating getStarCountFn={getStarCountFn} />
        {isShowContent && (
          <div className="textAreaDiv">
            <TextField
              value={remarks}
              onChange={handleRemarkChange}
              multiline={4}
              maxLength={300}
              error={errorMsg}
              autoComplete="off"
              placeholder={`The APP is ${place} because....`}
            />
            <div className="explain">At least 12 characters</div>
          </div>
        )}
      </Modal.Section>
    </div>
  )

  const submitAfterContent = (
    <div>
      <Modal.Section>
        <TextContainer>
          <div className="afterSubmitToop">
            <img
              src={submitIcon}
              alt=""
            />
            <span>Submitted.</span>
          </div>
          <div className="afterContent">
            Thank you for your feedback and suggestions. You are our driving force, and we will
            always strive to serve you.
          </div>
          <div className="afterHeart">
            <img
              src="/images/heart.png"
              alt=""
            />
          </div>
        </TextContainer>
      </Modal.Section>
    </div>
  )

  return (
    <div>
      <Modal
        open={props.isShowFavora}
        // open={active}
        onClose={handleClose}
        title=""
        primaryAction={
          isShowContent
            ? {
                content: 'Submit',
                onAction: handleSubmit,
              }
            : null
        }
        secondaryActions={
          isShowContent
            ? [
                {
                  content: 'Cancel',
                  onAction: handleClose,
                },
              ]
            : null
        }
      >
        {submitAfter ? submitAfterContent : submitBeforeContent}
      </Modal>
      {toastMarkup}
    </div>
  )
}

export default FavorableComm
