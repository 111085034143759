import { Card, Button, Spinner } from '@shopify/polaris'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import LazyLoad from 'react-lazyload'
import './index.scss'

const ProductCateList = ({ list }) => {
  const history = useHistory()

  // 进入成品详情页面handleViewDetail
  const handleViewDetail = (catelogId) => {
    history.push({
      pathname: '/designDetails',
      state: {
        id: catelogId,
      },
    })
  }

  // 进入设计页面
  const handleConfugureDesign = (catelogId) => {
    history.push({
      pathname: '/confugureDesign',
      state: {
        productId: catelogId,
      },
    })
  }

  return (
    <div className="ProductCateList row">
      {!_.isEmpty(list) &&
        _.map(list, (item) => (
          <div
            key={item.id}
            className="col-3 col-md-3 col-xs-4 col-sm-6"
          >
            <Card>
              <div className="ProductCateList-Image">
                <LazyLoad
                  height={200}
                  once
                  offset={200}
                  overflow
                  placeholder={
                    <Spinner
                      accessibilityLabel="Spinner example"
                      size="large"
                    />
                  }
                >
                  <img
                    src={item.picturesImage}
                    alt="print"
                    width="100%"
                    height="100%"
                  />
                </LazyLoad>
              </div>
              <div className="ProductCateList-Main">
                <div className="ProductCateList-Title">{item.title}</div>
                <div className="ProductCateList-Price">{`${item.moneyFormat} ${item.price}`}</div>
                <div className="ProductCateList-Rows">
                  <span>{item.SizeCount} sizes</span>
                  <ul className="ProductCateList-Size">
                    {item.Size &&
                      item.Size.map((size, ind) => {
                        return <li key={ind}>{size.toUpperCase()}</li>
                      })}
                  </ul>
                </div>
                <div className="ProductCateList-Rows">
                  <span>{item.colorCount} colors</span>
                  <div className="ProductCateList-Color">
                    {item.color &&
                      item.color.map((color, ind) => {
                        return (
                          <div
                            key={ind}
                            style={{ background: color }}
                          ></div>
                        )
                      })}
                  </div>
                </div>
              </div>

              <div className="ProductCateList-Mask">
                <Button
                  primary
                  fullWidth
                  onClick={() => handleConfugureDesign(item.id)}
                >
                  Design
                </Button>
                <Button
                  primary
                  fullWidth
                  onClick={() => handleViewDetail(item.id)}
                >
                  View detail
                </Button>
              </div>
            </Card>
          </div>
        ))}
    </div>
  )
}

export default ProductCateList
