import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { TextField, Tag, Stack, Autocomplete, TextContainer } from '@shopify/polaris'

const ProductOrganization = (props) => {
  const {
    productType,
    setProduct,
    // product,
    setCollectionsDataList,
    collectionsDataList,
    // setTags,
    getProductTypeValueFn,
  } = props
  const deselectedOptions = useMemo(() => {
    return productType
  }, [productType])
  // 产品类型
  const [selectedOptions, setSelectedOptions] = useState([])
  const [inputValue, setInputValue] = useState(props.product)
  const [optionsType, setOptions] = useState(deselectedOptions)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    let isMount = true
    if (isMount) {
      setInputValue(props.product)
    }
    return () => (isMount = false)
  }, [props.product])

  const updateText = useCallback(
    (value) => {
      if (inputValueRef.current === undefined) {
        inputValueRef.current = ''
      }
      setInputValue(value)
      setProduct(value)
      // props.getProductTypeValueFn(value)
      if (!loading) {
        setLoading(true)
      }
      setTimeout(() => {
        if (value === '') {
          setOptions(deselectedOptions)
          setLoading(false)
          // setProduct(product)
          return
        }
        const filterRegex = new RegExp(value, 'i')
        const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegex))
        setOptions(resultOptions)
        setLoading(false)
      }, 300)
    },
    // [deselectedOptions, loading, setProduct, product]
    [deselectedOptions, loading, setProduct]
  )
  const inputValueRef = useRef()
  const updateSelection = useCallback(
    (selected) => {
      const selectedText =
        selected !== undefined &&
        selected.map((selectedItem) => {
          const matchedOption = optionsType.find((option) => {
            return option.value.match(selectedItem)
          })
          return matchedOption && matchedOption.label
        })
      setSelectedOptions(selected)
      setInputValue(selectedText[0])
      inputValueRef.current = selectedText[0]
      getProductTypeValueFn(selectedText[0])
    },
    [optionsType, getProductTypeValueFn]
  )

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label="Type"
      value={inputValue}
      // placeholder="Search"
    />
  )

  const emptyState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </React.Fragment>
  )
  // 经销商
  const handleVendorChange = (newValue) => {
    props.setVendorData(newValue)
  }
  // 店铺分类

  const [inputValueCollection, setInputValueCollection] = useState('')
  const [optionsCollection, setOptionsCollection] = useState(props.collectionsData)
  // collection 选择
  const updateTextCollection = useCallback(
    (value) => {
      setInputValueCollection(value)
      if (value === '') {
        setOptionsCollection(props.collectionsData)
        return
      }
      const filterRegex = new RegExp(value, 'i')
      const resultOptions = props.collectionsData.filter((option) =>
        option.label.match(filterRegex)
      )

      setOptionsCollection(resultOptions)
    },
    [props.collectionsData]
  )

  const removeTagCollection = useCallback(
    (tag) => () => {
      const options = [...collectionsDataList]
      options.splice(options.indexOf(tag), 1)
      setCollectionsDataList(options)
      setInputValueCollection('')
    },
    [collectionsDataList, setCollectionsDataList]
  )
  const collectionsIdValue = collectionsDataList.join()
  const verticalContentMarkupCollection =
    collectionsIdValue !== '' ? (
      <Stack
        spacing="extraTight"
        alignment="center"
      >
        {props.collectionsData.map((option, ind) => {
          if (collectionsIdValue !== '' && collectionsIdValue.indexOf(option.value) !== -1) {
            return (
              <div
                style={{ marginTop: '5px' }}
                key={`option${ind}`}
              >
                <Tag onRemove={removeTagCollection(option)}>{option.label}</Tag>
              </div>
            )
          } else {
            return ''
          }
        })}
      </Stack>
    ) : null
  const textFieldCollection = (
    <div>
      <Autocomplete.TextField
        onChange={updateTextCollection}
        label="Collections"
        value={inputValueCollection}
        placeholder="collections"
      />
      {verticalContentMarkupCollection}
    </div>
  )

  return (
    <div>
      <Autocomplete
        options={optionsType}
        selected={selectedOptions}
        onSelect={updateSelection}
        emptyState={emptyState}
        loading={loading}
        textField={textField}
      />
      <br />
      <TextField
        label="Vendor"
        value={props.vendorData}
        onChange={(value) => handleVendorChange(value)}
        autoComplete="off"
      />
      <br />
      <Autocomplete
        allowMultiple
        options={props.collectionsData}
        selected={collectionsDataList}
        textField={textFieldCollection}
        onSelect={setCollectionsDataList}
      />
      <br />
    </div>
  )
}

export default ProductOrganization
