/*
 * @Author: huang-chao hc18174419502@163.com
 * @Date: 2022-11-17 17:14:47
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @LastEditTime: 2022-12-13 10:25:04
 * @FilePath: \pod-app-react\src\components\FavorableComm\components\StarRating.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import React from 'react'
import { useRef } from 'react'
import Star from './StarCom'

const StarRating = (props) => {
  // 分数显示
  const [rating, setRating] = React.useState(typeof props.rating == 'number' ? props.rating : 0)
  const ratingRef = useRef()

  const [selection] = React.useState(0)

  // 点击星星评分
  const starClick = (event) => {
    ratingRef.current = event.target.getAttribute('star-id')
    setRating(event.target.getAttribute('star-id') || rating)

    props.getStarCountFn(ratingRef.current)
  }

  return (
    <div
      className="starEl"
      // 点击选中星星
      onClick={(e) => starClick(e)}
    >
      {/* 创建5个星星组件 */}
      {Array.from({ length: 5 }, (s, i) => (
        <Star
          starId={i + 1}
          key={`star_${i + 1} `}
          marked={selection ? selection >= i + 1 : rating >= i + 1}
        />
      ))}
    </div>
  )
}
export default StarRating
