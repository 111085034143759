/*
 * @Description: 纵向Tabs
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2022-12-27 16:25:58
 * @LastEditTime: 2022-12-28 15:30:12
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\components\TabsVertical\index.jsx
 */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import './index.scss'

const TabsVertical = (props) => {
  const [currentIndex, setCurrentIndex] = useState(props.selected)
  const [tabs, setTabs] = useState([])
  const [panel, setPanel] = useState([])

  useEffect(() => {
    setCurrentIndex(props.selected)
    const tabs = _.find(props.children, (item) => {
      return item.key === 'Tabs'
    })
    if (tabs) {
      setTabs(tabs.props.children)
    }
    const panel = _.find(props.children, (item) => {
      return item.key === 'Panel'
    })
    if (panel) {
      setPanel(panel.props.children)
    }
  }, [props.selected])

  return (
    <div className="Tabs-Vertical">
      <div className="Tabs-Wrapper">
        <ul>
          {React.Children.map(tabs, (element, index) => {
            return React.cloneElement(element, {
              className: currentIndex === index ? 'active' : '',
              style: {
                color: currentIndex === index ? '#008060' : '',
              },
            })
          })}
        </ul>
      </div>
      <div className="Tabs-Panel">
        {React.Children.map(panel, (element) => {
          return (
            <div
              key={element.key}
              style={{
                display: String(currentIndex) === element.key ? 'block' : 'none',
                height: '100%',
              }}
            >
              {element}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TabsVertical
