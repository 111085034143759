/*
 * @Description: 设置 - 图库 - 为空时显示的内容
 * @Author: 黄广明 huangguangming_channelwill@aliyun.com
 * @Date: 2023-01-04 14:08:12
 * @LastEditTime: 2023-01-04 14:38:58
 * @LastEditors: 黄广明 huangguangming_channelwill@aliyun.com
 * @FilePath: \pod-app-react\src\pages\Setting\MyFiles\components\EmptyFiles\index.jsx
 */
import { Button } from '@shopify/polaris'
import './index.scss'

const EmptyFiles = ({ onUpload }) => {
  return (
    <div className="Empty-Files">
      <div className="Empty-Image">
        <img
          src="/images/setting/empty-files.png"
          alt=""
        />
      </div>
      <div className="Empty-Text">
        <h3>Upload and manage your files</h3>
        <p>Files can be images in JPG, JPEG, PNG</p>
      </div>
      <div className="Empty-Btn">
        <Button
          primary
          onClick={onUpload}
        >
          Upload files
        </Button>
      </div>
    </div>
  )
}

export default EmptyFiles
